import * as React from "react";
import { emptyArray, emptyString } from "common/utils/constants";
import countriesStates from "./countriesStates.json";

function CountryPicker(props: React.InputHTMLAttributes<HTMLSelectElement>) {
  const countriesOptions = React.useMemo(
    () =>
      countriesStates.countries.map((it) => (
        <option key={it.country} value={it.country}>
          {it.country}
        </option>
      )),
    emptyArray
  );
  return (
    // @ts-ignore
    <select {...props}>
      <option value={emptyString}>Select one</option>
      {countriesOptions}
    </select>
  );
}

export default CountryPicker;
