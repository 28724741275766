import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { useObjectVal } from "react-firebase-hooks/database";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import DatePicker from "react-date-picker";
import { pageSize } from 'common/utils/constants';
import { useUserInfo } from 'common/hooks/utils';
import "firebase/functions";

import { useDownloadClick, useFilterTransactions } from '../Report';

import { IFinalFilter } from '../Report/components/FilterForm'

import ExportToExcel from "./ExportToExcel";
import ExportToExcelFunction from "./ExportExcelFunction";

import { useMessages } from "config/messagesStateContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useHistory } from 'react-router'


import { useUserPermissions, useProcessorsName } from "common/hooks/firestore";


import Maybe from '../../common/components/Maybe';

import {
  emptyObject,
  today,
} from "common/utils/constants";
import { initCap } from "common/utils/fns";
import { buildBaseQuery } from "common/utils/firebase";
import ErrorMessage from "common/components/ErrorMessage";
import { IBatch, ITranx } from "types";
import { useAgentName, usePaginatedCollection } from "common/hooks/firestore";
import { batchFilesfolderName } from "common/utils/constants";
import UserBatchSelect from "./UserBatchSelect";
import Pagination from 'common/components/Pagination';

import ModalConfirmation from "../Report/ModalConfirmation";
// import { scanMyID } from "common/utils/fns";

export interface Recharges {
  rechargesCounter: number
}


function BatchItem(props: { item: IBatch }) {
  const { addSuccess } = useMessages();
  const [useData, setUseData] = React.useState<ITranx[]>([]);
  const maybeAccess = useUserPermissions();
  const [hiddenFilters, setHiddenFilters] = React.useState<IFinalFilter | undefined>({
    filterBy: "values",
    batchId: props.item.id
  });
  const agentName = useAgentName(props.item.createdBy);

  // from downloadUnfinnishedBatch -->
  const onBatchRecharge = firebase.functions().httpsCallable("onBatchRecharge");
  const onBatchRechargeDeclined = firebase.functions().httpsCallable("onBatchRechargeDeclined");

  const [clients, processors, isAdmin, fullPathProcessors, processorsClientTruncated] = useUserInfo(undefined);

  const [confirmVisible, setConfirmVisible] = React.useState<boolean>(false);
  const history = useHistory()
  let table: ITranx[];

  const getResponse = (trx: ITranx) => {
    switch (trx.processorType) {
      case 'azul':
        return `${trx?.resp?.ResponseMessage} ${trx?.resp?.ErrorDescription && `:${trx?.resp?.ErrorDescription}`}`;
      case 'trinity':
        return `${trx?.resp?.bank_message}`
      case 'resortcom':
        return `${trx?.resp?.CardAuthorizationStatusDescription}`
      default:
        return '';
    }
  }

  const getExcel = async (id: any) => {
    let myIbatchData: ITranx[] = [];
    let dataTrx = await firebase.firestore().collectionGroup("trxs").
      where("batchId", '==', id)
      .get()
    dataTrx.docs.forEach(doc1 => {
      myIbatchData.push(doc1.data() as ITranx)
    });

    table = myIbatchData.filter((trx) => { return trx.type !== 'refund' });

    const dataToBeExported = table.map(row => ({
      'Invoice #': row.invoiceNumber,
      'Payment Amount': row.paymentAmount,
      'Cardholder name': row.cardholderName,
      'Card No.': `${row.cardNumber}******${row.ccLast4}`,
      'Exp Date': row.expDate,
      'CVV': '***',
      'Account Number': row.orderNumber,
      'Billing Address': row.billingAddress.addressOne,
      // 'Subscription ID': row.recurrent_resp_token,
      'Authorization code': row.authCode,
      'Reference': row.descr,
      'Status': getResponse(row),
      // 'Recharges #': row.rechargesCounter
    }))

    ExportToExcelFunction({ DataArr: dataToBeExported, file: id })
  }
  const isThereTableData = props.item?.modalViewInfo



  // const tableData = isThereTableData ? getTableData() : []
  let tempNotApproved: string[] = []
  if (isThereTableData) {
    tempNotApproved = [...props.item?.modalViewInfo].filter(r => r.recurrent_resp_token === "not approved").map(r => r.invoiceNumber).sort((a, b) => a?.localeCompare(b))
  }


  const onBatchRechargeWithTrampoline = firebase.functions().httpsCallable("onBatchRechargeWithTrampoline");

  const makeBatchRecharge = async () => {
    console.log('makeBatchRecharge called: ', props.item.id)
    addSuccess('batch is being reprocessed, this might take a while');
    onBatchRechargeWithTrampoline({ batchID: props.item.id })
      .then(it => {
        setTimeout(() => {
          history.replace({ pathname: '/batch' })
        }, 2000);

        console.log('fulfilled: ', it)
      })
      .catch(err => { console.log(err) })
  }

  const onBatchRechargeUpdated = firebase.functions().httpsCallable("onBatchRechargeUpdated");

  const makeBatchRechargev2 = async () => {
    console.log('makeBatchRechargev2 called: ', props.item.id)
    addSuccess('batch is being reprocessed, this might take a while');
    onBatchRecharge({ batchID: props.item.id })
      .then(it => {
        setTimeout(() => {
          history.replace({ pathname: '/batch' })
        }, 2000);

        console.log('fulfilled: ', it)
      })
      .catch(err => { console.log(err) })
    setConfirmVisible(false);
  }

  const rechargeDeclinedOnly = async () => {
    console.log('rechargeDeclinedOnly called: ', props.item.id)
    addSuccess('reprocessing declined transactions only, please wait!');
    onBatchRechargeDeclined({ batchID: props.item.id })
      .then(it => {
        setTimeout(() => {
          history.replace({ pathname: '/batch' })
        }, 2000);

      })
      .catch(err => { console.log(err) })
  }

  const ArrayEquals = (a: string[], b: string[]) => a.length === b.length && a.every((v, i) => v === b[i]);

  const declinedList = typeof props.item?.declinedList === "undefined" ? [] : props.item?.declinedList;

  const originalDeclinedList = ArrayEquals(declinedList, [""]) ? [] : [...declinedList];

  const tempDeclinedList = originalDeclinedList.sort((a, b) => a == '' ? a?.localeCompare(b) : 0)

  // const rechargeDeclinedOnlyBoolean = ArrayEquals(tempDeclinedList, tempNotApproved) 
  const rechargeDeclinedOnlyBoolean = props.item.declined > 0 ? true : false;


  const keysOfProcessors = useProcessorsName();
  const [hiddenFilter, setHiddenFilter] = React.useState<IFinalFilter | undefined>({
    filterBy: "values", batchId: props.item.id
  });
  const [url] = useDownloadURL(
    props.item.status === "processing"
      ? null
      : firebase.storage().ref(batchFilesfolderName + "/" + props.item.fileName)
  );
  const {
    items,
    loading,
    error,
    onBackClick,
    onNextClick,
    isBackAvailable,
    pageSize,
    getFiltered,
  } = useFilterTransactions(hiddenFilter);

  // const [excelButtonText, isExcelLoading, onExcelClick] = useDownloadClick(
  //   "Export Excel",
  //   "excel",
  //   getFiltered,
  //   keysOfProcessors
  // );
  return (
    <>
      <tr>
        <td>{agentName}</td>
        <td>{props.item.id}</td>
        <td>{props.item.createdAt?.toDate().toLocaleDateString()}</td>
        <td title={props.item.error}>
          {initCap(props.item.status) ?? "Processing"}
        </td>
        <td>{props.item.totalRows ?? "..."}</td>
        <td>{props.item.approved ?? 0}</td>
        <td>{props.item.declined ?? 0}</td>
        <td>{props.item.amountProcessed?.toLocaleString() ?? 0}</td>
        <td>{props.item.errors ?? 0}</td>
        {/* <td>
        <a
          target="_blank"
          rel="noreferrer"
          href={url}
          className={props.item.ready ? undefined : "hidden"}
        >
          <img src="/assets/images/icons/Download.svg" alt="" />
        </a>
        <Maybe component={'span'} visible={!props.item.ready} >
            <div style={{cursor:'pointer'}} onClick={onExcelClick}> <img src="/assets/images/icons/Download.svg" alt="" /></div>
        </Maybe>
      </td> */}
        <td>
          {
            props.item.status == "processing"
              ? <></>
              :
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Maybe component={Link} visible={typeof props.item?.recurrentProcessorType !== 'undefined'} to={`/batch/BatchTableModal/${props.item.id}`}>
                    <img alt="View" src="/assets/images/icons/eye.svg" />
                  </Maybe>
                  {isThereTableData ?
                    <a
                      onClick={(e) => { getExcel(props.item.id) }}
                      target="_blank"
                      rel="noreferrer"
                      className={undefined}
                    >
                      <img src="/assets/images/icons/Download.svg" alt="" style={{ cursor: 'pointer' }} />
                    </a> : null

                  }


                  {/* QUITAR OPCIONES HASTA QUE SE PONGAN A FUNCIONAR POR COMPLETO */}
                  <div
                    // onClick={() => makeBatchRecharge()}
                    style={{ cursor: 'pointer' }}
                  >
                    {/* {props.item?.recurrentProcessorType
                      ? <FontAwesomeIcon icon={['fas', 'reply-all']} style={{ fontSize: '17px', color: '#0133ad' }} /> : undefined} */}
                  </div>

                  <div
                    // onClick={rechargeDeclinedOnly}
                    style={{ cursor: 'pointer' }}
                  >
                    {/* {rechargeDeclinedOnlyBoolean
                      ? <FontAwesomeIcon icon={['fas', 'recycle']} style={{ fontSize: '17px', color: '#0133ad' }} /> : undefined} */}
                  </div>


                </div>

              </>

          }
        </td>
      </tr>
      {/* <ModalConfirmation
      visible={confirmVisible}
      handleClose={() => setConfirmVisible(false)}
      operation="Reprocess"
      newInvoiceNumber={props.item.id}
      onSubmit={() => makeBatchRechargev2()}
    /> */}
    </>
  );
}

function BatchTableBody(props: { items: IBatch[]; loading: boolean }) {
  if (props.loading) {
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  }
  return (
    <>
      {props.items.map((it) => (
        <BatchItem key={it.id} item={it} />
      ))}
    </>
  );
}

interface IFilterValues {
  createdBy?: string;
  id?: string; // batch
  createdAt?: string;
  status?: string;
  recurrentsOn?: boolean;
}

interface IFilterFormProps {
  onSubmit: (values: IFilterValues) => void
}
function FilterForm(props: IFilterFormProps  ) {
  function onSubmit(values: IFilterValues) {
    console.log("FilterForm onSubmit values", values);
    props.onSubmit(values);
  }
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <form className="type-search filter-box" onSubmit={handleSubmit}>
          <div className="type-search-content form-two-column">
            <div>
              <div className="form-group form-inline">
                <label htmlFor="">Uploaded by</label>
                <UserBatchSelect  name="createdBy" />
              </div>
              <div className="form-group form-inline">
                <label htmlFor="">ID</label>
                <Field
                  component="input"
                  allowNull
                  name="id"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="second-column">
              <div className="form-group form-inline">
                <label htmlFor="">Date</label>
                <Field
                  allowNull
                  name="createdAt"
                  render={({ input }) => (
                    <DatePicker
                      onChange={input.onChange}
                      value={input.value}
                      className="form-control"
                      maxDate={today}
                    />
                  )}
                />
              </div>
              <div className="form-group form-inline">
                <label htmlFor="">Status</label>
                <Field
                  component="select"
                  allowNull
                  name="status"
                  className="form-control"
                >
                  <option value="">Select status</option>
                  <option value="processed">Processed</option>
                  <option value="processing">Processing</option>
                  <option value="error">Error</option>
                </Field>
              </div>
            </div>
          </div>
          <div className="form-buttons">
            <button
              type="reset"
              className="btn btn-secondary"
              onClick={() => form.reset()}
            >
              Clear All
            </button>
            &nbsp;&nbsp;
            <button type="submit" className="btn btn-primary">
              Search
            </button>
          </div>
        </form>
      )}
    />
  );
}

const localTimezoneOffset = 4 * 60 * 60 * 1000; // 4 hours
const dayInMs = 24 * 60 * 60 * 1000;
function buildQueryFilters(
  filters: IFilterValues,
  clients: string[][],
  processors: string[],
  processorsClientTruncated: string[][],
  isAdmin: boolean
) {
  const pq0 = buildBaseQuery(
    "batchs",
    isAdmin,
    clients,
    processors,
    processorsClientTruncated,
    firebase.firestore(),
    true
  );
  const pq1 = filters.createdBy
    ? pq0.where("createdBy", "==", filters.createdBy)
    : pq0;
  const pq2 = filters.id ? pq1.where("id", "==", filters.id) : pq1;
  const pq3 = filters.status ? pq2.where("status", "==", filters.status) : pq2;
  const pq4 = filters.createdAt
    ? pq3
      .where(
        "createdAt",
        ">=",
        firebase.firestore.Timestamp.fromMillis(
          new Date(filters.createdAt).getTime() - localTimezoneOffset
        )
      )
      .where(
        "createdAt",
        "<",
        firebase.firestore.Timestamp.fromMillis(
          new Date(filters.createdAt).getTime() +
          dayInMs -
          localTimezoneOffset
        )
      )
    : pq3;
  return manageRecurrentPermit(pq4, filters.recurrentsOn ?? false)
  // return pq4.orderBy("createdAt", "asc");
}

const manageRecurrentPermit = (
  prequeryX: firebase.firestore.Query<firebase.firestore.DocumentData>,
  isRecurrent: boolean
) => {
  console.log('manageRecurrentPermit: ', isRecurrent)
  if (isRecurrent) {
    return prequeryX.orderBy("createdAt", "desc");
  } else {
    return prequeryX.orderBy("createdAt", "desc"); //JC 
  }
}

function BatchPage() {
  const [clients, processors, isAdmin, fullPathProcessors, processorsClientTruncated] = useUserInfo(undefined);
  const maybeAccess = useUserPermissions();
  const [viewRecurrents, setViewRecurrents] = React.useState<boolean | undefined>(maybeAccess?.recurrentView)
  const [filters, setFilters] = React.useState<IFilterValues>({ recurrentsOn: !!viewRecurrents });
  const [shouldRefreshValue] = useObjectVal<number>(
    firebase.database().ref("should-refresh/batchs")
  );

  const tbodyKey = React.useMemo(
    () => (shouldRefreshValue ?? Date.now()).toString(),
    [shouldRefreshValue]
  );

  
  const filteredQuery = React.useMemo(() => buildQueryFilters(
    filters,
    clients,
    processors,
    processorsClientTruncated,
    isAdmin
  ), [filters, clients, processors, processorsClientTruncated, isAdmin]);




  let { page, items, error, loading, onBackClick, onNextClick, onReset } = usePaginatedCollection<IBatch>(filteredQuery, pageSize, processors.length > 0 ? true : false);
  
  if (!viewRecurrents) {
    items = items.filter(it => !!!it.recurrentProcessorType)
  }

  function onSubmit(values: IFilterValues) {
    setFilters(values);
    onReset();
  }
  return (
    <section className="main-content">
      {/* <!------------------ file upload section -------------------------- --> */}
      <div
        id="recurrent-payments"
        className="card tab-pane fade show active grid-item"
        role="tabpanel"
      >
        {/* <!-- head-component --> */}
        <div className="head grid-item">
          <h5>Batch Processing</h5>
          <Link
            to="/batch/upload"
            className="btn"
            data-toggle="modal"
            data-target="#cms-modal"
          >
            <img src="/assets/images/icons/Upload.svg" alt="" /> Upload file
          </Link>
        </div>
        {/* <!-- option-box-compoenent --> */}
        <div className="grid-item">
          <h6>Search by</h6>
          <FilterForm   onSubmit={onSubmit}  />
          <ErrorMessage text={error} />
        </div>
        {/* <!--how to read the buttons section --> */}
        <div style={{ display: 'flex', }}>
          {/* <div>
            <img src="/assets/images/icons/Download.svg" alt="" />
            <span style={{ color: 'black', marginLeft: '5px' }}>Download batch report</span>
          </div> */}

          {/* QUITAR HASTA QUE FUNCIONE POR COMPLETO */}
          {/* <div style={{ marginLeft: '10px' }}>
            <FontAwesomeIcon icon={['fas', 'reply-all']} style={{ fontSize: '17px', color: '#0133ad' }} />
            <span style={{ color: 'black', marginLeft: '5px' }}>Reprocess all</span>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <FontAwesomeIcon icon={['fas', 'recycle']} style={{ fontSize: '17px', color: '#0133ad' }} />
            <span style={{ color: 'black', marginLeft: '5px' }}> Reprocess only declined transactions</span>
          </div> */}

        </div>
        {/* <!-- table-component --> */}
        <table className="table text-center">
          <thead>
            <tr>
              <td className="twelve">Uploaded by</td>
              <td className="eight">ID</td>
              <td className="ten">Date</td>
              <td className="twelve">Status</td>
              <td className="ten">Transactions</td>
              <td className="ten">Approved</td>
              <td className="ten">Declined</td>
              <td className="ten">Amount Processed</td>
              <td className="ten">Errors</td>
              <td className="eight">Actions</td>
            </tr>
          </thead>
          <tbody>
            {/* <!-- table-row-component --> */}



            {
              
               (typeof items !== 'undefined' && items.length === 0)
                  ? <tr><td colSpan={10}>unsuccessful search, please try other parameters</td></tr>
                  : <BatchTableBody key={tbodyKey} items={items} loading={loading} />
              // (processors.length > 0)
              // ? (typeof items !== 'undefined' && items.length === 0)
              //     ? <tr><td colSpan={10}>unsuccessful search, please try other parameters</td></tr>
              //     : <BatchTableBody key={tbodyKey} items={items} loading={loading} />
              // : <tr><td colSpan={10}>unsuccessful search, please try other parameters</td></tr>
            }
          </tbody>
        </table>

        {/* <!-- pagination-component --> */}
        <Pagination
          page={page}
          pageSize={pageSize}
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          itemsLength={items.length}
        />
      </div>
    </section>
  );
}

export default BatchPage;
