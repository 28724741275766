export const emptyObject = Object.freeze({});
export const emptyArray = Object.freeze([]) as never[];
export const emptyString = "";
export const MESSAGE_ATTR = "message";
export const activeStr = "active";
export const unit = () => {};
export const today = new Date();
export const currencyFormatConfig = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: "currency",
  currency: "USD",
};

export const optionsByIdField = { idField: "id" };
export const valueSubsctiption = { subscription: { value: true } };
export const submitingSubscription = { submitting: true };

export const batchFilesfolderName = "batch-files";
export const attachmentsFolderName = "attachments";

export const pageSize = 10;
