import React from 'react';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { useHistory } from 'react-router-dom';
import { ITranx } from "types";
import { compose, getAttachmentFile, getMessage, lazyApply } from 'common/utils/fns';
import { buildFileName } from 'common/utils/fns';
import { uploadFile } from "common/utils/firebase";
import {
  attachmentsFolderName,
} from "common/utils/constants";
import { useMessages } from 'config/messagesStateContext';

interface IProps {
  state: ITranx,
  onClose: () => any,
}

function LoadingBtnContent() {
  return (
    <div className="loading-spin-wrapper">
      <div className="loading-spin">
        <div></div>
      </div>
    </div>
  );
}

function UploadForm(props: IProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { addError, addSuccess } = useMessages();
  const btnClassName = isLoading ? 'btn btn-primary has-loading' : 'btn btn-primary';
  const btnContent = isLoading
    ? <LoadingBtnContent />
    : 'Submit'; 
  const onSubmit = React.useCallback((ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const file = getAttachmentFile();
    // if (!file.name) return addError('Please, select a file');
    const { invoiceNumber, client } = props.state;
    const setTrxFileName = firebase
      .functions()
      .httpsCallable("setTrxFileName");
    const fileName = buildFileName(invoiceNumber, file);
    function onSuccess(result: any) {
      console.log('UploadForm setTrxFileName', result);
      addSuccess('Upload completed!');
      sessionStorage.setItem("shouldRefreshReport", "yes");
      props.onClose();
    }
    setIsLoading(true);
    uploadFile(attachmentsFolderName, invoiceNumber, file)
      .then(() => setTrxFileName({ fileName, client, invoiceNumber }))
      .then(onSuccess, compose(addError, getMessage))
      .then(lazyApply(setIsLoading, false));
  }, [props.state]);
  return (
    <form name="payment" onSubmit={onSubmit}>
      <fieldset disabled={isLoading}>
        <div className="form-group has-icon">
          <label htmlFor="attachment">Upload file</label>
          <div>
            <img src="/assets/images/icons/Upload.svg" alt="" />
            <input required name="attachment" type="file" title="Upload file" />
          </div>
        </div>
      </fieldset>
      <div className="form-footer">
        <button
          type="submit"
          className={btnClassName}
        >
          {btnContent}
        </button>
      </div>
    </form>
  )
}

export default UploadForm;
