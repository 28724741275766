import * as React from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import Modal from 'common/components/Modal';
import { ITranx } from 'types';
import VoidInput from './VoidInput';

function VoidModal() { 
  const { state } = useLocation<ITranx|undefined>();
  const history = useHistory();
  const onClose = history.goBack;
 
  if (!state) {
    return <Redirect to="/" />;
  }
  const title = `Void Payment ${state.invoiceNumber}`;
  return (
    <Modal
      name="void"
      title={title}
      onClose={onClose}
    >
      <VoidInput
        isModal={true}
        state={state}
        onClose={onClose}
      />
    </Modal>
  );
}

export default VoidModal;
