import React from "react";
import {
  Form,
  FormRenderProps,
  Field,
  FieldRenderProps,
} from "react-final-form";
import { submitingSubscription } from "common/utils/constants";
import ProcessorSelect from "common/components/ProcessorSelect";
import DateRangePickerInput from "common/components/DateRangePicker";
import { IAccess } from "../../../types";
import Maybe from "common/components/Maybe";

interface IFilter {
  filterBy: string;
}
interface IFilterById extends IFilter {
  filterBy: "id";
  invoiceNumber: string;
}
interface IFilterByValues extends IFilter {
  filterBy: "values";
  invoiceNumber?: string;
  merchant?: string;
  name?: string;
  orderNumber?: string;
  daterange?: [Date, Date];
  status?: string;
  paymentAmount?: string;
  cardholderName?: string;
  creditCard?: string;
  cardFirstDigits?: string;
  cardLastFour?: string;
  batchId?: string;
  disputestatus?: string;
  disputeOperationType?: string;
  subscriptionId?: string;
  distype?: string;
}
export type IFinalFilter = IFilterById | IFilterByValues;
interface IFormProps {
  initialValues: IFinalFilter | undefined;
  onSubmit: (values: IFinalFilter) => void;
  access: IAccess | undefined;
}

function ReportForm(props: IFormProps) {
  console.log("ReportForm - IFormProps: ", props);
  const initialVals = { filterBy: "values" } as IFinalFilter;

  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={initialVals}
      // initialValues={props.initialValues as IFinalFilter}
      // subscription={submitingSubscription}
    >
      {({ handleSubmit, form }: FormRenderProps<IFinalFilter>) => (
        <form
          action="seachByType"
          className="search-by-type"
          onSubmit={handleSubmit}
        >
          <div className="type-search">
            <div className="form-group">
              <Field
                component="input"
                type="radio"
                name="filterBy"
                id="findBy"
                value="id"
                onClick={() => form.reset(undefined)}
              />
              <label htmlFor="findBy">Find by</label>
            </div>
            <div
              className="type-search-content "
              style={{ marginLeft: "40px" }}
            >
              <div className="form-group form-inline">
                <label htmlFor="invoiceId">Invoice #</label>
                <Field
                  component="input"
                  name="invoiceNumber"
                  className="form-control"
                  type="text"
                  id="invoiceId"
                />
              </div>
            </div>
          </div>
          <div className="type-search">
            <div className="form-group">
              <Field
                component="input"
                type="radio"
                name="filterBy"
                id="searchBy"
                value="values"
                onClick={() => form.reset(undefined)}
              />
              <label htmlFor="searchBy">Search by</label>
            </div>
            <div className="type-search-content form-two-column">
              <div>
                <div className="form-group form-inline">
                  <label htmlFor="merchant">Merchant</label>
                  <Field name="merchant">
                    {({ input }: FieldRenderProps<string>) => (
                      <ProcessorSelect
                        {...input}
                        className="form-control"
                        id="merchant"
                        placeholder="All Merchants"
                      />
                    )}
                  </Field>
                  {/* <Field 
                        name="merchant"
                        className="form-control"
                        id="merchant"
                        placeholder="All Merchants"
                        component={ProcessorSelect} /> */}
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="clientName">Client</label>
                  <Field
                    component="input"
                    className="form-control"
                    type="text"
                    id="clientName"
                    name="name"
                    placeholder="Client name"
                  />
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="orderNumber">Account #</label>
                  <Field
                    component="input"
                    className="form-control"
                    type="text"
                    id="orderNumber"
                    name="orderNumber"
                    placeholder="Account #"
                  />
                </div>
                <div className="form-group form-inline">
                  <label>Date</label>
                  <DateRangePickerInput />
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="batchId">Batch ID</label>
                  <Field
                    component="input"
                    className="form-control"
                    type="text"
                    id="batchId"
                    name="batchId"
                  />
                </div>
                {/* <Maybe component={'div'} visible={!!props.access?.dispute || !!props.access?.disputeView}>
                <div className="form-group form-inline">
                    <label htmlFor="disputestatus">Dispute status</label>
                  <Field name="disputestatus" component="select" className="form-control" id="disputestatus" type="select">
                    <option value="" >-</option>
                    <option value="disputing">Disputing</option>
                    <option value="ending">Pending</option>
                    <option value="accepted">Accepted</option>
                    <option value="closed">Closed</option>
                    <option value="lost">LOST</option>
                    <option value="won">WON</option>
                    <option value="expired">Expired</option>
                    <option value="All">All status</option>
                  </Field>
                  </div>
                  </Maybe> */}
              </div>
              <div className="second-column">
                <div className="form-group form-inline">
                  <label htmlFor="status">Status</label>
                  <Field
                    component="select"
                    type="select"
                    className="form-control"
                    name="status"
                    id="status"
                  >
                    <option value="">-</option>
                    <option value="A">Approved</option>
                    <option value="D">Declined</option>
                    <option value="V">Void</option>
                    <option value="E">Incomplete</option>
                    <option value="U">Upload</option>
                  </Field>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="paymentAmount">Amount</label>
                  <Field
                    component="input"
                    className="form-control"
                    type="number"
                    id="paymentAmount"
                    name="paymentAmount"
                    step={0.01}
                  />
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="cardholderName">Card Holder Name</label>
                  <Field
                    component="input"
                    className="form-control"
                    type="text"
                    id="cardholderName"
                    name="cardholderName"
                  />
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="creditCard">Credit Card</label>
                  <Field
                    component="input"
                    minLength={10}
                    className="form-control"
                    type="text"
                    id="creditCard"
                    name="creditCard"
                  />
                </div>

                {/* <div className="form-group form-inline">
                  <label htmlFor="subscriptionId">Subscription ID</label>
                  <Field
                    component="input"
                    className="form-control"
                    type="text"
                    id="subscriptionId"
                    name="subscriptionId"
                  />
                </div> */}

                <div className="two-inputs">
                  <div className="form-group form-inline">
                    <label htmlFor="cardFirstDigits">Card First 6 Digits</label>
                    <Field
                      component="input"
                      className="form-control"
                      type="number"
                      minLength={6}
                      maxLength={6}
                      id="cardFirstDigits"
                      name="cardFirstDigits"
                    />
                  </div>

                  <div className="form-group form-inline">
                    <label htmlFor="cardLastFour">And Last 4</label>
                    <Field
                      component="input"
                      className="form-control"
                      type="number"
                      minLength={4}
                      maxLength={4}
                      id="cardLastFour"
                      name="cardLastFour"
                    />
                  </div>
                </div>

                {/* <Maybe component={'div'} visible={!!props.access?.dispute || !!props.access?.disputeView}>
                <div className="form-group form-inline">
                    <label htmlFor="disputeOperationType">Operation type</label>
                  <Field name="disputeOperationType" component="select" className="form-control" id="disputeOperationType" type="select">
                    <option value="" >-</option>
                    <option value="C">Chargeback</option>
                    <option value="I">Inquiry</option>
                    <option value="PA">Pre-arbitration</option>
                    <option value="All">All status</option>
                  </Field>
                  </div>
                  </Maybe>
                  <Maybe component={'div'} visible={!!props.access?.dispute || !!props.access?.disputeView}>
                      <div className="form-group form-inline">
                      <label htmlFor="distype">Dispute type</label>
                      <Field name="distype" component="select" className="form-control" id="distype" type="select">
                        <option value="unselected" >-</option>
                        <option value="RE">Reversal</option>
                        <option value="DE">Debit</option>   
                        <option value="CL">Claim</option>         
                        <option value="All">All type</option>         
                      </Field>

                      </div>
                  </Maybe> */}
              </div>
            </div>
          </div>

          <div className="form-buttons">
            <button className="btn btn-primary" type="submit">
              Search
            </button>
            <button
              type="reset"
              className="btn btn-secondary"
              onClick={() => form.reset()}
            >
              Clear All
            </button>
          </div>
        </form>
      )}
    </Form>
  );
}

export default ReportForm;
