import * as React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { emptyArray, emptyString, optionsByIdField } from 'common/utils/constants';
import { ICombinedProcessor, IProcessor } from 'types';
import { useMessages } from 'config/messagesStateContext';

interface IProps {
  client0: string,
  client1: string,
  merchantID: string|undefined,
  placeholder?: string,
  required?: boolean,
  onChange(merchantID: string|undefined): void,
  type: 'typed' | 'combined' | 'any',
}

function ProcessorSelect(props: IProps) {
  const { client0, client1, merchantID, onChange, type, required } = props;
  
  const query = React.useMemo(() => {
      const query = firebase.firestore()
        .doc(`clients/${client0}/clients/${client1}`)
        .collection('processors');
        
      return type === 'combined' || type === 'typed'
        ? query.where('type', type === 'combined' ? '==' : '!=', 'combined')
        : query;
    },
    [client0, client1, type]
  );
  const [processors, isLoading, error] = useCollectionDataOnce<IProcessor>(query, optionsByIdField);
  const placeholder = isLoading ? 'Loading...' : props.placeholder!;
  const processorsOptions = React.useMemo(() => processors?.map((it) => (
    <option key={it.id} value={it.id}>
      {[it.id, it.name, it.store, it.type].filter(Boolean).join(' | ')}
    </option>
  )), [processors]);
  const { addError } = useMessages();
  React.useEffect(() => {
    if (error) {
      addError(error.message);
    }
  }, [error]);
 
  const onLocalChange = React.useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => onChange(ev.target.value), [onChange]);
  return (
    <select
      className="form-control merchant-select"
      onChange={onLocalChange}
      value={merchantID}
      required={required}
    >
      <option value={emptyString}>{placeholder}</option>
      {processorsOptions}
    </select>
  );
}

ProcessorSelect.defaultProps = {
  placeholder: 'Add New Processor',
}

export default ProcessorSelect;
