import * as React from 'react';
import 'firebase/firestore';
import { useField } from 'react-final-form';
import { emptyString } from 'common/utils/constants';

interface IProps {
  ccType: string,
  required?: boolean,
}

function ConditionalProcessorSelect(props: IProps) {
  const { ccType, required } = props;
  const { input } = useField<string>(ccType);
  const { input: { value: azul } } = useField('pt.azul');
  const { input: { value: resortcom } } = useField('pt.resortcom');
  const { input: { value: trinity } } = useField('pt.trinity');
  const validProcessors = React.useMemo(() => [
      azul ? 'azul' : null,
      resortcom ? 'resortcom' : null,
      trinity ? 'trinity' : null,
    ].filter(Boolean) as string[],
    [azul, resortcom, trinity]
  );
  const onLocalChange = React.useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => input.onChange(ev.target.value), [input.onChange]);
  const options = React.useMemo(() => validProcessors.map(
    it => <option key={it} value={it}>{it[0].toUpperCase()}{it.slice(1)}</option>
  ), [validProcessors]);
  React.useEffect(() => {
    if (!validProcessors.includes(input.value)) {
      input.onChange(emptyString);
    }
  }, [validProcessors, input.value]);
  return (
    <td>
      <select
        className="form-control"
        value={input.value}
        onChange={onLocalChange}
        required={required}
      >
        <option value={emptyString}>Select one</option>
        {options}
      </select>
    </td>
  );
}

ConditionalProcessorSelect.defaultProps = {
  placeholder: 'Add New Processor',
}

export default ConditionalProcessorSelect;
