import * as React from 'react';
import Maybe from './Maybe';
import { useMessages } from 'config/messagesStateContext';
import { emptyString, activeStr } from 'common/utils/constants';

function getHead<T>(xs: T[]): T | undefined {
  return xs.length > 0 ? xs[0] : undefined
}

function Messages() {
  const { messages } = useMessages();
  const msg = getHead(messages);
  const imgStr = msg?.type === 'success'
    ? "/assets/images/status/success.svg"
    : msg?.type === 'error' 
    ? "/assets/images/status/error.svg"
    : undefined;
  const mainClassName = `pop-message card ${msg ? activeStr : emptyString}`;
  return (
    <div className={mainClassName}>
      <div className="pop-message-content">
        <img className="message-icon" src={imgStr} alt={msg?.type} />
        <p>{msg?.text}</p>
      </div>
    </div>
  );
}

export default Messages;
