import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { ITranx, IKeysOfStr, IAccess } from "types";
import ErrorMessage from "common/components/ErrorMessage";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  emptyArray,
  currencyFormatConfig,
  pageSize,
} from "common/utils/constants";
import {
  lazyApply,
  getMessage,
  compose,
  getStatusTextClass,
  idReduce,
  buildQueryStr,
  exportExcelContent,
  exportCSVContent,
  getCCName,
  runPromiseSeq,
  getRefundableAmount,
  isVoidAvailableAtTime,
  initCap,
  getSearchEndValue,
  scanMyID,
  addDays,
  roundDate,
} from "common/utils/fns";
import { buildBaseQuery } from "common/utils/firebase";
import Maybe from "common/components/Maybe";
import {
  useClientName,
  useAgentName,
  useProcessorsName,
  useUserPermissions,
  useSubMerchantName,
} from "common/hooks/firestore";
import { useMessages } from "config/messagesStateContext";
import ReportForm, { IFinalFilter } from "./components/FilterForm";
import {
  getProcessorTitle,
  getPlainRow,
  getPlainRowToAccountPayable,
} from "./utils";
import { useUserInfo } from "common/hooks/utils";
import { link } from "fs";
import { Recharges } from "../Batch/index";
import moment from "moment";
import { useGlobalState } from "config/globalStateContext";
import { IProcessor } from "types";

function TranxItem(props: {
  item: ITranx;
  keysOfProcessors: IKeysOfStr;
  access: IAccess;
}) {
  //const history = useHistory();

  if (props.item.invoiceNumber === "KWQUYTB0") {
    console.log("KWQUYTB0 ====>", props.item);
  }

  const to = {
    pathname: `/report/detail/${props.item.id}`,
    state: props.item,
  };
  const toRefund = {
    pathname: `/report/refund/${props.item.id}`,
    state: props.item,
  };
  const toRecharge = {
    pathname: `/report/recharge/${props.item.id}`,
    state: props.item,
  };
  const toVoid = {
    pathname: `/report/void/${props.item.id}`,
    state: props.item,
  };

  const toDispute = {
    pathname: `/report/dispute`,
    state: props.item,
  };

  const toDisputeReadOnly = {
    pathname: `/report/disputeReadOnly`,
    state: props.item,
  };

  const wichDispute = props.access?.dispute;

  console.log("props.item:::debug", props.item);
  const [statusText, statusClass, trnsType] = getStatusTextClass(
    props.item.type,
    props.item.status
  );
  const disputesList = props.item?.disputesList;
  // const disputeIconColor = !props.item.disputeData || !!!props.item.disputesList
  const disputeIconColor =
    typeof props.item.disputesList !== "undefined" &&
    props.item.disputesList.length > 0;
  // && !(typeof disputesList !== 'undefined' && disputesList.length === 0)
  if (props.item.invoiceNumber === "KR6PBB1T-2-0") {
    console.log("tranxItem item KR6PBB1T-2-0: ", props.item);
    console.log("tranxItem item KR6PBB1T-2-0 icon color: ", disputeIconColor);
  }
  const clientName = useClientName(props.item.client);
  const agentName = useAgentName(props.item.createdBy) ?? "...";

  const processor = props.item.altProcessor ?? props.item.processor;

  const subMerchantName = useSubMerchantName(
    props.item.client,
    props.item.processor,
    props.keysOfProcessors
  );
  const urlStr = React.useMemo(
    () => buildQueryStr(props.item, clientName, agentName, subMerchantName),
    [props.item, clientName, agentName, subMerchantName]
  );
  const refundableAmount = getRefundableAmount(props.item);
  const toPrintVersion = {
    pathname: `/report/${props.item.id}/print-version`,
    state: { url: urlStr, item: props.item },
  };

  const isSaleOrRechargeApproved =
    (props.item.type === "sale" ||
      props.item.type === "recharge" ||
      props.item.type === "recurrent") &&
    props.item.status === "A";
  const shouldRefundBeVisible =
    isSaleOrRechargeApproved && refundableAmount > 0 && !!props.access?.refund;
  const isTrinity = props.item.processorType === "trinity";
  const isAzul = props.item.processorType === "azul";
  const isRecurrentAzul =
    props.item.processorType === "azul" && !!props.item?.recurrent_resp_token;
  const shouldRechargeBeVisible =
    isSaleOrRechargeApproved &&
    (isTrinity || isRecurrentAzul) &&
    !!props.access?.recharge;

  console.log(
    "trnsType",
    !!!props.access?.dispute &&
      !!props.access?.disputeView &&
      statusText !== "Void"
  );

  const createdAt = props.item.createdAt?.toDate?.();
  // if(props.item.entryStatus === 'U' || props.item.statusRefund && props.item.statusRefund === 'U' ){
  //   createdAt.setDate(createdAt.getDate() + 1);
  // }

  const shouldVoidBeVisible =
    !!props.access?.void &&
    isSaleOrRechargeApproved &&
    refundableAmount > 0 &&
    !!createdAt &&
    isVoidAvailableAtTime(createdAt);
  //&& (isTrinity || isAzul);

  if (props.item.currency) {
    currencyFormatConfig.currency = props.item.currency;
  }

  const amount = (
    props.item.type === "refund"
      ? -1 * (props.item?.refundAmount ?? 0)
      : props.item.paymentAmount
  )?.toLocaleString(undefined, currencyFormatConfig);
  console.log("xxxxxxx-xxxxxxxxxx", props);
  return (
    <tr>
      <td>{props.item.name}</td>
      <td>{processor}</td>
      <td>{clientName}</td>
      <td>{subMerchantName}</td>
      <td>{props.item.invoiceNumber}</td>
      <td>
        {props.item.entryStatus === "U" ||
        (props.item.statusRefund && props.item.statusRefund === "U")
          ? moment(props.item.date).format("M/D/YYYY").toString()
          : createdAt?.toLocaleDateString()}
      </td>
      <td>{props.item.cardholderName}</td>
      <td>
        {props.item.cardNumber}******{props.item.ccLast4} {props.item.expDate}{" "}
        {getCCName(props.item.cardNumber)}
      </td>
      <td>{amount}</td>
      <td
        className={
          props.item.entryStatus === "U" ||
          (props.item.statusRefund && props.item.statusRefund === "U")
            ? ""
            : statusClass
        }
      >
        {props.item.entryStatus === "U" ||
        (props.item.statusRefund && props.item.statusRefund === "U")
          ? "Upload"
          : statusText}
      </td>
      <td>{trnsType}</td>
      <td>
        {props.item.entryStatus === "U" ||
        (props.item.statusRefund && props.item.statusRefund === "U")
          ? moment(props.item.date).format("M/D/YYYY").toString()
          : createdAt?.toLocaleDateString()}
      </td>
      <td>$0.00</td>
      <td>{initCap(props.item.type)}</td>
      <td>
        {props.item.descr && props.item.descr.length > 30
          ? props.item.descr.slice(0, 30) + "..."
          : props.item.descr}
      </td>
      <td>{props.item.orderNumber}</td>
      <td>{props.item.authCode}</td>
      <td>{agentName}</td>
      <td>{getProcessorTitle(props.item)}</td>
      <td className="payment-actions">
        <div style={{ display: "flex" }}>
          <Maybe component={Link} visible={!!props.access?.view} to={to}>
            <img alt="View" src="/assets/images/icons/eye.svg" />
          </Maybe>
          <Maybe
            component={Link}
            visible={!!props.access?.print}
            to={toPrintVersion}
          >
            <img alt="Print" src="/assets/images/icons/print-alt.svg" />
          </Maybe>

          <Maybe
            component={Link}
            visible={!!props.access?.dispute && statusText !== "Void"}
            to={toDispute}
          >
            <FontAwesomeIcon
              icon={["fab", "dochub"]}
              style={{
                fontSize: "20px",
                color: disputeIconColor ? "green" : "#0133ad",
              }}
            />
          </Maybe>

          <Maybe
            component={Link}
            visible={
              !!!props.access?.dispute &&
              !!props.access?.disputeView &&
              statusText !== "Void"
            }
            to={!!wichDispute ? toDispute : toDisputeReadOnly}
          >
            <FontAwesomeIcon
              icon={["fab", "dochub"]}
              style={{
                fontSize: "20px",
                color: !props.item.disputeData ? "#0133ad" : "green",
              }}
            />
          </Maybe>
        </div>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Maybe
            component={Link}
            title="Refund"
            visible={shouldRefundBeVisible}
            to={toRefund}
          >
            <img alt="Refund" src="/assets/images/icons/refund.svg" />
          </Maybe>
          <Maybe
            component={Link}
            title="Recharge"
            visible={shouldRechargeBeVisible}
            to={toRecharge}
          >
            <img alt="Recharge" src="/assets/images/icons/recharge.svg" />
          </Maybe>
          <Maybe
            component={Link}
            title="Void"
            visible={shouldVoidBeVisible}
            to={toVoid}
          >
            <img alt="Void" src="/assets/images/icons/void.svg" />
          </Maybe>
        </div>
      </td>
    </tr>
  );
}

function useQuery(): number {
  return React.useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    // @ts-ignore
    return parseInt(query.get("pageSize") ?? pageSize);
  }, [window.location.search]);
}

function getFilteredQuery(
  isAdmin: boolean,
  clients: string[][],
  processors: string[],
  processorsClientsTruncated: string[][],
  filters: IFinalFilter | undefined
): firebase.firestore.Query {
  const prequery0 = buildBaseQuery(
    "trxs",
    isAdmin,
    clients,
    processors,
    processorsClientsTruncated,
    firebase.firestore()
  );
  console.log("filter query", filters);
  console.log("clients", clients);
  console.log("processors", processors);
  console.log("prequery0", prequery0);
  const prequery1 =
    filters?.filterBy === "id" && filters?.invoiceNumber
      ? prequery0.where("invoiceNumber", "==", filters.invoiceNumber)
      : prequery0;
  const prequery2 =
    filters?.filterBy === "values" && filters.cardholderName
      ? prequery1.where("cardholderName", "==", filters.cardholderName)
      : prequery1;
  const prequery3 =
    filters?.filterBy === "values" && filters.name
      ? prequery2.where("name", "==", filters.name)
      : prequery2;
  const prequery4 =
    filters?.filterBy === "values" && filters.orderNumber
      ? prequery3.where("orderNumber", "==", filters.orderNumber)
      : prequery3;
  const prequery5 =
    filters?.filterBy === "values" &&
    filters.daterange &&
    filters.daterange.length > 0
      ? prequery4
          .where(
            "createdAt",
            ">=",
            firebase.firestore.Timestamp.fromDate(
              new Date(filters.daterange[0])
            )
          )
          .where(
            "createdAt",
            "<=",
            firebase.firestore.Timestamp.fromDate(
              new Date(filters.daterange[1])
            )
          )
      : prequery4;
  const prequery6 =
    filters?.filterBy === "values" && filters.status && filters.status !== "U"
      ? prequery5.where("status", "==", filters.status)
      : filters?.filterBy === "values" &&
        filters.status &&
        filters.status === "U"
      ? prequery5
          .where("entryStatus", "==", filters.status)
          .where("statusRefund", "==", filters.status)
      : prequery5;
  const prequery7 =
    filters?.filterBy === "values" && filters.creditCard
      ? prequery6
          .where("cardNumber", "==", filters.creditCard.slice(0, 6))
          .where("ccLast4", "==", filters.creditCard.slice(-4))
      : prequery6;
  const prequery8 =
    filters?.filterBy === "values" && filters.cardFirstDigits
      ? prequery7.where("cardNumber", "==", filters.cardFirstDigits)
      : prequery7;
  const prequery9 =
    filters?.filterBy === "values" && filters.cardLastFour
      ? prequery8.where("ccLast4", "==", filters.cardLastFour)
      : prequery8;
  const prequery10 =
    filters?.filterBy === "values" && filters.paymentAmount
      ? prequery9.where(
          "paymentAmount",
          "==",
          parseFloat(filters.paymentAmount)
        )
      : prequery9;
  const prequery11 =
    filters?.filterBy === "values" && filters.batchId
      ? prequery10.where("batchId", "==", filters.batchId)
      : // ? manageRecurrentBatchIdPrequery(prequery10, filters.batchId)
        prequery10;
  const prequery12 =
    filters?.filterBy === "values" &&
    filters.disputestatus &&
    filters.disputestatus !== "All"
      ? prequery11.where("disputeData.status", "==", filters.disputestatus)
      : prequery11;
  const prequery13 =
    filters?.filterBy === "values" &&
    filters.disputeOperationType &&
    filters.disputeOperationType !== "All"
      ? prequery12.where("disputeData.type", "==", filters.disputeOperationType)
      : prequery12;
  const prequery14 =
    filters?.filterBy === "values" && filters.subscriptionId
      ? prequery13.where("recurrent_resp_token", "==", filters.subscriptionId)
      : prequery13;
  const prequery15 =
    filters?.filterBy === "values" &&
    filters.distype &&
    filters.distype !== "All"
      ? prequery14.where("disputeData.distype", "==", filters.distype)
      : prequery14;
  const prequery = prequery15.orderBy("createdAt", "desc");
  console.log("Report/index.tsx line 264 - prequery: ", prequery);
  return prequery;
}

// not been used at the moment
// const manageRecurrentBatchIdPrequery =
// async (prequeryX: firebase.firestore.Query<firebase.firestore.DocumentData>,
//   batchId: string
//   ) => {
//     const idMetaInfo = scanMyID(batchId)
//     if(!idMetaInfo.isRootBatchID){
//       return prequeryX.where("batchId", "==", batchId)
//     }
//    const rechargesCounter = await firebase.firestore().doc(`recharges/${batchId}`)
//       .get().then(doc => (doc.data() as  Recharges).rechargesCounter)

//       return idMetaInfo.sequenceOfBatchIds(rechargesCounter).reduce((prequeryAcc, nextBatchIdString) =>{
//         return prequeryAcc.where("batchId", "==", nextBatchIdString)
//       }, prequeryX)
// }

const manageRecurrentBatchIdPrequery = (
  prequeryX: firebase.firestore.Query<firebase.firestore.DocumentData>,
  batchId: string
) => {
  const idMetaInfo = scanMyID(batchId);
  if (!idMetaInfo.isRootBatchID) {
    return prequeryX.where("batchId", "==", batchId);
  }
  return prequeryX.where("origBatchID", "==", batchId);
};

function manageFiltersQuery(
  isAdmin: boolean,
  clients: string[][],
  processors: string[],
  filters: IFinalFilter | undefined,
  firstOrLast:
    | [
        (
          | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
          | undefined
        ),
        (
          | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
          | undefined
        )
      ]
    | undefined,
  fullPathProcessors: string[][],
  processorsClientTruncated: string[][],
  localPageSize: number = pageSize,
  tQsnapshot: firebase.firestore.QuerySnapshot<ITranx> | undefined = undefined,
  recursionLimit: number = 15
): Promise<firebase.firestore.QuerySnapshot<ITranx>> {
  const prequery = getFilteredQuery(
    isAdmin,
    clients,
    processors,
    processorsClientTruncated,
    filters
  );
  const [first, last] = firstOrLast ?? emptyArray;
  const isNextSearch = first === undefined;
  const query = first
    ? prequery.endBefore(first).limitToLast(pageSize)
    : last
    ? prequery.startAfter(last).limit(pageSize)
    : prequery.limit(pageSize);
  // requires local filter because firebase limits of 10 items in 'in' query filter
  const requiresLocalFilters = !isAdmin && processors.length > 10; // false // temporal; //
  // TODO this function requires a better implementation of local filters
  function onSuccess(snapshot: firebase.firestore.QuerySnapshot<ITranx>) {
    if (!requiresLocalFilters) return snapshot;
    function filterItFn(qds: firebase.firestore.QueryDocumentSnapshot<ITranx>) {
      const data = qds.data();
      const isfiltered = fullPathProcessors.some(
        ([client0, client1, processor]) =>
          data.client[0] === client0 &&
          data.client[1] === client1 &&
          data.processor === processor
      );
      return isfiltered;
    }
    const preFilteredSize = snapshot.size;
    const filteredDocs = snapshot.docs.filter(filterItFn);
    let newSnapshot = snapshot;
    if (tQsnapshot) {
      tQsnapshot.docs.push(...filteredDocs);
      // @ts-ignore
      newSnapshot = {
        ...newSnapshot,
        docs: tQsnapshot.docs.slice(0, pageSize),
      };
    } else {
      // @ts-ignore
      newSnapshot = { ...newSnapshot, docs: filteredDocs.slice(0, pageSize) };
    }
    // if items is less than required size, there is not more to search, so stop recursive search
    if (preFilteredSize < localPageSize) return newSnapshot;

    if (newSnapshot.docs.length >= pageSize) return newSnapshot;

    const newRequiredSize = pageSize - newSnapshot.docs.length;

    if (newRequiredSize < 0) return newSnapshot;

    // Stop cycling
    if (recursionLimit <= 0) return newSnapshot;

    // recursive aproach to reach the required size
    return manageFiltersQuery(
      isAdmin,
      clients,
      processors,
      filters,
      isNextSearch
        ? [undefined, newSnapshot.docs[newSnapshot.docs.length - 1]]
        : [newSnapshot.docs[0], undefined],
      fullPathProcessors,
      processorsClientTruncated,
      newRequiredSize,
      newSnapshot,
      recursionLimit - 1
    );
  }
  return (
    query
      .get()
      // @ts-ignore
      .then(onSuccess)
  );
}

const dedupeMatrix = (bigArray: string[][]) => {
  const stringArray = bigArray.map((arr) => JSON.stringify(arr));
  const uniqueStringArray = new Set(stringArray);
  //@ts-ignore
  const uniqueArray = Array.from(uniqueStringArray, JSON.parse);
  return uniqueArray;
};

interface IResults {
  items: ITranx[];
  loading: boolean;
  error: Error | string | undefined;
  onBackClick(): void;
  onNextClick(): void;
  isBackAvailable: boolean;
  pageSize: number;
  getFiltered: () => firebase.firestore.Query;
}
export function useFilterTransactions(
  filters: IFinalFilter | undefined
): IResults {
  const pageSize = useQuery();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | string | undefined>(
    undefined
  );
  const [items, setItems] = React.useState<ITranx[]>(emptyArray);
  const [
    clients,
    processors,
    isAdmin,
    fullPathProcessors,
    processorsClientTruncated,
  ] = useUserInfo(filters);
  console.log("Report/index.tsx line 441 - clients: ", clients);
  console.log("Report/index.tsx line 442 - processors: ", processors);
  console.log(
    "Report/index.tsx line 443 - fullPathProcessors: ",
    fullPathProcessors.length
  );
  console.log(
    "Report/index.tsx line 444 - processorsClientTruncated: ",
    processorsClientTruncated
  );

  const [firstOrLast, setFirstOrLast] = React.useState<
    | [
        firebase.firestore.QueryDocumentSnapshot | undefined,
        firebase.firestore.QueryDocumentSnapshot | undefined
      ]
    | undefined
  >(undefined);
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [_onBackClick, setOnBackClick] = React.useReducer(idReduce, undefined);
  const [_onNextClick, setOnNextClick] = React.useReducer(idReduce, undefined);

  const getFiltered = () =>
    getFilteredQuery(
      isAdmin,
      clients,
      processors,
      processorsClientTruncated,
      filters
    );

  function onBackClick() {
    setPage((p) => p - 1);
    _onBackClick();
    return false;
  }
  function onNextClick() {
    setPage((p) => p + 1);
    _onNextClick();
    return false;
  }
  React.useEffect(() => {
    function fetchAll() {
      setLoading(true);
      setError(undefined);
      setItems(emptyArray);
      function onSuccess(snapshot: firebase.firestore.QuerySnapshot) {
        setOnBackClick(() => setFirstOrLast([snapshot.docs[0], undefined]));
        setOnNextClick(() =>
          setFirstOrLast([undefined, snapshot.docs[snapshot.docs.length - 1]])
        );
        const items = snapshot.docs.map(
          (it) => ({ id: it.id, ...it.data() } as ITranx)
        );

        setItems(items.filter((item) => item.invoiceNumber !== "KO7U6800")); // this recored was wrongly introduced, delete it and modify this line after.
      }
      manageFiltersQuery(
        isAdmin,
        clients,
        processors,
        filters,
        firstOrLast,
        fullPathProcessors,
        processorsClientTruncated
      )
        .then(onSuccess)
        .catch(compose(setError, getMessage))
        .then(lazyApply(setLoading, false));
    }
    if (processors.length > 0 || fullPathProcessors.length > 0) fetchAll();
    const unregister = history.listen((_location) => {
      const shouldRefreshReport = sessionStorage.getItem("shouldRefreshReport");
      if (shouldRefreshReport) {
        sessionStorage.removeItem("shouldRefreshReport");
        fetchAll();
      }
    });
    return () => {
      unregister();
      sessionStorage.removeItem("shouldRefreshReport");
    };
  }, [
    clients,
    processors,
    window.location.search,
    filters,
    firstOrLast,
    fullPathProcessors,
  ]);
  return {
    items,
    loading,
    error,
    onBackClick,
    onNextClick,
    isBackAvailable: page > 0,
    pageSize,
    getFiltered,
  };
}

const headersAccountPayable =
  "Client;Merchant;Merchant name;Sub-Merchant name;Invoice;Date;Card Holder;Card Info.;Amount;Status;Trans. Type;Date settled;Revised amount;Payment Type;Reference;Account #;Authorization code;Processed by;Processor;Com;Comm;NET;".split(
    ";"
  );

export function useDownloadClickAccountPayable(
  defaultText: string,
  tag: "excel" | "csv",
  getFiltered: () => firebase.firestore.Query,
  keysOfProcessors: IKeysOfStr
): [string, boolean, () => void] {
  const { addError } = useMessages();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  function onClick() {
    function handleSuccess(results: object[]) {
      switch (tag) {
        case "excel":
          return exportExcelContent(results, headersAccountPayable);
        case "csv":
          return exportCSVContent(results, headersAccountPayable);
      }
    }
    setIsLoading(true);
    getFiltered()
      .get()
      .then((query) => {
        return query.docs.map((it) => ({ id: it.id, ...it.data() } as ITranx));
      })
      .then((trxs) => {
        return runPromiseSeq(
          trxs.map((it) => getPlainRowToAccountPayable(it, keysOfProcessors))
        );
      })
      .then(handleSuccess, compose(addError, getMessage))
      .then(() => setIsLoading(false));
  }
  return [isLoading ? "Loading..." : defaultText, isLoading, onClick];
}

const headers =
  "Client;Merchant;Merchant name;Sub-Merchant name;Invoice;Date;Card Holder;Card Info.;Amount;Status;Trans. Type;Date settled;Revised amount;Payment Type;Reference;Account #;Authorization code;Processed by;Processor;Response".split(
    ";"
  );

export function useDownloadClick(
  defaultText: string,
  tag: "excel" | "csv",
  getFiltered: () => firebase.firestore.Query,
  keysOfProcessors: IKeysOfStr
): [string, boolean, () => void] {
  const { addError } = useMessages();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  
  function onClick() {
    function handleSuccess(results: object[]) {
      switch (tag) {
        case "excel":
          return exportExcelContent(results, headers);
        case "csv":
          return exportCSVContent(results, headers);
      }
    }
    setIsLoading(true);
    getFiltered()
      .get()
      .then((query) => {
        return query.docs.map((it) => ({ id: it.id, ...it.data() } as ITranx));
      })
      .then((trxs) => {
        return runPromiseSeq(
          trxs.map((it) => getPlainRow(it, keysOfProcessors))
        );
      })
      .then(handleSuccess, compose(addError, getMessage))
      .then(() => setIsLoading(false));
  }
  return [isLoading ? "Loading..." : defaultText, isLoading, onClick];
}

function getDefaultFilters(): IFinalFilter {
  const to = new Date();
  // if monday then take from friday (-3) otherwise yesterday (-1)
  const from = roundDate(addDays(to, to.getDay() === 1 ? -3 : -1));
  return {
    filterBy: "values",
    daterange: [from, to],
  };
}

// momentarilly until bringing roundDate and addDays from another branch
// function getDefaultFilters(): IFinalFilter | undefined {return undefined}

function Report() {
  // const [filters, setFilters] = React.useState<IFinalFilter | undefined>(getDefaultFilters());
  const [filters, setFilters] = React.useState<IFinalFilter | undefined>({
    filterBy: "values",
  });
  console.log("Report/index.tsx line 571 - filters: ", filters);

  const {
    items,
    loading,
    error,
    onBackClick,
    onNextClick,
    isBackAvailable,
    pageSize,
    getFiltered,
  } = useFilterTransactions(filters);
  const isNextAvailable = items.length >= pageSize;
  const keysOfProcessors = useProcessorsName();
  const maybeAccess = useUserPermissions();
  const [userData] = useGlobalState();
  console.log("maybeAccess", maybeAccess?.disputeView);
  const [
    excelButtonTextPayable,
    isExcelLoadingToAccountPayable,
    onExcelClickToAccountPayable,
  ] = useDownloadClickAccountPayable(
    "Export Accounts Payable",
    "excel",
    getFiltered,
    keysOfProcessors
  );
  const [excelButtonText, isExcelLoading, onExcelClick] = useDownloadClick(
    "Export Excel",
    "excel",
    getFiltered,
    keysOfProcessors
  );
  const [csvButtonText, isCSVLoading, onCSVClick] = useDownloadClick(
    "Export txt",
    "csv",
    getFiltered,
    keysOfProcessors
  );
  const rows = React.useMemo(
    () =>
      items.map((it: ITranx) => (
        <TranxItem
          key={it.id}
          item={it}
          keysOfProcessors={keysOfProcessors}
          access={maybeAccess!}
        />
      )),
    [items, keysOfProcessors, maybeAccess]
  );
  return (
    <section className="main-content">
      <div className="card payment-history-card">
        <ErrorMessage text={error} />
        <ReportForm
          initialValues={filters}
          onSubmit={setFilters}
          access={maybeAccess}
        />
        <table id="table-to-export" className="table">
          <thead>
            <tr>
              <th>Client</th>
              <th>Merchant</th>
              <th>Merchant name</th>
              <th>Sub-Merchant name</th>
              <th>Invoice</th>
              <th>Date</th>
              <th>Card Holder</th>
              <th>Card Info.</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Trans. Type</th>
              <th>Date settled</th>
              <th>Revised amount</th>
              <th>Payment Type</th>
              <th>Reference</th>
              <th>Account #</th>
              <th>Authorization code</th>
              <th>Processed by</th>
              <th>Processor</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
          <tfoot>
            <tr>
              <th>{loading && "Loading..."}</th>
            </tr>
          </tfoot>
        </table>
        <div className="export-button">
          <button
            className="btn btn-primary"
            onClick={onExcelClick}
            disabled={isExcelLoading}
          >
            {excelButtonText}
          </button>
          <button
            className="btn btn-primary"
            onClick={onCSVClick}
            disabled={isCSVLoading}
          >
            {csvButtonText}
          </button>
          {userData?.access?.accountsPayable && (
            <button
              className="btn btn-primary"
              onClick={onExcelClickToAccountPayable}
              disabled={isExcelLoadingToAccountPayable}
            >
              {excelButtonTextPayable}
            </button>
          )}
        </div>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                onClick={onBackClick}
                disabled={!isBackAvailable}
              >
                Previous
              </button>
            </li>
            {isNextAvailable && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={onNextClick}
                  disabled={!isNextAvailable}
                >
                  Next
                </button>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </section>
  );
}

export default Report;
