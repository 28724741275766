import * as React from "react";
import * as firebase from 'firebase/app';
import "firebase/firestore";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { Link, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";

import Maybe from 'common/components/Maybe';
import Modal from "common/components/Modal";
import Pagination from "common/components/Pagination";
import { emptyArray, emptyString, pageSize, optionsByIdField, emptyObject } from "common/utils/constants";
import { compose, constant, ignore } from "common/utils/fns";
import { usePaginatedCollection } from 'common/hooks/firestore';
import ErrorMessage from "common/components/ErrorMessage";

import { useMessages } from "config/messagesStateContext";
import { useGlobalState } from "config/globalStateContext";
import { ICombinedProcessor, IProcessor } from "types";

import FormBottom from './components/FormBottom';
import PlainProcessorForm from './components/PlainProcessorForm';
import { ConditionalProcessorsForm } from './ConditionalProcessors';
import { buildQuery } from './utils';
import { usePaymentId } from "common/hooks/utils";
import { IClient } from '../../types';
import { log, getMessage } from '../../common/utils/fns';

interface IProcessorSelectFormProps {
  parentId?: string,
  client1?: string,
  srcId?: string,
  id?: string,
  name?: string,
}
function SimpleProcessorSelectForm() {
  const { state: props } = useLocation<IProcessorSelectFormProps>();
  const { parentId: client0, client1 } = props;
  const [localLoading, setLocalLoading] = React.useState(false);
  const history = useHistory()
  const [globaState] = useGlobalState();
  const { addError, addSuccess } = useMessages();
  console.log('SimpleProcessorSelectForm globaState?.clientData?.opWProcessors', globaState?.clientData?.opWProcessors);
  const query = React.useMemo(() =>
    (globaState?.clientData?.opWProcessors?.length ?? 0) > 0
      ? firebase
          .firestore()
          .collection('processors')
          .where(firebase.firestore.FieldPath.documentId(), 'in', globaState?.clientData?.opWProcessors)
      : null,
    [globaState?.clientData?.opWProcessors]
  );
  const [processors, loading, error] = useCollectionDataOnce<IProcessor>(query, optionsByIdField);
  const onSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback((ev) => {
    ev.preventDefault();
    setLocalLoading(true);
    const formData = new FormData(ev.target as HTMLFormElement);
    const values = Object.fromEntries(formData);
    const { srcId, id, name } = values;
    const processorSelected = processors?.find(it => it.id === srcId)!;
    const { id: _, ...data } = processorSelected;
    const newValues = {
      ...data,
      // @ts-ignore
      srcId,
      name,
    } as ICombinedProcessor;
    function onSuccess() {
      addSuccess(`Successfully saved!`);
      history.goBack();
    }
    buildQuery(client0, client1).doc(id as string)
      .set(newValues)
      .then(onSuccess, err => addError(err.message))
      .then(compose(setLocalLoading, constant(false)));
  }, [client0, client1, processors])
  const options = React.useMemo(
    () => processors?.map(it => <option key={it.id} value={it.id}>{showMerchantExtraInfo(it)}</option>) ?? null,
    [processors]
  );
  const [maybeId] = usePaymentId();
  return (
    <Modal title="Add new sub-merchant" name="SimpleProcessorSelect" onClose={history.goBack}>
      <form name="simpleProcessorSelect" onSubmit={onSubmit}>
        <ErrorMessage text={error} />
        <fieldset disabled={loading}>
          <div className="form-group">
            <label htmlFor="processorID">Merchant ID</label>
            <input
              type="text"
              className="form-control"
              id="id"
              name="id"
              defaultValue={props.id ?? maybeId}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Merchant Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              required
              defaultValue={props.name}
            />
          </div>
          <div className="form-group">
            <label htmlFor="srcId">Use processor</label>
            <select
              className="form-control"
              id="srcId"
              name="srcId"
              required
              defaultValue={props.srcId}
            >
              <option value={emptyString}>Select one</option>
              {options}
            </select>
          </div>
          <FormBottom
            isNew={true}
            onDeleteClick={ignore}
            loading={localLoading}
          />
        </fieldset>
      </form>
    </Modal>
  );
}

function showMerchantExtraInfo(p: IProcessor) {
  if (p.name) return p.name;
  switch(p.type) {
    // @ts-ignore
    case "azul": return p.auth1;
    // @ts-ignore
    case "resortcom": return p.username;
    // @ts-ignore
    case "trinity": return `${p.merchant_token.slice(0, 6)}…${p.merchant_token.slice(-6)}`;
    default: return emptyString;
  }
}
interface ItemRowProps {
  baseUrl: string, isSuperAdmin: boolean, client0?: string, client1?: string, it:IProcessor, doDelete: (id: string) => void
}
function ItemRow(props: ItemRowProps) {
  const { it, client0, client1, baseUrl, isSuperAdmin } = props;
  const to = {
    pathname: `${baseUrl}/${it.id}`,
    state: { ...it, parentId: client0, client1 },
  };
  const onDeleteClick = React.useCallback(() => {
    const confirmed = window.confirm(`Please confirme you want to delete this item.`);
    if (confirmed) {
      props.doDelete(it.id);
    }
  }, [props.doDelete, it.id]);
  return (
    <tr key={it.id}>
      <td>{it.id}</td>
      <td>{it.type}</td>
      <td>{showMerchantExtraInfo(it)}</td>
      <td>
        <Maybe component={Link} visible={isSuperAdmin} to={to}>
          <img alt="View" src="/assets/images/icons/eye.svg" />
        </Maybe>
        <button type="button" className="btn" onClick={onDeleteClick}>
          <img alt="Delete" src="/assets/images/icons/trash-alt-solid.svg" />
        </button>
      </td>
    </tr>
  );
}

interface ILocalFormProps {
  values: IProcessor,
  client0?: string|undefined,
  client1: string,
  id?: string,
  doClose(): void,
  doSave(it: IProcessor): Promise<void>,
  doDelete(): Promise<void>,
}
function LocalForm(props: ILocalFormProps) {
  const { values, client0, client1, id, doClose, doDelete, doSave } = props;
  let form = null;
  if (values.type === 'combined' || values.type === undefined) {
    form = (
      <ConditionalProcessorsForm
        client0={client0!}
        client1={client1}
        merchantID={id}
        processor={values}
        onReset={doClose}
      />
    );
  } else {
    form = (
      <PlainProcessorForm
        values={values}
        onReset={doClose}
        id={id}
        doSave={doSave}
        doDelete={doDelete}
        hideNonGlobal={!client0}
      />
    );
  }
  return form;
}

function useInternalGlobalProcessorForm() {
  const { state: values } = useLocation<IProcessor>();
  const { id, parentId: client0, client1 } = values;
  
  const { addSuccess, addError } = useMessages();
  const doSave = React.useCallback((values: IProcessor) => {
    const { id, ...restValues } = values;
    //@ts-ignore
     Object.keys(restValues).forEach((key:string) => restValues[key] === undefined && delete restValues[key])
    return buildQuery(client0, client1)
      .doc(id ?? restValues.store)
      .set(restValues)
      .then(() => addSuccess('Saved!'), err => addError(err.message));
  }, [client0, client1, addSuccess, addError]);
  const history = useHistory();
  const doClose = history.goBack;
  const doDelete = React.useCallback(() => {
    return buildQuery(client0, client1)
      .doc(id)
      .delete()
      .then(() => addSuccess(`Deleted Successfully!`), err => addError(err.message));
  }, [client0, client1, addSuccess, addError, id]);
  return {
    values, id, client0, client1, doSave, doClose, doDelete,
  };
}
export function Form() {
  const { values, id, client0, client1, doSave, doClose, doDelete } = useInternalGlobalProcessorForm();
  const form = React.useMemo(
    () => (
      <LocalForm
        client0={client0}
        client1={client1}
        id={id}
        values={values}
        doClose={doClose}
        doSave={doSave}
        doDelete={doDelete}
      />
    ),
    [client0, client1, id, values, doClose, doSave, doDelete]
  );
  return (
    <Modal title="Processor form" name="processor" onClose={doClose}>
      {form}
    </Modal>
  );
}

Form.defaultProps = {
  values: {},
};

interface IProps {
  EditorForm?: React.FC<any>
}

interface IParams {
  client0?: string,
  client1?: string,
}

function MaybeTitle(props: { client0: string|undefined, client1: string|undefined }) {
  const { client0, client1 } = props;
  if (!(client0 || client1)) return null;
  return <p>{client0} / {client1} </p>
}

function GlobalProcessors(props: IProps) {
  const { EditorForm } = props;
  const [gError, setError] = React.useState<string|undefined>(undefined);
  const [userData] = useGlobalState();
  const { url } = useRouteMatch();
  const { client0: maybeClient0, client1 } = useParams<IParams>();
  const { state } = useLocation<{ parentId: string }>();
  const client0 = React.useMemo(() => state?.parentId ?? maybeClient0, [state?.parentId, maybeClient0]);
  const query = React.useMemo(() => buildQuery(client0, client1).orderBy('type'), [client0, client1]);
  const { page, onBackClick, onNextClick, items: processors, loading, error } = usePaginatedCollection<IProcessor>(query, pageSize);
  console.log('GlobalProcessors 286 query: ', query)
  const { addSuccess } = useMessages();
  const [globalState] = useGlobalState();
  const doDelete = React.useCallback((id: string) => {
    return buildQuery(client0, client1).doc(id).delete()
      .then(() => addSuccess(`Deleted Successfully!`), err => setError(err.message));
  }, [client0, client1, addSuccess, setError]);
  const rows = React.useMemo(() => processors?.map((it) => (
    <ItemRow
      key={it.id}
      client0={client0}
      client1={client1}
      baseUrl={url}
      it={it}
      doDelete={doDelete}
      isSuperAdmin={!!globalState?.access?.superAdmin}
    />
  ) ?? null), [processors, !!globalState?.access?.superAdmin, client0, client1, url]);
  const finalError = gError || error?.message;
  const toAddRate = React.useMemo(() => ({ pathname: url + '/rate/company', state: { parentId: client0, clientId: client1 } }), [url, client0]);
  const toAddCreditRate = React.useMemo(() => ({ pathname: url + '/credit/rate/company', state: { parentId: client0, clientId: client1 } }), [url, client0]);
  const toAddPlainForm = React.useMemo(() => ({
    pathname: url + '/new',
    state: Object.assign(
      { type: 'any_no_combined' },
      client0 && client1
        ? { parentId: client0, client1: client1 }
        : null
    ),
  }), [url, client0, client1]);
  const toAddForm = React.useMemo(() => ({
    pathname: url + '/new',
    state: client0 && client1
      ? { parentId: client0, client1: client1 }
      : {},
  }), [url, client0, client1]);
  const toSimpleForm = React.useMemo(() => ({
    pathname: url + '/simple/new',
    state: { parentId: client0, client1: client1 },
  }), [url, client0, client1])
  const isGotoSimpleFormVisible = !globalState?.access?.superAdmin && !!globalState?.access?.createClients && client0 !== undefined;
  return (
    <div>
      <Maybe
        component="div"
        visible={!!finalError}
        className="alert alert-danger"
        role="alert"
      >
        {finalError}
      </Maybe>
      <div className="clearfix">
        <Maybe component={React.Fragment} visible={!!globalState?.access?.superAdmin}>
          <Link to={toAddForm} className="btn btn-primary btn-sm float-right">+ New Conditional</Link>
          <Link to={toAddPlainForm} className="btn btn-primary btn-sm float-right">+ New</Link>
          { (userData?.access?.superAdmin && userData?.access?.accountsPayable && client0 !== undefined) && (<Link to={toAddCreditRate} className="btn btn-primary btn-sm float-right"> $ Credit Rates</Link> )}
          { (userData?.access?.superAdmin && userData?.access?.accountsPayable && client0 !== undefined) && (<Link to={toAddRate} className="btn btn-primary btn-sm float-right"> $ Rates</Link> )}
        </Maybe>
        <Maybe
          component={Link}
          visible={isGotoSimpleFormVisible}
          to={toSimpleForm}
          className="btn btn-primary btn-sm float-right"
        >
          + New
        </Maybe>
        <MaybeTitle client0={client0} client1={client1} />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Extra Info</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>

      <Pagination
        page={page}
        pageSize={pageSize}
        onNextClick={onNextClick}
        onBackClick={onBackClick}
        itemsLength={processors.length}
      />
      <Switch>
        <Route exact path={`${url}/rate/company`} component={RateCompanyModal} />
        <Route exact path={`${url}/credit/rate/company`} component={CreditRateCompanyModal} />
        <Route path={`${url}/simple/new`} component={SimpleProcessorSelectForm} />
        <Route path={`${url}/simple/:id`} component={SimpleProcessorSelectForm} />
        <Route path={`${url}/:id`} component={EditorForm} />
      </Switch>
    </div>
  );
}

export function CreditRateCompanyModal() {
  const history = useHistory();
  const goBack = history.goBack;
  const { state } = useLocation<IClient>();
  const [loading, setLoading] = React.useState(false);
  const [editRate, setEditRate] = React.useState(false);
  const { addError, addSuccess } = useMessages();
  const initialState = {
    id: null,
    azul: {
      visa: '',
      mc: '',
      discover: '',
      diners: '',
      amex: '',
      jcb: ''
    },
    resortcom: {
      visa: '',
      mc: '',
      discover: '',
      diners: '',
      amex: '',
      jcb: ''
    },
    trinity: {
      visa: '',
      mc: '',
      discover: '',
      diners: '',
      amex: '',
      jcb: ''
    }
  }
  const [rateBypercentagePrev, setRateBypercentagePrev] = React.useState(initialState)
  const [rateBypercentage, setRateBypercentage] = React.useState(initialState)

  const [rateByAmount, setRateByAmount] = React.useState(initialState)
  const [rateByAmountPrev, setRateByAmountPrev] = React.useState(initialState)

  const getCompany =  () => {
    firebase.firestore().collection('clients').doc(`${state.parentId}/clients/${state.clientId}`).collection('credit-rates-percentage').onSnapshot( (snapshot) => {
      const ratesPercentage = snapshot.docs.map( doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      })
      if(ratesPercentage.length > 0) {
        //@ts-ignore
        setRateBypercentage(ratesPercentage[0]);
        //@ts-ignore
        setRateBypercentagePrev(ratesPercentage[0])
      }
    })

    firebase.firestore().collection('clients').doc(`${state.parentId}/clients/${state.clientId}`).collection('credit-rates-amount').onSnapshot( (snapshot) => {
      const ratesAmount = snapshot.docs.map( doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      })
      if(ratesAmount.length > 0) {
        //@ts-ignore
        setRateByAmount(ratesAmount[0]);
        //@ts-ignore
        setRateByAmountPrev(ratesAmount[0])
      }
    })
  }

  const saveRate = () => {
    setLoading(true)
    function onSuccess(data: any) {
      addSuccess(`Company updated successfully!`);
      setLoading(false)
    }
    if(!rateBypercentage.id) {
      const data = rateBypercentage;
      //@ts-ignore
      delete data.id;
      firebase.firestore().doc(`clients/${state.parentId}/clients/${state.clientId}`).collection("credit-rates-percentage").add(data)
      .then(onSuccess, compose(addError, log, getMessage)).catch(console.log);
    }

    if(!rateByAmount.id) {
      const data = rateByAmount;
      //@ts-ignore
      delete data.id;
      firebase.firestore().doc(`clients/${state.parentId}/clients/${state.clientId}`).collection("credit-rates-amount").add(data)
      .then(onSuccess, compose(addError, log, getMessage)).catch(console.log);
    }

    if(rateBypercentage.id) {
      firebase.firestore().doc(`clients/${state.parentId}/clients/${state.clientId}/credit-rates-percentage/${rateBypercentage.id}`).set(rateBypercentage)
      .then(onSuccess, compose(addError, log, getMessage)).catch(console.log);
    }

    if(rateByAmount.id) {
      firebase.firestore().doc(`clients/${state.parentId}/clients/${state.clientId}/credit-rates-amount/${rateByAmount.id}`).set(rateByAmount)
      .then(onSuccess, compose(addError, log, getMessage)).catch(console.log);
    }
    history.goBack();
  }

  React.useEffect(() => {
    getCompany();
    setRateBypercentagePrev(rateBypercentage);
  }, [])

  React.useEffect(() => {
    if(!editRate) {
      //@ts-ignore
      setRateBypercentage(rateBypercentagePrev);
      setRateByAmount( rateByAmountPrev );
    }
  }, [editRate])
  
  


  return (
    <Modal
      title={`Edit ${state.parentId}`}
      name="company"
      onClose={goBack}
    >
      <div className="clearfix">
        <h6>
          
          <button className="btn btn-primary btn-sm float-right" onClick={() => setEditRate(!editRate)}>{ editRate ? 'Cancel' : 'Edit' }</button>
        </h6>
      </div>
      
      <p>Rate calculated by percentage</p>
      <table className="table">
        <thead>
          <tr>
            <th>Company Provider</th>
            <th>Visa</th>
            <th>MC</th>
            <th>Discover</th>
            <th>Diners</th>
            <th>Amex</th>
            <th>JCB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ResortCom</td>
            <td>
              { (!editRate && rateBypercentage.resortcom.visa) && rateBypercentage.resortcom.visa + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  type="number"
                  value={rateBypercentage.resortcom.visa}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.mc) && rateBypercentage.resortcom.mc + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.mc}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.discover) && rateBypercentage.resortcom.discover + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.discover}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.diners) && rateBypercentage.resortcom.diners + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.diners}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.amex) && rateBypercentage.resortcom.amex + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.amex}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.jcb) && rateBypercentage.resortcom.jcb + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.jcb}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

          </tr>
          <tr>
            <td>Trinity</td>
            <td>
              { (!editRate && rateBypercentage.trinity.visa) && rateBypercentage.trinity.visa + '%'}
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.visa}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.mc) && rateBypercentage.trinity.mc + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.mc}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.discover) && rateBypercentage.trinity.discover + '%'}
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.discover}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.diners) && rateBypercentage.trinity.diners + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.diners}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.amex) && rateBypercentage.trinity.amex + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.amex}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.jcb) && rateBypercentage.trinity.jcb + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.jcb}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Azul</td>
            <td>
              { (!editRate && rateBypercentage.azul.visa) && rateBypercentage.azul.visa + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.visa}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.mc) && rateBypercentage.azul.mc + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.mc}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.discover) && rateBypercentage.azul.discover + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.discover}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.diners) && rateBypercentage.azul.diners + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.diners}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.amex) && rateBypercentage.azul.amex + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.amex}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.jcb) && rateBypercentage.azul.jcb + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.jcb}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>Rate calculated by amount</p>
      <table className="table">
        <thead>
          <tr>
            <th>Company Provider</th>
            <th>Visa</th>
            <th>MC</th>
            <th>Discover</th>
            <th>Diners</th>
            <th>Amex</th>
            <th>JCB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ResortCom</td>
            <td>
              { !editRate && rateByAmount.resortcom.visa }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.visa}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.mc }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.mc}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.discover }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.discover}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.diners }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.diners}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.amex }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.amex}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.jcb }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.jcb}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

          </tr>
          <tr>
            <td>Trinity</td>
            <td>
              { !editRate && rateByAmount.trinity.visa }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.visa}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.mc }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.mc}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.discover }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.discover}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.diners }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.diners}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.amex }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.amex}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.jcb }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.jcb}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Azul</td>
            <td>
              { !editRate && rateByAmount.azul.visa }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.visa}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.mc }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.mc}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.discover }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.discover}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.diners }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.diners}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.amex }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.amex}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.jcb }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.jcb}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div className="clearfix">
        <h6>  
          <button className="btn btn-secondary btn-sm float-right" onClick={goBack}>Close</button>
          <button className="btn btn-primary btn-sm float-right" style={{ marginRight: 10 }} onClick={() => saveRate()}>{ loading ? 'Loading...' : 'Save'}</button>
        </h6>
      </div>
    </Modal>
  )
}

export function RateCompanyModal() {
  const history = useHistory();
  const goBack = history.goBack;
  const { state } = useLocation<IClient>();
  const [loading, setLoading] = React.useState(false);
  const [editRate, setEditRate] = React.useState(false);
  const { addError, addSuccess } = useMessages();
  const initialState = {
    id: null,
    azul: {
      visa: '',
      mc: '',
      discover: '',
      diners: '',
      amex: '',
      jcb: ''
    },
    resortcom: {
      visa: '',
      mc: '',
      discover: '',
      diners: '',
      amex: '',
      jcb: ''
    },
    trinity: {
      visa: '',
      mc: '',
      discover: '',
      diners: '',
      amex: '',
      jcb: ''
    }
  }
  const [rateBypercentagePrev, setRateBypercentagePrev] = React.useState(initialState)
  const [rateBypercentage, setRateBypercentage] = React.useState(initialState)

  const [rateByAmount, setRateByAmount] = React.useState(initialState)
  const [rateByAmountPrev, setRateByAmountPrev] = React.useState(initialState)

  const getCompany =  () => {
    firebase.firestore().collection('clients').doc(`${state.parentId}/clients/${state.clientId}`).collection('rates-percentage').onSnapshot( (snapshot) => {
      const ratesPercentage = snapshot.docs.map( doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      })
      if(ratesPercentage.length > 0) {
        //@ts-ignore
        setRateBypercentage(ratesPercentage[0]);
        //@ts-ignore
        setRateBypercentagePrev(ratesPercentage[0])
      }
    })

    firebase.firestore().collection('clients').doc(`${state.parentId}/clients/${state.clientId}`).collection('rates-amount').onSnapshot( (snapshot) => {
      const ratesAmount = snapshot.docs.map( doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      })
      if(ratesAmount.length > 0) {
        //@ts-ignore
        setRateByAmount(ratesAmount[0]);
        //@ts-ignore
        setRateByAmountPrev(ratesAmount[0])
      }
    })
  }

  const saveRate = () => {
    setLoading(true)
    function onSuccess(data: any) {
      addSuccess(`Company updated successfully!`);
      setLoading(false)
    }
    if(!rateBypercentage.id) {
      const data = rateBypercentage;
      //@ts-ignore
      delete data.id;
      firebase.firestore().doc(`clients/${state.parentId}/clients/${state.clientId}`).collection("rates-percentage").add(data)
      .then(onSuccess, compose(addError, log, getMessage)).catch(console.log);
    }

    if(!rateByAmount.id) {
      const data = rateByAmount;
      //@ts-ignore
      delete data.id;
      firebase.firestore().doc(`clients/${state.parentId}/clients/${state.clientId}`).collection("rates-amount").add(data)
      .then(onSuccess, compose(addError, log, getMessage)).catch(console.log);
    }

    if(rateBypercentage.id) {
      firebase.firestore().doc(`clients/${state.parentId}/clients/${state.clientId}/rates-percentage/${rateBypercentage.id}`).set(rateBypercentage)
      .then(onSuccess, compose(addError, log, getMessage)).catch(console.log);
    }

    if(rateByAmount.id) {
      firebase.firestore().doc(`clients/${state.parentId}/clients/${state.clientId}/rates-amount/${rateByAmount.id}`).set(rateByAmount)
      .then(onSuccess, compose(addError, log, getMessage)).catch(console.log);
    }
    history.goBack();
  }

  React.useEffect(() => {
    getCompany();
    setRateBypercentagePrev(rateBypercentage);
  }, [])

  React.useEffect(() => {
    if(!editRate) {
      //@ts-ignore
      setRateBypercentage(rateBypercentagePrev);
      setRateByAmount( rateByAmountPrev );
    }
  }, [editRate])

  return (
    <Modal
      title={`Edit ${state.parentId}`}
      name="company"
      onClose={goBack}
    >
      <div className="clearfix">
        <h6>
          
          <button className="btn btn-primary btn-sm float-right" onClick={() => setEditRate(!editRate)}>{ editRate ? 'Cancel' : 'Edit' }</button>
        </h6>
      </div>
      
      <p>Rate calculated by percentage { state.parentId + 'CLIENT ' + state.clientId}</p>
      <table className="table">
        <thead>
          <tr>
            <th>Company Provider</th>
            <th>Visa</th>
            <th>MC</th>
            <th>Discover</th>
            <th>Diners</th>
            <th>Amex</th>
            <th>JCB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ResortCom</td>
            <td>
              { (!editRate && rateBypercentage.resortcom.visa) && rateBypercentage.resortcom.visa + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  type="number"
                  value={rateBypercentage.resortcom.visa}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.mc) && rateBypercentage.resortcom.mc + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.mc}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.discover) && rateBypercentage.resortcom.discover + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.discover}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.diners) && rateBypercentage.resortcom.diners + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.diners}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.amex) && rateBypercentage.resortcom.amex + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.amex}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.jcb) && rateBypercentage.resortcom.jcb + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.jcb}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

          </tr>
          <tr>
            <td>Trinity</td>
            <td>
              { (!editRate && rateBypercentage.trinity.visa) && rateBypercentage.trinity.visa + '%'}
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.visa}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.mc) && rateBypercentage.trinity.mc + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.mc}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.discover) && rateBypercentage.trinity.discover + '%'}
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.discover}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.diners) && rateBypercentage.trinity.diners + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.diners}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.amex) && rateBypercentage.trinity.amex + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.amex}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.jcb) && rateBypercentage.trinity.jcb + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.jcb}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Azul</td>
            <td>
              { (!editRate && rateBypercentage.azul.visa) && rateBypercentage.azul.visa + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.visa}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.mc) && rateBypercentage.azul.mc + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.mc}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.discover) && rateBypercentage.azul.discover + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.discover}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.diners) && rateBypercentage.azul.diners + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.diners}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.amex) && rateBypercentage.azul.amex + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.amex}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.jcb) && rateBypercentage.azul.jcb + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.jcb}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>Rate calculated by amount</p>
      <table className="table">
        <thead>
          <tr>
            <th>Company Provider</th>
            <th>Visa</th>
            <th>MC</th>
            <th>Discover</th>
            <th>Diners</th>
            <th>Amex</th>
            <th>JCB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ResortCom</td>
            <td>
              { !editRate && rateByAmount.resortcom.visa }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.visa}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.mc }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.mc}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.discover }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.discover}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.diners }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.diners}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.amex }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.amex}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.jcb }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.jcb}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

          </tr>
          <tr>
            <td>Trinity</td>
            <td>
              { !editRate && rateByAmount.trinity.visa }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.visa}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.mc }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.mc}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.discover }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.discover}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.diners }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.diners}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.amex }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.amex}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.jcb }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.jcb}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Azul</td>
            <td>
              { !editRate && rateByAmount.azul.visa }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.visa}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.mc }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.mc}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.discover }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.discover}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.diners }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.diners}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.amex }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.amex}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.jcb }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.jcb}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div className="clearfix">
        <h6>  
          <button className="btn btn-secondary btn-sm float-right" onClick={goBack}>Close</button>
          <button className="btn btn-primary btn-sm float-right" style={{ marginRight: 10 }} onClick={() => saveRate()}>{ loading ? 'Loading...' : 'Save'}</button>
        </h6>
      </div>
    </Modal>
  )
}


GlobalProcessors.defaultProps = {
  EditorForm: Form,
}

export default React.memo(GlobalProcessors);