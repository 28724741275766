import * as firebase from "firebase/app";
import "firebase/storage";
import { IDEntity, IProcessor, IClient, IUser, ITranx } from "types";
import { buildFileName, buildQueryStr } from "./fns";
import { emptyString } from "./constants";

export const reduceQueryByClient = (
  clients: string[],
  firestore: firebase.firestore.Firestore
): firebase.firestore.DocumentReference =>
  // @ts-ignore
   clients.reduce((acc, it) => acc.collection("clients").doc(it), firestore);

export const buildDocumentDataAsObj = (
  extraAttr: Object | undefined = undefined
) => <T extends IDEntity>(doc: firebase.firestore.DocumentSnapshot): T =>
  ({ ...doc.data(), ...extraAttr, id: doc.id } as T);

export function getDocsByID<T extends IDEntity>(
  collectionPath: string,
  ids: string[]
): Promise<T[]> {
  const getDocByID = (id: string): Promise<T> =>
    // @ts-ignore
    firebase
      .firestore()
      .doc(`${collectionPath}/${id}`)
      .get()
      .then(buildDocumentDataAsObj());
  return Promise.all(ids.map(getDocByID));
}

export function getProcessors(ref: string): Promise<IProcessor[]> {
  function getDocByQuery<T extends IDEntity>(
    docRef: firebase.firestore.DocumentReference,
    client: string[]
  ): Promise<T[]> {
    return docRef
      .collection("processors")
      .get()
      .then(
        (snapshot) =>
          snapshot.docs.map(buildDocumentDataAsObj({ client })) as T[]
      );
  }
  const refArr = ref.split("/");
  const isProcessor = ref.indexOf("/p/") >= 0;
  const processorID = refArr[refArr.length - 1]; // last item
  const client = isProcessor ? refArr.slice(0, -2) : refArr;
  const buildDocStr = client.map((it) => `clients/${it}`).join("/");
  const clientQuery = firebase.firestore().doc(buildDocStr);
  const docs = isProcessor
    ? clientQuery
        .collection("processors")
        .doc(processorID)
        .get()
        .then((doc) => ({ ...doc.data(), client, id: doc.id }))
        .then(Array.of)
    : getDocByQuery<IProcessor>(clientQuery, client);
  // @ts-ignore
  return docs;
}

export function fetchClientName(clientPath: string[]): Promise<string> {
  
  function onSuccess(result: string) {
    // @ts-ignore
    sessionStorage.setItem(JSON.stringify(clientPath), result);
    return result;
  }
  return reduceQueryByClient(clientPath, firebase.firestore())
    .get()
    .then((it) => it.data() as IClient)
    .then((it) => onSuccess(it.name));
}

export function getCompanyClientName(clientPath: string[]): Promise<string> {
  const clientName = sessionStorage.getItem(JSON.stringify(clientPath));
  if (clientName) {
    return Promise.resolve(clientName);
  }
  
  return fetchClientName(clientPath);
}


const buildClientProcessorKey = (client: string[], processor: string) =>
  `${client[0]}_${client[1]}_${processor}`;

export function fetchSubMerchantName(client: string[], processor: string): Promise<string> {
  if (!(client || processor)) {
    return Promise.reject(Error('Missing client and processor'));
  }
  const key = buildClientProcessorKey(client, processor);
  const value = sessionStorage.getItem(key);
  if (value !== null) {
    return Promise.resolve(value);
  }
  function onSuccess(it: IProcessor) {
    if (it === undefined) {
      return `${client[0]}/${client[1]}/p/${processor}_not-found`;
    }
    sessionStorage.setItem(key, it.name);
    return it.name;
  }
  return firebase.firestore().doc(`clients/${client[0]}/clients/${client[1]}/processors/${processor}`).get()
    .then(ds => ds.data() as IProcessor)
    .then(onSuccess);
}


export function fetchAgentName(uid: string, doGetEmail: boolean = false): Promise<string> {
  const agentDisplayName = sessionStorage.getItem(uid + (doGetEmail ? '@email' : emptyString));
  
  if (agentDisplayName) {
    return Promise.resolve(agentDisplayName);
  }
  function onSuccess(result: IUser) {
    if(!!!result){
      return `${uid}_not-found`;
    }
    // @ts-ignore
    sessionStorage.setItem(uid, result.displayName);
    sessionStorage.setItem(uid + '@email', result.email);
    return doGetEmail ? result.email : result.displayName ;
  }
  return firebase.firestore().doc(`users/${uid}`).get()
    .then(it => it.data() as IUser)
    .then(onSuccess);
}

export const showPrintVersion = (
  subMerchantName: string,
  history: History,
  basepath: string = "",
  shouldReplace: boolean = false
) => async (tranx: ITranx) => {
  // @ts-ignore
  const clientName = await getCompanyClientName(tranx.client);
  // @ts-ignore
  const agentName = await fetchAgentName(tranx.createdBy);
  const urlStr = buildQueryStr(
    tranx,
    clientName,
    agentName,
    subMerchantName
  );
  console.log('showPrintVersion-urlStr: ', urlStr)
  console.log('showPrintVersion-clientName: ', clientName)
  console.log('showPrintVersion-agentName: ',agentName)
  console.log('showPrintVersion-tranx: ',tranx)
  const toPrintVersion = {
    pathname: basepath + `/${tranx.invoiceNumber}/print-version`,
    state: { url: urlStr },
  };
  if (shouldReplace) {
    // @ts-ignore
    history.replace(toPrintVersion);
  } else {
    // @ts-ignore
    history.push(toPrintVersion);
  }
};

export function buildBaseQuery(
  collectionId: string,
  isAdmin: boolean,
  clients: string[][],
  processors: string[],
  processorsClientsTruncated: string[][],
  firestoreInst: firebase.firestore.Firestore,
  useSimpleCollection: boolean = false
) {
  const query = useSimpleCollection
    ? firestoreInst.collection(collectionId)
    : firestoreInst.collectionGroup(collectionId);
  const areParentClients =
    clients.length > 0 && clients.every((it) => it.length === 1);
  switch (true) {
    case processors.length === 1:
      return query.where("processor", "in", processors);
    case !isAdmin && areParentClients:
      return query.where("client", "array-contains-any", clients.flat());
    case !isAdmin && clients.length > 0:
      return query.where("client", "in", clients);
    case !isAdmin && processors.length > 1 && processors.length <= 10:
      return query.where("processor", "in", processors);
    case !isAdmin && processors.length > 10 && processorsClientsTruncated.length <=10:
      return query.where("client", "in", processorsClientsTruncated);
    default:
      return query;
  }

  return query

}



export function uploadFile(
  folderName: string,
  key: string,
  file: File
): Promise<string> {
  const fileName = buildFileName(key, file);
  const ref = firebase.storage().ref(folderName).child(fileName);
  const uploadTask = ref.put(file, { contentType: file.type });
  return new Promise((resolve, reject) => {
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
        // @ts-ignore
      },
      reject,
      () => resolve(fileName)
    );
  });
}
