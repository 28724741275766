import React from 'react';
import Root from 'Root';
import init from 'config/init';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faRecycle, faReplyAll } from '@fortawesome/free-solid-svg-icons'
library.add(fab, faRecycle, faReplyAll);
init();

const App: React.FC = () => {
  return (
    // @ts-ignore
    <Root />
  );
}

export default App;
