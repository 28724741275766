import * as React from "react";
import { useGlobalState } from "config/globalStateContext";

function buildURL(uid: string | undefined): string {
  const obj = {
    "ds0.uid": uid,
    "ds1.uid": uid,
    "ds2.uid": uid,
  };
  return `${process.env.REACT_APP_DASHBOARD_URL}?params=${encodeURIComponent(
    JSON.stringify(obj)
  )}`;
}

function Dashboard() {
  const [state] = useGlobalState();
  const url = React.useMemo(() => buildURL(state?.uid), [state?.uid]);
  return (
    <div className="main-content">
      <iframe
        width="100%"
        height="1300"
        src={url}
        frameBorder="0"
        style={{ border: 0, display: "block" }}
        allowFullScreen
      ></iframe>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          height: 65,
          width: "100%",
          backgroundColor: "white",
        }}
      ></div>
    </div>
  );
}

export default Dashboard;
