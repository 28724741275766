import React from "react";
import { useField } from "react-final-form";
import ProcessorSelect from "common/components/ProcessorSelect";
import { valueSubsctiption } from "common/utils/constants";

function ProcessorSelectField({ required = true, recurrent = false }) {
  const { input } = useField("merchantInfo");
  
  const { input: cardNumberInput } = useField("cardNumber", valueSubsctiption);
  console.log('ProcessorSelectField ', cardNumberInput.value)
  return (
    <ProcessorSelect
      className="form-control merchant-select"
      cardNumber={cardNumberInput.value}
      required={required}
      recurrent= { recurrent ? true : false }
      {...input}
    />
  );
}

export default ProcessorSelectField;
