import * as React from "react";
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { ITranx, IDisputeFormValues, IDisputeData } from 'types';
import { Form, Field, FormRenderProps } from 'react-final-form';
import { getCCName, getAttachmentFile, buildFileName, lazyApply, log, compose, getMessage } from 'common/utils/fns';
import { uploadFile } from "common/utils/firebase";
import { attachmentsFolderName } from "common/utils/constants";

import { useMessages } from "config/messagesStateContext";
import ErrorMessage from "common/components/ErrorMessage";
import firebase from "firebase";

import { FormApi } from "final-form";
import Maybe from "common/components/Maybe"

import { useDownloadURL } from "react-firebase-hooks/storage";
import { usePaymentId } from "../../common/hooks/utils"


import { useGlobalState } from "config/globalStateContext";
import {useAgentName, useUserPermissions} from "../../common/hooks/firestore";


import  Tabs  from "./components/Tabs"
import Swal from "sweetalert2";




interface IDisputesTabsProps{
    disputesList: string[],
    invoiceNumber: string,
    client: string[],
    cardNum: string,
    paymentAmount: number,
    createdBy: string,
    disputeDataList: IDisputeData[];
    itranx: ITranx

}

const amountFormatted = ( amount: any ): string => {
        
    

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    
      return formatter.format(amount);
}

const DisputesWithTabs = (props: IDisputesTabsProps) =>{
    const { disputesList,
            invoiceNumber,
            client, 
            paymentAmount,
            createdBy, 
            disputeDataList ,
            itranx
            } = props;
        
    const [paymentAmountFormttted, setpaymentAmountFormttted] = React.useState("0.00");
           
    const maybeAccess = useUserPermissions();
    const disputes = !!!maybeAccess?.dispute;
    console.log('DisputesWithTabs disputes: ', disputes)
    const [tokenSeed, resetToken] = usePaymentId('D');
    const [token, setToken] = React.useState(`${invoiceNumber}-${tokenSeed}`)
    const [labels, setLabels] = React.useState([""]);
    const [activeTab, setActiveTab] = React.useState(labels[0]);
    const [currentState, setCurrentState] = React.useState<IDisputeData>();
    const agentName = useAgentName(createdBy);

    const [loading, setIsLoading] = React.useState(false);
    const [canceling, setCenceling] = React.useState(false);
    const notInitialRender = React.useRef(false);
    const { addSuccess, addError, clear } = useMessages();
    console.log('disputeDataList', disputeDataList)
    console.log('DisputesWithTabs - agentName: ', agentName)
    console.log('disputesList: ', disputesList)
    console.log('path: ',client[0], client[1], 'trxs', invoiceNumber)
     

    React.useEffect(() => {
       

        
        const amountOut = amountFormatted( paymentAmount );
        setpaymentAmountFormttted( amountOut );

       

    }, [paymentAmount])
    
    


    
    const myBlankIDisputeFormValues = {
        brand: "",
        debitDate: "",
        reversalDate: "",
        paymentDate: "",
        caseNumber: "",
        date: "",
        type: "",
        dueDate: "",
        status: "",
        reason: "",
        comments: "",
        distype: "",
        disputeID: "",
        disputeAmount: paymentAmountFormttted,
      } as IDisputeData

    function onSuccess(result: firebase.functions.HttpsCallableResult) {
        addSuccess(result.data.message);
     goBackToReport();
    }



    const [userData] = useGlobalState();
    const fetchFromDoc = async (docAddrs: string) =>{
        return await firebase.firestore().doc(`disputes/trxs/${invoiceNumber}/${!!docAddrs ? docAddrs : 'nonExistent'}`)
        .get()
        .then(doc =>{
            if(doc.exists){
                const docContent = doc.data() as IDisputeData
                return docContent
            }
        })

    }

    // const setCurrStateFromProp = (tabID: string) =>{
    //     (typeof disputeDataList !== 'undefined' && disputeDataList.length === 0)
    //     ? setCurrentState(myBlankIDisputeFormValues)
    //     : setCurrentState(disputeDataList.find(({ disputeID}) => disputeID === tabID))
    // }
    const cardNum = props.cardNum;
    React.useEffect(() =>{

        const preliminaryArr = (typeof disputesList !== 'undefined' && disputesList.length === 0) 
        ? [token, 'new dispute']
        : [...disputesList, 'new dispute']

        const initial = disputes ? preliminaryArr.slice(0, preliminaryArr.length-1) : preliminaryArr
        
        setLabels(initial);
        setActiveTab(initial[0]);

        // setCurrStateFromProp(initial[0])

       (async () =>{
        const doc = await fetchFromDoc(disputesList[0])
        console.log('IIEF DOC: ', doc)
        if(doc?.disputeAmount){
            
            doc.disputeAmount = amountFormatted( parseFloat(doc?.disputeAmount || "0.00") );
        }
        if(!!doc){
            setCurrentState(doc)
        } else{
            setCurrentState(myBlankIDisputeFormValues)
        }
   
       })()

    },[])
    
    React.useEffect(() =>{
        if(notInitialRender.current && labels.includes(activeTab)){
            // setCurrStateFromProp(activeTab)
            fetchFromDoc(activeTab)
            .then((data) =>{
                    console.log('tab change v2: ', data)
                    if(!!data){
                        if(data?.disputeAmount){
                            data.disputeAmount = amountFormatted( parseFloat(data?.disputeAmount || "0.00") ).replace("-", "");
                        }
                        setCurrentState(data)
                    } else {
                        setCurrentState(myBlankIDisputeFormValues)
                    }
                
            })
            console.log('tab ID: ', activeTab)

        } else {
            notInitialRender.current = true;
            
        }

        
    },[activeTab])

    const [url] = useDownloadURL(
        currentState?.fileName
          ? firebase
              .storage()
              .ref(attachmentsFolderName + "/" + currentState.fileName)
          : null
      );

      const isLastTabStateFull = (lastTabStr: string) =>{
        const isLastTabStateFull =  !!disputeDataList.find(data => data.disputeID === lastTabStr)
        console.log('isLastTabStateFull - disputeDataList', disputeDataList)
        console.log('isLastTabStateFull - lastTabStr:', lastTabStr)
        console.log('isLastTabStateFull: ', isLastTabStateFull)
        return isLastTabStateFull
      }

    console.log('currentState', currentState)
    const history = useHistory();
    const onClose = history.goBack;

    const handleTabs = (tab: string) =>{
        if(!handleAddDisputeTab(tab)){
            setActiveTab(tab)
        }

    }

    const handleAddDisputeTab = (tab: string) =>{
        if(tab === labels[labels.length - 1]){
            if(isLastTabStateFull(labels[labels.length - 2])){
                let labelsCopy = [...labels]
                labelsCopy.splice(labels.length-1, 0, token)
                setLabels(labelsCopy)
                setActiveTab(token)
                setCurrentState(myBlankIDisputeFormValues)
                resetToken()
                return true
            } else {
                return true
            }
        } 
        return false

    }
    console.log('activeTab', activeTab)

    const bigTomatoDiv =  <div style={{width:'500px', height:'500px', backgroundColor:'tomato'}}>
                              <h1>{activeTab.toLocaleUpperCase()}</h1>
                          </div>
    const handleOnSubmit = async (values: IDisputeFormValues, form: FormApi<IDisputeFormValues>) => {
        setIsLoading(true);
        
      
        const updateDispute = firebase.functions().httpsCallable('updateDispute');
        console.log('handleOnSubmit called in DisputesWithTabs')
        const newDisputeList = disputesList.includes(activeTab) ? [...disputesList] : [...disputesList, activeTab];
        const file = getAttachmentFile('disputeForm');
        
       
        let dAmount = values.disputeAmount === undefined ? "0" : values.disputeAmount;
        
        if(dAmount?.includes("$")){
            dAmount = dAmount.replace("$","").trim();
        }

        if(dAmount?.includes(",")){
            dAmount = dAmount.replace(",","").trim();
        }
        
    
        const tempAmount = !!values.disputeAmount ? parseFloat(dAmount) : 0

    

        // const disAmount = values.distype === "RE" ? Math.abs(tempAmount) : -Math.abs(tempAmount)

        const disAmount = values.distype === "RE" ?  tempAmount : - tempAmount;



        let myDisputeData = {
            brand: values.brand,
            debitDate: values.debitDate === undefined ? null : values.debitDate ,
            reversalDate: values.reversalDate === undefined ? null : values.reversalDate ,
            paymentDate: values.paymentDate === undefined ? null : values.paymentDate ,
            caseNumber: values.caseNumber,
            date: values.date === undefined ? null : values.date ,
            type: values.type,
            dueDate: values.dueDate === undefined ? null : values.dueDate ,
            status: values.status,
            reason: values.reason === undefined ? null : values?.reason,
            comments:values.comments === undefined ? null : values?.comments,
            invoiceNumber: invoiceNumber,
            createdBy: userData?.uid,
            distype: values?.distype,
            ...(file.name ? { fileName: buildFileName(invoiceNumber + "_" + activeTab, file) } : undefined),
            disputeID:activeTab,
            disputeAmount: disAmount.toString(),
           disputeBy: userData?.displayName,
           itranx: itranx
        } as IDisputeData
        console.log('myDisputeData: ', myDisputeData)

       


        const idisputeIndex = disputeDataList.findIndex(({disputeID}) => disputeID === activeTab)
        console.log('idisputeIndex: ', idisputeIndex);
        let newDisputeDataArr = [...disputeDataList]
            if(idisputeIndex === -1){
                newDisputeDataArr.push(myDisputeData)
            } else{
                newDisputeDataArr.splice(idisputeIndex,1,myDisputeData)
            }
           

            await firebase.firestore().doc(`disputes/trxs/${invoiceNumber}/${activeTab}`)
            .set(myDisputeData)
            
            await firebase.firestore().doc(`disputes/all/tabs/${activeTab}`).set(myDisputeData)
            
            delete myDisputeData.itranx.disputeData;
            myDisputeData.itranx.disputeDataList = [];

            const updateDisputeData = {
                client: client,
                invoiceNumber: invoiceNumber,
                // tab: activeTab,
                disputeData: myDisputeData,
                disputesList: newDisputeList,
                disputeDataList: newDisputeDataArr
            }

            updateDispute(updateDisputeData)
            .then(onSuccess)
            .catch(compose(addError, getMessage, log("err while creating a dispute")))
            .then(lazyApply(setIsLoading, false))
    
            
        // V2
        // firebase.firestore()
        // .doc(`clients/${client[0]}/clients/${client[1]}/trxs/${invoiceNumber}`)
        // .update({
        //     disputesList: newDisputeList,
        //     disputeDataList: newDisputeDataArr
        // })
        // .then(() =>{
        //     firebase.firestore().doc(`disputes/trxs/${invoiceNumber}/${activeTab}`)
        //     .set(myDisputeData)
        // })
        // .then(() =>{
        //     addSuccess('Successfully applied');
        //     goBackToReport();
        //  })

        // V1
        // prototype with another location other than the transaction itself
        //  firebase.firestore().doc(`disputes/trxs/${invoiceNumber}/${activeTab}`)
        //  .set(myDisputeData)
        //  .then(() =>{
        //      firebase.firestore()
        //      .doc(`clients/${client[0]}/clients/${client[1]}/trxs/${invoiceNumber}`)
        //      .update({
        //          disputesList: newDisputeList
        //      })
        //     addSuccess('Successfully applied');
        //     goBackToReport();
        //  })
         
       
        setIsLoading(false);
        
    }
    const goBackToReport = () =>{
        history.push({pathname:`/refreshReport`})
    }

    const forgetDispute = () =>{
        const disputesListCopy = [...disputesList]
        const currDisId = disputesListCopy.findIndex(dis => dis === activeTab)
        disputesListCopy.splice(currDisId, 1)
        const idisputeIndex = disputeDataList.findIndex(({disputeID}) => disputeID === activeTab)
        const newDisputeDataArr = [...disputeDataList]
        newDisputeDataArr.splice(idisputeIndex,1)
        firebase.firestore()
        .doc(`clients/${client[0]}/clients/${client[1]}/trxs/${invoiceNumber}`) 
        .update({
            disputesList: disputesListCopy,
            disputeDataList: newDisputeDataArr
        })
        .then(async () =>  {
            console.log('forgetDispute called in DisputesWithTabs: deleted from itranx')
            await firebase.firestore().doc(`disputes/all/tabs/${activeTab}`).delete()
            addSuccess('Successfully Deleted');
            goBackToReport()
        })
        // .then(() =>{
        //     firebase.firestore().doc(`disputes/tabs/${invoiceNumber}/${activeTab}`)
        //     .delete()
        // })
        // .then(() => console.log('forgetDispute called in DisputesWithTabs: deleted from disputes collec'))
        // .then(() =>{
        //     addSuccess('Successfully Deleted');
        //     goBackToReport()
        // })
        
    }

    const todaysDate = () =>{
        const d = new Date();
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${ye}-${mo}-${da}`;
    }

    // const validateDate = (value: string) =>{
    //     const incomingDate = new Date(value)
    //     const todaysDate = new Date(formattedDate()).getTime()
    //     // console.log('validateDate:', incomingDate.getTime() > todaysDate)
    //     return undefined

    // }
    const operationTypeRequired = (value: string) => {

        if(value === 'unselected' || value?.length === 0 || value === undefined)
            return "Required";
        
        return undefined;
    }
    const statusRequired = (value: string) => (value === 'unselected' ? "Required" : undefined);

    const currentUser = firebase.auth().currentUser?.displayName;

    const onCancelDispute = () => { 

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
          
          swalWithBootstrapButtons.fire({
            title: 'Are you sure to proceed?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true,
            buttonsStyling: true
          }).then((result) => {
            if (result.isConfirmed) {
              forgetDispute(); 
            } 
          })
    }
    
    return(
        <div className="modal show scrollable-y " id="disputeModal" role="form" >
             <div className="modal-dialog modal-dialog-centered">
             <div className="modal-content">
             <div className="modal-header">
                    <div className="modal-title" id="disputeModalLabel">
                       <h1>{`Dispute > ${!!currentState?.disputeID ? 'Edit dispute' : 'New dispute'}`}</h1> 
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={onClose}
                            >
                            <span aria-hidden="true">&times;</span>
                            </button>
                </div>
                <div className="tabs-container">
                    <Tabs labels={labels} activeTab={activeTab} setActiveTab={handleTabs} />
                    <div className="modal-body" >
                    <Form 
                                onSubmit={handleOnSubmit}
                                        
                                        initialValues={currentState as IDisputeFormValues }
                                        render={({ handleSubmit, values, form}) => (
                                        <>
                                        <form onSubmit={handleSubmit} name="disputeForm">
                                            <div className="row">
                                            <div className="col">
                                                    <label htmlFor="brand">Brand</label>
                                                    <Field
                                                        name="brand"
                                                        className="form-control"
                                                        component="input"
                                                        id="brand"
                                                        type="text"
                                                        placeholder={cardNum}
                                                        initialValue={cardNum}
                                                        disabled={true}
                                                        
                                                    />
                                            </div>
                                            <div className='col'>
                                                 <label htmlFor="debitDate">Debit date</label>
                
                                                <Field
                                                    name='debitDate'
                                                    component="input"
                                                    type="date"
                                                    className="form-control" 
                                                    id='debitDate'
                                                    max={todaysDate()}
                                                    disabled={disputes}
                                                    
                                                />
                                         
                                            
                                             </div>
                                             <div className='col'>
                                                 <label htmlFor="reversalDate">Reversal Date </label>
                
                                                <Field
                                                    name='reversalDate'
                                                    component="input"
                                                    type="date"
                                                    className="form-control" 
                                                    id='reversalDate'
                                                    max={todaysDate()}
                                                    disabled={disputes}
                                                      />
                                            
                                             </div>
                                             <div className='col'>
                                                 <label htmlFor="paymentDate">Payment date</label>
                
                                                <Field
                                                    name='paymentDate'
                                                    component="input"
                                                    type="date" 
                                                    className="form-control"
                                                    id='paymentDate' 
                                                    max={todaysDate()}
                                                    disabled={disputes}
                                                    />
                                            
                                             </div>
                                            
                                            </div>
                                            <div className="row">
                                                 <div className="col">
                                                    <label htmlFor="disputeAmount">Dispute Amount</label>
                                                    <Field
                                                        name='disputeAmount'
                                                        type="text" 
                                                        className="form-control"
                                                        id='disputeAmount'
                                                        component="input"
                                                        initialValue={ paymentAmountFormttted }
                                                        disabled={disputes}
                                                         
                                                    />
                                                    
                                                </div>
                                                <div className="col">
                                                    {/* <label htmlFor="date"> Date (*)</label> */}
                                                    <label htmlFor="date"> Date</label>

                                                    <Field
                                                        name='date'
                                                        type="date"
                                                        className="form-control" 
                                                        id='date' 
                                                        component="input"
                                                        // required
                                                        max={todaysDate()}
                                                        disabled={disputes}
                
                                                    />
                                                </div>
                                                <div className="col">
                                                <label htmlFor="type">Operation Type (*)</label>
                                                <Field
                                                     name='type'
                                                     required
                                                     validate={operationTypeRequired}
                                             
                                                >
                                                        {({input, meta}) =>(
                                                            <>
                                                            
                                                            <select className="form-control" id='type' {...input} disabled={disputes} required>
                                                             <option value="" >-- Operation type --</option>
                                                            <option value="Chargeback">Chargeback</option>
                                                            <option value="Inquiry">Inquiry</option>
                                                            <option value="Pre-arbitration">Pre-arbitration</option>
                                                            <option value="ARBITRATION">Arbitration</option>
                                                            <option value="RETRIEVAL">Retrieval</option>
                
                                                            </select>
                                                            {meta.touched && meta.error && <span style={{color:'red'}}>{meta.error}</span>}
                                                            </>
                                                        )}
                                        
                                                </Field>
                                                </div>
                                                <div className="col">
                                                {/* <label htmlFor="dueDate">Due date (*)</label> */}
                                                <label htmlFor="dueDate">Due date</label>
                
                                                    <Field
                                                        name='dueDate'
                                                        type="date" className="form-control" id='dueDate'
                                                        component="input"
                                                        // required
                                                 
                                                        disabled={disputes}
                                                    />
                                                </div>
                
                                            </div>
                                            <div className="row">
                                            <div className="col-3">
                                                    <label htmlFor="disputeby">Dispute by</label>
                                                    <Field
                                                        name="disputeby"
                                                        className="form-control"
                                                        component="input"
                                                        id="disputeby"
                                                        type="text"
                                                        placeholder={ currentState?.disputeID ? currentState?.disputeBy : currentUser }
                                                        initialValue={ currentState?.disputeID ? currentState?.disputeBy : currentUser }
                                                        disabled={true}
                                                        
                                                    />
                                            </div>
                                                <div className="col-3">
                                                <label htmlFor="status">Status (*)</label>
                                                <Field
                                                     name='status'
                                                     required
                                                    validate={statusRequired}
                                                >
                                                        {({input, meta}) =>(
                                                            <>
                                                            
                                                            <select required className="form-control" id='status' {...input} disabled={disputes}>
                                                            <option value="" >-- Dispute status --</option>
                                                            <option value="disputing">Disputing</option>
                                                            <option value="expired">Expired</option>
                                                            <option value="pending">Pending</option>
                                                            <option value="accepted">Accepted</option>
                                                            <option value="closed">Closed</option>
                                                            <option value="lost">LOST</option>
                                                            <option value="won">WON</option>
                
                                                            </select>
                                                            {meta.touched && meta.error && <span style={{color:'red'}}>{meta.error}</span>}
                                                            </>
                                                        )}
                                        
                                                </Field>
                                                </div>
                                                <div className="col-3">
                                                <label htmlFor="distype">Dispute Type</label>
                                                <Field
                                                     name='distype'
                                               
                                                     
                                                >
                                                        {({input, meta}) =>(
                                                            <>
                                                            
                                                            <select className="form-control" id='status' {...input} disabled={disputes}>
                                                            <option value="unselected" >-- Dispute type --</option>
                                                            <option value="RE">Reversal</option>
                                                            <option value="DE">Debit</option>
                                                            <option value="CL">Claim</option>
                                                           
                
                                                            </select>
                                                            {meta.touched && meta.error && <span style={{color:'red'}}>{meta.error}</span>}
                                                            </>
                                                        )}
                                        
                                                </Field>
                                                </div>
                                                <div className="col-3">
                                                    <label htmlFor="caseNumber">Case # (*)</label>
                                                    <Field
                                                        name='caseNumber'
                                                        type="text" 
                                                        className="form-control"
                                                        id='caseNumber'
                                                        component="input"
                                                         required
                                                         disabled={disputes}
                
                                                    />
                                                    
                                                </div>
                                              
                                                {url ?
                                                    <div className="col" style={{display:'flex', flexDirection:'column'}}>
                                                    <label>Download evidence</label>
                                                    <a target="_blank" href={url} className={url ? undefined : "hidden"}>
                                                        <img src="/assets/images/icons/Download.svg" alt="" />
                                                    </a>
                                                    </div>
                                                    : <></>
                                                }
                                            </div>
                                            
                                            <div className="row">
                                           
                                                <div className="col-4">
                                                    <label htmlFor="disputeID">Dispute ID</label>
                                                    <Field
                                                        name='disputeID'
                                                        type="text" 
                                                        className="form-control"
                                                        id='disputeID'
                                                        component="input"
                                                        placeholder={activeTab}
                                                        disabled={true}
                
                                                    />
                                                    
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                <label htmlFor="reason">Reason</label>
                                  
                                                    <Field
                                                        name='reason'
                                                        className="form-control"
                                                        component='textarea'
                                                        id='reason'
                                                        type='text'
                                                        rows='5'
                                                        initialValue={""}
                                                        disabled={disputes}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                <label htmlFor="comments">Comments</label>
                                  
                                                    <Field
                                                        name='comments'
                                                        className="form-control"
                                                        component='textarea'
                                                        id='comments'
                                                        type='text'
                                                        rows='5'
                                                        initialValue={""}
                                                        disabled={disputes}
                                                    />
                                                </div>
                                            </div>
                
                                            <div className="row">
                                            <fieldset  style={{paddingLeft:'20px'}}>
                                                <div className="form-group has-icon">
                                                
                                                    <label htmlFor="attachment">Upload file (i.e.: evidence)</label>
                                                    <div>
                                                        <img src="/assets/images/icons/Upload.svg" alt="" />
                                                        <input name="attachment" type="file" title="TODO" />
                                                    </div>
                                                </div>
                
                                          
                                               
                                            </fieldset>
                                            
                                            {/* <UploadForm state={state} onClose={onClose}/>  justifyContent:'space-between'*/}
                                            </div>
                                            {/* <pre>{JSON.stringify(values)}</pre> */}
                                            <div style={{display:'flex', flexDirection: `row-reverse`, 
                                                }}>
                                                {/* <button
                                                 className="btn btn-primary"
                                                 onClick={() => form.reset()}
                                                 >
                                                    Reset all
                                                </button> */}
                                                <button
                                                    disabled={loading}
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    style={{marginRight:'15px'}}
                                                >
                                                    {loading ? 'Saving...' : 'Save'}
                                                </button>
                                                
                                                <Maybe component={'div'} style={{marginRight:'15px'}} visible={currentState?.disputeID !== ""}>
                                                <button
                                                    disabled={loading}
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    data-dismiss="modal"
                                                    onClick={() => onCancelDispute() }
                                                    style={{backgroundColor:'red'}}
                                                    >
                                                    {canceling ?'canceling...' : 'cancel dispute'}
                                            </button>
                                           
                                            </Maybe>
                                                </div>
                                            </form>
                                        </>
                                        
                                        )}
                                    />
                    </div>
                </div>
                <div className="modal-footer">
            
                    <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={onClose}
                    >
                     Close
                </button>

                </div>
             </div>
             </div>
        </div>
    )
}

export default DisputesWithTabs