import * as React from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { ITranx } from 'types';
import RechargeInput from './RechargeInput';

function RechargeModal() { 
  const { state } = useLocation<ITranx|undefined>();
  const history = useHistory();
  const onClose = history.goBack;
  if (!state) {
    return <Redirect to="/" />;
  }
  return(
    <div className="modal show" id="rechargeModal" tabIndex={-1} role="dialog" aria-labelledby="rechargeModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="rechargeModalLabel">Recharge Payment {state.invoiceNumber}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <RechargeInput
              isModal={true}
              state={state}
              onClose={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RechargeModal;
