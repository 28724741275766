import * as React from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { ITranx } from 'types';

interface IProps {
}

function PrintableDetail(props: IProps) {
  const history = useHistory();
  const { state } = useLocation<{ url: string }>();
  console.log('PrintableDetail - state: ', state)
  function onPrintClick() {
    // @ts-ignore
    const voucherIframe = window.frames['voucher'];
    voucherIframe.focus();
    voucherIframe.contentWindow.print();
    return false;
  }
  if (!state) {
    return <Redirect to="/" />;
  }

  //console.log('PrintableDetail-url: ', state.url)
  
  return (
    <div className="modal fade print-voucher-modal show" id="printVoucherModal" tabIndex={-1} role="dialog" aria-labelledby="printVoucherModalTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">Payment voucher</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={history.goBack}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <iframe id="voucher" src={state.url} frameBorder={0} width={400} height={640} />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={history.goBack}>Close</button>
            <button className="print btn btn-primary" onClick={onPrintClick}>
              <img src="/assets/images/icons/print.svg" alt="" /> Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintableDetail;