import * as React from 'react';
import * as firebase from 'firebase/app';
// import 'firebase/auth';
import 'firebase/firestore';
import { useDocumentDataOnce, useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useDocumentDataOnceByIds } from 'common/hooks/firestore';
import { emptyArray, emptyString, optionsByIdField } from 'common/utils/constants';
import { IClient } from 'types';
import { useMessages } from 'config/messagesStateContext';

interface IProps {
  parent?: string,
  client?: string|undefined,
  disabled?: boolean, 
  required?: boolean,
  onChange(client: string|undefined): void,
}


function ClientSelect(props: IProps) {
  const { parent, client, onChange, disabled, required } = props;
  const query = React.useMemo(() =>
    firebase.firestore()
      .collection((parent ? `clients/${parent}/` : emptyString) + 'clients' ),
    [parent]
  );
  const [clients, loading, error] = useCollectionDataOnce<IClient>(query, optionsByIdField);
  const placeholder = loading ? 'Loading...': 'Select a client';
  const clientsOptions = React.useMemo(() => clients?.map((it) => (
    <option key={it.id} value={it.id}>
      {it.name}
    </option>
  )), [clients]);
  const { addError } = useMessages();
  React.useEffect(() => {
    if (error) {
      addError(error.message);
    }
  }, [error]);
  const onLocalChange = React.useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => onChange(ev.target.value), [onChange]);
  return (
    <select
      className="form-control merchant-select"
      disabled={disabled}
      required={required}
      value={client}
      onChange={onLocalChange}
    >
      <option value={emptyString}>{placeholder}</option>
      {clientsOptions}
    </select>
  );
}

export default ClientSelect;
