import React, { useState, useEffect } from "react";
import { Field, Form, FormRenderProps } from "react-final-form";

import { useLocation, useHistory, Redirect, Link } from "react-router-dom";

import firebase from "firebase";

import FrequencySelect from "./components/FrequencySelect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import ProcessorSelectField from "../Payment/components/ProcessorSelectField";
import { RecurringPaymentServices } from "services/Recurring Payment/RecurringPaymentServices";
import { ISchedule } from "services/Recurring Payment/models/responses/ISchedule";
import { formatDate } from "common/utils/card";
import Maybe from "common/components/Maybe";
import { IFindRequest } from "services/Recurring Payment/models/IFindRequest";
import { IStatus } from "services/Recurring Payment/models/responses/IStatus";
import moment from "moment";

interface IFormValueProps {
  [key: string]: any;
  // id?: string,
  // merchantInfo?: string,
  // datefrom?: string,
  // dateTo?: string,
  // freqSelect?: string,
  // amount?: string,
  // cardHolder?: string,
  // cardNumber?: string,
  // expiry?: string,
  // cvv?: string,
  // contact?: string,
  // email?: string,
  // addressOne?: string,
  // reference?: string,
  // numOfEvents?: string,
  // countrySelect?: string,
  // state?: string,
  data: ISchedule;
  loadData?: Function;
}

// receive string and return string withouth first and last character
const removeFirstLastChar = (str: string) => {
  return str.substring(1, str.length - 1);
};

const reccurringPayment = new RecurringPaymentServices();

const RowItem = ({ data }: IFormValueProps) => {
  const [formValues, setFormValues] = useState<IFormValueProps>();
  const [freqSelect, setFreqSelect] = useState<any>(data);
  const [toggleEdit, setToggleEdit] = useState(false);

  const to = {
    pathname: `/RecurringPay/details/${data._id}`,
    state: {
      data: { ...data, edit: false },
      freqSelect: freqSelect,
    },
  };

  const toEdit = {
    pathname: `/RecurringPay/details/${data._id}`,
    state: {
      data: { ...data, edit: true },
      freqSelect: freqSelect,
    },
  };

  // console.log('freqSelect', freqSelect)
  const startDate = moment(data.startDate).toDate();
  const endDate = moment(data.finalDate).toDate();
  console.log("reccurringPayment - RowItem", data);

  return (
    <>
      <td className={data.havePendingTransaction ? "color-white" : ""}>
        {data._id}
      </td>
      <td className={data.havePendingTransaction ? "color-white" : ""}>
        {data.frecuencyDb && data.frecuencyDb.length > 0
          ? data.frecuencyDb![0].description!
          : ""}
      </td>
      <td className={data.havePendingTransaction ? "color-white" : ""}>
        {data.merchantName?.toUpperCase()}
      </td>
      <td className={data.havePendingTransaction ? "color-white" : ""}>
        {data.reference}
      </td>
      <td className={data.havePendingTransaction ? "color-white" : ""}>
        {formatDate(startDate) + " / " + formatDate(endDate)}
      </td>
      <td className={data.havePendingTransaction ? "color-white" : ""}>
        {data.procesadorType}
      </td>
      <td className={data.havePendingTransaction ? "color-white" : ""}>
        {formatDate(data.lastRun)}
      </td>
      <td className={data.havePendingTransaction ? "color-white" : ""}>
        {data.amount}
      </td>
      <td className={data.havePendingTransaction ? "color-white" : ""}>
        {data.status == "complete" ? `${data.status}d` : data.status}
      </td>
      <td className={data.havePendingTransaction ? "color-white" : ""}>
        {/* <Link to={to}>
        <img alt="View" src="/assets/images/icons/eye.svg" />
      </Link> */}
        {/* <img alt="View" src="/assets/images/icons/eye.svg" onClick={() => setToggleEdit(!toggleEdit)}/> */}
        <Maybe component={Link} visible={true} to={to}>
          <img
            alt="View"
            src="/assets/images/icons/eye.svg"
            onClick={() => setToggleEdit(!toggleEdit)}
          />
        </Maybe>
        <Maybe component={Link} visible={true} to={toEdit}>
          <img
            style={{ width: "20px", marginLeft: "2px" }}
            alt="Edit"
            src="/assets/images/icons/edit.svg"
            onClick={() => setToggleEdit(!toggleEdit)}
          />
        </Maybe>
      </td>
    </>
  );
};

const ScheduledPayMain = () => {
  const [fromDate, setFromDate] = useState<string>("");
  const [createdDate, setCreatedDate] = useState<string>("");
  const [lastDate, setLastDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [data, setData] = useState<ISchedule[]>([]);
  const [status, setStatus] = useState<IStatus[]>([]);
  const [nextPage, setNextPage] = useState(1);
  const history = useHistory();

  const scheduleData = async () => {
    console.log("Buscando datos", "Hola mundo");
    const resp = await reccurringPayment.GetSchedulesList(nextPage, 10);
    if (resp != null) {
      console.log(resp);
      if (!resp.hasError) {
        // console.log("xxxxxx", resp.Data?.docs)
        const d = resp.Data?.docs as ISchedule[];
        setData(d);
      }
    }
  };

  const getStatus = async () => {
    const resp = await reccurringPayment.GetStatus();
    if (resp !== null) {
      if (!resp.hasError) {
        const s = resp.Data as IStatus[];
        setStatus(s);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    scheduleData();
  }, [nextPage, setNextPage, history.action]);

  const loadData = async () => {
    await scheduleData();
    await getStatus();
  };

  const handledNextPage = () => {
    if (status.length > 0) {
      setNextPage(nextPage + 1);
    }
  };

  const handledPreviousPage = () => {
    if (nextPage > 1) {
      setNextPage(nextPage - 1);
    }
  };

  console.log("history", history);
  const onClose = history.goBack;
  const initialValue: IFindRequest = {};

  const handleOnSubmit = (value: IFindRequest) => {
    value.endDate = toDate.length > 0 ? new Date(toDate) : undefined;
    value.startDate = fromDate.length > 0 ? new Date(fromDate) : undefined;
    value.createdDate =
      createdDate.length > 0 ? new Date(createdDate) : undefined;
    value.lastProcessedDate =
      lastDate.length > 0 ? new Date(lastDate) : undefined;

    //TODO: logica para buscar la data y cargar la data
    reccurringPayment
      .GetFindSchedules(value, 1, 10)
      .then((resp) => {
        if (resp?.hasError === false) {
          let d = resp.Data?.docs as ISchedule[];
          if (!d) {
            d = resp.Data;
          }
          setData(d);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        setData([]);
      });
  };

  return (
    <div className="main-content " style={{ height: "100vh" }}>
      <div className="row" style={{ marginTop: "20px" }}>
        <div className="col-4">
          <h2>Search</h2>
        </div>
      </div>

      <Form
        onSubmit={handleOnSubmit}
        initialValues={initialValue}
        // onSubmit={(x ) => console.log("xxxxxxxxxxxx", x)}
        // render=
      >
        {({ handleSubmit, values, form }: FormRenderProps<IFindRequest>) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-3">
                <div
                  className="form-group form-inline"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <label htmlFor="subscriptionId">Subscription Id</label>
                  <Field
                    component="input"
                    name="subscriptionId"
                    className="form-control"
                    type="text"
                    id="subscriptionId"
                  />
                </div>
              </div>

              <div className="col-3">
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <label htmlFor="merchant" style={{ marginBottom: "0px" }}>
                    Merchant
                  </label>
                  {/* <Field
                                component="input"
                                name="merchant"
                                className="form-control"
                                type="text"
                                id="merchant"
                  
                              /> */}
                  <ProcessorSelectField required={false} recurrent />
                </div>
              </div>
              <div className="col-3">
                <div
                  className="form-group form-inline"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <label htmlFor="createdDate">Created Date </label>

                  <Field name="createdDate ">
                    {({ input }) => (
                      <input
                        {...input}
                        className="form-control"
                        id="createdDate"
                        type="date"
                        value={createdDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setCreatedDate(e.target.value)
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className="col-3">
                <div
                  className="form-group form-inline"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <label htmlFor="lastProcessedDate">
                    Last processed date{" "}
                  </label>

                  <Field name="lastProcessedDate ">
                    {({ input }) => (
                      <input
                        {...input}
                        className="form-control"
                        id="lastProcessedDate"
                        type="date"
                        value={lastDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setLastDate(e.target.value)
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <div
                  className="form-group form-inline"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <label htmlFor="startDate">Start Date</label>

                  <Field name="startDate">
                    {({ input }) => (
                      <input
                        {...input}
                        className="form-control"
                        id="startDate"
                        type="date"
                        value={fromDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFromDate(e.target.value)
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className="col-3">
                <div
                  className="form-group form-inline"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <label htmlFor="endDate">End Date</label>

                  <Field name="endDate">
                    {({ input }) => (
                      <input
                        {...input}
                        className="form-control"
                        id="endDate"
                        type="date"
                        value={toDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setToDate(e.target.value)
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className="col-3">
                <div
                  className="form-group form-inline"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <label htmlFor="state">Status</label>
                  {/* <select name="state" id="status"  className="form-control" style={{paddingRight:'40px'}}>
                                  <option value="">--Choose an option--</option>
                                  {
                                    status.map(s =>{
                                      return  <option value="${s.code}">${s.descripcion}</option>
                                    })
                                  }
                              </select> */}
                  <Field name="status" type="text" id="status">
                    {({ input }) => (
                      <select
                        {...input}
                        className="form-control"
                        style={{ paddingRight: "40px" }}
                        id="status"
                        placeholder="--Choose an option--"
                      >
                        <option value="">--Choose an option--</option>
                        {status?.map((f: IStatus) => (
                          <option key={f._id} value={f.code}>
                            {f.descripcion}
                          </option>
                        ))}
                      </select>
                    )}
                  </Field>
                </div>
              </div>

              <div className="col-3">
                <div
                  className="form-group form-inline"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <label htmlFor="amount">Amount</label>
                  <Field
                    component="input"
                    name="amount"
                    className="form-control"
                    type="text"
                    id="amount"
                  />
                </div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div className="form-buttons">
                <button
                  className="btn btn-primary"
                  type="submit"
                  style={{ marginRight: "20px" }}
                >
                  Search
                </button>
                <button
                  type="reset"
                  className="btn btn-secondary"
                  onClick={() => {
                    setToDate("");
                    setFromDate("");
                    setCreatedDate("");
                    setLastDate("");
                    scheduleData();
                    form.reset();
                  }}
                >
                  Clear All
                </button>
              </div>
            </div>
          </form>
        )}
      </Form>
      {/* </> */}

      <div className="row">
        <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                <th>Subscription ID</th>
                <th>Frequency</th>
                <th>Merchant</th>
                <th>Reference</th>
                <th>Start date - End date</th>
                <th>Processor</th>
                <th>Last Processed day</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!!data &&
                data.map((item) => (
                  // havePendingTransaction
                  <tr
                    style={{
                      backgroundColor: item.havePendingTransaction
                        ? "#dc3545"
                        : "white",
                    }}
                  >
                    {" "}
                    <RowItem
                      loadData={() => {
                        scheduleData();
                      }}
                      key={item._id}
                      data={item}
                    />{" "}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className="page-item">
            <button className="page-link" onClick={() => handledPreviousPage()}>
              Previous
            </button>
          </li>
          <li className="page-item">
            <button className="page-link" onClick={() => handledNextPage()}>
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default ScheduledPayMain;
