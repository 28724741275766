import * as React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useField } from 'react-final-form';
import { emptyArray, emptyString, optionsByIdField } from 'common/utils/constants';
import { IProcessor } from 'types';
import { useMessages } from 'config/messagesStateContext';

interface IProps {
  type: string,
  merchantID: string|undefined,
  placeholder?: string,
  onChange(merchantID: string|undefined): void,
}

function ProcessorSelect(props: IProps) {
  const { type, merchantID, onChange } = props;
  const query = React.useMemo(() => firebase.firestore().collection('processors').where('type', '==', type), [type]);
  const [processors, isLoading, error] = useCollectionDataOnce<IProcessor>(query, optionsByIdField);
  const placeholder = isLoading ? 'Loading...' : props.placeholder!;
  const processorsOptions = React.useMemo(() => processors?.map((it) => (
    <option key={it.id} value={it.id}>
      {it.id}
    </option>
  ) ?? null), [processors]);
  const { addError } = useMessages();
  React.useEffect(() => {
    if (error) {
      addError(error.message);
    }
  }, [error]);
  const onLocalChange = React.useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => onChange(ev.target.value), [onChange]);
  return (
    <select
      className="form-control merchant-select"
      onChange={onLocalChange}
      value={merchantID}
    >
      <option value={emptyString}>{placeholder}</option>
      {processorsOptions}
    </select>
  );
}

ProcessorSelect.defaultProps = {};

export function FinalProcessorSelect(props: { type: string, placeholder?: string }) {
  const { type, placeholder } = props;
  const { input } = useField<string>(`pt.${type}`);
  return (
    <td>
      <ProcessorSelect
        type={type}
        merchantID={input.value}
        onChange={input.onChange}
        placeholder={placeholder}
      />
    </td>
  );
}

FinalProcessorSelect.defaultProps = {
  placeholder: 'Select one',
}

export default ProcessorSelect;
