import * as React from "react";
import "firebase/firestore";
// @ts-ignore
import payment from "payment";
import { useGlobalState } from "config/globalStateContext";
import { IProcessor } from "types";
import firebase from "firebase";

const formatProcessorConfig = (it: IProcessor): string => {
  const recurrent = it.isRecurrent ? "- Recurrent" : "";
  return `${it.name} - ${it.id} ${recurrent}`;
};

function getProcessorsByCardType(
  processors: IProcessor[] | undefined,
  cardNumber?: string
): IProcessor[] | undefined {
  const cardType = payment.fns.cardType(cardNumber);
  const anyRestorCom = processors?.some((it) => it.type === "resortcom");
  return cardType === "amex" && anyRestorCom
    ? processors
        ?.map((it) => ({ ...it, active: it.type === "resortcom" }))
        ?.sort((a, b) => Number(b.active) - Number(a.active))
    : processors;
}

type Props = React.InputHTMLAttributes<HTMLSelectElement> & {
  cardNumber?: string;
  recurrent?: boolean;
};

function ProcessorSelect(props: Props) {
  const [user, setUserData] = useGlobalState();
  const [value, setValue] = React.useState(props.defaultValue ?? props.value);
  const [codikaProcessor, setCodikaProcessor] = React.useState<IProcessor>();
  const [processorType, setProcessorType] = React.useState("");
  const [proccesorRecurrent, setProccesorRecurrent] = React.useState<
    IProcessor[]
  >([]);
  console.log("processorType:", processorType);

  function onLocalChange(ev: React.SyntheticEvent<HTMLSelectElement>) {
    // @ts-ignore
    props.onChange?.(ev);
    // @ts-ignore
    setValue(ev.target.value);
  }

  React.useEffect(() => {
    const processor = user?.processors?.find((it) => it?.id === value);
    if (processor) {
      // @ts-ignore
      setUserData((global) => ({
        ...global,
        specificClient: processor?.client,
      }));
      console.log("processor: ", processor.store);
      console.log(processor?.srcId);
    }
  }, [value]);

  React.useEffect(() => {
    const processor = user?.processors?.find((it) => it?.id === value);
    if (processor) {
      // @ts-ignore
      setProcessorType(processor.type);
      console.log("processor clients", processor.client, processor.client1);
    }
  }, [value]);

  React.useEffect(() => {
    const arr: IProcessor[] = [];
    user?.processors?.filter((it) => {
      if (it?.isRecurrent) {
        arr.push(it);
      }
    });
    setProccesorRecurrent(arr);
  }, [user]);

  console.log("ProcessorSelect", user?.processors);
  console.log("ProcessorSelect - recurrent", props?.recurrent);
  return (
    <>
      {props?.recurrent ? (
        <select {...props} onChange={onLocalChange}>
          <option value="">{props.placeholder ?? "Select a merchant"}</option>
          {proccesorRecurrent.map((it) => (
            <option key={it.id} value={it.id} disabled={it.active === false}>
              {formatProcessorConfig(it)}
            </option>
          ))}
        </select>
      ) : (
        <select {...props} onChange={onLocalChange}>
          <option value="">{props.placeholder ?? "Select a merchant"}</option>
          {user?.processors?.map((it) => (
            <option key={it.id} value={it.id} disabled={it.active === false}>
              {formatProcessorConfig(it)}
            </option>
          ))}
        </select>
      )}
    </>
  );
}

export default React.memo(ProcessorSelect);
// export default ProcessorSelect
