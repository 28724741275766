import React from "react";
import { Form, Field } from 'react-final-form';
import { IProcessor } from 'types';
import { emptyArray, submitingSubscription } from 'common/utils/constants';
import { FinalProcessorSelect as TypedProcessorSelect } from './TypedProcessorSelect';
import { usePaymentId } from "common/hooks/utils";
import { initCap } from "common/utils/fns";
import Maybe from "common/components/Maybe";
import ConditionalProcessorSelect from './ConditionalProcessorSelect';
import FormBottom from './FormBottom';
import { useInternalProcessorForm } from '../utils';

interface IProps {
  onReset(): void,
  doSave(values: IProcessor): Promise<void>,
  doDelete(): Promise<void>,
  values?: IProcessor,
  id: string|undefined,
}

const IsReccurrentOption = () =>{
  return (
    <div className="form-group">
        <Field
          allowNull
          type="checkbox"
          defaultValue={false}
          component="input"
          id="isRecurrent"
          name="isRecurrent"
        />
        &nbsp;
        <label htmlFor="isRecurrent">Is this a recurrent merchant?</label>
      </div>
    
  )
}



const cc = 'visa,mastercard,amex,discover,dinersclub,jcb'.split(',');

function ConditionalProcessorsForm(props: IProps) {
  const ccHeaders = React.useMemo(() => cc.map(it => <th key={it}>{initCap(it)}</th>), emptyArray);
  const rows = React.useMemo(() => cc.map(it => (
    <ConditionalProcessorSelect
      key={it}
      ccType={`cc.${it}`}
    />
  )), emptyArray);
  const { onSubmit, onDeleteClick, loading, isNew } = useInternalProcessorForm(props);
  const randomn = React.useMemo(() => (Math.random() * 10).toFixed(0), emptyArray);
  const [maybeId] = usePaymentId(randomn, true);
  return (
    <Form
      onSubmit={onSubmit}
      // onSubmit={(e) => {  console.log("xxxxxxxxx", e)  }}

      initialValues={props.values}
      subscription={submitingSubscription}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          
          <Field component="input" type="hidden" name="type" defaultValue="combined" />
          <fieldset disabled={loading}>
            <legend>Basic Info</legend>
            <div className="form-group">
              <label htmlFor="merchantId">Merchant ID</label>
              <Field
                component="input"
                className="form-control"
                id="merchantId"
                name="id"
                defaultValue={maybeId}
                required
                disabled={!isNew}
              />
            </div>
            <div className="form-group">
              <label htmlFor="store">Merchant Name</label>
              <Field
                component="input"
                className="form-control"
                name="name"
                required
              />
            </div>

            <IsReccurrentOption />

          </fieldset>
          <fieldset disabled={loading}>
            <legend>Merchant Types</legend>
            <table className="table">
              <thead>
                <tr>
                  <th>Azul</th>
                  <th>Resortcom</th>
                  <th>Trinity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TypedProcessorSelect type="azul" />
                  <TypedProcessorSelect type="resortcom" />
                  <TypedProcessorSelect type="trinity" />
                </tr>
              </tbody>
            </table>
          </fieldset>
          <fieldset disabled={loading}>
            <legend>Credit Card Types </legend>
            <table className="table">
              <thead>
                <tr>
                  {ccHeaders}
                  <th>Default</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {rows}
                  <ConditionalProcessorSelect
                    ccType="cc.default"
                    required
                  />
                </tr>
              </tbody>
            </table>
          </fieldset>
          <FormBottom
            isNew={isNew}
            onDeleteClick={onDeleteClick}
            loading={loading}
          />
        </form>
      )}
    />
  );
}

export default ConditionalProcessorsForm;
