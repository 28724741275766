import * as React from "react";
import ClientSelect from './components/ClientSelect';
import ProcessorSelect from './components/ProcessorSelect';
import PlainProcessorForm from './components/PlainProcessorForm';
import Maybe from 'common/components/Maybe';
import { IProcessor } from "types";
import { useProcessorKeys, useProcessorForm } from './utils';

interface IProps {
  client0: string,
  client1: string,
  merchantID: string|undefined,
  processor: IProcessor|undefined,
  onReset(): void,
}

function ProcessorForm(props: IProps) {
  const { client0, client1, merchantID, processor, onReset } = props;
  const { doSave, doDelete } = useProcessorForm(client0, client1, merchantID);
  return (
    <PlainProcessorForm
      values={processor}
      onReset={onReset}
      id={merchantID}
      doSave={doSave}
      doDelete={doDelete}
    />
  );
}

function Processors() {
  const {
    client0,
    setClient0,
    client1,
    setClient1,
    merchantID,
    setMerchantID,
    processor,
    processorSelectKey,
    handleReset,
  } = useProcessorKeys();
  return (
    <div>
      <ClientSelect client={client0} onChange={setClient0} />
      <Maybe
        component={ClientSelect}
        visible={!!client0}
        parent={client0}
        client={client1}
        onChange={setClient1}
      />
      <Maybe
        component={ProcessorSelect}
        visible={!!(client0 && client1)}
        client0={client0}
        client1={client1}
        merchantID={merchantID}
        onChange={setMerchantID}
        key={processorSelectKey}
        type="typed"
      />
      <Maybe
        component={ProcessorForm}
        visible={!!(client0 && client1)}
        client0={client0}
        client1={client1}
        merchantID={merchantID}
        processor={processor}
        onReset={handleReset}
      />
    </div>
  );
}

export default Processors;
