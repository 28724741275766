import React from 'react'

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

interface IExcelExportProps{
    DataArr: unknown[],
    file: string
}

export default function ExportToExcelFunction({DataArr, file}: IExcelExportProps) {
    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";


    const ws = XLSX.utils.json_to_sheet(DataArr);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, file + fileExtension);
  

};
