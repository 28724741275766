
import * as React from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { useMessages } from "config/messagesStateContext";
import { lazyApply, log, compose, getMessage } from 'common/utils/fns';
import { attachmentsFolderName } from "common/utils/constants";
import firebase from "firebase";
import { ITranx } from 'types';

const DisputeCancellationConfirmModal = () => {
    const { addSuccess, addError, clear } = useMessages();
    const [loading, setIsLoading] = React.useState(false);
    const [canceling, setCanceling] = React.useState(false);
    const history = useHistory();
    const {state} = useLocation<ITranx>()
   
    
    const onClose = history.goBack;
    const forgetDispute2 = async () =>{
        const goBackToReport = () =>{
            history.push({pathname:`/refreshReport`})
        }

        const successfullDelete = (result: firebase.functions.HttpsCallableResult) =>{
            addSuccess(result.data.message);
            goBackToReport()
        }
        setCanceling(true);
        const updateDisputeData = {
            client: state.client,
            invoiceNumber: state.invoiceNumber,
            disputeData: undefined
        }
        const updateDispute = firebase.functions().httpsCallable('updateDispute');
        updateDispute(updateDisputeData)
        .then(successfullDelete)
        .catch(compose(addError, getMessage, log("err while creating a dispute")))
        .then(lazyApply(setIsLoading, false))

        if(state.disputeData?.fileName){
            firebase.storage().ref(attachmentsFolderName).child(state.disputeData.fileName).delete();
        }

        

    }
return (
    <div className="modal show" id="disputeModal" role="form" >
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
            <div className="modal-header">
                <div className="modal-title" id="disputeModalLabel">
                   <h1>{`Please confirm dispute cancellation`}</h1> 
                    </div>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={onClose}
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
            </div>
            <div className="modal-body" style={{display:'flex', justifyContent:'center'}}>
                             <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => { forgetDispute2() }}
                                    style={{backgroundColor:'red'}}
                                    >
                                     {canceling ?'canceling...' : 'cancel dispute'}
                            </button>
            </div>
            <div className="modal-footer">
        
                <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={onClose}
                >
                 Close
            </button>

            </div>

        </div>
        </div>
    </div>
)

}
export default DisputeCancellationConfirmModal