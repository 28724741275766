import * as React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { Field } from 'react-final-form';
import { optionsByIdField, emptyArray } from 'common/utils/constants';
import { IUser, IDEntity } from 'types';
import { useAgentName } from 'common/hooks/firestore';
import { useUserInfo } from 'common/hooks/utils';

interface IProps {
  name: string
}

function Option(props: { uid: string }) {
  const agentName = useAgentName(props.uid);
  // console.log('UserBatchSelect: ', agentName, props.uid)
  return (
    <option value={props.uid}>{agentName}</option>
  );
}

function UserBatchSelect(props: IProps) {
  const [clients, processors, isAdmin, fullPathProcessors, processorsClientTruncated , adminOf] = useUserInfo(undefined);

  const [items, loading, error] = useCollectionDataOnce<IUser&IDEntity>(
    firebase.firestore().collection('users').where('access.batch', '==', true),
    optionsByIdField
  );
  
  const filterItems =  items?.filter((item)=>{ return item.adminOf?.some((adm)=> adminOf.includes(adm))})

  const defaultText = loading ? 'Loading...' : (error ? error.message : 'Select an user');
  return (
    <Field component="select" allowNull name={props.name} className="form-control">
      <option value="">{defaultText}</option>
      {(filterItems ?? emptyArray).map(it => <Option key={it.id} uid={it.id} />)}
    </Field>
  );
}

export default UserBatchSelect;
