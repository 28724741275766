import * as React from 'react';
import { activeStr, emptyString } from 'common/utils/constants';

type IProps = {
  newInvoiceNumber: string;
  operation: string,
  onSubmit: () => void;
  visible: boolean;
  handleClose: () => void;
};

function ModalConfirmation(props: IProps) {
  const mainClassName = `pop-message card refund-confirmation ${
    props.visible ? activeStr : emptyString
  }`;
  return (
    <div className={mainClassName} id="rechargeConfirmation">
      <a className="close dismiss-message" onClick={props.handleClose}>
        <img src="/assets/images/icons/close.svg" alt="" />
      </a>
      <div className="pop-message-content">
        <p>
          {props.newInvoiceNumber} Are you sure you want to {props.operation.toLocaleLowerCase()} this payment?
        </p>
        <button className="btn btn-revoke" onClick={props.onSubmit}>
          {props.operation} payment
        </button>
      </div>
    </div>
  );
}

export default ModalConfirmation;
