import React from 'react';
import * as firebase from 'firebase/app';
import "firebase/firestore";
import { FormApi } from 'final-form';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { usePaymentId } from 'common/hooks/utils';
import { emptyArray, optionsByIdField } from "common/utils/constants";
import { buildProcessorPath } from "common/utils/fns";
import { IProcessor } from "types";
import { useMessages } from "config/messagesStateContext";
import { useGlobalState } from 'config/globalStateContext';


export function buildQuery(client0: string|undefined, client1: string|undefined) {
  const fb = firebase.firestore();
  return !!(client0 && client1)
    ? fb.doc(`clients/${client0}/clients/${client1}`).collection(`processors`)
    : fb.collection(`processors`);
}

export function useProcessorKeys() {
  const [client0, setClient0] = React.useState<string|undefined>(undefined);
  const [client1, setClient1] = React.useState<string|undefined>(undefined);
  const [merchantID, setMerchantID] = React.useState<string|undefined>(undefined);
  const [processorSelectKey, resetProcessorSelectKey] = usePaymentId();
  const [globalState] = useGlobalState();
  const query = React.useMemo(() => merchantID
    ? buildQuery(client0, client1).doc(merchantID)
    : null,
    [client0, client1, merchantID]
  );
  const [maybeProcessor, loading, error] = useDocumentDataOnce<IProcessor>(query, optionsByIdField);
  const processor = React.useMemo(() => maybeProcessor ?? { type: 'combined' } as IProcessor, [maybeProcessor]);
  const { addError } = useMessages();
  React.useEffect(() => {
    if (error) {
      addError(error.message);
    }
  }, [error]);
  const handleReset = React.useCallback(() => {
    resetProcessorSelectKey();
    setMerchantID(undefined);
  }, [resetProcessorSelectKey, setMerchantID]);
  return {
    client0, setClient0,
    client1, setClient1,
    merchantID, setMerchantID,
    processor: processor,
    processorSelectKey,
    handleReset,
    includedIn: globalState?.clientData?.opWProcessors ?? emptyArray,
  };
}

export function useProcessorForm(client0: string, client1: string, merchantID: string|undefined) {
  const doSave = React.useCallback((values: IProcessor) => {
    const { id, parentId: _0, client1: _1, ...restValues } = values
    return buildQuery(client0, client1).doc(merchantID ?? id)
      .set(restValues)
  }, [client0, client1, merchantID]);

  const doDelete = React.useCallback(() => {
    return buildQuery(client0, client1).doc(merchantID!)
      .delete()
  },[client0, client1, merchantID]);
  return {
    doSave,
    doDelete,
  };
}

interface IProps {
  onReset(): void,
  doSave(values: IProcessor): Promise<void>,
  doDelete(): Promise<void>,
  id: string|undefined,
}

export function useInternalProcessorForm(props: IProps) {
  const { addError, addSuccess } = useMessages();
  const { doSave, doDelete, id, onReset } = props;
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback((values: IProcessor, formApi: FormApi<IProcessor>) => {
    setLoading(true);
    function onSuccess() {
      formApi.reset();
      addSuccess(`Saved!`);
      onReset();
    }
    doSave(values as IProcessor)
      .then(onSuccess, err => addError(err.message))
      .then(() => setLoading(false));
  }, emptyArray);
  const isNew = !(!!id);
  const onDeleteClick = React.useCallback(() => {
    const confirmed = window.confirm(`Please, confirm you want to delete this item.`);
    if (confirmed) {
      doDelete()
        .then(onReset)
        .catch(err => addError(err.message));
    }
  }, [doDelete, onReset]);
  return {
    isNew,
    onDeleteClick,
    onSubmit,
    loading,
  };
}
