import * as React from 'react';

type IProps<T> = {
  component: React.ReactNode, // React.FC<T>
  visible: boolean,
} & T;

function Maybe<T>(props: IProps<T>) {
  const { component: Component, visible, ...rest } = props;
  return (
    visible
      // @ts-ignore
      ? <Component {...rest} />
      : null
  );
}

export default Maybe;