import * as React from "react";
import { useGlobalState } from "config/globalStateContext";

import "firebase/firestore";
import "firebase/auth";
import * as firebase from "firebase/app";
import ErrorMessage from "common/components/ErrorMessage";

//@ts-ignore
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { IDisputeData, IDisputeFormValues } from "../../types";

import {
  useClientName,
  useAgentName,
  useProcessorsName,
  useUserPermissions,
  useSubMerchantName,
} from "common/hooks/firestore";

import { IDisputesFilters } from "./components/DisputesFilterForm";

import { buildBaseQuery } from "common/utils/firebase";
import DisputesFilterForm from "./components/DisputesFilterForm";

import {
  emptyArray,
  currencyFormatConfig,
  pageSize,
} from "common/utils/constants";

import { capitalizeFirstLetter } from "../Report/utils";

import { useMessages } from "config/messagesStateContext";

import { ITranx, IKeysOfStr, IAccess } from "types";

import {
  lazyApply,
  getMessage,
  compose,
  getStatusTextClass,
  idReduce,
  buildQueryStr,
  exportExcelContent,
  exportCSVContent,
  getCCName,
  runPromiseSeq,
  getRefundableAmount,
  isVoidAvailableAtTime,
  initCap,
  getSearchEndValue,
} from "common/utils/fns";

import { useUserInfo } from "common/hooks/utils";

import { useHistory, Link } from "react-router-dom";

import {
  getProcessorTitle,
  getPlainRow,
  getPlainRowForDisputes,
} from "./utils";

function useQuery(): number {
  return React.useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    // @ts-ignore
    return parseInt(query.get("pageSize") ?? pageSize);
  }, [window.location.search]);
}

// dueDate old
// filters?.dueDate
// ? prequery1.where("disputeData.dueDate", "==", filters.dueDate)
// : prequery1;

const getDaysArray = function (d1: string, d2: string) {
  const start = new Date(d1);
  const end = new Date(d2);
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr
    .map((v) => v.toISOString().slice(0, 10))
    .join(" ")
    .split(" ");
};

function omitNull(obj: IDisputesFilters) {
  return Object.keys(obj).reduce((result, key) => {
    if (obj[key] !== null) {
      result[key] = obj[key];
    }
    return result;
  }, {} as IDisputesFilters);
}

// merchant?: string;

// disputestatus?:string;

const getDateFormattedString = (date: string) => {
  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month =
    currentDate.getMonth() < 9
      ? "0" + (currentDate.getMonth() + 1)
      : currentDate.getMonth() + 1;
  const day =
    currentDate.getDate() < 10
      ? "0" + currentDate.getDate()
      : currentDate.getDate();
  const resp = `${year}-${month}-${day}`;
  // console.log("xxxxxxxxx",resp);
  return resp;
};

function getFilteredQuery(
  isAdmin: boolean,
  clients: string[][],
  processors: string[],
  processorsClientsTruncated: string[][],
  filters: IDisputesFilters | undefined
): firebase.firestore.Query {
  const prequery0 = firebase.firestore().collection("disputes/all/tabs");
  console.log("ESMER", prequery0);

  // console.log('getFilteredQuery filters', filters)

  const myFilters = !!filters ? omitNull(filters) : {};

  const filterKeyTemp = Object.keys(myFilters).filter((k) => k !== "filterBy");
  let query: firebase.firestore.Query = prequery0;

  console.log("filters Pruebas", filters);
  console.log(prequery0, "By Es");
  console.log("getFilteredQuery myFilters", myFilters);

  if (filterKeyTemp.length == 0) {
    query = prequery0;
  } else {
    for (const filterKey of filterKeyTemp) {
      switch (filterKey) {
        case "merchant":
          query = filters?.merchant
            ? query.where("itranx.processor", "==", filters.merchant)
            : query;
          break;
        case "caseNumber":
          query = filters?.caseNumber
            ? query.where("caseNumber", "==", filters.caseNumber)
            : query;
          break;

        case "dueDate":
          if (filters?.dueDate != undefined && filters?.dueDate != null) {
            query = query.where(
              "dueDate",
              ">=",
              getDateFormattedString(filters?.dueDate[0])
            );
            query = query.where(
              "dueDate",
              "<=",
              getDateFormattedString(filters?.dueDate[1])
            );
          }
          break;

        case "paymentDate":
          if (
            filters?.paymentDate != null &&
            filters?.paymentDate != undefined
          ) {
            query = query.where(
              "paymentDate",
              ">=",
              getDateFormattedString(filters?.paymentDate[0])
            );
            query = query.where(
              "paymentDate",
              "<=",
              getDateFormattedString(filters?.paymentDate[1])
            );
          }

          break;

        case "date":
          if (filters?.date != null && filters?.date != undefined) {
            console.log("filters?.date", filters?.date);
            query = query.where(
              "date",
              ">=",
              getDateFormattedString(filters?.date[0])
            );
            query = query.where(
              "date",
              "<=",
              getDateFormattedString(filters?.date[1])
            );
          }

          break;

        case "invoiceNumber":
          query = filters?.invoiceNumber
            ? query.where("invoiceNumber", "==", filters.invoiceNumber)
            : query;
          break;

        case "distype":
          query =
            filters?.distype && filters.distype !== "All"
              ? query.where("distype", "==", filters.distype)
              : query;
          break;

        case "disputeID":
          console.log("getFilteredQuery hi im disputeID case");
          query = filters?.disputeID
            ? query.where("disputeID", "==", filters.disputeID)
            : query;
          break;

        case "disputeOperationType":
          query =
            filters?.disputeOperationType &&
            filters.disputeOperationType !== "All"
              ? query.where("type", "==", filters.disputeOperationType)
              : query;
          break;

        case "disputestatus":
          query =
            filters?.disputestatus && filters.disputestatus !== "All"
              ? query.where("status", "==", filters.disputestatus)
              : query;
          break;

        default:
          query = query;
      }
    }
  }

  console.log("getFilteredQuery query", query);
  return query;
}

function getFilteredQueryNotThatOld(
  isAdmin: boolean,
  clients: string[][],
  processors: string[],
  processorsClientsTruncated: string[][],
  filters: IDisputesFilters | undefined
): firebase.firestore.Query {
  const prequery0 = firebase.firestore().collection("disputes/all/tabs");

  const prequery1 = filters?.invoiceNumber
    ? prequery0.where("invoiceNumber", "==", filters.invoiceNumber)
    : prequery0;
  const prequery2 =
    filters?.filterBy === "values" &&
    filters.dueDate &&
    filters.dueDate.length > 0
      ? prequery1
          .where(
            "createdAt",
            ">=",
            firebase.firestore.Timestamp.fromDate(new Date(filters.dueDate[0]))
          )
          .where(
            "createdAt",
            "<=",
            firebase.firestore.Timestamp.fromDate(new Date(filters.dueDate[1]))
          )
      : prequery1;
  const prequery3 =
    filters?.filterBy === "values" &&
    filters.paymentDate &&
    filters.paymentDate.length > 0
      ? prequery2
          .where(
            "createdAt",
            ">=",
            firebase.firestore.Timestamp.fromDate(
              new Date(filters.paymentDate[0])
            )
          )
          .where(
            "createdAt",
            "<=",
            firebase.firestore.Timestamp.fromDate(
              new Date(filters.paymentDate[1])
            )
          )
      : prequery2;
  const prequery4 = filters?.caseNumber
    ? prequery3.where("caseNumber", "==", filters.caseNumber)
    : prequery3;
  const prequery5 =
    filters?.disputestatus && filters.disputestatus !== "all"
      ? prequery4.where("status", "==", filters.disputestatus)
      : prequery4;
  const prequery6 = prequery5;
  // filters?.disputeOperationType && (filters.disputeOperationType !== "all")
  // ? prequery5.where("type", "==", filters.disputeOperationType)
  // : prequery5
  const prequery7 =
    filters?.filterBy === "values" &&
    filters.reversalDate &&
    filters.reversalDate.length > 0
      ? prequery6
          .where(
            "createdAt",
            ">=",
            firebase.firestore.Timestamp.fromDate(
              new Date(filters.reversalDate[0])
            )
          )
          .where(
            "createdAt",
            "<=",
            firebase.firestore.Timestamp.fromDate(
              new Date(filters.reversalDate[1])
            )
          )
      : prequery6;
  const prequery8 =
    //filters?.disputestatus && (filters.disputestatus === "all")
    false
      ? prequery7.where("status", "in", [
          "disputing",
          "pending",
          "accepted",
          "closed",
          "lost",
          "won",
        ])
      : prequery7;
  const prequery9 = prequery8;
  // filters?.disputeOperationType && (filters.disputeOperationType === "all")
  // ? prequery8.where("type", "in", ["C","I","PA"])
  // : prequery8
  const prequery10 =
    filters?.filterBy === "values" && filters.distype
      ? prequery9.where("distype", "==", filters.distype)
      : prequery9;
  const prequery11 = prequery10;
  // filters?.filterBy === "values" && filters.disputeID
  //   ? prequery10.where("invoiceNumber","==", breakDisputeID(filters.disputeID)[0])
  //   : prequery10

  const prequery = prequery11.orderBy("createdAt", "desc");
  console.log("prequery: ", prequery);
  return prequery;
}

function getFilteredQueryOld(
  isAdmin: boolean,
  clients: string[][],
  processors: string[],
  processorsClientsTruncated: string[][],
  filters: IDisputesFilters | undefined
): firebase.firestore.Query {
  // const prequery0 = buildBaseQuery(
  //   "trxs",
  //   isAdmin,
  //   clients,
  //   processors,
  //   processorsClientsTruncated,
  //   firebase.firestore()
  // );

  const prequery0 = firebase.firestore().collection("disputes/all/tabs");

  const prequery1 = filters?.invoiceNumber
    ? prequery0.where("invoiceNumber", "==", filters.invoiceNumber)
    : prequery0;
  const prequery2 =
    filters?.filterBy === "values" &&
    filters.dueDate &&
    filters.dueDate.length > 0
      ? prequery1
          .where(
            "createdAt",
            ">=",
            firebase.firestore.Timestamp.fromDate(new Date(filters.dueDate[0]))
          )
          .where(
            "createdAt",
            "<=",
            firebase.firestore.Timestamp.fromDate(new Date(filters.dueDate[1]))
          )
      : prequery1;
  const prequery3 =
    filters?.filterBy === "values" &&
    filters.paymentDate &&
    filters.paymentDate.length > 0
      ? prequery2
          .where(
            "createdAt",
            ">=",
            firebase.firestore.Timestamp.fromDate(
              new Date(filters.paymentDate[0])
            )
          )
          .where(
            "createdAt",
            "<=",
            firebase.firestore.Timestamp.fromDate(
              new Date(filters.paymentDate[1])
            )
          )
      : prequery2;
  const prequery4 = filters?.caseNumber
    ? prequery3.where("caseNumber", "==", filters.caseNumber)
    : prequery3;
  const prequery5 =
    filters?.disputestatus && filters.disputestatus !== "all"
      ? prequery4.where("status", "==", filters.disputestatus)
      : prequery4;
  const prequery6 =
    filters?.disputeOperationType && filters.disputeOperationType !== "all"
      ? prequery5.where("type", "==", filters.disputeOperationType)
      : prequery5;
  const prequery7 =
    filters?.filterBy === "values" &&
    filters.reversalDate &&
    filters.reversalDate.length > 0
      ? prequery6
          .where(
            "createdAt",
            ">=",
            firebase.firestore.Timestamp.fromDate(
              new Date(filters.reversalDate[0])
            )
          )
          .where(
            "createdAt",
            "<=",
            firebase.firestore.Timestamp.fromDate(
              new Date(filters.reversalDate[1])
            )
          )
      : prequery6;
  const prequery8 =
    //filters?.disputestatus && (filters.disputestatus === "all")
    false
      ? prequery7.where("status", "in", [
          "disputing",
          "pending",
          "accepted",
          "closed",
          "lost",
          "won",
        ])
      : prequery7;
  const prequery9 =
    filters?.disputeOperationType && filters.disputeOperationType === "all"
      ? prequery8.where("type", "in", [
          "C",
          "I",
          "PA",
          "ARBITRATION",
          "RETRIEVAL",
        ])
      : prequery8;
  const prequery10 =
    filters?.filterBy === "values" && filters.distype
      ? prequery9.where("distype", "==", filters.distype)
      : prequery9;
  const prequery11 =
    filters?.filterBy === "values" && filters.disputeID
      ? // ? prequery10.where("invoiceNumber","==", breakDisputeID(filters.disputeID)[0])
        prequery10.where("disputeID", "==", filters.disputeID)
      : prequery10;
  const prequery12 = filters?.caseNumber
    ? prequery11.where("caseNumber", "==", filters.caseNumber)
    : prequery11;

  const prequery = prequery12.orderBy("createdAt", "desc");
  return prequery;
}

// "3KR6IVX27-2-4-KRP3YJ73D" for testing purposes

const breakDisputeID = (id: string): [string, string] => {
  const [batch, line, recharge, individualDispute] = id.split("-");
  const invID = `${batch}-${line}-${recharge}`;
  // console.log('breakDisputeID: ', invID)
  return [invID, individualDispute];
};

function manageFiltersQuery(
  isAdmin: boolean,
  clients: string[][],
  processors: string[],
  filters: IDisputesFilters | undefined,
  firstOrLast:
    | [
        (
          | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
          | undefined
        ),
        (
          | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
          | undefined
        )
      ]
    | undefined,
  fullPathProcessors: string[][],
  processorsClientTruncated: string[][],
  localPageSize: number = pageSize,
  tQsnapshot:
    | firebase.firestore.QuerySnapshot<IDisputeData>
    | undefined = undefined,
  recursionLimit: number = 15
): Promise<firebase.firestore.QuerySnapshot<IDisputeData>> {
  const prequery = getFilteredQuery(
    isAdmin,
    clients,
    processors,
    processorsClientTruncated,
    filters
  );

  const [first, last] = firstOrLast ?? emptyArray;
  const isNextSearch = first === undefined;
  const query = first
    ? prequery.orderBy("dueDate").endBefore(first).limitToLast(pageSize)
    : last
    ? prequery.startAfter(last).limit(pageSize)
    : prequery.limit(pageSize);

  // requires local filter because firebase limits of 10 items in 'in' query filter
  const requiresLocalFilters = !isAdmin && processors.length > 10; // false // temporal; //
  // TODO this function requires a better implementation of local filters
  function onSuccess(snapshot: firebase.firestore.QuerySnapshot<IDisputeData>) {
    // console.log('manageFiltersQuery onSuccess', snapshot.docs.map(d => d.data()))
    if (!requiresLocalFilters) return snapshot;
    function filterItFn(
      qds: firebase.firestore.QueryDocumentSnapshot<IDisputeData>
    ) {
      const data = qds.data();
      debugger;
      const isfiltered = fullPathProcessors.some(
        ([client0, client1, processor]) =>
          data.itranx.client[0] === client0 &&
          data.itranx.client[1] === client1 &&
          data.itranx.processor === processor
      );
      return isfiltered;
    }

    const preFilteredSize = snapshot.size;
    const filteredDocs = snapshot.docs.filter(filterItFn);
    let newSnapshot = snapshot;
    if (tQsnapshot) {
      tQsnapshot.docs.push(...filteredDocs);
      // @ts-ignore
      newSnapshot = {
        ...newSnapshot,
        docs: tQsnapshot.docs.slice(0, pageSize),
      };
    } else {
      // @ts-ignore
      newSnapshot = { ...newSnapshot, docs: filteredDocs.slice(0, pageSize) };
    }
    // if items is less than required size, there is not more to search, so stop recursive search
    if (preFilteredSize < localPageSize) return newSnapshot;

    if (newSnapshot.docs.length >= pageSize) return newSnapshot;

    const newRequiredSize = pageSize - newSnapshot.docs.length;

    if (newRequiredSize < 0) return newSnapshot;

    // Stop cycling
    if (recursionLimit <= 0) return newSnapshot;

    // recursive aproach to reach the required size
    return manageFiltersQuery(
      isAdmin,
      clients,
      processors,
      filters,
      isNextSearch
        ? [undefined, newSnapshot.docs[newSnapshot.docs.length - 1]]
        : [newSnapshot.docs[0], undefined],
      fullPathProcessors,
      processorsClientTruncated,
      newRequiredSize,
      newSnapshot,
      recursionLimit - 1
    );
  }
  // query.get().then(snap => console.log('manageFilterQuery', snap.docs.map(d => d.data())))
  return (
    query
      .get()
      // @ts-ignore
      .then(onSuccess)
  );
}

interface IResults {
  items: ITranx[];
  loading: boolean;
  error: Error | string | undefined;
  onBackClick(): void;
  onNextClick(): void;
  isBackAvailable: boolean;
  pageSize: number;
  getFiltered: () => firebase.firestore.Query;
}

function useFilterTransactions(
  filters: IDisputesFilters | undefined
): IResults {
  const pageSize = useQuery();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | string | undefined>(
    undefined
  );
  const [items, setItems] = React.useState<ITranx[]>(emptyArray);
  const [
    clients,
    processors,
    isAdmin,
    fullPathProcessors,
    processorsClientTruncated,
  ] = useUserInfo(filters);

  const [firstOrLast, setFirstOrLast] = React.useState<
    | [
        firebase.firestore.QueryDocumentSnapshot | undefined,
        firebase.firestore.QueryDocumentSnapshot | undefined
      ]
    | undefined
  >(undefined);
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [_onBackClick, setOnBackClick] = React.useReducer(idReduce, undefined);
  const [_onNextClick, setOnNextClick] = React.useReducer(idReduce, undefined);

  const getFiltered = () =>
    getFilteredQuery(
      isAdmin,
      clients,
      processors,
      processorsClientTruncated,
      filters
    );
  function onBackClick() {
    setPage((p) => p - 1);
    _onBackClick();
    return false;
  }
  function onNextClick() {
    setPage((p) => p + 1);
    _onNextClick();
    return false;
  }
  React.useEffect(() => {
    function fetchAll() {
      setLoading(true);
      setError(undefined);
      setItems(emptyArray);
      function onSuccess(snapshot: firebase.firestore.QuerySnapshot) {
        console.log(
          "onSuccess: snapshot: ",
          snapshot.docs.map((d) => d.data())
        );
        setOnBackClick(() => setFirstOrLast([snapshot.docs[0], undefined]));
        setOnNextClick(() =>
          setFirstOrLast([undefined, snapshot.docs[snapshot.docs.length - 1]])
        );
        const mydata = snapshot.docs.map(
          (it) => ({ id: it.id, ...it.data() } as ITranx)
        );
        console.log("fetchAll onSuccess", mydata);
        setItems([...mydata]);
      }
      manageFiltersQuery(
        isAdmin,
        clients,
        processors,
        filters,
        firstOrLast,
        fullPathProcessors,
        processorsClientTruncated
      )
        .then(onSuccess)
        .catch(compose(setError, getMessage))
        .then(lazyApply(setLoading, false));
    }
    if (processors.length > 0 || fullPathProcessors.length > 0) fetchAll();
    const unregister = history.listen((_location) => {
      const shouldRefreshReport = sessionStorage.getItem("shouldRefreshReport");
      if (shouldRefreshReport) {
        sessionStorage.removeItem("shouldRefreshReport");
        fetchAll();
      }
    });
    return () => {
      unregister();
      sessionStorage.removeItem("shouldRefreshReport");
    };
  }, [
    clients,
    processors,
    window.location.search,
    filters,
    firstOrLast,
    fullPathProcessors,
  ]);
  return {
    items,
    loading,
    error,
    onBackClick,
    onNextClick,
    isBackAvailable: page > 0,
    pageSize,
    getFiltered,
  };
}

const capitalizer = (word: string) => {
  return word.slice(0, 1).toUpperCase() + word.slice(1);
};
const headers =
  "Brand;Debit date;Reversal date; Payment date;Case #;Date;Operation type;Dispute type;Due date;Reason;Comments;Status;Disputed By;Client;Merchant;Merchant name;Sub-Merchant name;Invoice;Trans Date;Card Holder;Card Info.;Dispute Amount;Amount;Trans Status;Trans. Type;Date settled;Revised amount;Payment Type;Reference;Account #;Authorization code;Processed by;Processor".split(
    ";"
  );

function useDownloadClick(
  defaultText: string,
  tag: "excel" | "csv",
  getFiltered: () => firebase.firestore.Query,
  keysOfProcessors: IKeysOfStr
): [string, boolean, () => void] {
  const { addError } = useMessages();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  function onClick() {
    function handleSuccess(results: object[]) {
      console.log("DisputeReport handleSuccess 306 results[]", results);
      switch (tag) {
        case "excel":
          return exportExcelContent(results, headers, true);
        case "csv":
          return exportCSVContent(results, headers);
      }
    }
    setIsLoading(true);
    getFiltered()
      .get()
      .then((query) => {
        //  return query.docs.map((it) => ({ id: it.id, ...it.data() } as ITranx))}
        return query.docs.map((it) => ({ ...it.data() } as IDisputeData));
      })
      .then((trxsRaw) => {
        console.log("download excel: ", trxsRaw);

        const newTrxsRaw: IDisputeData[] = [];

        trxsRaw.map((trxs) => {
          trxs.status = capitalizer(trxs.status);
          newTrxsRaw.push(trxs);
        });

        console.log(newTrxsRaw, "By ES");
        // const trxs = flatten(trxsRaw.map((item) => disputeRow(item)))
        return runPromiseSeq(
          newTrxsRaw.map((it) => {
            return getPlainRowForDisputes(it, keysOfProcessors);
          })
        );
      })
      .then(handleSuccess, compose(addError, getMessage))
      .then(() => setIsLoading(false));
  }
  return [isLoading ? "Loading..." : defaultText, isLoading, onClick];
}

const DisputeInfoRow = (props: { item: ITranx }) => {
  const history = useHistory();

  const keysOfProcessors = useProcessorsName();

  const processor = props.item.altProcessor ?? props.item.processor;
  const clientName = useClientName(props.item.client);
  const subMerchantName = useSubMerchantName(
    props.item.client,
    props.item.processor,
    keysOfProcessors
  );
  const createdAt = props.item.createdAt?.toDate?.();
  // const amount = (props.item.type === "refund"
  //   ? -1 * (props.item?.refundAmount ?? 0)
  //   : props.item.paymentAmount
  // const amount = props.item.disputeData?.disputeAmount?.toLocaleString //(undefined, currencyFormatConfig)

  // )?.toLocaleString(undefined, currencyFormatConfig)

  const doesItHasADisputeAmount = !!props.item.disputeData?.disputeAmount
    ? props.item.disputeData?.disputeAmount
    : props.item.paymentAmount.toString();

  const amount = doesItHasADisputeAmount;

  const [statusText, statusClass, trnsType] = getStatusTextClass(
    props.item.type,
    props.item.status
  );

  const operationTypeProp = props.item.disputeData?.type ?? "not";

  const disputeTypeProp = props.item.disputeData?.distype ?? "not";

  const operationTypes = (letter: string) => {
    switch (letter) {
      case "C":
        return "Chargeback";
      case "I":
        return "Inquiry";
      case "P":
        return "Pre-arbritation";
      default:
        return letter.length > 1 ? letter : "not specified";
    }
  };

  const disputeTypes = (letter: string) => {
    switch (letter) {
      case "RE":
        return "reversal";
      case "DE":
        return "debit";
      case "CL":
        return "Claim";

      default:
        return letter.length > 1 ? letter : "not specified";
    }
  };

  //  const capitalizeFirstLetter = (str: string | undefined) => {
  //     if(!!str){
  //       return str.charAt(0).toUpperCase() + str.slice(1);
  //     } else {
  //       return 'status not set'
  //     }
  // }
  const status = capitalizeFirstLetter(props.item.disputeData?.status);
  const disputeType = capitalizeFirstLetter(disputeTypes(disputeTypeProp));
  const agentName = useAgentName(props.item.createdBy) ?? "...";
  return (
    <tr>
      <td>{getCCName(props.item.cardNumber)}</td>
      <td>{props.item.disputeData?.dueDate}</td>
      <td>{props.item.disputeData?.reversalDate}</td>
      <td>{props.item.disputeData?.paymentDate}</td>
      <td>{props.item.disputeData?.caseNumber}</td>
      <td>{props.item.disputeData?.date}</td>
      <td>{operationTypes(operationTypeProp)}</td>
      <td>{disputeType}</td>
      <td>{props.item.disputeData?.dueDate}</td>
      <td>{props.item.disputeData?.reason}</td>
      <td>{props.item.disputeData?.comments}</td>
      <td>{status}</td>
      <td>{props.item.disputeData?.disputeBy}</td>
      <td>{props.item.name}</td>
      <td>{processor}</td>
      <td>{clientName}</td>
      <td>{subMerchantName}</td>
      <td>{props.item.invoiceNumber}</td>
      <td>{createdAt?.toLocaleDateString()}</td>
      <td>{props.item.cardholderName}</td>
      <td>
        {props.item.itranx.cardNumber}******{props.item.itranx.ccLast4}{" "}
        {props.item.itranx.expDate} {getCCName(props.item.itranx.cardNumber)}
      </td>
      <td>{amount}</td>
      <td className={statusClass}>{statusText}</td>
      <td>{trnsType}</td>
      <td>{createdAt?.toLocaleDateString()}</td>
      <td>$0.00</td>
      <td>{initCap(props.item.itranx.type)}</td>
      <td>{props.item.descr}</td>
      <td>{props.item.orderNumber}</td>
      <td>{props.item.authCode}</td>
      <td>{agentName}</td>
      <td>{getProcessorTitle(props.item)}</td>
    </tr>
  );
};

const RowInfo = (props: { item: IDisputeData }) => {
  const operationTypeProp = props.item.type ?? "not";

  const disputeTypeProp = props.item.distype ?? "not";

  const keysOfProcessors = useProcessorsName();

  const processor =
    props.item.itranx?.altProcessor ?? props.item.itranx?.processor;
  const clientName = useClientName(props.item.itranx?.client);
  const subMerchantName = useSubMerchantName(
    props.item.itranx?.client,
    props.item.itranx?.processor,
    keysOfProcessors
  );
  const createdAt = props.item.itranx?.createdAt?.toDate?.();

  const [statusText, statusClass, trnsType] = getStatusTextClass(
    props.item.itranx?.type,
    props.item.itranx?.status
  );

  const doesItHasADisputeAmount = !!props.item.disputeAmount
    ? props.item.disputeAmount
    : "0";

  const amount = doesItHasADisputeAmount;
  const paymentAmount = !!props.item.itranx.paymentAmount
    ? props.item.itranx.paymentAmount.toString()
    : "0";

  // console.log("xxxxxxxxx", props)

  const operationTypes = (letter: string) => {
    switch (letter) {
      case "C":
        return "Chargeback";
      case "I":
        return "Inquiry";
      case "PA":
        return "Pre-arbritation";
      case "ARBITRATION":
        return "Arbitration";
      case "RETRIEVAL":
        return "Retrieval";
      default:
        return letter.length > 1 ? letter : "not specified";
    }
  };

  const disputeTypes = (letter: string) => {
    switch (letter) {
      case "RE":
        return "reversal";
      case "DE":
        return "debit";
      case "CL":
        return "Claim";

      default:
        return letter.length > 1 ? letter : "not specified";
    }
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: props?.item?.itranx.currency ?? "USD",
  });

  let amountFormatted = formatter.format(parseInt(amount));
  if (amountFormatted.includes("-")) {
    amountFormatted = amountFormatted.replace("-", "");
  }

  let paymentAmountFormatted = formatter.format(parseInt(paymentAmount));
  if (paymentAmountFormatted.includes("-")) {
    paymentAmountFormatted = paymentAmountFormatted.replace("-", "");
  }

  const status = capitalizeFirstLetter(props.item.status);
  const disputeType = capitalizeFirstLetter(disputeTypes(disputeTypeProp));
  const agentName = useAgentName(props.item.itranx.createdBy) ?? "...";

  return (
    <tr>
      <td>{getCCName(props.item.itranx?.cardNumber)}</td>
      <td>{props.item.debitDate}</td>
      <td>{props.item.reversalDate}</td>
      <td>{props.item.paymentDate}</td>
      <td>{props.item.caseNumber}</td>
      <td>{props.item.date}</td>
      <td>{operationTypes(operationTypeProp)}</td>
      <td>{disputeType}</td>
      <td>{props.item.dueDate}</td>
      <td>
        {props.item.reason && props.item.reason.length > 30
          ? props.item.reason.slice(0, 30) + "..."
          : props.item.reason}
      </td>
      <td>
        {props.item.comments && props.item.comments.length > 30
          ? props.item.comments.slice(0, 30) + "..."
          : props.item.comments}
      </td>
      <td>{status}</td>
      <td>{props.item.disputeBy}</td>

      <td>{props.item.itranx.name}</td>
      <td>{amountFormatted}</td>
      <td>{processor}</td>
      <td>{clientName}</td>
      <td>{subMerchantName}</td>
      <td>{props.item.invoiceNumber}</td>
      <td>{createdAt?.toLocaleDateString()}</td>
      <td>{props.item.itranx.cardholderName}</td>
      <td>
        {props.item.itranx.cardNumber}******{props.item.itranx.ccLast4}{" "}
        {props.item.itranx.expDate} {getCCName(props.item.itranx.cardNumber)}
      </td>
      <td>{paymentAmountFormatted}</td>
      <td className={statusClass}>{statusText}</td>
      <td>{trnsType}</td>
      <td>{createdAt?.toLocaleDateString()}</td>
      <td>$0.00</td>
      <td>{initCap(props.item.itranx.type)}</td>
      <td>{props.item.itranx.descr}</td>
      <td>{props.item.itranx.orderNumber}</td>
      <td>{props.item.itranx.authCode}</td>
      <td>{agentName}</td>
      <td>{getProcessorTitle(props.item.itranx)}</td>
    </tr>
  );
};

const disputeRow = (item: ITranx): ITranx[] => {
  const disputeDataList = item.disputeDataList;
  const hasDisputeDataList =
    Array.isArray(disputeDataList) && disputeDataList.length !== 0;
  let newItranx: ITranx = item;
  if (!hasDisputeDataList) {
    return [newItranx];
  } else {
    return disputeDataList.map((data) => {
      let newData = Object.assign({}, newItranx);
      newData.disputeData = data;
      return newData;
    });
  }
};

const flatten = (arr: ITranx[][]) => ([] as ITranx[]).concat(...arr);

const formInitialValue = {
  filterBy: "values",
};

const DisputesReport = () => {
  const maybeAccess = useUserPermissions();
  console.log("DisputesReport::maybeAccess", maybeAccess);

  const data = useGlobalState();
  console.log("ES", data);

  // accepted queries: caseNumber, status, type, disputeID, reversalDate, invoiceNumber, distype, paymentDate

  // })
  // const disputestabs = firebase.firestore().collection('disputes/all/tabs')
  // const q1 = disputestabs.where("paymentDate", '==', '2021-09-01')
  // q1.get()
  // .then(snap => {
  //  console.log('snap data firestore ===>', snap.docs[0].data())
  // }).catch(err => console.log('snap data firestore ===> Err'))

  // console.log('q1', q1)

  const [filters, setFilters] = React.useState<IDisputesFilters | undefined>(
    formInitialValue
  );

  // firebase.firestore().collection('disputes/all/tabs').where('disputeID',"==", '1KUEJQV02-3-1-KUH0RSWBD')
  // .get()
  // .then((snap) => {
  //   console.log('disputes/all/tabs', snap.docs.map(d => d.data()))
  // })

  console.log("filters", filters);
  const {
    items,
    loading,
    error,
    onBackClick,
    onNextClick,
    isBackAvailable,
    pageSize,
    getFiltered,
  } = useFilterTransactions(filters);
  const keysOfProcessors = useProcessorsName();

  // console.log('my items new search: ', items)

  const [excelButtonText, isExcelLoading, onExcelClick] = useDownloadClick(
    "Export Excel",
    "excel",
    getFiltered,
    keysOfProcessors
  );

  const excelProps = {
    excelButtonText: excelButtonText,
    isExcelLoading: isExcelLoading,
    onExcelClick: onExcelClick,
  };

  const isNextAvailable = items.length >= pageSize;
  // const rows = React.useMemo(() =>
  //       items.map((it: ITranx) => (
  //           <DisputeInfoRow
  //               key={it.id}
  //               item={it}
  //           />
  //           )),
  //           [items]
  //       );

  // const flattened = React.useMemo(() =>  flatten(items.map((it) => disputeRow(it)))
  // .filter(tran => tran.disputeData?.status !== undefined ), [items])

  //@ts-ignore
  const disputesArr = items as IDisputeData[];
  console.log("disputesArr: ", disputesArr);

  let rows: JSX.Element[] = [];
  rows = disputesArr.map((d) => <RowInfo key={d.disputeID} item={d} />);

  return (
    <section className="main-content">
      <ErrorMessage text={error} />
      <DisputesFilterForm
        initialValues={formInitialValue}
        onSubmit={setFilters}
        access={maybeAccess}
        excelProps={excelProps}
        loading={loading}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "40px",
        }}
      >
        <p>You can scroll left and right to see all the info</p>
      </div>
      <div className="resizableScrollableXdiv ">
        <table id="table-to-export" className="table">
          <thead>
            <tr>
              <th>Brand</th>
              <th>Debit date</th>
              <th>Reversal date</th>
              <th>Payment date</th>
              <th>Case number</th>
              <th>Date</th>
              <th>Operation type</th>
              <th>Dispute type</th>
              <th>Due date</th>
              <th>Reason</th>
              <th>Comments</th>
              <th>Status</th>
              <th>Disputed by</th>
              <th>Client</th>
              <th>Dispute Amount</th>
              <th>Merchant</th>
              <th>Merchant name</th>
              <th>Sub-Merchant name</th>
              <th>Invoice</th>
              <th>Date</th>
              <th>Card Holder</th>
              <th>Card Info.</th>
              <th>Payment Amount</th>
              <th>Trans. Status</th>
              <th>Trans. Type</th>
              <th>Date settled</th>
              <th>Revised amount</th>
              <th>Payment Type</th>
              <th>Reference</th>
              <th>Account #</th>
              <th>Authorization code</th>
              <th>Processed by</th>
              <th>Processor</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
          <tfoot>
            <tr>
              <th>{loading && "Loading..."}</th>
            </tr>
          </tfoot>
        </table>
      </div>
      {/* <div className="export-button">
          <button

            className="btn btn-primary"
            onClick={onExcelClick}
            disabled={isExcelLoading}
          >
            {excelButtonText}
          </button>

        </div> */}

      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className="page-item">
            <button
              className="page-link"
              onClick={onBackClick}
              disabled={!isBackAvailable}
            >
              Previous
            </button>
          </li>
          {isNextAvailable && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={onNextClick}
                disabled={!isNextAvailable}
              >
                Next
              </button>
            </li>
          )}
        </ul>
      </nav>
    </section>
  );
};

export default DisputesReport;
