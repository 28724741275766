
import React from 'react';
import Maybe from 'common/components/Maybe';

interface IProps {
  isNew: boolean,
  onDeleteClick(reset: () => void): void,
  loading: boolean,
}

export default function FormBottom(props: IProps) {
  const { isNew, onDeleteClick, loading } = props;
  return (
    <div className="text-align-right">
      <Maybe
        component="button"
        type="button"
        visible={!isNew}
        className="btn btn-secondary"
        disabled={loading}
        onClick={onDeleteClick}
      >
        Delete
      </Maybe>
      &nbsp;
      <button
        type="submit"
        className="btn btn-primary"
        disabled={loading}
      >
        {loading ? 'Saving...' : 'Save'}
      </button>
    </div>
  );
}