import * as React from 'react';
import countriesStates from './countriesStates.json';

interface IProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  country: string
}

function StatePicker(props: IProps) {
  const stateOptions = React.useMemo(() => countriesStates.countries
    .find(it => it.country === props.country)
    ?.states?.map((it) => (
      <option key={it} value={it}>{it}</option>
    )),
  [props.country]);
  return (
    // @ts-ignore
    <select {...props}>
      <option value="">Select one</option>
      {stateOptions}
    </select>
  );
}

export default React.memo(StatePicker);
