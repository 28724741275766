import * as React from "react";
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { ITranx, IDisputeFormValues, IDisputeData } from 'types';
import { Form, Field, FormRenderProps } from 'react-final-form';
import { getCCName, getAttachmentFile, buildFileName, lazyApply, log, compose, getMessage } from 'common/utils/fns';
import { uploadFile } from "common/utils/firebase";
import { attachmentsFolderName } from "common/utils/constants";

import firebase from "firebase";

import { FormApi } from "final-form";


import { useDownloadURL } from "react-firebase-hooks/storage";


import { useGlobalState } from "config/globalStateContext";


import DisputesWithTabs from "./DisputeModalWithTabs";
import TabbedReadOnlyDisputes from "./TabbedReadOnlyDisputes";


function DisputeModalReadOnly(){
    const { state } = useLocation<ITranx>();
    const [userData] = useGlobalState();


    const [url] = useDownloadURL(
        state.disputeData?.fileName
          ? firebase
              .storage()
              .ref(attachmentsFolderName + "/" + state.disputeData.fileName)
          : null
      );

    const cardNum = (state?.cardNumber && (getCCName(state?.cardNumber) !== null)) ? getCCName(state?.cardNumber) : "not a CC" ;
   
   
    const history = useHistory();
    const onClose = history.goBack;
    

    

    const goBackToReport = () =>{
        history.push({pathname:`/refreshReport`})
    }


  
 

  


    const handleOnSubmit = async (values: IDisputeFormValues, form: FormApi<IDisputeFormValues>) =>{
 
    
        const genDispute = firebase.functions().httpsCallable('genDispute');
        const updateDispute = firebase.functions().httpsCallable('updateDispute');
        const file = getAttachmentFile('disputeForm');
        const myDisputeData = {
            brand: values.brand,
            debitDate: values.debitDate,
            reversalDate: values.reversalDate,
            paymentDate: values.paymentDate,
            caseNumber: values.caseNumber,
            date: values.date,
            type: values.type,
            dueDate: values.dueDate,
            status: values.status,
            reason: values?.reason,
            comments:values?.comments,
            invoiceNumber: state.invoiceNumber,
            createdBy: userData?.uid,
            ...(file.name ? { fileName: buildFileName(state.invoiceNumber, file) } : undefined),
        }
        const updateDisputeData = {
            client: state.client,
            invoiceNumber: state.invoiceNumber,
            disputeData: myDisputeData
        }
    
     

    }

    return (
        true 
        ? <TabbedReadOnlyDisputes
            cardNum={cardNum} 
            disputesList={state.disputesList ?? []} 
            invoiceNumber={state.invoiceNumber} 
            client={state.client}
            paymentAmount={state.paymentAmount}
            disputeDataList={state.disputeDataList ?? []} 
            createdBy={state.createdBy}
        />
        :
        <div className="modal show" id="disputeModal" role="form" >
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <div className="modal-title" id="disputeModalLabel">
                       <h1>{`Dispute > Overview`}</h1> 
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={onClose}
                            >
                            <span aria-hidden="true">&times;</span>
                            </button>
                </div>
                <div className="modal-body">
                    <Form
                        onSubmit={handleOnSubmit}
                        
                        initialValues={state.disputeData as IDisputeFormValues }
                        render={({ handleSubmit, values}) => (
                        <>
                        <form onSubmit={handleSubmit} name="disputeForm">
                            <div className="row">
                            <div className="col">
                                    <label htmlFor="brand">Brand</label>
                                    <Field
                                        name="brand"
                                        className="form-control"
                                        component="input"
                                        id="brand"
                                        type="text"
                                        placeholder={cardNum}
                                        disabled={true}
                                        
                                    />
                            </div>
                            <div className='col'>
                                 <label htmlFor="debitDate">Debit date </label>

                                <Field
                                    name='debitDate'
                                    component="input"
                                    type="date"
                                    className="form-control" 
                                    id='debitDate'
                                    readOnly
                                   
                                />
                            
                             </div>
                             <div className='col'>
                                 <label htmlFor="reversalDate">Reversal Date </label>

                                <Field
                                    name='reversalDate'
                                    component="input"
                                    type="date"
                                    className="form-control" 
                                    id='reversalDate'
                                    readOnly
                                      />
                            
                             </div>
                             <div className='col'>
                                 <label htmlFor="paymentDate">Payment date </label>

                                <Field
                                    name='paymentDate'
                                    component="input"
                                    type="date" 
                                    className="form-control"
                                    id='paymentDate' 
                                    
                                    readOnly
                                    />
                            
                             </div>
                            
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="caseNumber">Case # </label>
                                    <Field
                                        name='caseNumber'
                                        type="text" 
                                        className="form-control"
                                        id='caseNumber'
                                        component="input"
                                        
                                        readOnly
                                    />
                                    
                                </div>
                                <div className="col">
                                    <label htmlFor="date"> Date </label>
                                    <Field
                                        name='date'
                                        type="date"
                                        className="form-control" 
                                        id='date' 
                                        component="input"
                                        
                                        readOnly
                                    />
                                </div>
                                <div className="col">
                                <label htmlFor="type">Dispute Type </label>
                                <Field
                                     name='type'
                                    
                                >
                                        {({input, meta}) =>(
                                            <>
                                            
                                            <select className="form-control" id='type' {...input} disabled={true}>
                                             <option value="unselected" >-- Dispute type --</option>
                                            <option value="C">Chargeback</option>
                                            <option value="I">Inquiry</option>
                                            <option value="PA">Pre-arbitration</option>

                                            </select>
                                            {meta.touched && meta.error && <span style={{color:'red'}}>{meta.error}</span>}
                                            </>
                                        )}
                        
                                </Field>
                                </div>
                                <div className="col">
                                <label htmlFor="dueDate">Due date </label>

                                    <Field
                                        name='dueDate'
                                        type="date" className="form-control" id='dueDate'
                                        component="input"
                                        
                                        readOnly
                                    />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-3">
                                <label htmlFor="status">Status </label>
                                <Field
                                     name='status'
                                     
                                >
                                        {({input, meta}) =>(
                                            <>
                                            
                                            <select className="form-control" id='status' {...input} disabled={true}>
                                            <option value="unselected" >-- Dispute type --</option>
                                            <option value="disputing">Disputing</option>
                                            <option value="pending">Pending</option>
                                            <option value="accepted">Accepted</option>
                                            <option value="closed">Closed</option>
                                            <option value="lost">LOST</option>
                                            <option value="won">WON</option>

                                            </select>
                                            {meta.touched && meta.error && <span style={{color:'red'}}>{meta.error}</span>}
                                            </>
                                        )}
                        
                                </Field>
                                </div>
                                {url ?
                                    <div className="col" style={{display:'flex', flexDirection:'column'}}>
                                    <label>Download evidence</label>
                                    <a target="_blank" href={url} className={url ? undefined : "hidden"}>
                                        <img src="/assets/images/icons/Download.svg" alt="" />
                                    </a>
                                    </div>
                                    : <></>
                                }
                            </div>
                            <div className="row">
                                <div className="col-6">
                                <label htmlFor="reason">Reason</label>
                  
                                    <Field
                                        name='reason'
                                        className="form-control"
                                        component='textarea'
                                        id='reason'
                                        type='text'
                                        rows='5'
                                        readOnly
                                    />
                                </div>
                                <div className="col-6">
                                <label htmlFor="comments">Comments</label>
                  
                                    <Field
                                        name='comments'
                                        className="form-control"
                                        component='textarea'
                                        id='comments'
                                        type='text'
                                        rows='5'
                                        readOnly
                                    />
                                </div>
                            </div>
                            
                            </form>
                        </>
                        
                        )}
                    />
                    
                </div>
                <div className="modal-footer">
            
                    <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={onClose}
                    >
                     Close
                </button>

                </div>

            </div>
            </div>
        </div>
    )
}

export default  DisputeModalReadOnly;