import * as React from 'react';

interface IProps {
  text: Error|string|undefined|null,
}

function ErrorMessage(props: IProps) {
  return (
    !!props.text
      ? <div className="alert alert-danger" role="alert">
          {props.text instanceof Error ? props.text.message : props.text}
        </div>
      : null
  );
}

export default React.memo(ErrorMessage);
