import * as React from "react";
import "firebase/firestore";
// @ts-ignore
import payment from "payment";
import { useGlobalState } from "config/globalStateContext";
import { IProcessor } from "types";
import { useCollection } from 'react-firebase-hooks/firestore';
import { buildQuery } from '../../pages/Admin/utils';

import { Field} from 'react-final-form';

type Props = React.InputHTMLAttributes<HTMLSelectElement> & {
    cardNumber?: string;
  };

const formatProcessorConfig = (it: IProcessor): string =>
  `${it.name} - ${it.id}`;

function RecurrentProcessorSelect(props: Props){
    const [user, setUserData] = useGlobalState();
    const [value, setValue] = React.useState(props.defaultValue ?? props.value);
    const [processorType, setProcessorType] = React.useState('')
    console.log('processorType:',processorType)
    const query =  buildQuery(undefined, undefined).orderBy('type')
    const [qs, loading, error] = useCollection(query);

    const items = React.useMemo(
      () =>
        qs?.docs.map((it) => ({ id: it.id, ...it.data() } as IProcessor)) ??
        [],
      [qs]
    );

    function onLocalChange(ev: React.SyntheticEvent<HTMLSelectElement>) {
        // @ts-ignore
        props.onChange?.(ev);
        // @ts-ignore
        setValue(ev.target.value);
   
        // setProcessorType(type.type)
      }
      React.useEffect(() => {
        const processor = user?.processors?.find((it) => it?.id === value);
        if (processor) {
          // @ts-ignore
          setUserData((global) => ({
            ...global,
            specificClient: processor?.client,
          }));
          console.log('processor: ', processor.store)
        }
      }, [value]);
      React.useEffect(() => {
        const processor = user?.processors?.find((it) => it?.id === value);
        if (processor) {
          // @ts-ignore
         setProcessorType(processor.type)
        }
      }, [value]);
      
    
      const processors = items.filter(p => p.isRecurrent)
  
  return (
    <>
    <select {...props} onChange={onLocalChange}>
      <option value="">{props.placeholder ?? "Select a merchant"}</option>
      {processors?.map((it) => (
        <option key={it.id} value={it.id} disabled={it.active === false}>
          {formatProcessorConfig(it)}
        </option>
      ))}
    </select>
    <input type="hidden" id="recurrentProcessorType" name="recurrentProcessorType" value={`${processorType}`}/>
    </>
  );

}


export default RecurrentProcessorSelect