import * as React from "react";
import { Field } from 'react-final-form';

function ResortcomProcessorFields() {
  return (
    <>
      <div className="form-group">
        <label htmlFor="username">Username</label>
        <Field
          className="form-control"
          component="input"
          name="username"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <Field
          className="form-control"
          component="input"
          name="password"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="currencyCode">Currency Code</label>
        <Field
          component="input"
          className="form-control"
          name="currencyCode"
          defaultValue="USD"
          maxLength={3}
          required
        />
      </div>
    </>
  );
}

export default ResortcomProcessorFields;
