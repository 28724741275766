import * as React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import { useUserPermissions } from "common/hooks/firestore";

interface IDisputesPrivateProps {
    component: React.FC;
    path: string;
    exact: boolean;
}
function DisputesPrivateRoute(props: IDisputesPrivateProps) {
    const maybeAccess = useUserPermissions();
    const location = useLocation();
    // Fix JC, Solo hacer redirect si estoy cargando disputas.
    return (
        (maybeAccess) ? (

            (maybeAccess?.dispute || maybeAccess?.disputeView || maybeAccess?.superAdmin) && props.path === '/disputes')

            ? (<Route path={props.path} exact={props.exact} component={props.component} />)

            : location.pathname == '/disputes' ?

                (<Route path={props.path} exact={props.exact} component={props.component} />)

                : (<Route path={props.path} exact={props.exact} component={props.component} />)

            : (<></>))


}


export default DisputesPrivateRoute;