import React from "react";

import Tab from "./Tab";

interface ITabsProps{
    labels: string[],
	activeTab: string,
	setActiveTab: (tag:string) => void
}

const Tabs = (props: ITabsProps) => {
	const {labels, activeTab, setActiveTab} = props;
	const onClickTabItem = (tab: string) => setActiveTab(tab);
	return (
		<div className="tabs">
			<ol className="tab-list">
				{props.labels.map((label, idx) => {
					return (
						<Tab
							activeTab={activeTab}
							key={label + idx}
							label={label}
							onClick={onClickTabItem}
						/>
					);
				})}
			</ol>

		</div>
	);
};

export default Tabs;
