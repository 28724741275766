import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { useHistory } from "react-router-dom";
import { usePaymentId } from "common/hooks/utils";
import ErrorMessage from "common/components/ErrorMessage";
import { useGlobalState } from "config/globalStateContext";
import { batchFilesfolderName } from "common/utils/constants";
import ProcessorSelect from "common/components/ProcessorSelect";
import ChooseProcessorSelect from "common/components/ChooseProcessorSelect";
import { uploadFile } from "common/utils/firebase";
import { faSnapchatSquare } from "@fortawesome/free-brands-svg-icons";
import  { IFinalFilter } from '../Report/components/FilterForm';
import {useUserInfo} from '../../common/hooks/utils';
import {useUserPermissions} from "../../common/hooks/firestore";
function UploadModal() {

  const defaultFilterVal: IFinalFilter = { filterBy: "values",}
  const [clients, processors, isAdmin, fullPathProcessors, processorsClientTruncated] = useUserInfo(defaultFilterVal);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [global] = useGlobalState();
  const [error, setError] = React.useState<Error | string | undefined>(
    undefined
  );
  const history = useHistory();
  const onClose = history.goBack;
  const [batchID] = usePaymentId();
  function onSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    setLoading(true);
    const formData = new FormData(ev.target as HTMLFormElement);
    const client = global?.specificClient!;
    const processor = formData.get("processor") as string;
    const recurrentProcessorType = formData.get("recurrentProcessorType") as string;
    function onSuccess(fileName: string) {
      const uid = firebase.auth().currentUser?.uid;
      function onLocalSuccess() {
        history.goBack();
      }
      console.log("onSuccess", fileName, client, processor);
      firebase
        .firestore()
        .doc(`batchs/${batchID}`)
        .set({
          id: batchID,
          origBatchID: batchID,
          createdBy: uid,
          processor,
          fileName,
          client,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          recurrentProcessorType,
          declinedList: [],
          prequeryInfo:{clients:JSON.stringify(clients), processors:processors, isAdmin:isAdmin, processorsClientTruncated:JSON.stringify( processorsClientTruncated)},
        })
        .then(onLocalSuccess, setError);
    }
   
    uploadFile(
      batchFilesfolderName,
      formData.get("batchID") as string,
      formData.get("file") as File
    )
      .then(onSuccess, setError)
      .then();
      // .then(() => setLoading(false));
  }
  
  return (
    <div
      className="modal show refund-details"
      id="uploadModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="uploadModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="uploadModalLabel">
              Upload file
            </h5>
          </div>
          <div className="modal-body">
            <ErrorMessage text={error} />
            <form onSubmit={onSubmit}>
              <div style={{marginTop:'10px'}} className="form-group">
                {/* <ProcessorSelect
                  className="form-control merchant-select"
                  name="processor"
                  required
                /> */}
                <ChooseProcessorSelect/>
              </div>
              <div className="form-column">
                <div className="form-group">
                  <label htmlFor="batchID">Batch ID</label>
                  <input
                    type="text"
                    disabled
                    name="batchID"
                    defaultValue={batchID}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="file">File</label>
                  <input
                    type="file"
                    name="file"
                    className="form-control"
                    required
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  // disabled={loading}
                  data-dismiss="modal"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="print btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "Uploading..." : "Process file"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadModal;
