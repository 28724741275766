import * as React from "react";
import ClientSelect from '../components/ClientSelect';
import ProcessorSelect from '../components/ProcessorSelect';
import Maybe from 'common/components/Maybe';
import { IProcessor } from "types";
import ErrorMessage from "common/components/ErrorMessage";
import firebase from "firebase";
import { useMessages } from "config/messagesStateContext";


function SimpleToNewLogicMigra() {
  const [client0, setClient0] = React.useState<string|undefined>(undefined);
  const [client1, setClient1] = React.useState<string|undefined>(undefined);
  const [srcMerchantId, setSrcMerchantID] = React.useState<string|undefined>(undefined);
  const [dstMerchantId, setDstMerchantID] = React.useState<string|undefined>(undefined);
  const [error, setError] = React.useState<string|undefined>(undefined);
  const [loading, setIsLoading] = React.useState<boolean>(false);
  const { addSuccess } = useMessages();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback(ev => {
    ev.preventDefault();
    setIsLoading(true);
    const simpleMigraTrxs = firebase.functions().httpsCallable('simpleMigraTrxs');
    const params = {
      client0,
      client1,
      srcMerchantId,
      dstMerchantId,
    };
    function onSuccess(result: firebase.functions.HttpsCallableResult) {
      addSuccess(result.data.message);
      // reset fields
      setClient0(undefined);
      setClient1(undefined);
      setSrcMerchantID(undefined);
      setDstMerchantID(undefined);
    }
    simpleMigraTrxs(params)
      .then(onSuccess, err => setError(err.message))
      .then(() => setIsLoading(false));
  }, [client0, client1, srcMerchantId, dstMerchantId, addSuccess]);
  return (
    <form name="simpleMigraTrxs" onSubmit={handleSubmit}>
      <ErrorMessage text={error} />
      <p>Simple Migratation from *Old logic* trxs to *New logic*.</p>
      <fieldset disabled={loading}>
        <legend>From:</legend>
        <ClientSelect client={client0} onChange={setClient0} required />
        <Maybe
          component={ClientSelect}
          visible={!!client0}
          parent={client0}
          client={client1}
          onChange={setClient1}
          required
        />
        <Maybe
          component={ProcessorSelect}
          visible={!!(client0 && client1)}
          client0={client0}
          client1={client1}
          merchantID={srcMerchantId}
          onChange={setSrcMerchantID}
          type="typed"
          required={!!(client0 && client1)}
          placeholder="Select an old typed"
        />
      </fieldset>
      <fieldset disabled={loading}>
        <legend>To:</legend>
        <Maybe
          component={ProcessorSelect}
          visible={!!(client0 && client1)}
          client0={client0}
          client1={client1}
          merchantID={dstMerchantId}
          onChange={setDstMerchantID}
          // key={processorSelectKey}
          type="combined"
          required={!!(client0 && client1)}
          placeholder="Select a new combined"
        />
      </fieldset>
      <div className="text-align-right">
        <button type="submit" className="btn btn-primary" disabled={loading}>Migrate</button>
      </div>
    </form>
  );
}

export default SimpleToNewLogicMigra;
