import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { today } from 'common/utils/constants';

interface IProps {
    name:string
    zindex: string
    disabled: boolean
    onChange: Function | undefined
}

function DateRangeWithName({ name,zindex, disabled, onChange }: IProps) {
  return (
    // <Field name="daterange">
    <Field name={name}  >
      {({ input }: FieldRenderProps<[Date, Date]>) => (
        <DateRangePicker
          value={input.value}
          onChange={(e:any) => {
            input.onChange(e)
            if(onChange != undefined){
              onChange(e, name)
            }
          }}
          className={`form-control ${zindex}`}
          id={name}
          disabled={disabled}
        //   maxDate={today}
        />
      )}
    </Field>
  );
}

export default DateRangeWithName;
