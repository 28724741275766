import * as React from "react";
import "firebase/firestore";
import { Form, Field, useField, useForm } from 'react-final-form';
import CommonProcessorFields from './CommonProcessorFields';
import AzulProcessorFields from './AzulProcessorFields';
import TrinityProcessorFields from './TrinityProcessorFields';
import ResortcomProcessorFields from './ResortcomProcessorFields';
import FormBottom from './FormBottom';
import { submitingSubscription } from "common/utils/constants";
import { IProcessor } from "types";
import { useInternalProcessorForm } from '../utils';

const IsReccurrentOption = () =>{
  return (
    <div className="form-group">
        <Field
          allowNull
          type="checkbox"
          defaultValue={false}
          component="input"
          id="isRecurrent"
          name="isRecurrent"
        />
        &nbsp;
        <label htmlFor="isRecurrent">Is this a recurrent merchant?</label>
      </div>
    
  )
}

function ProcessorTypeFields() {
  const { input } = useField('type');
  if (input.value === 'azul') {
    return (
    <>
      <IsReccurrentOption/>
      <AzulProcessorFields />
    </>
    );
  }
  if (input.value === 'trinity') {
    return (
    <>
      <IsReccurrentOption/>
     <TrinityProcessorFields />;
    </> )

    // return  <TrinityProcessorFields />;
  }
  if (input.value === 'resortcom') {
    return <ResortcomProcessorFields />;
  }
  return null;
}

interface IProps {
  onReset(): void,
  doSave(values: IProcessor): Promise<void>,
  doDelete(): Promise<void>,
  values?: IProcessor,
  id: string|undefined,
  hideNonGlobal?: boolean,
}

function PlainProcessorForm(props: IProps) {
  const { onSubmit, onDeleteClick, isNew, loading } = useInternalProcessorForm(props);
  return (
    <Form
      initialValues={props.values}
      onSubmit={onSubmit}
      subscription={submitingSubscription}
      render={({ handleSubmit }) => (
        <form name="processorAdmin" onSubmit={handleSubmit}>
          <fieldset disabled={loading}>
            <CommonProcessorFields isNew={isNew} hideNonGlobal={props.hideNonGlobal} />
            <ProcessorTypeFields />
            <FormBottom
              isNew={isNew}
              onDeleteClick={onDeleteClick}
              loading={loading}
            />
          </fieldset>
        </form>
      )}
    />
  );
}

PlainProcessorForm.defaultProps = {
  processor: {},
}

export default PlainProcessorForm;
