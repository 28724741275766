import React, { useRef, useCallback } from 'react'
import { useHistory, useParams } from "react-router-dom";
import firebase, { firestore } from "firebase";

import { useTable } from "react-table";

import { useMessages } from "config/messagesStateContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IBatch, ITranx } from 'types';

import ModalConfirmation from 'pages/Report/ModalConfirmation';

const Loading = () =>
    <div className="concentric-loading">
        <div></div>
        <div></div>
    </div>




interface ITranxListProps {
    dataList: ITranx[];
    //notifyGranpa: React.Dispatch<React.SetStateAction<ITranx[] | undefined>>
    verifyEdit: (row: number, column: string, newCellVal: CellValue) => void
    handleSelect: (individualId: string) => void
    notApproved: string[]
    declinedList: string[]

}



interface IBatchRecord {
    [key: string]: string | number | null
    batchId: string;
    seq: number;
    paymentAmount: number;
    cardNumber: string;
    cardholderName: string;
    expDate: string;
    cvv: string;
    descr: string | null;
    invoiceNumber: string;
    orderNumber: string | null;
    addressOne: string | null;
    zipCode: string | null;
    name: string | null; // clientName
    recurrent_token: string
}

interface ICoolCellProps {
    row: number
    column: string
    value: string | number | null
    //notifyParent: React.Dispatch<React.SetStateAction<IBatchRecord[] | undefined>>
    verifyEdit: (row: number, column: string, newCellVal: CellValue) => void
}

interface IDMeta {
    rootBatchID: string
    batchIDFrontNumber: string
    lineNumber: string
    recharges: string
    rootTransactionId: string
    nextTransID: string

}

const batchIDScan = (batchID: string) => {
    let sublen = batchID.length - 8
    if (sublen > 0) {
        return [batchID.substring(0, sublen), batchID.substring(sublen)]
    } else {
        return ["yesIsEmpty", batchID.substring(sublen)]
    }
}

const getTransRootID = (rootID: string, transID: string) => {
    const front = transID.substring(0, transID.indexOf(rootID))
    if (front !== "") {
        return [front, transID.substring(front.length)]
    } else {
        return ["yesIsEmpty", transID]
    }
}

interface Recharges {
    rechargesCounter: number
}

const scanID = (id: string): IDMeta => {
    const [batchID, line, recharges] = id.split('-')
    const [batchCounter, rootBatchID] = batchIDScan(batchID)
    return {
        rootBatchID: rootBatchID,
        batchIDFrontNumber: batchCounter,
        lineNumber: line,
        recharges: recharges,
        rootTransactionId: rootBatchID + "-" + line + "-" + "0",
        nextTransID: (Number(batchCounter) + 1) + rootBatchID + "-" + line + "-" + (Number(recharges) + 1)
    }

}

const CoolCell = ({ row, column, value, verifyEdit }: ICoolCellProps) => {
    const [toggle, setToggle] = React.useState(true)
    const [cellData, setCellData] = React.useState<string | number | null>(value)
    const nonModifiableColumns = [column] //NO MODIFICAR NINGUNA COLUMNA, POR AHORA

    const conditionallyToggle = () => {
        if (nonModifiableColumns.includes(column)) {
            return
        }
        setToggle(!toggle)
    }

    const myOnBlur = () => {
        setToggle(!toggle)
        verifyEdit(row, column, cellData)
    }

    return (
        <>
            {toggle ?
                <td onClick={conditionallyToggle}>{cellData}</td>
                :
                <td >
                    <input
                        //@ts-ignore
                        value={cellData}
                        onChange={(e) => setCellData(e.target.value)}
                        autoFocus
                        onFocus={e => e.currentTarget.select()}
                        onBlur={myOnBlur}
                    />
                </td>
            }
        </>
    )

}

interface ICellSelectProps {
    invoice: string
    handleSelect: (individualId: string) => void
    disableDeclined: string[]
}

const CellSelect = ({ invoice, handleSelect, disableDeclined }: ICellSelectProps) => {
    const [checkboxValue, setCheckboxValue] = React.useState(false)
    console.log('CellSelect useState var ', checkboxValue)
    const cbValue = useRef(!checkboxValue)
    const setChekBox = () => {
        setCheckboxValue(!checkboxValue)
        handleSelect(invoice)
        console.log('checkboxValue ', checkboxValue)

    }
    return (
        <td>
            <input
                disabled={disableDeclined.includes(invoice)}
                checked={checkboxValue}
                onChange={setChekBox}
                type="checkbox"
            />
        </td>
    )

}


const CoolTable = ({ dataList, verifyEdit, handleSelect, notApproved, declinedList }: ITranxListProps) => {
   // "Select",  SE LE QUITO EL CAMPOS  HASTA QUE SE TENGA FUNCIONALIDAD"Subscription ID", recurrent_resp_token
    const columns_titles = ["Invoice #", "Payment amount", "CardHolder Name", "Card No.", "Exp date", "CVV",'Account Number', "Billing address",  "Authorization code", "Reference", 'Status']
    const columns = ['invoiceNumber', 'paymentAmount', 'cardholderName', 'maskedCC', 'expDate', 'cvv','orderNumber', 'billingAddress.addressOne','authCode','descr','response']
    const [mainTable, setMainTable] = React.useState(dataList)
    const rechargeCounter = React.useState(0)
    const uuid = () =>
        "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });

    const isEven = (n: number) => {
        if (n % 2 == 0) {
            return 'even-itranx-list-modal'
        }
    }
    //fetch from recharges document
    React.useEffect(() => {
        const newTableData: ITranx[] = []
        const getRecharges = async (theList: ITranx[]) => {
            await Promise.all(
                theList.map(async tranx => {

                    const idMetaInfo = scanID(tranx.invoiceNumber)
                    // console.log('idmetaInfo ', idMetaInfo)
                    await firebase.firestore().doc(`recharges/${idMetaInfo.rootTransactionId}`).get()
                        .then(doc => {
                            if (doc.exists) {
                                const counter = doc.data() as Recharges
                                tranx.rechargesCounter = counter.rechargesCounter
                                newTableData.push(tranx)
                            }
                        })
                })
            )

        }
        getRecharges(dataList).then(() => setMainTable(newTableData))
    }, [dataList])



    return (
        <div className="table-container table-scroll-style">
            <table className="itranx-list-modal">
                <thead>
                    <tr>
                        {columns_titles.map((title, idx) => (
                            <th key={`${title}-${idx}`} className="sticky-th">{title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>



                    {
                        mainTable.map((row, idx) => (
                            <tr className={`${notApproved.includes(row.invoiceNumber) || declinedList.includes(row.invoiceNumber) ? 'rose' : isEven(idx + 1)}`}>
                                {/* <CellSelect invoice={row.invoiceNumber} handleSelect={handleSelect} disableDeclined={notApproved} /> */}
                                {
                                    columns.map(cell => {
                                        return (
                                            <CoolCell key={uuid()}
                                                row={idx}
                                                column={cell}
                                                value={row[cell]}
                                                verifyEdit={verifyEdit} />)
                                    }

                                    )
                                }



                            </tr>
                        ))
                    }


                </tbody>
            </table>
        </div>
    )

}

type CellValue = string | number | null

const objectCompare = (x: Object, y: Object) => {
    return JSON.stringify(x) === JSON.stringify(y)
}


export default function ViewBatchTableModal() {
    const history = useHistory();
    const onClose = history.goBack;
    const { addSuccess } = useMessages();

    const [loading, setLoading] = React.useState(true)

    //@ts-ignore
    let { batchID } = useParams();

    // get list of declined transactions
    const [notApproved, setNotApproved] = React.useState<string[]>([])
    const [declinedList, setDeclinedList] = React.useState<string[]>([])
    const [confirmVisible, setConfirmVisible] = React.useState<boolean>(false);


    const onLaunchSelected = firebase.functions().httpsCallable('onLaunchSelected');

    const [data, setData] = React.useState<ITranx[]>([])
    const [originalData, setOriginalData] = React.useState<ITranx[]>([])
    const [toBeSaved, setToBeSaved] = React.useState<ITranx[]>([])
    const [toolTip, setToolTip] = React.useState("")
    const [rechargeSelected, setRechargeSelected] = React.useState<string[]>([])

    // to be deleted, this was for debugging purposes
    React.useEffect(() => {
        console.log('rechargeSelected ', rechargeSelected)
    }, [rechargeSelected])

    const handleSelect = (individualId: string) => {
        let selectedCopy = [...rechargeSelected]

        if (selectedCopy.includes(individualId)) {
            let arrIndex = selectedCopy.indexOf(individualId);
            selectedCopy.splice(arrIndex, 1);
            setRechargeSelected(selectedCopy)
        } else {
            setRechargeSelected([...selectedCopy, individualId])
        }
    }



    // to be deleted, this was for debugging purposes
    React.useEffect(() => {
        console.log('to be saved: ', toBeSaved)
        console.log('whole data: ', data)
    }, [toBeSaved, data])

    const onBatchRecharge = firebase.functions().httpsCallable("onBatchRecharge");
    const onBatchRechargeUpdated = firebase.functions().httpsCallable("onBatchRechargeUpdated");
    const onBatchRechargeDeclined = firebase.functions().httpsCallable("onBatchRechargeDeclined");

    const makeBatchRechargev2 = async () => {
        console.log('makeBatchRechargev2 called: ', batchID)
        addSuccess('batch is being reprocessed, this might take a while');
        onBatchRecharge({ batchID: batchID })
            .catch(err => { console.log(err) })
        setConfirmVisible(false)
        onClose()

    }

    const makeBatchRecharge = async () => {
        console.log('makeBatchRecharge called: ', batchID)
        addSuccess('batch is being reprocessed, this might take a while');
        onBatchRecharge({ batchID: batchID })
            .catch(err => { console.log(err) })
        onClose()
    }

    const rechargeDeclinedOnly = async () => {
        console.log('rechargeDeclinedOnly called: ', batchID)
        addSuccess('reprocessing declined transactions only, please wait!');
        onBatchRechargeDeclined({ batchID: batchID })
            .catch(err => { console.log(err) })
        onClose()
    }

    const rechargeDeclinedOnly2 = async () => {
        const candidates = [...declinedList.filter(d => !notApproved.includes(d))]
        console.log('rechargeDeclinedOnly called: ', batchID)
        addSuccess('reprocessing declined transactions only, please wait!');
        onLaunchSelected({ selected: candidates, action: "rechargeDeclinedOnly2", batchID: batchID })
            .catch(err => { console.log(err) })
        onClose()
    }

    const getGoodCandidateForSave = (original: ITranx, y: ITranx, cb: React.Dispatch<React.SetStateAction<ITranx[]>>) => {
        if (!objectCompare(original, y)) {
            const cardNumber = original['cardNumber'] === y['cardNumber']
            const expDate = original['expDate'] === y['expDate']
            const cvv = original['cvv'] === y['cvv']
            if (cardNumber && expDate && cvv) {
                cb([...toBeSaved, y])
            }
        }
    }

    const verifyEdit = (row: number, column: string, newCellVal: CellValue) => {
        if (data) {
            const dataCandidate = [...data];
            const clone = Object.assign({}, dataCandidate[row])
            clone[column] = newCellVal
            dataCandidate.splice(row, 1, clone)

            for (let _i = 0; _i < data.length; _i++) {
                let data_entry = data[_i];
                let candidate_entry = dataCandidate[_i];
                getGoodCandidateForSave(data_entry, candidate_entry, setToBeSaved)
            }
            setData(dataCandidate)

        }


    }

    const resetChanges = () => {
        setData(originalData)
        setToBeSaved([])
    }




    const launchSelectedBtn = () => {
        setLoading(true)
        if (rechargeSelected) {
            onLaunchSelected({ selected: rechargeSelected, action: "launchSelectedBtn", batchID: batchID })
                .then(data => {
                    addSuccess(data.data.message)
                    console.log(data)
                    setLoading(false)
                    onClose()
                }).catch(err => console.log(err))

        }

    }


    const onSaveInModal = firebase.functions().httpsCallable('onSaveInModal');

    const saveBtn0 = () => {
        if (toBeSaved) {
            setLoading(true)
            onSaveInModal({ toBeSaved: toBeSaved })
                .then(data => {
                    addSuccess(data.data.message)
                    console.log(data)
                    setLoading(false)
                }).catch(err => console.log(err))
        }
    }

    const saveBtn = async () => {
        if (toBeSaved) {
            setLoading(true)
            let nextData = [...data]
            for (let _i = 0; _i < toBeSaved.length; _i++) {
                const item = toBeSaved[_i];
                const index = data.findIndex(elem => elem.invoiceNumber === item.invoiceNumber)
                nextData.splice(index, 1, item)
            }
            await firebase.firestore().doc(`batchs/${batchID}`)
                .update({
                    modalViewInfo: nextData
                })
            onSaveInModal({ toBeSaved: toBeSaved })
                .then(data => {
                    addSuccess(data.data.message)
                    console.log(data)
                    setData(nextData)
                    setToBeSaved([])
                    setLoading(false)
                }).catch(err => console.log(err))




        }
    }

    const getResponse = (trx: ITranx) => {
        switch (trx.processorType) {
          case 'azul':
            return `${trx?.resp?.ResponseMessage} ${trx?.resp?.ErrorDescription && `:${trx?.resp?.ErrorDescription}`}`;
          case 'trinity':
            return `${trx?.resp?.bank_message}`
          case 'resortcom':
            return `${trx?.resp?.CardAuthorizationStatusDescription}`
          default:
            return '';
        }
      }

    //@ts-ignore  
    React.useEffect(() => {

        let mounted = true;
        const getTableData = async () => {
            await firebase.firestore().doc(`batchs/${batchID}`)
                .get().then(async doc => {
                    if (mounted) {
                        if (doc.exists) {
                            let myIbatchData: ITranx[] = [];
                            let dataTrx = await firebase.firestore().collectionGroup("trxs").
                                where("batchId", '==', batchID)
                                .get()
                            dataTrx.docs.forEach(doc1 => {
                                myIbatchData.push(doc1.data() as ITranx)
                            });

                            const myIbatch = (doc.data() as IBatch)
                            const table = ((myIbatchData) as ITranx[])

                                .map(tranx => ({ ...tranx, maskedCC: `${tranx.cardNumber}******${tranx.ccLast4}`, response: getResponse(tranx) }))
                            setData(table)
                            setNotApproved(table.filter(r => r.recurrent_resp_token === "not approved").map(r => r.invoiceNumber))
                            setOriginalData(table)
                            setDeclinedList(table.filter(r => r.status === "D").map(r => r.invoiceNumber))
                            setLoading(false)
                        }
                    }
                })
        }
        getTableData()
        return () => mounted = false;

    }, [])



    const ArrayEquals = (a: string[], b: string[]) => a.length === b.length && a.every((v, i) => v === b[i]);


    const tempDeclinedList = [...declinedList].sort((a, b) => a == '' ? a?.localeCompare(b) : 0)
    const tempNotApproved = [...notApproved].sort((a, b) => a == '' ? a?.localeCompare(b) : 0)
    const rechargeDeclinedOnlyBoolean = ArrayEquals(tempDeclinedList, tempNotApproved)




    const saveBtnMsg = 'The changes will only be saved in the corresponding cells if the Card no., Exp date or CVV are not modified'
    const rechargeAllBtnMsg = 'This will make a recharge to all the transactions'
    const rechargeDeclinedBtnMsg = 'Use this to recharge only the transactions that as a Declined status'
    const resetToOriginalBtnMsg = 'This will reset all the changes'
    const selectedBtnMsg = 'This will launch the recharge for the selected items'
    return (
        <div
            className="modal show refund-details"
            id="ViewBatchTableModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="ViewBatchTableModal"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title" id="ViewBatchTableLabel">
                            Batch transactions info
                        </h1>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={onClose}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        {/* <h1>{batchID}</h1> */}
                        {loading ?
                            <Loading />
                            : <CoolTable
                                dataList={data}
                                verifyEdit={verifyEdit}
                                handleSelect={handleSelect}
                                notApproved={notApproved}
                                declinedList={declinedList}

                            />
                        }
                        <div style={{ height: '20px', marginTop: '10px' }}>
                            {toolTip}
                        </div>
                    </div>

                    <div className="modal-footer" >

                        {/* //Agregado JC, pa que no se vea tan vacio */}
                        <button
                            className="btn btn-primary"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={onClose}
                        >
                        <span> close</span>
                        </button>


                        {/* temporarily disabled */}
                        {/* <button
                            className="btn btn-primary"
                            onClick={saveBtn}
                            onMouseEnter={() => setToolTip(saveBtnMsg)}
                            onMouseLeave={() => setToolTip('')}
                            >
                               <span> save changes</span>
                            </button> */}


                        {/* QUITAR HASTA QUE FUNCIONEN POR COMPLETO ESTAS     */}

                        {/* <button
                            className="btn btn-primary"
                            onClick={() => setConfirmVisible(true)}
                            onMouseEnter={() => setToolTip(rechargeAllBtnMsg)}
                            onMouseLeave={() => setToolTip('')}
                        >

                            <FontAwesomeIcon icon={['fas', 'reply-all']} style={{ fontSize: '17px', color: 'white' }} />
                            <span style={{ marginLeft: '10px' }}>Recharge All</span>

                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={rechargeDeclinedOnlyBoolean}
                            onClick={rechargeDeclinedOnly2}
                            onMouseEnter={() => setToolTip(rechargeDeclinedBtnMsg)}
                            onMouseLeave={() => setToolTip('')}
                        >
                            <FontAwesomeIcon icon={['fas', 'recycle']} style={{ fontSize: '17px', color: 'white' }} />
                            <span style={{ marginLeft: '10px' }}>Recharge declined</span>
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={typeof rechargeSelected !== 'undefined' && rechargeSelected.length === 0}
                            onClick={launchSelectedBtn}
                            onMouseEnter={() => setToolTip(selectedBtnMsg)}
                            onMouseLeave={() => setToolTip('')}
                        >
                            <span>Launch selected</span>
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={resetChanges}
                            onMouseEnter={() => setToolTip(resetToOriginalBtnMsg)}
                            onMouseLeave={() => setToolTip('')}
                        >
                            <span>Reset</span>
                        </button> */}

                        <ModalConfirmation
                            visible={confirmVisible}
                            operation="Reprocess"
                            handleClose={() => setConfirmVisible(false)}
                            newInvoiceNumber={(batchID as string)}
                            onSubmit={() => makeBatchRechargev2()}
                        />

                    </div>

                </div>

            </div>

        </div>
    )
}
