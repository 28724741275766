import React from "react";

interface ITabProps{
    label: string;
    onClick: (tab: string) => void;
    activeTab: string;
}

const Tab = (props: ITabProps) => {
	const onClick = () => {
		const { label, onClick } = props;
		onClick(label);
	};

	let className = "tab-list-item";

	if (props.activeTab === props.label) {
		className += " tab-list-active";
	}
	return (
		<li className={className} onClick={onClick}>
			{props.label}
		</li>
	);
};

export default Tab;
