import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import Select from 'react-select';
import { useField } from 'react-final-form';
import { emptyObject, emptyString, emptyArray, optionsByIdField } from 'common/utils/constants';
import { IProcessor } from 'types';
import { useMessages } from 'config/messagesStateContext';
import ErrorMessage from 'common/components/ErrorMessage';
import { initCap } from 'common/utils/fns';

interface IProps {
  values?: string[],
  onChange(values: string[]): void,
}

function buildInputGroupedValues(values: IProcessor[]) {
  function reducFn(acc: Record<string, { label: string, value: string}[]>, it: IProcessor) {
    acc[it.type] = acc[it.type] ?? [];
    acc[it.type].push({ label: `${it.id} ${it.name}`, value: it.id });
    return acc;
  }
  const rawValues = values.reduce(reducFn, {});
  return Object.entries(rawValues).map(([key, value]) => ({ label: initCap(key), options: value }));
}

export function ProcessorListInput(props: IProps) {
  const query = React.useMemo(() => firebase.firestore().collection('processors').where('type', '==', 'combined'), emptyArray);
  const [rawValues, loading, error] = useCollectionDataOnce<IProcessor>(query, optionsByIdField);

  const options = React.useMemo(
    () => buildInputGroupedValues(rawValues ?? emptyArray),
    [rawValues]
  );
  const onLocalChange = React.useCallback((values: { value: string, label: string }[]) => {
    const cleanValues = values.map(it => it.value);
    props.onChange(cleanValues);
  }, [props.onChange]);
  const value = React.useMemo(
    () => props.values?.map(it => ({ label: it, value: it })) ?? emptyArray,
    [props.values]
  );
  return (
    <div>
      <Select
        isMulti
        options={options}
        value={value}
        // @ts-ignore
        onChange={onLocalChange}
      />
      <ErrorMessage text={error} />
    </div>
  );
}
ProcessorListInput.defaultProps = {
  values: emptyArray,
}


interface IFinalProps {
  name: string,
}
export default function FinalProcessorListInput(props: IFinalProps) {
  const { input } = useField(props.name, { defaultValue: emptyArray });
  return (
    <ProcessorListInput
      values={input.value}
      onChange={input.onChange}
    />
  );
}

