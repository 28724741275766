import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { useHistory, useLocation, Link, Redirect } from "react-router-dom";
import {
  useDocumentDataOnce,
  useCollectionDataOnce,
} from "react-firebase-hooks/firestore";
import { useDownloadURL } from "react-firebase-hooks/storage";
import {
  optionsByIdField,
  emptyArray,
  currencyFormatConfig,
} from "common/utils/constants";
import moment from 'moment';
import { ITranx, IKeysOfStr, IAccess } from "types";
import Maybe from "common/components/Maybe";
import { useGlobalState } from "config/globalStateContext";
import {
  useClientName,
  useAgentName,
  useProcessorsName,
  useUserPermissions,
  useSubMerchantName,
} from "common/hooks/firestore";
import { useMessages } from "config/messagesStateContext";
import { buildQueryStr, getStatusTextClass, getCCName } from "common/utils/fns";
import { attachmentsFolderName } from "common/utils/constants";
import { reduceQueryByClient } from "common/utils/firebase";
import ErrorMessage from "common/components/ErrorMessage";
import RefundInput from "./RefundInput";
import UploadForm from './UploadForm';

function Delayed(props: { as: React.FC; ms: number }) {
  const { as: As, ms, ...rest } = props;
  const [delayed, setDelayed] = React.useState(true);
  React.useEffect(() => {
    const timeout = setTimeout(setDelayed, ms, false);
    return () => clearTimeout(timeout);
  }, emptyArray);
  return delayed ? null : <As {...rest} />;
}


export const getRespErrorMessage = (
  resp: any|null
): string =>
  (resp)?.Error?.messages?.[0]?.description ??
  ((resp)?.CardAuthorizationDetailStatus ||
    (resp)?.CardAuthorizationStatusDescription) ??
  ((resp)?.ErrorDescription ||
    (resp)?.ResponseMessage);

function DetailItem(props: {
  state: ITranx;
  keysOfProcessorNames: IKeysOfStr;
  access: IAccess,
}) {
  const { state } = props;
  const [statusText, statusClass, transType] = getStatusTextClass(
    state.type,
    state?.status
  );
  const maybeErrorText = getRespErrorMessage(state.resp);
  const clientName = useClientName(state.client);
  // @ts-ignore
  const agentName = useAgentName(state.createdBy);
  const subMerchantName = useSubMerchantName(state.client, state.processor, props.keysOfProcessorNames);
  const urlStr = React.useMemo(
    () =>
      buildQueryStr(
        state,
        clientName,
        agentName,
        subMerchantName,
      ),
    [state, clientName, agentName, subMerchantName]
  );
  const toPrintVersion = {
    pathname: `/report/${state.invoiceNumber}/print-version`,
    state: { url: urlStr, item: state },
  };
  const dateStr = React.useMemo(
    () =>
      state.statusRefund === 'U' ?
          moment(state.date).format('M/D/YYYY').toString() 
      :
      new Date(
        // @ts-ignore
        (state.createdAt.seconds ?? state.createdAt._seconds) * 1000
      ).toLocaleDateString(),
    [state.createdAt, state.date]
  );
  const [url] = useDownloadURL(
    props.state.fileName
      ? firebase
          .storage()
          .ref(attachmentsFolderName + "/" + props.state.fileName)
      : null
  );
  return (
    <tr title={maybeErrorText}>
      <td>{state.invoiceNumber}</td>
      <td>
        {state.cardNumber}******{state.ccLast4} {state.expDate}{" "}
        {getCCName(state.cardNumber)}
      </td>
      <td>{state.entryStatus === 'U' || state.statusRefund === 'U' ? moment(state.date).format('M/D/YYYY').toString() : dateStr}</td>
      <td className={state.entryStatus === 'U' || state.statusRefund === 'U' ? "" : statusClass}>{ state.entryStatus === 'U' || state.statusRefund === 'U' ? "Upload" :statusText}</td>
      <td>{transType}</td>
      <td>$0.00</td>
      <td>
        {(state.type === "refund"
          ? -1 * (state?.refundAmount ?? 0)
          : state.paymentAmount
        )?.toLocaleString(undefined, currencyFormatConfig)}
      </td>
      <td>{agentName}</td>
      <td className="payment-actions">
        <Maybe component={Link} visible={!!props.access?.print} to={toPrintVersion}>
          <svg
            width="18px"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // style="width: 16px !important;"
          >
            <path
              d="M14 6V2.4375C14 2.15625 13.875 1.90625 13.6875 1.71875L12.2813 0.3125C12.0938 0.125 11.8438 0 11.5625 0H3C2.4375 0 2 0.46875 2 1V6C0.875 6 0 6.90625 0 8V11.5C0 11.7813 0.21875 12 0.5 12H2V15C2 15.5625 2.4375 16 3 16H13C13.5313 16 14 15.5625 14 15V12H15.5C15.75 12 16 11.7813 16 11.5V8C16 6.90625 15.0938 6 14 6ZM12 14H4V11H12V14ZM12 7H4V2H10V3.5C10 3.78125 10.2188 4 10.5 4H12V7ZM13.5 9.25C13.0625 9.25 12.75 8.9375 12.75 8.5C12.75 8.09375 13.0625 7.75 13.5 7.75C13.9063 7.75 14.25 8.09375 14.25 8.5C14.25 8.9375 13.9063 9.25 13.5 9.25Z"
              fill="#0033AE"
            />
          </svg>
        </Maybe>
        &nbsp;
        <a target="_blank" href={url} className={url ? undefined : "hidden"}>
          <img src="/assets/images/icons/Download.svg" alt="" />
        </a>
      </td>
    </tr>
  );
}

function OriginalTranx(props: {
  orig: ITranx;
  keysOfProcessorNames: IKeysOfStr;
  access: IAccess,
}) {
  const refPath = props.orig?.client?.map((it) => `clients/${it}`).join("/");
  const [data, loading, error] = useDocumentDataOnce<ITranx>(
    refPath && props.orig.origInvoiceNumber
      ? firebase
          .firestore()
          .doc(`${refPath}/trxs/${props.orig.origInvoiceNumber}`)
      : null
  );
  // Not a Refund tranx;
  if (!props.orig.origInvoiceNumber) {
    return null;
  }
  if (error) return <ErrorMessage text={error} />;
  if (data)
    return (
      <DetailItem
        state={data}
        keysOfProcessorNames={props.keysOfProcessorNames}
        access={props.access}
      />
    );
  else return null;
}

function RelatedTranxs(props: {
  client: string[];
  field: string;
  fieldValue: string | undefined;
  shoudIgnoreId?: string;
  keysOfProcessorNames: IKeysOfStr;
  access: IAccess,
}) {
  const queryRef =
    props.client && props.fieldValue && 
      reduceQueryByClient(props.client, firebase.firestore())
        .collection("trxs")
        .where(props.field, "==", props.fieldValue);
  const [data, loading, error] = useCollectionDataOnce<ITranx>(
    queryRef && props.fieldValue ? queryRef : null,
    optionsByIdField
  );
  console.log('RelatedTranxs: ', data)
  const cleanData = React.useMemo(
    () => data?.filter((it) => it.id !== props.shoudIgnoreId),
    [data]
  );
  if (error) return <ErrorMessage text={error} />;
  if (cleanData)
    return (
      <>
        {cleanData.map((it) => (
         
            <DetailItem
              key={it.id}
              state={it}
              access={props.access}
              keysOfProcessorNames={props.keysOfProcessorNames}
            />
    
        ))}
      </>
    );
  else return null;
}



function DetailModal() {
  const { state } = useLocation<ITranx | undefined>();
  console.log('DetailModal - state: ', state)
  const history = useHistory();
  const [localLoading, setLoading] = React.useState<boolean>(false);
  const [global] = useGlobalState();
  const loading = localLoading || !!global?.isLoading;
  const { addError } = useMessages();
  const clientName = useClientName(state?.client, setLoading, addError);
  const access = useUserPermissions()!;
  const onClose = history.goBack;
  // @ts-ignore
  const agentName = useAgentName(state?.createdBy);
  const keysOfProcessorNames = useProcessorsName();
  const subMerchantName = useSubMerchantName(state!.client, state!.processor, keysOfProcessorNames);
  // @ts-ignore
  const urlStr = React.useMemo(
    () =>
      buildQueryStr(
        state,
        clientName,
        agentName,
        subMerchantName,
      ),
    [state, clientName, agentName, subMerchantName]
  );
  const toPrintVersion = {
    pathname: state ? `/report/${state.id}/print-version` : null,
    state: { url: urlStr, item: state },
  };
  if (!state) {
    return <Redirect to="/" />;
  }
  return (
    <div
      className="modal payment-details-modal show scrollable-y"
      id="paymentDetail"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="paymentDetailLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title" id="paymentDetailLabel">
              Invoice information
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="invoice-information">
              <div className="form-group form-inline">
                <label htmlFor="invoice">Invoice</label>
                <input
                  type="text"
                  className="form-control"
                  id="invoice"
                  value={state.invoiceNumber}
                  disabled
                />
              </div>
              <div className="form-group form-inline">
                <label htmlFor="merchantNumber">Merchant Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="merchantNumber"
                  value={state.processor}
                  disabled
                />
              </div>
              <div className="form-group form-inline">
                <label htmlFor="merchantName">Merchant Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="merchantName"
                  value={clientName}
                  disabled
                />
              </div>
            </div>
           <div className="table-scroll-style">
              <table className="table">
                <thead>
                  <tr>
                    <th>Invoice</th>
                    <th>Card Info.</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Trans. Type</th>
                    <th>Revised Amount</th>
                    <th>Amount</th>
                    <th>Processed by</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <DetailItem
                    state={state}
                    keysOfProcessorNames={keysOfProcessorNames}
                    access={access}
                  />
                  <OriginalTranx
                    orig={state}
                    keysOfProcessorNames={keysOfProcessorNames}
                    access={access}
                  />
                  {/* <RelatedTranxs
                    client={state.client}
                    field="origInvoiceNumber"
                    fieldValue={state.origInvoiceNumber}                                                                                                                                                                                                                                                                                                          
                    shoudIgnoreId={state.id}
                    keysOfProcessorNames={keysOfProcessorNames}
                    access={access}
                  /> */}
                  <RelatedTranxs
                    client={state.client}
                    field="origInvoiceNumber"
                    fieldValue={state.invoiceNumber}
                    keysOfProcessorNames={keysOfProcessorNames}
                    access={access}
                  />
                </tbody>
           
            </table>
            </div>
            <RefundInput state={state} onClose={onClose} />
            <UploadForm state={state} onClose={onClose} />
          </div>
          <div className="modal-footer">
            <Maybe
              component={Link}
              visible={state.status === "A" && !!access?.print}
              to={toPrintVersion}
              className="print btn btn-primary"
            >
              <img src="/assets/images/icons/print.svg" alt="" /> Print
            </Maybe>
            <button
              type="button"
              className="btn btn-secondary"
              disabled={loading}
              data-dismiss="modal"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailModal;
