import * as React from "react";
import { emptyObject, unit } from "common/utils/constants";
import { IGlobal } from "types";

type IGlobalState = [
  IGlobal | null,
  React.Dispatch<React.SetStateAction<IGlobal | null>>,
  (v: boolean) => void
];

// @ts-ignore
export const GlobalStateContext = React.createContext<IGlobalState>([
  null,
  unit,
  (v: boolean) => false,
]);

interface IProps {
  children: React.ReactNode;
}

export function GlobalStateProvider(
  props: IProps
): React.FC<React.ReactElement> {
  const [state, setState] = React.useState<IGlobal | null>(null);
  const setLoading = (v: boolean) =>
    setState(
      (global) => ({ ...global, isLoading: !!!global?.isLoading } as IGlobal)
    );
  const value = React.useMemo(() => [state, setState, setLoading], [state]);
  
  // @ts-ignore
  return <GlobalStateContext.Provider value={value} {...props} />;
}

export function useGlobalState() {
  const context = React.useContext(GlobalStateContext);
  if (!context) {
    throw new Error(`useCount must be used within a CountProvider`);
  }
  return context;
}
