import * as React from "react";
import * as firebase from 'firebase/app';
// import * as firebase from 'firebase';
import "firebase/firestore";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { IClient } from "types";
import { emptyArray, submitingSubscription, pageSize } from "common/utils/constants";
import { compose, getMessage, ignore, constant, log } from "common/utils/fns";
import { usePaginatedCollection } from "common/hooks/firestore";
import Maybe from 'common/components/Maybe';
import Modal from "common/components/Modal";
import Pagination from "common/components/Pagination";
import { useMessages } from "config/messagesStateContext";
import { useGlobalState } from "config/globalStateContext";
import { usePaymentId } from "common/hooks/utils";
import ProcessorListInput from "./ProcessorListInput";


function buildCompanyQuery(parentId: string|undefined) {
  const clientRootQuery  = firebase.firestore().collection('clients')
  const query = parentId ? clientRootQuery.doc(parentId).collection('clients') : clientRootQuery;
  return query;
}

function doDeleteCompany(id: string, parentId: string|undefined) {
  return buildCompanyQuery(parentId).doc(id).delete();
}

function ItemRow(props: { client: IClient, parentId: string|undefined, baseUrl: string, }) {
  const { client, parentId } = props;
  const toViewPathname = `${props.baseUrl}/${client.id}`;
  const state = { ...client, parentId };
  const toView = {
    pathname: toViewPathname,
    state,
  };
  const toChildrenPath = {
    pathname: toViewPathname + ((parentId === undefined) ? '/companies' : '/processors'),
    state,
  };
  const { addError } = useMessages();
  const onDeleteClick = React.useCallback(() => {
    const doDelete = window.confirm(`Please confirm you want to delete this item: '${client.name}' (${client.id}).`);
    if (doDelete) {
      doDeleteCompany(client.id, parentId)
        .then(ignore, compose(addError, getMessage));
    }
  }, [client.id, parentId]);
  return (
    <tr>
      <td>{client.id}</td>
      <td>{client.name}</td>
      <td>
        <Link to={toView}>
          <img alt="View" src="/assets/images/icons/eye.svg" />
        </Link>
        &nbsp;
        <Link to={toChildrenPath}>
          <img alt="Stores" src="/assets/images/icons/store.svg" />
        </Link>
        &nbsp;
        <img alt="Delete" onClick={onDeleteClick} src="/assets/images/icons/trash-alt-solid.svg" />
      </td>
    </tr>
  ); 
}

interface IProps {
  parentId?: string;
}

function Companies(props: IProps) {
  const [userData] = useGlobalState();
  const { parentId: pathParentId } = useParams<{ parentId: string|undefined }>();
  const parentId = React.useMemo(() => props.parentId ?? pathParentId, [props.parentId, pathParentId]);
  const { state } = useLocation<IClient|undefined>();
  const { url } = useRouteMatch();
  const query = React.useMemo(() => buildCompanyQuery(parentId).orderBy('name'), [parentId]);
  const { page, onBackClick, onNextClick, items, loading, error } = usePaginatedCollection<IClient>(query, pageSize);
  const rows = React.useMemo(() => items.map(it => <ItemRow key={it.id} baseUrl={url} client={it} parentId={parentId} />), [items, url, parentId]);
  const toAddForm = React.useMemo(() => ({ pathname: url + '/new', state: { parentId } }), [url, parentId]);
  const toAddRate = React.useMemo(() => ({ pathname: url + '/rate/company', state: { parentId } }), [url, parentId]);
console.log(userData)
  return (
    <div>
      <Maybe
        component="div"
        visible={!!error}
        className="alert alert-danger"
        role="alert"
      >
        {error?.message}
      </Maybe>
      <br />
      <div className="clearfix">
        <h6>
          {state?.name ?? parentId}
          <Link to={toAddForm} className="btn btn-primary btn-sm float-right" style={{ marginLeft: 10 }}>+ New</Link>
          {/* { (userData?.access?.superAdmin && userData?.access?.accountsPayable && parentId  ) && (<Link to={toAddRate} className="btn btn-primary btn-sm float-right"> $ Rates</Link> )} */}
        </h6>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
      
      <Pagination
        page={page}
        pageSize={pageSize}
        onNextClick={onNextClick}
        onBackClick={onBackClick}
        itemsLength={items.length}
      />
      <Switch>
        <Route exact path={`${url}/:id`} component={CompanyFormModal} />
        <Route exact path={`${url}/rate/company`} component={RateCompanyModal} />
      </Switch>
    </div>
  );
}

export function MyCompany() {
  const [globalState] = useGlobalState();
  const client = globalState?.client;
  return client ? <Companies parentId={client?.[0]} /> : null;
}

export function RateCompanyModal() {
  const history = useHistory();
  const goBack = history.goBack;
  const { state } = useLocation<IClient>();
  const [loading, setLoading] = React.useState(false);
  const [editRate, setEditRate] = React.useState(false);
  const { addError, addSuccess } = useMessages();
  const initialState = {
    id: null,
    azul: {
      visa: '',
      mc: '',
      discover: '',
      diners: '',
      amex: '',
      jcb: ''
    },
    resortcom: {
      visa: '',
      mc: '',
      discover: '',
      diners: '',
      amex: '',
      jcb: ''
    },
    trinity: {
      visa: '',
      mc: '',
      discover: '',
      diners: '',
      amex: '',
      jcb: ''
    }
  }
  const [rateBypercentagePrev, setRateBypercentagePrev] = React.useState(initialState)
  const [rateBypercentage, setRateBypercentage] = React.useState(initialState)

  const [rateByAmount, setRateByAmount] = React.useState(initialState)
  const [rateByAmountPrev, setRateByAmountPrev] = React.useState(initialState)

  const getCompany =  () => {
    firebase.firestore().collection('clients').doc(state.parentId).collection('rates-percentage').onSnapshot( (snapshot) => {
      const ratesPercentage = snapshot.docs.map( doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      })
      if(ratesPercentage.length > 0) {
        //@ts-ignore
        setRateBypercentage(ratesPercentage[0]);
        //@ts-ignore
        setRateBypercentagePrev(ratesPercentage[0])
      }
    })

    firebase.firestore().collection('clients').doc(state.parentId).collection('rates-amount').onSnapshot( (snapshot) => {
      const ratesAmount = snapshot.docs.map( doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      })
      if(ratesAmount.length > 0) {
        //@ts-ignore
        setRateByAmount(ratesAmount[0]);
        //@ts-ignore
        setRateByAmountPrev(ratesAmount[0])
      }
    })
  }

  const saveRate = () => {
    setLoading(true)
    function onSuccess(data: any) {
      addSuccess(`Company updated successfully!`);
      setLoading(false)
    }
    if(!rateBypercentage.id) {
      const data = rateBypercentage;
      //@ts-ignore
      delete data.id;
      firebase.firestore().doc(`clients/${state.parentId}`).collection("rates-percentage").add(data)
      .then(onSuccess, compose(addError, log, getMessage)).catch(console.log);
    }

    if(!rateByAmount.id) {
      const data = rateByAmount;
      //@ts-ignore
      delete data.id;
      firebase.firestore().doc(`clients/${state.parentId}`).collection("rates-amount").add(data)
      .then(onSuccess, compose(addError, log, getMessage)).catch(console.log);
    }

    if(rateBypercentage.id) {
      firebase.firestore().doc(`clients/${state.parentId}/rates-percentage/${rateBypercentage.id}`).set(rateBypercentage)
      .then(onSuccess, compose(addError, log, getMessage)).catch(console.log);
    }

    if(rateByAmount.id) {
      firebase.firestore().doc(`clients/${state.parentId}/rates-amount/${rateByAmount.id}`).set(rateByAmount)
      .then(onSuccess, compose(addError, log, getMessage)).catch(console.log);
    }
    history.goBack();
  }

  React.useEffect(() => {
    getCompany();
    setRateBypercentagePrev(rateBypercentage);
  }, [])

  React.useEffect(() => {
    if(!editRate) {
      //@ts-ignore
      setRateBypercentage(rateBypercentagePrev);
      setRateByAmount( rateByAmountPrev );
    }
  }, [editRate])
  
  


  return (
    <Modal
      title={`Edit ${state.parentId}`}
      name="company"
      onClose={goBack}
    >
      <div className="clearfix">
        <h6>
          
          <button className="btn btn-primary btn-sm float-right" onClick={() => setEditRate(!editRate)}>{ editRate ? 'Cancel' : 'Edit' }</button>
        </h6>
      </div>
      
      <p>Rate calculated by percentage</p>
      <table className="table">
        <thead>
          <tr>
            <th>Company Provider</th>
            <th>Visa</th>
            <th>MC</th>
            <th>Discover</th>
            <th>Diners</th>
            <th>Amex</th>
            <th>JCB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ResortCom</td>
            <td>
              { (!editRate && rateBypercentage.resortcom.visa) && rateBypercentage.resortcom.visa + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  type="number"
                  value={rateBypercentage.resortcom.visa}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.mc) && rateBypercentage.resortcom.mc + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.mc}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.discover) && rateBypercentage.resortcom.discover + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.discover}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.diners) && rateBypercentage.resortcom.diners + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.diners}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.amex) && rateBypercentage.resortcom.amex + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.amex}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.resortcom.jcb) && rateBypercentage.resortcom.jcb + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.resortcom.jcb}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      resortcom: {
                        ...rateBypercentage.resortcom,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

          </tr>
          <tr>
            <td>Trinity</td>
            <td>
              { (!editRate && rateBypercentage.trinity.visa) && rateBypercentage.trinity.visa + '%'}
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.visa}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.mc) && rateBypercentage.trinity.mc + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.mc}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.discover) && rateBypercentage.trinity.discover + '%'}
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.discover}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.diners) && rateBypercentage.trinity.diners + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.diners}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.amex) && rateBypercentage.trinity.amex + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.amex}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.trinity.jcb) && rateBypercentage.trinity.jcb + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.trinity.jcb}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      trinity: {
                        ...rateBypercentage.trinity,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Azul</td>
            <td>
              { (!editRate && rateBypercentage.azul.visa) && rateBypercentage.azul.visa + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.visa}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.mc) && rateBypercentage.azul.mc + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.mc}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.discover) && rateBypercentage.azul.discover + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.discover}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.diners) && rateBypercentage.azul.diners + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.diners}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.amex) && rateBypercentage.azul.amex + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.amex}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { (!editRate && rateBypercentage.azul.jcb) && rateBypercentage.azul.jcb + '%' }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateBypercentage.azul.jcb}
                  onChange={(e) => {
                    setRateBypercentage({
                      ...rateBypercentage,
                      azul: {
                        ...rateBypercentage.azul,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>Rate calculated by amount</p>
      <table className="table">
        <thead>
          <tr>
            <th>Company Provider</th>
            <th>Visa</th>
            <th>MC</th>
            <th>Discover</th>
            <th>Diners</th>
            <th>Amex</th>
            <th>JCB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ResortCom</td>
            <td>
              { !editRate && rateByAmount.resortcom.visa }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.visa}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.mc }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.mc}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.discover }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.discover}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.diners }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.diners}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.amex }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.amex}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.resortcom.jcb }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.resortcom.jcb}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      resortcom: {
                        ...rateByAmount.resortcom,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

          </tr>
          <tr>
            <td>Trinity</td>
            <td>
              { !editRate && rateByAmount.trinity.visa }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.visa}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.mc }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.mc}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.discover }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.discover}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.diners }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.diners}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.amex }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.amex}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.trinity.jcb }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.trinity.jcb}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      trinity: {
                        ...rateByAmount.trinity,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Azul</td>
            <td>
              { !editRate && rateByAmount.azul.visa }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.visa}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        visa: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.mc }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.mc}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        mc: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.discover }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.discover}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        discover: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.diners }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.diners}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        diners: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.amex }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.amex}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        amex: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>

            <td>
              { !editRate && rateByAmount.azul.jcb }
              { editRate && (
                <input 
                  className="form-control" 
                  value={rateByAmount.azul.jcb}
                  onChange={(e) => {
                    setRateByAmount({
                      ...rateByAmount,
                      azul: {
                        ...rateByAmount.azul,
                        jcb: e.target.value
                      }
                    })
                  }}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div className="clearfix">
        <h6>  
          <button className="btn btn-secondary btn-sm float-right" onClick={goBack}>Close</button>
          <button className="btn btn-primary btn-sm float-right" style={{ marginRight: 10 }} onClick={() => saveRate()}>{ loading ? 'Loading...' : 'Save'}</button>
        </h6>
      </div>
    </Modal>
  )
}

function CompanyForm() {
  const { state } = useLocation<IClient>();
  const isNew = !(!!state.id);
  const [loading, setLoading] = React.useState(false);
  const { addError, addSuccess } = useMessages();
  const history = useHistory();
  const onSubmit = React.useCallback((rawValues: IClient) => {
    const { parentId, ...values } = rawValues;
    setLoading(true);
    console.log('CompanyForm values', values);
    function onSuccess(data: any) {
      console.log('upsertUser success', data);
      addSuccess(isNew ? 'Company created successfully!': `Company updated successfully!`);
      history.goBack();
    }
    const collectionQuery = buildCompanyQuery(state.parentId);
    collectionQuery.doc(values.id).set(values, { merge: true })
      .then(onSuccess, compose(addError, log, getMessage))
      .then(compose(setLoading, constant(false), ignore));
  }, [state.parentId]);
  const randomn = React.useMemo(() => (Math.random()*10).toFixed(0), emptyArray);
  const [maybeId] = usePaymentId(randomn, true);
  return (
    <Form
      initialValues={state}
      subscription={submitingSubscription}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <fieldset disabled={loading}>
            <div className="form-group">
              <label htmlFor="id">ID</label>
              <Field
                component="input"
                className="form-control"
                id="id"
                name="id"
                defaultValue={maybeId}
                required
                disabled={!isNew}
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="name">Display Name</label>
              <Field
                component="input"
                className="form-control"
                id="name"
                name="name"
                required
              />
            </div>

            <Maybe visible={state.parentId == undefined} component="div" className="form-group">
              <label htmlFor="opWProcessors">Processors</label>
              <ProcessorListInput name="opWProcessors" />
            </Maybe>

            <div className="text-align-right">
              <button
                type="submit"
                className="btn btn-primary"
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
            </div>
          </fieldset>
        </form>
      )}
    />
  );
}

export function CompanyFormModal() {
  const history = useHistory();
  const goBack = history.goBack;
  return (
    <Modal
      title="Company Form"
      name="company"
      onClose={goBack}
    >
      <CompanyForm />
    </Modal>
  )
}

export default Companies;
