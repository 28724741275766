import * as React from "react";
import { Field } from 'react-final-form';
import { emptyString } from "common/utils/constants";

function AzulProcessorFields() {
  return (
    <>
      <div className="form-group">
        <label htmlFor="auth1">Auth 1</label>
        <Field
          component="input"
          className="form-control"
          name="auth1"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="auth1">Auth 2</label>
        <Field
          component="input"
          className="form-control"
          name="auth2"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="auth1">e-Commerce URL</label>
        <Field
          component="input"
          className="form-control"
          name="eCommerceUrl"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="auth1">Customer Service Phone</label>
        <Field
          component="input"
          className="form-control"
          name="customerServicePhone"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="auth1">Currency Code</label>
        <Field
          component="select"
          className="form-control"
          name="currency"
          required
          defaultValue="U$S"
        >
          <option value={emptyString}>Select a currency code</option>
          <option value="U$S" title="U$S">USD</option>
          <option value="$" title="$">DOP</option>
        </Field>
      </div>
    </>
  );
}

export default AzulProcessorFields;
