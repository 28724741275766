import * as React from "react";
import * as firebase from "firebase/app";
// import * as firebase from 'firebase';
import "firebase/firestore";
import { Switch, Route, Redirect } from "react-router-dom";
import { Form, Field, useField } from "react-final-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { IUser } from "types";
import {
  emptyArray,
  emptyString,
  submitingSubscription,
  pageSize,
} from "common/utils/constants";
import { getSearchEndValue, initCap, similarity } from "common/utils/fns";
import { usePaginatedCollection } from "common/hooks/firestore";
import Maybe from "common/components/Maybe";
import ClientSelect from "./components/ClientSelect";
import ProcessorSelect from "./components/ProcessorSelect";
import Modal from "common/components/Modal";
import Pagination from "common/components/Pagination";
import { useMessages } from "config/messagesStateContext";
import { useGlobalState } from "config/globalStateContext";
import { usePaymentId } from "common/hooks/utils";

import XLSX from "xlsx";

import ReactPaginate from "react-paginate";

//@ts-ignore
import FuzzySearch from "react-fuzzy";

import Toggle, { ToggleAdapater } from "common/components/Switch";

const RowInfo = (props: { user: IUser }) => {
  const { user } = props;

  const to = {
    pathname: `/super-admin/users/${user.uid}`,
    state: { ...user, uid: user.uid },
  };

  return (
    <tr>
      <td>{user.uid}</td>
      <td>{user.displayName}</td>
      <td>{user.email}</td>
      <td>
        <Maybe component="span" visible={!user.disabled}>
          &#10003;
        </Maybe>
      </td>
      <td>{user.client && user.client[0] ? user.client[0] : ""}</td>
      <td>
        <Link to={to}>
          <img alt="View" src="/assets/images/icons/eye.svg" />
        </Link>
      </td>
    </tr>
  );
};

function ItemRow(props: { user: IUser }) {
  const { user } = props;

  // console.log('user', user)
  const to = {
    pathname: `/super-admin/users/${user.uid}`,
    state: { ...user, uid: user.uid },
  };
  return (
    <tr>
      <td className="special-td pl-3" style={{ width: "14%" }}>
        {user.uid}
      </td>
      <td className="special-td" style={{ width: "25%" }}>
        {user.displayName}
      </td>
      <td className="special-td">{user.email}</td>
      <td className="special-td" style={{ paddingLeft: "100px" }}>
        <Maybe component="span" visible={!user.disabled}>
          &#10003;
        </Maybe>
      </td>
      <td className="special-td">
        <Link to={to}>
          <img alt="View" src="/assets/images/icons/eye.svg" />
        </Link>
      </td>
    </tr>
  );
}

function UsersFiltersForm(props: {
  filters: Record<string, any>;
  onSubmit: (p: object) => void;
}) {
  const { addError } = useMessages();
  const onSubmit = React.useCallback(
    (values: Record<string, any>) => {
      if (values.email && values.name) {
        return addError('You can filter "Name" or "E-Mail" only at once');
      }
      props.onSubmit(values);
    },
    [props.onSubmit]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={props.filters}
      render={({ handleSubmit, values }) => (
        <form className="container" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <br />
              {/* <span>{JSON.stringify(values)}</span> */}
              <h6>Search by</h6>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="uid">UID</label>
                <Field
                  component="input"
                  className="form-control"
                  name="uid"
                  type="search"
                  id="uid"
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="uid">Name</label>
                <Field
                  component="input"
                  className="form-control"
                  name="name"
                  id="name"
                  type="search"
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="uid">E-Mail</label>
                <Field
                  component="input"
                  className="form-control"
                  name="email"
                  id="email"
                  type="search"
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>&nbsp;</label>
                <br />
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Apply Filter"
                />
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
}

const toAddForm = {
  pathname: "/super-admin/users/new",
  state: { adminOf: emptyArray },
};
function buildUsersQuery(
  filters: Record<string, any>,
  maybeUsersClient?: string[]
): firebase.firestore.Query<any> {
  const query = firebase.firestore().collection(`users`);
  const prequery = filters?.uid
    ? query
        .where(firebase.firestore.FieldPath.documentId(), "==", filters.uid)
        .where(
          firebase.firestore.FieldPath.documentId(),
          "==",
          getSearchEndValue(filters.uid)
        )
    : query;
  const prequery0 = filters?.name
    ? prequery
        .where("displayName", "==", filters.name)
        .where("displayName", "==", getSearchEndValue(filters.name))
    : prequery;
  const prequery1 = filters?.email
    ? prequery0
        .where("email", "==", filters.email)
        .where("email", "==", getSearchEndValue(filters.email))
    : prequery0;
  const newQuery =
    maybeUsersClient?.length ?? 0 > 0
      ? prequery1.where("client", "==", maybeUsersClient!)
      : prequery1;
  // return Object.keys(filters).length > 0 ? newQuery.orderBy('email') : newQuery.orderBy('email');
  return prequery1;
}
function getUserSearchParams() {
  const sp = new URLSearchParams(window.location.search);
  console.log("getUserSearchParams", sp);
  return Array.from(sp.entries()).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    {}
  );
}

// is object
function isObject(obj: any) {
  return obj !== null && typeof obj === "object";
}

export default function Users() {
  const [globalState] = useGlobalState();
  const testquery = firebase.firestore().collection(`users`);
  const [myUsers, setMyUsers] = React.useState<IUser[]>([]);
  const [userFiltered, setUserFiltered] = React.useState<IUser[]>([]);
  const [showingUsers, setShowingUsers] = React.useState<IUser[]>([]);
  const [usersPerPage] = React.useState(9);
  const [offset, setOffset] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(0);
  const [getData, setGetData] = React.useState(false);
  const [initPage, setInitPage] = React.useState(0);
  const [search, setSearch] = React.useState<string>("");
  const [endPage, setEndPage] = React.useState(5);
  const saveSuccess = React.useRef("init");

  const location = useLocation();

  React.useEffect(() => {
    // console.log('location in useeffect', location);
    if (!!location?.state) {
      setGetData(!getData);
      // console.log('setGetData in useeffect called');
    }
  }, [location]);

  const [downloadSearch, setDownloadSearch] = React.useState(false);

  const downloadMe = React.useRef<IUser[]>([]);

  const reloadAll = () => {
    setGetData(!getData);
  };

  const currentUserAdminUsersOf = globalState?.access?.createUsers
    ? globalState.client
    : undefined;

  const [filters, setFilters] =
    React.useState<Record<string, any>>(getUserSearchParams);
  const [filters2, setFilters2] =
    React.useState<Record<string, any>>(getUserSearchParams);
  console.log("filters", filters);

  // const query = React.useMemo(() => buildUsersQuery(filters, currentUserAdminUsersOf), [filters, currentUserAdminUsersOf]);
  const query = React.useMemo(
    () => buildUsersQuery(filters2, currentUserAdminUsersOf),
    [filters2, currentUserAdminUsersOf]
  );

  const {
    page,
    onBackClick,
    onNextClick,
    items: users,
    loading,
    error,
  } = usePaginatedCollection<IUser>(query, pageSize);

  const isNextAvailable = users.length >= pageSize;
  const isBackAvailable = page > 0;
  // take and object and an array of keys and return an object with only the keys
  // const pick = (obj: IUser, keys: string[]) => {
  //   return keys.reduce((acc, key) => {
  //     if (obj[key]) {
  //       //@ts-ignore
  //       acc[key] = obj[key];
  //     }
  //     return acc;
  //   }, {});
  // };

  const downloadxls = () => {
    // const data = downloadMe.current.map(user => pick(user, ['uid', 'displayName', 'disabled','email']));
    if (downloadMe.current.length > 0) {
      const data = downloadMe.current.map((user) => ({
        uid: user.uid,
        displayName: user.displayName,
        disabled: user.disabled,
        email: user.email,
      }));
      // console.log('downloadxls', data)
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Search");
      /* generate XLSX file and send to client */
      XLSX.writeFile(wb, "search_.xlsx");
    }
  };

  // fetch all users
  React.useEffect(() => {
    const juan = "juan@codika.net";
    const gabriel = "analista.procesos6@unlimitedvc.com";
    query.get().then((coll) => {
      const allDocs = coll.docs.map(
        (d) => ({ uid: d.id, ...d.data() } as IUser)
      );
      const cleanDocs = allDocs.filter((u) => !isObject(u.displayName));
      console.log("cleanDocs", cleanDocs);

      // if(globalState?.email === gabriel) {
      //   const filteredUsers = cleanDocs.filter(u => u.email.includes('unlimitedvc'))
      //   setMyUsers(filteredUsers)
      // } else {
      //   setMyUsers(cleanDocs)
      // }

      if (globalState?.access?.superAdmin) {
        setMyUsers(cleanDocs);
      } else if (globalState?.access?.createClients) {
        if (
          globalState?.specificClient !== undefined &&
          globalState?.specificClient?.length > 0
        ) {
          const filteredUsers = cleanDocs.filter((u) => {
            if (u.client !== undefined && u.client?.length > 0) {
              //@ts-ignore
              if (u.client[0] === globalState.specificClient[0]) {
                return u;
              }
            }
          });

          setMyUsers(filteredUsers);
        } else {
        }
      } else {
        const filteredUsers = cleanDocs.filter(
          (u) => u?.createdBy === globalState?.email
        );
        setMyUsers(filteredUsers);
      }
    });
  }, [getData]);

  const handlePageClick = (event: { selected: number }) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
  };

  const sliceMyUsers = () => {
    const slice = myUsers?.slice(offset - 1, offset - 1 + usersPerPage);
    setShowingUsers(slice);
    setPageCount(
      Math.ceil(!!myUsers ? myUsers.length / usersPerPage : usersPerPage)
    );
  };
  // handle paginations
  React.useEffect(() => {
    sliceMyUsers();
  }, [offset]);

  const uniqueArray = (arr: IUser[]): IUser[] => {
    return arr.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return (
        index ===
        arr.findIndex((obj) => {
          return JSON.stringify(obj) === _thing;
        })
      );
    });
  };
  function reorderStr(str: string) {
    return [...str.split("")].sort().join("");
  }
  // handle search by filters
  // React.useEffect(() => {
  //   const namesDict = (key: string) => {
  //     switch (key) {
  //       case 'name':
  //         return 'displayName'
  //       default:
  //         return key
  //     }
  //   }
  //   if(Object.keys(filters).length === 0){
  //     console.log('bringing all data')
  //       setGetData(!getData)
  //   } else {
  //     // handle pagination after finishing with cases
  //     let result:IUser[] = []
  //     for(const key of Object.keys(filters)){
  //      result = [...result, ...myUsers.filter(u => similarity(u[namesDict(key)] ,filters[key]  ) > 0.3 ) ]
  //     }
  //     console.log('result', result)
  //     setShowingUsers(uniqueArray(result))
  //   }

  // }, [filters])

  // const rows = React.useMemo(() => users.length > 0 ?
  //   users.map((it) => <ItemRow key={it.id} user={it} />) : null, [users]);

  const rows2 = React.useMemo(
    () =>
      showingUsers.length > 0
        ? showingUsers.map((it) => <ItemRow key={it.uid} user={it} />)
        : null,
    [showingUsers]
  );
  React.useEffect(() => {
    // if there are no results and display name 1st letter is lower case, dispatch another filter by 1st uppercase.
    if (
      !loading &&
      (users?.length || 0) === 0 &&
      filters.name &&
      filters.name[0] === filters.name[0].toLowerCase() // is 1st letter lowercase?
    ) {
      setFilters({
        ...filters,
        name: initCap(filters.name),
      });
    }
  }, [filters, users, loading]);

  React.useEffect(() => {
    if (search.length === 0) {
      setUserFiltered(myUsers.slice(initPage, endPage));
      downloadMe.current = myUsers;
    }
  }, [myUsers]);

  React.useEffect(() => {
    setUserFiltered(myUsers.slice(initPage, endPage));
  }, [initPage, endPage]);

  if (
    !(globalState?.access?.createUsers || globalState?.access?.superAdmin) &&
    globalState?.access?.createClients
  ) {
    return <Redirect to="/super-admin/mycompany" />;
  }

  if (!(globalState?.access.createUsers || globalState?.access.superAdmin)) {
    return <Redirect to="/" />;
  }

  const getUserFiltered = (search: string) => {
    let user: IUser[] = myUsers.filter(
      (user) =>
        user.email.includes(search) ||
        user.displayName
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        user.uid.includes(search)
    );
    setUserFiltered(user);

    if (search === "") {
      setUserFiltered(myUsers.slice(initPage, endPage));
    }
    downloadMe.current = myUsers;
  };

  let rows: JSX.Element[] = [];
  rows = userFiltered.map((user) => <RowInfo key={user.id} user={user} />);

  return (
    <div>
      <div className="clearfix" style={{ marginTop: "60px" }}>
        <div
          className="btn btn-outline-primary btn-sm float-right"
          onClick={downloadxls}
        >
          Export
        </div>
        <Link
          to={toAddForm}
          className="btn btn-outline-primary btn-sm float-right"
          style={{ marginRight: "10px" }}
        >
          + New user
        </Link>
        <input
          name="search"
          className="form-control float-right"
          placeholder="Search by UID, display name or email"
          style={{ width: "70%", marginRight: "30px" }}
          onChange={(e) => {
            setSearch(e.target.value);
            getUserFiltered(e.target.value);
          }}
          value={search}
        />
      </div>
      <table style={{ width: "100%" }}>
        <thead style={{ visibility: "hidden" }}>
          <tr>
            <th>UID</th>
            <th>Display Name</th>
            <th>Email</th>
            <th>Enabled?</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          <td colSpan={5}>
            <table className="table">
              <thead>
                <tr>
                  <th>UID</th>
                  <th>Display Name</th>
                  <th>Email</th>
                  <th>Enabled?</th>
                  <th>Company</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>

            {/* <FuzzySearch
        list={myUsers}
        keys={["displayName", "email", "uid", "tag"]}
        // keys={["displayName"]}
        listWrapperStyle={{ overflowY:'scroll'}}
        width={'100%'}
        shouldShowDropdownAtStart={true}
        distance={100}
        threshold={0.3}
        // autoFocus={true}
        tokenize={true}
        //@ts-ignore
        // inputProps={}
        // @ts-ignore
        // onSelect={(item) => setRowData([...rowData, item])}
        placeholder="Search by UID, display name or email"
          //@ts-ignore
          resultsTemplate={(props, state, styles, clickHandler) => {
            const juan = 'codika'
            const unlimitedvc = 'unlimitedvc'
            const questrovc = 'questrovc'
            console.log('props', state)
            console.log('gobalState', globalState.email)
            console.log('filtered props', state.results.filter((item: IUser) => item.email.toLowerCase().includes(questrovc) || item.email.toLowerCase().includes(unlimitedvc) ))
            let found = state.results.filter((item: IUser) => item.displayName.toLowerCase().includes(state.value.toLowerCase()) || item.email.toLowerCase().includes(state.value.toLowerCase()) || item.uid.toLowerCase().includes(state.value.toLowerCase()))
            console.log('found', found)
            downloadMe.current = found
            // if(globalState.email.toLowerCase().includes(unlimitedvc)){
            //   const temp = found.filter((item: IUser) => item.email.toLowerCase().includes(unlimitedvc) || item.email.toLowerCase().includes(questrovc))
            //   found = temp
            //   downloadMe.current = temp

            // } else {
            //   downloadMe.current = found
            // }
           
            
             //@ts-ignore
            return state.results.map((val, index) => {
              // return <div>{val.displayName}</div>
              //@ts-ignore
              return( 
                <table key={val.uid} style={{width: '100%', display: `${!found.some( (it: IUser) => it.displayName === val.displayName) ? 'none' : ''}`}}  className="row-on-hover">
                   <thead style={{visibility:'hidden'}}>
                          <tr>
                            <th>UID</th>
                            <th>Display Name</th>
                            <th>Email</th>
                            <th>Enabled?</th>
                            <th>Actions</th>
                          </tr>

                        </thead>
                <tbody className="row-on-hover">
                  <ItemRow key={val.uid} user={val} /> 
                </tbody>
                </table>
                )
            })
 
          }}

      /> */}
          </td>
        </tbody>
      </table>
      {/*       
      <Maybe
        component="div"
        visible={!!error}
        className="alert alert-danger"
        role="alert"
      >
        {error?.message}
      </Maybe>
      <UsersFiltersForm filters={filters} onSubmit={setFilters} />
      <hr />
      <div className="clearfix">
        <Link to={toAddForm} className="btn btn-primary btn-sm float-right">+ New</Link>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>UID</th>
            <th>Display Name</th>
            <th>Email</th>
            <th>Enabled?</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows2}
        </tbody>
      </table> */}

      {/* <Pagination
        page={page}
        pageSize={pageSize}
        onNextClick={onNextClick}
        onBackClick={onBackClick}
        itemsLength={users.length}
      /> */}
      {/* <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            // breakClassName={"paginate"}
            pageLinkClassName="page-link"
            breakLinkClassName="page-link"
            breakClassName={'break-me'}
            nextLinkClassName="page-link"
            previousLinkClassName="page-link"
            pageClassName="page-item"
            // breakClassName="page-item"
            nextClassName="page-item"
            previousClassName="page-item"
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageRangeDisplayed={0}
            marginPagesDisplayed={1}
            activeClassName={"active"} 
        
            /> */}

      <div>
        <ul className="pagination">
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => {
                setInitPage(initPage - 5);
                setEndPage(endPage - 5);
              }}
              disabled={initPage <= 0}
            >
              Previous
            </button>
          </li>
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => {
                setInitPage(initPage + 5);
                setEndPage(endPage + 5);
              }}
              disabled={userFiltered.length === 0}
            >
              Next
            </button>
          </li>
        </ul>
      </div>
      <Switch>
        <Route path="/super-admin/users/:id" component={UserFormModal} />
      </Switch>
    </div>
  );
}

interface IUserClientSelectProps {
  name: string;
  title: string;
  defaultValue?: string[] | undefined;
  disabled?: boolean;
}

function UserClientSelect(props: IUserClientSelectProps) {
  const { input } = useField<string[]>(props.name, {
    defaultValue: props.defaultValue,
  });
  console.log("UserClientSelect input.value", input.value);
  const client0 = input.value?.[0];
  const client1 = input.value?.[1];
  const onClient0Change = React.useCallback(
    (client: string) => input.onChange([client]),
    [input.onChange]
  );
  const onClient1Change = React.useCallback(
    (client: string) => input.onChange(client ? [client0, client] : [client0]),
    [input.onChange]
  );
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>{props.title}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <ClientSelect
              disabled={props.disabled}
              client={client0}
              onChange={onClient0Change}
            />
          </td>
          <td>
            <Maybe
              component={ClientSelect}
              disabled={props.disabled}
              visible={!!client0}
              parent={client0}
              client={client1}
              onChange={onClient1Change}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

interface IUserMerchantItemProps {
  item: string;
  defaultClient?: string[] | undefined;
  disabledClient?: boolean;
  onChange(it: string): void;
  onDelete(): void;
}
const UserMerchantItem = React.memo((props: IUserMerchantItemProps) => {
  const { item, onChange, defaultClient, disabledClient } = props;
  const [client0, client1, _, processor] = React.useMemo(
    () =>
      !item && (defaultClient?.length ?? 0) > 0
        ? defaultClient!
        : item.split("/"),
    [item, defaultClient]
  );
  const onClient0Change = React.useCallback(
    (client: string) => onChange(client),
    [onChange]
  );
  const onClient1Change = React.useCallback(
    (client: string) =>
      client ? onChange(`${client0}/${client}`) : onChange(client),
    [client0]
  );
  const onProcessorChange = React.useCallback(
    (processor: string) =>
      processor
        ? onChange(`${client0}/${client1}/p/${processor}`)
        : onChange(`${client0}/${client1}`),
    [client0, client1]
  );
  return (
    <tr>
      <td>
        <ClientSelect
          disabled={disabledClient}
          client={client0}
          onChange={onClient0Change}
        />
      </td>
      <td>
        <Maybe
          component={ClientSelect}
          visible={!!client0}
          disabled={disabledClient && defaultClient?.length === 2}
          parent={client0}
          client={client1}
          onChange={onClient1Change}
        />
      </td>
      <td>
        <Maybe
          component={ProcessorSelect}
          visible={!!client1}
          client0={client0}
          client1={client1}
          merchantID={processor}
          onChange={onProcessorChange}
          placeholder="-"
        />
      </td>
      <td>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={props.onDelete}
        >
          <img alt="Delete" src="/assets/images/icons/trash-alt-solid.svg" />
        </button>
      </td>
    </tr>
  );
});

const deleteListItem = (key: string, list: string[] | undefined) =>
  list?.filter((v) => v !== key);
const updateListItem = (
  key: string,
  list: string[] | undefined,
  newValue: string
) => list?.map((v) => (v === key ? newValue : v));

interface IUserMerchantsSelectsProps {
  name: string;
  disabledClient?: boolean;
  defaultClient?: string[];
}
function UserMerchantsSelects(props: IUserMerchantsSelectsProps) {
  const { input } = useField<string[] | undefined>(props.name);
  // console.log('UserMerchantsSelects disable', props.defaultClient)

  const rows = React.useMemo(
    () =>
      input?.value?.map((v, i) => (
        <UserMerchantItem
          key={v + "-" + i}
          item={v}
          disabledClient={props.disabledClient}
          // disabledClient={true}
          defaultClient={props.defaultClient}
          onChange={(value) =>
            input.onChange(updateListItem(v, input.value, value))
          }
          onDelete={() => input.onChange(deleteListItem(v, input.value))}
        />
      )),
    [input.value, props.disabledClient, props.defaultClient]
  );
  const addNew = React.useCallback(() => {
    console.log("addNew", input.value);
    input.onChange(Array.prototype.concat(emptyString, input.value));
  }, [input.value]);
  return (
    <table className="table">
      <thead>
        <tr>
          <th colSpan={2}>Merchants</th>
          <th colSpan={2} className="clearfix">
            <button
              className="btn btn-primary btn-sm float-right"
              type="button"
              onClick={addNew}
            >
              + New
            </button>
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}

function UserForm() {
  const [globalState] = useGlobalState();
  const isCurrentUserAdminUsersOf = !!globalState?.access?.createUsers;
  const currentUserPermissionList = globalState?.access;
  const isCurrentUserSuperAdmin = !!globalState?.access?.superAdmin;

  console.log("UserForm globalState", globalState?.email);
  console.log("UserForm isCurrentUserSuperAdmin", isCurrentUserSuperAdmin);
  console.log("UserForm isCurrentUserAdminUsersOf", isCurrentUserAdminUsersOf);

  const [maybeUID] = usePaymentId(undefined, true);
  const { state } = useLocation<IUser>();

  const isNew = !!!state.uid;
  console.log("uid userform", state.uid);
  const [loading, setLoading] = React.useState(false);
  const { addError, addSuccess } = useMessages();
  const history = useHistory();
  const onSubmit = React.useCallback(
    (values: IUser) => {
      setLoading(true);
      const validValues = {
        ...values,
        adminOf: values.adminOf?.filter(Boolean),
        disabled: !!values.disabled,
        createdBy: globalState?.email,
      };
      // console.log('onSubmit validValues', validValues)

      function onSuccess(data: any) {
        console.log("upsertUser success", data);
        addSuccess(
          isNew ? "User created successfully!" : `User updated successfully!`
        );
        //   setTimeout(function(){
        //     history.push('/super-admin');
        //  }, 2000);
      }
      const location = {
        pathname: "/super-admin",
        state: { userAdded: true },
      };
      const upsertUser = firebase
        .functions()
        .httpsCallable(isNew ? "createUser" : "updateUser");
      function handleSendResetPassword(data: any) {
        return isNew
          ? firebase.auth().sendPasswordResetEmail(values.email)
          : Promise.resolve(data);
      }
      upsertUser(validValues)
        .then(handleSendResetPassword)
        .then(onSuccess, (err) => addError(err.message))
        .then(() => {
          setTimeout(() => {
            history.push(location);
          }, 1500);
          setLoading(false);
        });
    },
    [isNew, addError, addSuccess, history]
  );

  return (
    <Form
      initialValues={state}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit}>
          {/* {console.log('values', JSON.stringify(values?.access))} */}
          <fieldset disabled={loading}>
            <div className="text-align-right">
              <button type="submit" className="btn btn-primary">
                {loading ? "Saving..." : "Save"}
              </button>
            </div>

            <div className="form-group">
              <label htmlFor="uid">UID</label>
              <Field
                component="input"
                className="form-control"
                id="uid"
                name="uid"
                defaultValue={maybeUID}
                disabled={!isNew}
              />
            </div>

            <div className="form-group">
              <label htmlFor="displayName">Display Name</label>
              <Field
                component="input"
                className="form-control"
                id="displayName"
                name="displayName"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">E-Mail</label>
              <Field
                component="input"
                className="form-control"
                id="email"
                name="email"
                type="email"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field
                component="input"
                className="form-control"
                id="password"
                name="password"
                type="password"
                required={isNew}
                autocomplete="new-password"
              />
            </div>

            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <div className="form-group">
                      <Field
                        component="input"
                        id="mustResetPassword"
                        name="mustResetPassword"
                        type="checkbox"
                      />
                      <label htmlFor="mustResetPassword">
                        &nbsp;Must Reset Password
                      </label>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <Field
                        component="input"
                        id="disabled"
                        name="disabled"
                        type="checkbox"
                      />
                      <label htmlFor="disabled">&nbsp;Disabled</label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table">
              <thead>
                <tr>
                  <th colSpan={6}>Access</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.list
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.list"
                        name="access.list"
                        type="checkbox"
                      />
                      {/* <Field name="access.list" type="checkbox">
                        {props => {
                          console.log( props.input.name, props.input.value)
                          
                         return  <Toggle
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    onChange={props.input.onChange}
                                    input={props.input}
                                    />
                                    
                        }}

                      </Field> */}
                      {/* <Field 
                        name="access.list" 
                        id="access.list"  
                        type="checkbox"
                        component={Toggle}
                      /> */}

                      <label
                        htmlFor="access.list"
                        title="User can read transactions"
                        className="fivePxML"
                      >
                        Payment History
                      </label>
                    </div>
                  </td>

                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.sale
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.sale"
                        name="access.sale"
                        type="checkbox"
                      />
                      {/* <Field name="access.sale"  type="checkbox">
                        {props => {
                           console.log( props.input.name, props.input.value)
                  
                         return  <Toggle 
                                    input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    onChange={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      {/* <Field 
                        name="access.sale" 
                        id="access.sale"  
                        type="checkbox"
                        component={Toggle}
                      /> */}
                      <label htmlFor="access.sale" title="User can do sales">
                        Sale
                      </label>
                    </div>
                  </td>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.refund
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.refund"
                        name="access.refund"
                        type="checkbox"
                      />
                      {/* <Field name="access.refund">
                        {props => {
                          console.log( props.input.name, props.input)
                         return  <Toggle 
                         input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      <label
                        htmlFor="access.refund"
                        title="User can do refunds"
                        className="fivePxML"
                      >
                        Refund
                      </label>
                    </div>
                  </td>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.void
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.void"
                        name="access.void"
                        type="checkbox"
                      />
                      {/* <Field name="access.void">
                        {props => {
                         return  <Toggle  input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      <label
                        htmlFor="access.void"
                        title="User can do void"
                        className="fivePxML"
                      >
                        Void
                      </label>
                    </div>
                  </td>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.recharge
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.recharge"
                        name="access.recharge"
                        type="checkbox"
                      />
                      {/* <Field name="access.recharge">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      <label
                        htmlFor="access.recharge"
                        title="User can recharge"
                        className="fivePxML"
                      >
                        Recharge
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.batch
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.batch"
                        name="access.batch"
                        type="checkbox"
                      />
                      {/* <Field name="access.batch">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      <label
                        htmlFor="access.batch"
                        title="User can do sales in batch files"
                        className="fivePxML"
                      >
                        Batch
                      </label>
                    </div>
                  </td>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.view
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.view"
                        name="access.view"
                        type="checkbox"
                      />
                      {/* <Field name="access.view">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      <label
                        htmlFor="access.view"
                        title="User can view the transaction detail"
                        className="fivePxML"
                      >
                        View
                      </label>
                    </div>
                  </td>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.print
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.print"
                        name="access.print"
                        type="checkbox"
                      />
                      {/* <Field name="access.print">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      <label
                        htmlFor="access.print"
                        title="User can print"
                        className="fivePxML"
                      >
                        Print
                      </label>
                    </div>
                  </td>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.dashboard
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.dashboard"
                        name="access.dashboard"
                        type="checkbox"
                      />
                      {/* <Field name="access.dashboard">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      <label
                        htmlFor="access.dashboard"
                        title="User can read the dashboard"
                        className="fivePxML"
                      >
                        Dashboard
                      </label>
                    </div>
                  </td>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.dispute
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.dispute"
                        name="access.dispute"
                        type="checkbox"
                      />
                      {/* <Field name="access.dispute">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      <label
                        htmlFor="access.dispute"
                        title="User can make disputes"
                        className="fivePxML"
                      >
                        Dispute view & edit
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.disputeView
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.disputeView"
                        name="access.disputeView"
                        type="checkbox"
                      />
                      {/* <Field name="access.disputeView">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      <label
                        htmlFor="access.disputeView"
                        title="User can only review disputes"
                        className="fivePxML"
                      >
                        Dispute view only
                      </label>
                    </div>
                  </td>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.doRecurrents
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.doRecurrents"
                        name="access.doRecurrents"
                        type="checkbox"
                      />
                      {/* <Field name="access.doRecurrents">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      <label
                        htmlFor="access.doRecurrents"
                        title="User can make disputes"
                        className="fivePxML"
                      >
                        Recurrent payments{" "}
                      </label>
                    </div>
                  </td>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.recurrentView
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.recurrentView"
                        name="access.recurrentView"
                        type="checkbox"
                      />
                      {/* <Field name="access.recurrentView">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      <label
                        htmlFor="access.recurrentView"
                        title="User can make disputes"
                        className="fivePxML"
                      >
                        Recurrent history{" "}
                      </label>
                    </div>
                  </td>
                  <td
                    className={
                      isCurrentUserSuperAdmin ||
                      !!currentUserPermissionList?.scheduledPay
                        ? ""
                        : "displayNone"
                    }
                  >
                    <div className="form-group">
                      <Field
                        component="input"
                        id="access.scheduledPay"
                        name="access.scheduledPay"
                        type="checkbox"
                      />
                      {/* <Field name="access.scheduledPay">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                      <label
                        htmlFor="access.scheduledPay"
                        title="User can schedule future and frequent payments"
                        className="fivePxML"
                      >
                        Scheduled payments{" "}
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <Maybe
                    visible={isCurrentUserSuperAdmin}
                    component={React.Fragment}
                  >
                    <td>
                      <div className="form-group">
                        <Field
                          component="input"
                          id="access.superAdmin"
                          name="access.superAdmin"
                          type="checkbox"
                        />
                        {/* <Field name="access.superAdmin">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                        <label
                          htmlFor="access.superAdmin"
                          title="User can do Super Admin Operations"
                          className="fivePxML"
                        >
                          Super Admin
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <Field
                          component="input"
                          id="admin"
                          name="admin"
                          type="checkbox"
                        />
                        {/* <Field name="admin">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                        <label
                          htmlFor="admin"
                          title="user can read all transactions"
                          className="fivePxML"
                        >
                          Read all trxs.
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <Field
                          component="input"
                          id="access.createClients"
                          name="access.createClients"
                          type="checkbox"
                        />
                        {/* <Field name="access.createClients">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                        <label
                          htmlFor="access.createClients"
                          title="user can creates companies"
                          className="fivePxML"
                        >
                          Create Companies
                        </label>
                      </div>
                    </td>

                    <td>
                      <div className="form-group">
                        <Field
                          component="input"
                          id="access.manualPayments"
                          name="access.manualPayments"
                          type="checkbox"
                        />

                        <label
                          htmlFor="access.createClients"
                          title="user can creates manual payments"
                          className="fivePxML"
                        >
                          Manual Payments
                        </label>
                      </div>
                    </td>

                    <td>
                      <div className="form-group">
                        <Field
                          component="input"
                          id="access.accountsPayable"
                          name="access.accountsPayable"
                          type="checkbox"
                        />

                        <label
                          htmlFor="access.accountsPayable"
                          title="user can creates accounts payable"
                          className="fivePxML"
                        >
                          Accounts Payable
                        </label>
                      </div>
                    </td>

                    <td>
                      <div className="form-group">
                        <Field
                          component="input"
                          id="access.createUsers"
                          name="access.createUsers"
                          type="checkbox"
                        />
                        {/* <Field name="access.createUsers">
                        {props => {
                         return  <Toggle input={props.input}
                                    name={props.input.name} 
                                    isOn={props.input.value}
                                    changeVal={props.input.onChange}/>
                                    
                        }}
                      </Field> */}
                        <label
                          htmlFor="access.createUsers"
                          title="user can creates users"
                          className="fivePxML"
                        >
                          Create Users
                        </label>
                      </div>
                    </td>
                  </Maybe>
                </tr>
              </tbody>
            </table>

            <UserClientSelect
              defaultValue={globalState?.client}
              disabled={!isCurrentUserSuperAdmin}
              // disabled={true}
              name="client"
              title="Default Client"
            />

            <UserMerchantsSelects
              defaultClient={globalState?.client}
              disabledClient={!isCurrentUserSuperAdmin}
              name="adminOf"
            />

            <div className="text-align-right">
              <button type="submit" className="btn btn-primary">
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </fieldset>
        </form>
      )}
    />
  );
}

export function UserFormModal() {
  const history = useHistory();
  const goBack = history.goBack;
  return (
    <Modal
      title="User Form"
      name="user"
      onClose={() => history.push("/super-admin")}
    >
      <UserForm />
    </Modal>
  );
}
