import * as React from 'react';
import { Form, Field, FormRenderProps } from 'react-final-form';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { useHistory, Link } from 'react-router-dom';
import { FormApi } from 'final-form';
import ErrorMessage from 'common/components/ErrorMessage';
import { compose, getMessage, lazyApply } from 'common/utils/fns';
import { useMessages } from 'config/messagesStateContext';

type IValues = {
  password: string,
  confirmPassword: string,
}

function ChangePassword() {
  const [error, setError] = React.useState<undefined|string>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { addSuccess } = useMessages();
  const history = useHistory();
  function onSubmit(values: IValues, formApi: FormApi<IValues>) {
    if (values.confirmPassword !== values.password) {
      return setError("The passwords must match");
    }
    setError(undefined);
    setIsLoading(true);

    function onSuccess(result: firebase.functions.HttpsCallableResult) {
      console.log('onSuccess', result.data.message);
      addSuccess(result.data.message);
      formApi.reset();
      history.replace('/home');
    }
    const updatePassword = firebase.functions().httpsCallable('updatePassword');
    updatePassword({ password: values.password })
      .then(onSuccess)
      .catch(compose(setError, getMessage))
      .then(lazyApply(setIsLoading, false));
  }
  return (
    <div className="login-wrapper">
      <img src="/assets/images/logos/netpay.svg" alt="Isotipo de Caribbean Marketing Services" />
      <div className="card">
        <p>Please enter your new password</p>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }: FormRenderProps) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="password">New password</label>
                <Field
                  component="input"
                  type="password"
                  name="password"
                  className="form-control"
                  id="password"
                  autoComplete="new-password"
                  required
                  minLength={8}
                  disabled={isLoading}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm password</label>
                <Field
                  component="input"
                  type="password"
                  name="confirmPassword"
                  className="form-control"
                  id="confirmPassword"
                  autoComplete="new-password"
                  required
                  minLength={8}
                  disabled={isLoading}
                />
              </div>
              <ErrorMessage text={error} />
              <button type="submit" disabled={isLoading} className="btn btn-primary">
                {isLoading ? '...' : 'Change password'}
              </button>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
}

export default ChangePassword;
