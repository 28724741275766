import { emptyString, currencyFormatConfig } from "common/utils/constants";
import { initCap, getStatusTextClass, getCCName } from "common/utils/fns";
import { ITranx, IKeysOfStr, IDisputeData } from "types";
import {
  getCompanyClientName,
  fetchAgentName,
  fetchSubMerchantName,
} from "common/utils/firebase";
// @ts-ignore
import payment from "payment";
import firebase from "firebase";
import { useState } from "react";
import moment from "moment";

export const getProcessorTitle = (item: ITranx): string =>
  item?.processorType === "azul"
    ? "P1"
    : // @ts-ignore
    item?.processorType === "resortcom" || item?.processorType === "restcom"
    ? "P2"
    : item?.processorType === "trinity"
    ? "P3"
    : item?.processorType;

const getCompany = (company: string, client: string) => {
  return new Promise((resolve: any, reject: any) => {
    firebase
      .firestore()
      .collection("clients")
      .doc(`${company}/clients/${client}`)
      .collection("rates-percentage")
      .onSnapshot((snapshot) => {
        const ratesPercentage = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        if (ratesPercentage.length > 0) {
          //@ts-ignore
          return resolve(ratesPercentage[0]);
        }
        return resolve(null);
      });
  });
};

const getCompanyAmount = (company: string, client: string) => {
  return new Promise((resolve: any, reject: any) => {
    firebase
      .firestore()
      .collection("clients")
      .doc(`${company}/clients/${client}`)
      .collection("rates-amount")
      .onSnapshot((snapshot) => {
        const ratesAmount = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        if (ratesAmount.length > 0) {
          //@ts-ignore
          return resolve(ratesAmount[0]);
        }
        return resolve(null);
      });
  });
};

const getCreditCompany = (company: string, client: string) => {
  return new Promise((resolve: any, reject: any) => {
    firebase
      .firestore()
      .collection("clients")
      .doc(`${company}/clients/${client}`)
      .collection("credit-rates-percentage")
      .onSnapshot((snapshot) => {
        const ratesPercentage = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        if (ratesPercentage.length > 0) {
          //@ts-ignore
          return resolve(ratesPercentage[0]);
        }
        return resolve(null);
      });
  });
};

const getCreditCompanyAmount = (company: string, client: string) => {
  return new Promise((resolve: any, reject: any) => {
    firebase
      .firestore()
      .collection("clients")
      .doc(`${company}/clients/${client}`)
      .collection("credit-rates-amount")
      .onSnapshot((snapshot) => {
        const ratesAmount = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        if (ratesAmount.length > 0) {
          //@ts-ignore
          return resolve(ratesAmount[0]);
        }
        return resolve(null);
      });
  });
};

export async function getPlainRowToAccountPayable(
  item: ITranx,
  processors: IKeysOfStr
): Promise<string[]> {
  const amount = (
    item.type === "refund" ? -1 * (item?.refundAmount ?? 0) : item.paymentAmount
  )?.toLocaleString(undefined, currencyFormatConfig);

  const amountNoFormatted =
    item.type === "refund"
      ? -1 * (item?.refundAmount ?? 0)
      : item.paymentAmount;

  let calculateNet = 0;
  let percentage = "";
  let rateAmount = "";

  let rateByPercentage = null;
  let ratesByAmount = null;

  item.client =
    item.client && item.client.length > 0 ? item.client : ["cms", "CnIlMgIC"];
  const company = item.client[0];
  if (company) {
    const proccesotType = item.processorType;
    let cardType = payment.fns.cardType(item.cardNumber)
      ? payment.fns.cardType(item.cardNumber)
      : "visa";
    if (cardType === "mastercard") {
      cardType = "mc";
    }

    if (cardType === "dinersclub") {
      cardType = "diners";
    }

    rateByPercentage =
      item.type === "refund"
        ? await getCreditCompany(company, item.client[1])
        : await getCompany(company, item.client[1]);

    if (rateByPercentage) {
      //@ts-ignore
      percentage = proccesotType
        ? rateByPercentage[proccesotType]
        : rateByPercentage["azul"];
      percentage = percentage ? percentage[cardType] : "0";
      if (percentage !== "" && percentage !== "0") {
        calculateNet = Number(amountNoFormatted) * (Number(percentage) / 100);
      }
    }

    if (percentage === "0" || percentage === "") {
      ratesByAmount =
        item.type === "refund"
          ? await getCreditCompanyAmount(company, item.client[1])
          : await getCompanyAmount(company, item.client[1]);

      if (ratesByAmount) {
        //@ts-ignore
        rateAmount = proccesotType
          ? ratesByAmount[proccesotType]
          : ratesByAmount["azul"];
        rateAmount = rateAmount ? rateAmount[cardType] : "0";
        calculateNet = Number(rateAmount);
        console.log(rateAmount);
      }
    }
  }
  const clientName = await getCompanyClientName(item.client);

  const processor = item.altProcessor ?? item.processor;
  const subMerchantName = await fetchSubMerchantName(
    item.client,
    item.processor
  );
  const [statusText, _statusClass, trnsType] = getStatusTextClass(
    item.type,
    item.status
  );
  const agentName = await fetchAgentName(item.createdBy);
  const cardInfo = `${item.cardNumber}******${item.ccLast4} ${
    item.expDate
  } ${getCCName(item.cardNumber)}`;

  return [
    item.name,
    processor,
    clientName,
    subMerchantName,
    item.invoiceNumber,
    item.createdAt?.toDate?.().toLocaleDateString(),
    item.cardholderName,
    cardInfo,
    amount,
    statusText,
    trnsType,
    item.createdAt?.toDate?.().toLocaleDateString(),
    "$0.00",
    initCap(item.type),
    item.descr,
    item.orderNumber ?? emptyString,
    item.authCode,
    agentName ?? item.createdBy,
    getProcessorTitle(item),
    percentage === "0" ? "0%" : percentage ? percentage + "%" : "",
    calculateNet.toString() === "NaN" ? "" : calculateNet.toString(),
    calculateNet.toString() === "NaN"
      ? amountNoFormatted.toString()
      : (Number(amountNoFormatted) - calculateNet).toString(),
    item?.resp !== undefined && item?.resp !== null
      ? item?.resp?.ResponseMessage + " " + item?.resp?.ErrorDescription
      : "",
  ];
}
export async function getPlainRow(
  item: ITranx,
  processors: IKeysOfStr
): Promise<string[]> {
  const clientName = await getCompanyClientName(item.client);

  const processor = item.altProcessor ?? item.processor;
  const subMerchantName = await fetchSubMerchantName(
    item.client,
    item.processor
  );
  const [statusText, _statusClass, trnsType] = getStatusTextClass(
    item.type,
    item.status
  );
  const agentName = await fetchAgentName(item.createdBy);
  const cardInfo = `${item.cardNumber}******${item.ccLast4} ${
    item.expDate
  } ${getCCName(item.cardNumber)}`;
  const amount = (
    item.type === "refund" ? -1 * (item?.refundAmount ?? 0) : item.paymentAmount
  )?.toLocaleString(undefined, currencyFormatConfig);
 
  const proccesor = getProcessorTitle(item);
  var errorMessage = ""

  switch (proccesor) {
    case "P1":
      //Azul
      if(item?.resp){
        errorMessage = (item?.resp?.ResponseMessage ?? "") + " " + (item?.resp?.ErrorDescription ?? "")
      }
      break;
      case "P2":
      //Resort
      if(item?.resp){
        const r = item?.resp as any;
        errorMessage = (r?.CardAuthorizationDetailStatus ? r?.CardAuthorizationDetailStatus :"")
      }
      break;
      case "P3":
      //Triniy
      if(item?.resp){
        const t = item?.resp as any;
        if(t?.Error){
          if(t?.Error?.messages){
            errorMessage = t?.Error?.messages[0]?.code + " " + t?.Error?.messages[0]?.description
          }
        }
      }
      break;
  
    default:
      break;
  }

  return [
    item.name,
    processor,
    clientName,
    subMerchantName,
    item.invoiceNumber,
    item.entryStatus === "U" || item.statusRefund === "U"
      ? moment(item.date).format("M/D/YYYY").toString()
      : item.createdAt?.toDate?.().toLocaleDateString(),
    item.cardholderName,
    cardInfo,
    amount,
    item.entryStatus === "U" || item.statusRefund === "U"
      ? "Upload"
      : statusText,
    trnsType,
    item.entryStatus === "U" || item.statusRefund === "U"
      ? moment(item.date).format("M/D/YYYY").toString()
      : item.createdAt?.toDate?.().toLocaleDateString(),
    "$0.00",
    initCap(item.type),
    item.descr,
    item.orderNumber ?? emptyString,
    item.authCode,
    agentName ?? item.createdBy,
    proccesor,
    errorMessage
    
  ];
}
const operationTypes = (letter: string) => {
  switch (letter) {
    case "C":
      return "Chargeback";
    case "I":
      return "Inquiry";
    case "PA":
      return "Pre-arbritation";
    case "ARBITRATION":
      return "Arbitration";
    case "RETRIEVAL":
      return "Retrieval";
    default:
      return letter.length > 1 ? letter : "not specified";
  }
};

const disputeTypes = (letter: string) => {
  switch (letter) {
    case "RE":
      return "reversal";
    case "DE":
      return "debit";
    case "CL":
      return "Claim";

    default:
      return letter.length > 1 ? letter : "not specified";
  }
};

export const capitalizeFirstLetter = (str: string | undefined) => {
  if (!!str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return "status not set";
  }
};

export async function getPlainRowForDisputes(
  item: IDisputeData,
  processors: IKeysOfStr
): Promise<string[]> {
  const clientName = await getCompanyClientName(item.itranx?.client);

  const processor = item.itranx?.altProcessor ?? item.itranx?.processor;
  const subMerchantName = await fetchSubMerchantName(
    item.itranx?.client,
    item.itranx?.processor
  );
  const [statusText, _statusClass, trnsType] = getStatusTextClass(
    item.itranx.type,
    item.itranx.status
  );
  const operation = operationTypes(item.type || "");
  const distype = capitalizeFirstLetter(disputeTypes(item.distype || ""));
  const capitalizedStatus = capitalizeFirstLetter(item.status || "");
  const neg = (n: number) => (n < 0 ? n : -1 * n);
  const agentName = await fetchAgentName(item.itranx.createdBy);
  const cardInfo = `${item.itranx.cardNumber}******${item.itranx.ccLast4} ${
    item.itranx.expDate
  } ${getCCName(item.itranx.cardNumber)}`;
  const amountTemp =
    item.itranx.type === "refund"
      ? -1 * (item.itranx?.refundAmount ?? 0)
      : item.itranx.paymentAmount; // ?.toLocaleString(undefined, currencyFormatConfig)
  const amount = (
    item.status.toLocaleLowerCase() !== "won" ? neg(amountTemp) : amountTemp
  )?.toLocaleString(undefined, currencyFormatConfig);
  let amountDispute = !!item.disputeAmount ? item.disputeAmount : "$0.00";
  if (amountDispute.includes("-")) {
    amountDispute = amountDispute.replace("-", "");
  }

  if (!amountDispute.includes("$")) {
    amountDispute = "$" + amountDispute;
  }

  if (!amountDispute.includes(".")) {
    amountDispute = amountDispute.trim() + ".00";
  }
  console.log("xxxxxxxxxxx", item?.resp);
  const createdAt = item.itranx.createdAt?.toDate?.();
  //old way of doing things
  // ------------------
  // let disputeData:string[] = []
  // if(!!item.itranx.disputeData){
  //   disputeData.push(
  //     getCCName(item.itranx.cardNumber)|| "",
  //     item?.dueDate || "",
  //     item?.reversalDate || "",
  //     item?.paymentDate || "",
  //     item?.caseNumber || "",
  //     item?.date || "",
  //     operation,
  //     distype,
  //     item?.dueDate || "",
  //     item?.reason || "",
  //     capitalizedStatus,
  //     item?.disputeBy || "",
  //   )
  // }

  // const originalTranx = [

  //   item.itranx.name ?? emptyString,
  //   processor,
  //   clientName,
  //   subMerchantName,
  //   item.invoiceNumber,
  //   item.itranx.createdAt?.toDate?.().toLocaleDateString(),
  //   item.itranx.cardholderName ?? emptyString,
  //   cardInfo,
  //   amount,
  //   statusText,
  //   trnsType,
  //   item.itranx.createdAt?.toDate?.().toLocaleDateString(),
  //   "$0.00",
  //   initCap(item.itranx.type),
  //   item.descr ?? emptyString,
  //   item.itranx.orderNumber ?? emptyString,
  //   item.itranx.authCode ?? emptyString,
  //   agentName ?? item.itranx.createdBy,
  //   getProcessorTitle(item.itranx),
  // ];

  const rowValues = [
    getCCName(item.itranx.cardNumber),
    item?.debitDate || emptyString,
    item?.reversalDate || emptyString,
    item?.paymentDate || emptyString,
    item?.caseNumber || emptyString,
    item?.date || emptyString,
    operation,
    distype,
    item?.dueDate || emptyString,
    item?.reason || emptyString,
    item?.comments || emptyString,
    item?.status || emptyString,
    item?.disputeBy || emptyString,
    item.itranx.name,
    processor,
    clientName,
    subMerchantName,
    item.invoiceNumber,
    createdAt?.toLocaleDateString(),
    item.itranx.cardholderName || emptyString,
    cardInfo,
    amountDispute,
    amount,
    // capitalizedStatus,
    statusText,
    trnsType,
    createdAt?.toLocaleDateString(),
    "$0.00",
    initCap(item.itranx.type),
    item.itranx.descr || emptyString,
    item.itranx.orderNumber || emptyString,
    item.itranx.authCode || emptyString,
    agentName,
    getProcessorTitle(item.itranx),
    item?.resp?.ResponseMessage !== undefined &&
    item?.resp?.ResponseMessage !== null
      ? item?.resp?.ResponseMessage + " " + item?.resp?.ErrorDescription
      : "",
  ];

  // return disputeData.concat(originalTranx)
  return rowValues;
}
