import * as React from 'react';
import { emptyArray, unit } from 'common/utils/constants';
import { IMessage } from 'types';

type IMessagesState = {
  messages: IMessage[], 
  addError: (text: string) => void,
  addSuccess: (text: string) => void,
  shift: () => void,
  clear: () => void,
};

const defaultState: IMessagesState = {
  messages: emptyArray, 
  addError(text: string) {},
  addSuccess(text: string) {},
  shift() {},
  clear() {},
};

// @ts-ignore
export const MessagesContext = React.createContext<IMessagesState>(defaultState);

interface IProps {
  children: React.ReactNode,
}

export function MessagesProvider(props: IProps): React.FC<React.ReactElement> {
  const [messages, setMessages] = React.useState<IMessage[]>(emptyArray);
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  function addError(text: string) {
    setMessages(messages.concat({ type: 'error', text }));
  }
  function addSuccess(text: string) {
    setMessages(messages.concat({ type: 'success', text }));
  }
  function shift() {
    // @ts-ignore
    clearTimeout(timeoutRef.current);
    setMessages(messages.slice(1));
  }
  function clear() {
    // @ts-ignore
    clearTimeout(timeoutRef.current);
    setMessages(emptyArray);
  }
  React.useEffect(() => {
    if (messages.length > 0) {
      timeoutRef.current = setTimeout(shift, 5000);
    }
  }, [messages]);
  const value = React.useMemo<IMessagesState>(
    () => ({
      messages,
      addError,
      addSuccess,
      shift,
      clear,
    }) as IMessagesState, [messages]);
  // @ts-ignore
  return <MessagesContext.Provider value={value} {...props} />;
}

export function useMessages(): IMessagesState {
  const context = React.useContext(MessagesContext)
  if (!context) {
    throw new Error(`useCount must be used within a CountProvider`)
  }
  // const [state, setState] = context;
  return context;
}


