// @ts-ignore
import moment from "moment";
import Payment from "payment";

function clearNumber(value: string = ""): string {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value: string): string {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(
  value: string,
  prevValue: string,
  allValues = {}
): string {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  // @ts-ignore
  if (allValues.number) {
    // @ts-ignore
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value: string): string {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatDate(value?: Date | string): string {
  //@ts-ignore
  if (value !== undefined && value !== null) {
    const date = moment(value).toDate();
    const day = date?.getDate();
    const month =
      date?.getMonth() < 9
        ? "0" + (date?.getMonth() + 1)
        : date?.getMonth() + 1;
    const year = date?.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return "";
}
