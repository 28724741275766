import React from "react";
import { useField } from "react-final-form";
// @ts-ignore
import payment from "payment";
import {
  valueSubsctiption,
  emptyString,
  activeStr,
} from "common/utils/constants";

function CardNumberFlags() {
  const { input } = useField("cardNumber", valueSubsctiption);
  // @ts-ignore
  const cardType = payment.fns.cardType(input.value);
  const visaActiveStr = cardType === "visa" ? activeStr : emptyString;
  const mcActiveStr = cardType === "mastercard" ? activeStr : emptyString;
  const aeActiveStr = cardType === "amex" ? activeStr : emptyString;
  const discoverActiveStr = cardType === "discover" ? activeStr : emptyString;
  const dcActiveStr = cardType === "dinersclub" ? activeStr : emptyString;
  const jcbActiveStr = cardType === "jcb" ? activeStr : emptyString;
  return (
    <div className="form-group form-icon credit-card-icon-wrapper">
      <div className={`credit-card-form-icon visa ${visaActiveStr}`}></div>
      <div className={`credit-card-form-icon mc ${mcActiveStr}`}></div>
      <div className={`credit-card-form-icon ae ${aeActiveStr}`}></div>
      <div
        className={`credit-card-form-icon discover ${discoverActiveStr}`}
      ></div>
      <div className={`credit-card-form-icon dc ${dcActiveStr}`}></div>
      <div className={`credit-card-form-icon jcb ${jcbActiveStr}`}></div>
    </div>
  );
}

export default React.memo(CardNumberFlags);
