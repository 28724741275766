import React, {useState, useEffect} from "react";
import * as Realm from "realm-web";

const Playground = () => {
  const [user, setUser] = useState<Realm.User | null>(null);

  const appId = 'application-0-oeksj'
  const app: Realm.App = new Realm.App({ id: appId });

  //@ts-ignore
  const mongodb = app.currentUser.mongoClient("mongodb-atlas")


    const read = async () => {
    const movies = await mongodb.db("sample_mflix").collection("movies").find({})
    console.log('read', movies);
  }


  const anonAuth = async () => {
    // Create an anonymous credential
    const credentials = Realm.Credentials.anonymous();
    try {
        // Authenticate the user
        const user: Realm.User = await app.logIn(credentials);
        // `App.currentUser` updates to match the logged in user
        // console.log(user.id === app.currentUser.id)
        // console.log('user', user)
        // return user
        setUser(user)
    } catch(err) {
        console.error("Failed to log in", err);
    }
  }

  // useEffect(() => {
  //   anonAuth()
  //   read()
  // }, [])

    return (
        <div
            style={{display:'flex',  alignItems:'center', justifyContent:'center', height: '100vh'}}
        >
            <Switch />
        </div>
    )

}

export default Playground

const Switch = () => {
    const [value, setValue] = React.useState(false);
    return (
      <>
        <input
 
          className="react-switch-checkbox"
          id={`react-switch-new`}
          type="checkbox"
          checked={value}
          onChange={() => setValue(!value)}
        />
        <label
          className="react-switch-label"
          htmlFor={`react-switch-new`}
          style={{ backgroundColor: value ? '#06D6A0' : '' }}
        >
          <span className={`react-switch-button`} />
        </label>
      </>
    );
  };
  
