import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { today } from 'common/utils/constants';


interface IProps {

}

function DateRangePickerInput(props: IProps) {
  const [currentDay, setCurrentDay] = React.useState<[Date,Date] | undefined>(undefined)
  const didMountRef = React.useRef(false);

  React.useEffect(() => {

      setCurrentDay([new Date(), new Date()])
    
  }, [])

  return (
    // <Field name="daterange">
    <Field name="daterange">
      {({ input }: FieldRenderProps<[Date, Date]>) => (
        <DateRangePicker
          value={input.value}
          onChange={input.onChange}
          className="form-control"
          maxDate={today}
        />
      )}
    </Field>
  );
}

export default DateRangePickerInput;
