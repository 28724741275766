import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { FormApi } from "final-form";
import {
  Form,
  Field,
  FormRenderProps,
  FieldRenderProps,
  FormSpy,
} from "react-final-form";
import { useHistory, Link, useLocation } from "react-router-dom";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "common/utils/card";
import {
  currencyFormatConfig,
  attachmentsFolderName,
  emptyString,
  submitingSubscription,
} from "common/utils/constants";
// @ts-ignore
import { useMessages } from "config/messagesStateContext";
import {
  getMessage,
  compose,
  log,
  lazyApply,
  buildFileName,
  getAttachmentFile,
} from "common/utils/fns";
import { usePaymentId } from "common/hooks/utils";
import { useProcessorsName, useUserPermissions } from "common/hooks/firestore";
import { showPrintVersion, uploadFile } from "common/utils/firebase";
import Maybe from "common/components/Maybe";
import { useGlobalState } from "config/globalStateContext";
import CountryPicker from "./components/CountryPicker";
import StatePicker from "./components/StatePicker";
import CardNumberFlags from "./components/CardNumberFlags";
import ProcessorSelectField from "./components/ProcessorSelectField";
import CVVField from "./components/CVVField";
import { ITranx } from "types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";

// import {useUserInfo} from '../../../common/hooks/utils';
import { useUserInfo } from "../../common/hooks/utils";

import { IFinalFilter } from "../Report/components/FilterForm";
import { RecurringPaymentServices } from "services/Recurring Payment/RecurringPaymentServices";
import { IServicesResponse } from "services/Recurring Payment/models/IServicesResponse";
import { IFrecuency } from "services/Recurring Payment/models/responses/IFrecuency";
import DatePicker from "react-multi-date-picker";
import { IScheduleRequest } from "services/Recurring Payment/models/IScheduleRequest";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { format } from "date-fns";

//import ScheduledPaymentTerminal from "./components/ScheduledPaymentFormForPaymentTerminal";
const recurringPaymentServices = new RecurringPaymentServices();
// const recurrentPaymentServices = new RecurringPaymentServices();
interface IPaymentValues {
  name: string;
  email: string;
  sendReceipt: boolean;
  invoiceNumber: string;
  orderNumber: string;
  paymentAmount: number | string;
  cardNumber: string;
  cardholderName: string;
  merchantInfo: string;
  expDate: string;
  cvv: number;
  paymentDescription: string;
  addressOne: string;
  addressTwo: string;
  state: string;
  countrySelect: string;
  zipCode: string;
  citySelect: string;
  recurringPayment: boolean;
  startDate?: Date | string;
  endDate?: Date | string;
  frecuency?: string;
  calenders?: string[] | null;
}

function useOnSubmitPayment() {
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSucess, setIsSuccess] = React.useState<boolean>(false);
  const { addSuccess, addError, clear } = useMessages();
  const [state] = useGlobalState();
  const [paymentId, resetToken] = usePaymentId();
  const keysOfProcessorNames = useProcessorsName();
  function onSubmit(values: IPaymentValues, form: FormApi) {
    const isValidExp = values.expDate.split("/");
    //@ts-ignore
    if (Number(isValidExp[0]) > 12 || Number(isValidExp[1]) < 0) {
      addError("Invalid exp");
      return;
    }
    // debugger
    const recurrinAmount = values.paymentAmount;
    let paymentAmount = values.paymentAmount;
    if (values.recurringPayment) {
      const todaySpan = Date.now();
      const today = new Date(todaySpan);
      const todayString = format(today, "dd-MM-yyyy");

      // debugger
      // if(todayString !== values.startDate!){
      //   paymentAmount = 1;
      // }
    }

    const customClient = state?.processors?.find(
      (it) => it.id === values.merchantInfo
    )?.client;
    const file = getAttachmentFile();
    values.cardNumber = values.cardNumber.replaceAll(" ", "");
    const tranx = {
      name: values.name,
      email: values.email,
      sendReceipt: !!values.sendReceipt,
      invoiceNumber: values.invoiceNumber,
      orderNumber: values.orderNumber ?? emptyString,
      client: customClient ?? state?.client,
      processor: values.merchantInfo,
      paymentAmount: parseFloat(paymentAmount as string),
      cardholderName: values.cardholderName,
      cardNumber: values.cardNumber,
      expDate: values.expDate,
      ...(values.cvv && { cvv: values.cvv }),
      descr: values.paymentDescription,
      billingAddress: {
        addressOne: values.addressOne,
        country: values.countrySelect,
        state: values.state,
      },
      recurringPayment: values.recurringPayment,
      startDate: values.startDate,
      endDate: values.endDate,
      frecuency: values.frecuency,
      ...(file.name ? { fileName: buildFileName(paymentId, file) } : undefined),
    };
    setIsLoading(true);
    clear();
    function maybeUploadAttachment(): Promise<string> {
      return file.name
        ? uploadFile(attachmentsFolderName, paymentId, file)
        : Promise.resolve("No file to upload");
    }
    function resetForm() {
      form.change("cardNumber", "");
      form.resetFieldState("cardNumber");
      form.reset();
    }
    function onSuccess(result: firebase.functions.HttpsCallableResult) {
      // debugger
      // console.log("xxxxxxxxxx", result.data.data)
      // CreateRecurringPayment
      const resultTranx: ITranx = result.data.data;

      console.log("resortcom test", result);
      addSuccess(result.data.message);
      showPrintVersion(
        keysOfProcessorNames[values.merchantInfo],
        // @ts-ignore
        history
      )(resultTranx);
      resetForm();
      setIsSuccess(true);

      debugger;
      if (values.recurringPayment) {
        const request: IScheduleRequest = {
          amount: recurrinAmount.toLocaleString(),
          batch: resultTranx.batchId,
          finalDate: values.endDate,
          startDate: values.startDate,
          frecuency: values.frecuency,
          merchant: values.merchantInfo,
          procesador: resultTranx?.processor,
          state: values.state,
          country: values.countrySelect,
          date: values.calenders,
          procesadorType: resultTranx.processorType,
          email: values.email,
          invoiceNumber: resultTranx.invoiceNumber,
          tranxId: resultTranx.id,
          tranxToken: resultTranx.recurrent_resp_token,
          reference: resultTranx.descr,
          clients: resultTranx.client,
          tranx: resultTranx,
        };

        recurringPaymentServices.CreateRecurringPayment(request);
      }
    }

    const makePaymentAzul = firebase
      .functions()
      .httpsCallable("makePaymentAzul");

    makePaymentAzul(tranx)
      .then((resp) => {
        onSuccess(resp);
      })
      .then(maybeUploadAttachment)
      .catch((err) => {
        console.log("Testing -------", err);
        addError(err.message ? err.message : "Error");
        compose(addError, getMessage, log("makePaymentAzul err"));
        console.log(err);
        // debugger;
      })
      .then(lazyApply(setIsLoading, false))
      .then(resetToken);
  }
  return {
    paymentId,
    onSubmit,
    isLoading,
    isSucess,
  };
}

interface IFrequencyProps {
  from: string;
  to: string;
  message: string;
  selection: number[];
}

interface toScheduledPay {
  pathname: string;
  state: Object;
}

const Scheduled = (
  visibility: boolean,
  nonEmptyState: string,
  message: string,
  to: toScheduledPay
) => {
  return (
    <>
      <Maybe
        component="button"
        visible={visibility}
        className="btn btn-primary has-loading"
        to={to}
      >
        Set schedule
      </Maybe>
      <Maybe component={"span"} visible={visibility}>
        <Field
          component="input"
          className="form-control"
          name="freqMessage"
          id="freqMessage"
          value={nonEmptyState ? message : undefined}
        />
      </Maybe>
    </>
  );
};

interface IFormValues {
  freqSelect: string;
  dueDate: string[];
  chargeDay: string;
  contact?: string;
  email?: string;
  sendInvoice?: string;
}

interface IBetweenRoutes {
  paymentForm: IPaymentValues;
  scheduledForm: IFormValues;
}

const formatDate = (d: string) => {
  let formatMe = new Date(d);
  let dd = String(formatMe.getDate()).padStart(2, "0");
  let mm = String(formatMe.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = formatMe.getFullYear();

  let res = mm + "/" + dd + "/" + yyyy;
  return res;
};

function Payment() {
  const { onSubmit, isLoading, paymentId, isSucess } = useOnSubmitPayment();
  const [toggleScheduledPayment, setScheduledPaymentModal] =
    React.useState<boolean>(false);
  const [interRoute, setInterRoute] = React.useState<IPaymentValues>(
    {} as IPaymentValues
  );
  const [scheduleMsg, setScheduleMsg] =
    React.useState<string>("schedule not set");
  const accessManager = useUserPermissions();
  const [globalThing] = useGlobalState();
  const defaultFilterVal: IFinalFilter = { filterBy: "values" };
  const [
    clients,
    processors,
    isAdmin,
    fullPathProcessors,
    processorsClientTruncated,
  ] = useUserInfo(defaultFilterVal);
  const prequery = {
    clients: clients,
    processors: processors,
    isAdmin: isAdmin,
    processorsClientTruncated: processorsClientTruncated,
  };

  const todaysDate = () => {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${ye}-${mo}-${da}`;
  };

  console.log("clients ===> ", clients);
  console.log("globalThing ===> ", JSON.stringify(globalThing?.client));
  const prequeryInfo = {
    client1: prequery.clients[1] || [],
    client2: prequery.clients[1] || [],
    isAdmin: prequery.isAdmin,
    processorsClientTruncated1: prequery.processorsClientTruncated[0],
    processorsClientTruncated2: prequery.processorsClientTruncated[1],
    processors: prequery.processors,
  };
  console.log("prequeryInfo", prequeryInfo);
  console.log(
    "Payment maybeAccess.scheduledPay: ",
    accessManager?.scheduledPay
  );

  const submitFunc = (values: IPaymentValues, form: FormApi<object>) => {
    // debugger
    if (isRecurrentPayment) {
      values.recurringPayment = true;
      if (values.frecuency === "calendar") {
        values.calenders = calendarsDay;
      }
    } else {
      values.startDate = undefined;
      values.endDate = undefined;
      values.frecuency = undefined;
      values.recurringPayment = false;
    }

    onSubmit(values, form);

    // handleScheduleTask(state?.scheduledForm)
    // state["paymentForm"] = {} as IPaymentValues
    // state["scheduledForm"] = {} as IFormValues
  };

  // React.useEffect(() =>{
  //   if(isSucess){
  //     handleScheduleTask(state?.scheduledForm)
  //     state["paymentForm"] = {} as IPaymentValues
  //     state["scheduledForm"] = {} as IFormValues
  //     console.log('isSuccess useEffect executed!')
  //   }
  //   console.log('isSucess: ', isSucess)
  // },[isSucess])

  const history = useHistory();

  const { state } = useLocation<IBetweenRoutes>();

  // console.log('IFrequencyProps payment terminal', JSON.stringify(state))

  // React.useEffect(() => {
  //   if(!!state?.scheduledForm) {
  //     const dueDate = state.scheduledForm.dueDate
  //     const start = formatDate(dueDate.length > 0 ? dueDate[0] : "")
  //     const end = formatDate(dueDate.length > 0 ? dueDate[1] : "")
  //     setScheduleMsg(`schedule set starting ${start} and ending ${end}`)
  //   }
  // },[])

  // const nonEmptyState = (!!state) && (state.from !== "") && (state.to !== "") && (state.message !== "")

  const handleScheduleTask = (item: IFormValues) => {
    if (!!item) {
      firebase
        .firestore()
        .doc(`scheduled/${item.freqSelect}`)
        .set({
          ...item,
          invoiceNumber: paymentId,
          next: "today",
          uid: globalThing?.uid,
          ...prequeryInfo,
        });
      console.log("handleScheduleTask- task should be in firestore");
    }
    console.log("handleScheduleTask - invoiceNumber: ", paymentId);
  };

  // PAGOS RECURRENTES
  const [isRecurrentPayment, setisRecurrentPayment] = React.useState(false);
  const [frecuencies, setFrecuencies] = React.useState<IFrecuency[]>([]);
  const [calendarsDay, setCalendarsDay] = React.useState<string[] | null>([]);
  const processorInput = React.useRef(null);

  const getFrecuencies = async () => {
    const resp = await recurringPaymentServices.GetFrecuenciesList();
    const respData = resp as IServicesResponse<IFrecuency[]>;
    if (!respData.hasError) {
      setFrecuencies(respData.Data);
    }
  };

  const isRecurrentPaymentFunc = (e: any) => {
    setisRecurrentPayment(e.currentTarget.checked);
  };

  React.useEffect(() => {
    if (frecuencies.length === 0) {
      getFrecuencies();
    }
  }, []);

  const canPaymentRecurrent = (value: string | undefined | null) => {
    if (value === undefined || value === null) {
      setisRecurrentPayment(false);
      if (calendarsDay !== null && calendarsDay.length > 0) {
        setCalendarsDay([]);
      }
      return false;
    }

    const processor = globalThing?.processors?.find((x) => x?.id === value);
    if (processor === undefined) {
      setisRecurrentPayment(false);
      if (calendarsDay !== null && calendarsDay.length > 0) {
        setCalendarsDay([]);
      }
      return false;
    }

    if (processor?.isRecurrent === true) {
      return true;
    }

    setisRecurrentPayment(false);
    if (calendarsDay !== null && calendarsDay.length > 0) {
      setCalendarsDay([]);
    }
    return false;
  };

  // React.useEffect(()=>{
  //   // merchantInfo
  // }, values)

  // const toScheduledPay = {
  //   pathname: `/scheduledPayment`,
  //   state: {paymentForm: interRoute, scheduledForm: !!state?.scheduledForm ? state?.scheduledForm : {}},
  // };

  return (
    <div className="main-content payment">
      <section className="title-section">
        <h4>Payment Terminal </h4>
        <div className="payments-methods">
          <img src="/assets/images/payment/visa.png" alt="" />
          <img src="/assets/images/payment/mc.png" alt="" />
          <img src="/assets/images/payment/ae.png" alt="" />
          <img src="/assets/images/payment/discover.png" alt="" />
          <img src="/assets/images/payment/dc.png" alt="" />
          <img src="/assets/images/payment/jcb.png" alt="" />
        </div>
      </section>
      <div className="card">
        <Form
          // @ts-ignore
          onSubmit={submitFunc}
          // initialValues={!!state?.paymentForm ? state.paymentForm : {} as IPaymentValues}
          // subscription={submitingSubscription}
        >
          {({ handleSubmit, values }: FormRenderProps) => (
            <form
              className="payment-form"
              name="payment"
              onSubmit={handleSubmit}
            >
              <fieldset disabled={isLoading}>
                <legend>Card information</legend>
                {/* {setInterRoute(values as IPaymentValues)} */}
                <pre>{/* {JSON.stringify(interRoute)} */}</pre>

                <div className="form-group has-icon payment-terminal-paddingBottomFifteenPx">
                  <label htmlFor="paymentAmount">Amount</label>
                  <div className="form-icon amount-icon"></div>
                  <Field
                    component="input"
                    className="form-control"
                    name="paymentAmount"
                    type="number"
                    id="paymentAmount"
                    step={0.01}
                    required
                  />
                </div>

                <div style={{ display: "flex" }}>
                  <CardNumberFlags />

                  {/* <Maybe
                            component="div"
                            visible={!!accessManager?.scheduledPay}
                            className="btn btn-primary has-loading"
                            to={toScheduledPay}
                          >
                            Set schedule
                            </Maybe>
                            <Maybe component="div" visible={!!accessManager?.scheduledPay}>
                              <div 
                              style={{width:"50px", height:"50px", backgroundColor:"tomato"}}
                              onClick={() => history.push(toScheduledPay)}
                              >

                              </div>

                            </Maybe>

                          <Maybe component={'span'} visible={!!accessManager?.scheduledPay}>
                            <input
                             style={{width: '300px'}}
                              value={nonEmptyState ? state.message : undefined}
                            />
                             <Field
                                component="input"
                                className="form-control"
                                name="freqMessage"
                                id="freqMessage"
                                value={nonEmptyState ? state.message : undefined}
                              />
                             
                          </Maybe> */}
                </div>

                <div className="form-group has-icon credit-card-input">
                  <label htmlFor="cardNumber">Card number</label>
                  <div className="form-icon credit-card-icon"></div>
                  <Field
                    component="input"
                    className="form-control"
                    name="cardNumber"
                    id="cardNumber"
                    pattern="[\d| ]{16,22}"
                    format={formatCreditCardNumber}
                    required
                  />
                </div>

                <ProcessorSelectField />

                <div className="form-three-column">
                  <div className="form-group has-icon">
                    <label htmlFor="cardholderName">Cardholder Name</label>
                    <div className="form-icon client-icon"></div>
                    <Field
                      component="input"
                      className="form-control"
                      name="cardholderName"
                      type="text"
                      id="cardholderName"
                    />
                  </div>
                  <div className="form-group has-icon">
                    <label htmlFor="expDate">Exp. Date</label>
                    <div className="form-icon exp-date-icon"></div>
                    <Field
                      component="input"
                      id="expDate"
                      name="expDate"
                      className="form-control mask"
                      format={formatExpirationDate}
                      placeholder="MM/YY"
                      required
                      // pattern="(1[0-2]|0[1-9])\/(1[5-9]|2\d)"
                    />
                  </div>
                  <div className="form-group has-icon">
                    <label htmlFor="cvv">CVV</label>
                    <div className="form-icon security-icon"></div>
                    <CVVField />
                  </div>
                </div>
                <div className="two-column-form">
                  <div className="form-group has-icon">
                    <label htmlFor="invoiceNumber">Invoice number</label>
                    <div className="form-icon invoice-icon"></div>
                    <Field
                      component="input"
                      className="form-control"
                      name="invoiceNumber"
                      id="invoiceNumber"
                      type="text"
                      readOnly
                      defaultValue={paymentId}
                    />
                  </div>
                  <div className="form-group has-icon">
                    <label htmlFor="orderNumber">Account #</label>
                    <div className="form-icon order-icon"></div>
                    <Field
                      component="input"
                      className="form-control"
                      name="orderNumber"
                      id="orderNumber"
                      type="text"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="paymentDescription">Reference</label>
                  <Field
                    component="textarea"
                    className="form-control"
                    id="paymentDescription"
                    name="paymentDescription"
                    rows={3}
                  />
                </div>
              </fieldset>

              {/* PAGOS RECURRENTES */}
              <fieldset disabled={isLoading}>
                {canPaymentRecurrent(values?.merchantInfo) ? (
                  <div>
                    <label
                      style={{ marginRight: "10px" }}
                      htmlFor="recurringPayment"
                    >
                      Recurring Payment
                    </label>
                    <Field
                      name="recurringPayment"
                      type="date"
                      id="recurringPayment"
                      required
                    >
                      {({ input }) => (
                        <input
                          {...input}
                          id="recurringPayment"
                          type="checkbox"
                          onChange={isRecurrentPaymentFunc}
                        />
                      )}
                    </Field>
                  </div>
                ) : (
                  <div></div>
                )}

                {isRecurrentPayment &&
                canPaymentRecurrent(values?.merchantInfo) ? (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="col-3">
                      <label htmlFor="datefrom">Start Date</label>

                      <Field
                        initialValue={todaysDate()}
                        name="startDate"
                        type="date"
                        id="startDate"
                      >
                        {({ input }) => (
                          <input
                            readOnly
                            {...input}
                            className="form-control"
                            required={isRecurrentPayment}
                            id="startDate"
                            min={todaysDate()}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="col-3">
                      <label htmlFor="datefrom">End Date</label>
                      <Field name="endDate" type="date" id="endDate">
                        {({ input }) => (
                          <input
                            {...input}
                            disabled={
                              values?.startDate === undefined ? true : false
                            }
                            required={isRecurrentPayment}
                            className="form-control"
                            id="endDate"
                            min={values?.startDate}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="col-3">
                      <label htmlFor="datefrom">Select Frequency</label>
                      <Field
                        name="frecuency"
                        type="text"
                        id="frecuency"
                        defaultValue="daily"
                      >
                        {({ input }) => (
                          <select
                            {...input}
                            required={isRecurrentPayment}
                            className="form-control"
                            id="frecuency"
                            placeholder="--- Frecuencies ---"
                          >
                            {frecuencies.map((f: IFrecuency) => (
                              <option key={f._id} value={f.code}>
                                {f.description}
                              </option>
                            ))}
                          </select>
                        )}
                      </Field>
                    </div>

                    <div className="col-3">
                      <label htmlFor="datefrom">Calendar Days</label>

                      <Field name="calendars" type="date" id="calendars">
                        {({ input }) => (
                          <DatePicker
                            style={{
                              padding: "0.375rem 0.75rem",
                              height: "calc(1.5rem + 0.75rem + 2px)",
                            }}
                            plugins={[<DatePanel />]}
                            minDate={values?.startDate}
                            maxDate={values?.endDate}
                            sort
                            required={
                              isRecurrentPayment &&
                              values.frecuency === "calendar"
                                ? true
                                : false
                            }
                            disabled={
                              values.frecuency === "calendar" &&
                              values.startDate !== undefined &&
                              values.endDate !== undefined
                                ? false
                                : true
                            }
                            multiple
                            value={values.calendars}
                            onChange={(days: any[] | null) => {
                              let daysString: string[] = [];
                              days?.map((d) => {
                                daysString.push(formatDate(d));
                              });

                              setCalendarsDay(daysString);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </fieldset>

              <fieldset disabled={isLoading}>
                <legend>Billing address</legend>
                <div className="form-group">
                  <label htmlFor="addressOne">Address 1</label>
                  <Field
                    component="input"
                    className="form-control"
                    type="text"
                    id="addressOne"
                    name="addressOne"
                    placeholder="Address1, Address2, City, Zipcode"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="countrySelect">Country</label>
                  <Field name="countrySelect">
                    {({ input }: FieldRenderProps<string>) => (
                      <CountryPicker
                        className="form-control"
                        id="countrySelect"
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className="form-group">
                  <label htmlFor="stateSelect">State</label>
                  <Field name="countrySelect">
                    {({ input: cInput }: FieldRenderProps<string>) => (
                      <Field name="state">
                        {({ input }: FieldRenderProps<string>) => (
                          <StatePicker
                            className="form-control"
                            id="stateSelect"
                            country={cInput.value}
                            title="Select a country first"
                            {...input}
                          />
                        )}
                      </Field>
                    )}
                  </Field>
                </div>
              </fieldset>

              <fieldset className="two-column-section" disabled={isLoading}>
                <legend>Client's information</legend>
                <div className="form-group has-icon">
                  <label htmlFor="name">Client name</label>
                  <div className="form-icon client-icon"></div>
                  <Field
                    component="input"
                    className="form-control"
                    name="name"
                    id="name"
                    type="text"
                  />
                </div>
              </fieldset>

              <fieldset disabled={isLoading}>
                <div className="form-group has-icon">
                  <label htmlFor="attachment">Upload file</label>
                  <div>
                    <img src="/assets/images/icons/Upload.svg" alt="" />
                    <input name="attachment" type="file" title="TODO" />
                  </div>
                </div>
              </fieldset>

              <Maybe component="div" visible={false}>
                <div onClick={() => {}}>
                  <FontAwesomeIcon
                    icon={faStopwatch}
                    style={{ fontSize: "20px", color: "#0133ad" }}
                  />

                  <span style={{ color: "black", marginLeft: "5px" }}>
                    Set schedule payment
                  </span>
                </div>
                {/* <div 
                  style={{width:"50px", height:"50px", backgroundColor:"tomato"}}
                  onClick={() => history.push(toScheduledPay)}
                  >

                  </div> */}
              </Maybe>

              <Maybe component={"span"} visible={false}>
                <input
                  style={{ width: "600px", border: "0" }}
                  value={scheduleMsg}
                />
              </Maybe>

              <div className="form-footer">
                <Field name="paymentAmount">
                  {({ input }: FieldRenderProps<number>) => (
                    <Maybe
                      component="button"
                      visible={!isLoading}
                      type="submit"
                      className="btn btn-primary"
                    >
                      Pay{" "}
                      {Number(input.value).toLocaleString(
                        undefined,
                        currencyFormatConfig
                      )}
                    </Maybe>
                  )}
                </Field>
                <Maybe
                  component="button"
                  visible={isLoading}
                  className="btn btn-primary has-loading"
                >
                  <div className="loading-spin-wrapper">
                    <div className="loading-spin">
                      <div></div>
                    </div>
                  </div>
                </Maybe>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
}

export default Payment;
