import * as React from "react";
import {
  useHistory,
  useLocation,
  Link,
  Redirect,
  NavLink,
} from "react-router-dom";
import { useGlobalState } from "config/globalStateContext";
import FrequencySelect from "./FrequencySelect";
import { database } from "firebase";
import { formatDate } from "common/utils/card";
import {
  DateObject,
  ISchedule,
} from "services/Recurring Payment/models/responses/ISchedule";
import {
  Field,
  FieldRenderProps,
  Form,
  FormRenderProps,
} from "react-final-form";
import ProcessorSelect from "common/components/ProcessorSelect";
import DateRangePickerInput from "common/components/DateRangePicker";
import Maybe from "common/components/Maybe";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { IStatus } from "services/Recurring Payment/models/responses/IStatus";
import { IFrecuency } from "services/Recurring Payment/models/responses/IFrecuency";
import { RecurringPaymentServices } from "services/Recurring Payment/RecurringPaymentServices";
import { IServicesResponse } from "services/Recurring Payment/models/IServicesResponse";
import { IEditSchedule } from "services/Recurring Payment/models/iEditSchedule";
import { IScheduleRecord } from "services/Recurring Payment/models/IScheduleRecord";
import { useMessages } from "config/messagesStateContext";
import { ITranx } from "types";
import moment from "moment";
import Messages from "common/components/Messages";

interface IRecurringTableProp {
  data: any;
  onClose: any;
  loading: boolean;
  skip?: Function;
  retry?: Function;
}

const reccurringPayment = new RecurringPaymentServices();

const RecuttingTable = ({ data, onClose, loading }: IRecurringTableProp) => {
  return (
    <div className="modal-body">
      <div className="table-scroll-style">
        <table className="table">
          <thead>
            <tr>
              <th>Invoice</th>
              <th>Merchant</th>
              <th>Merchant name</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((r: any) => (
                <tr>
                  <td>{r?.invoiceNumber}</td>
                  <td>{r?.processor}</td>
                  <td>{r?.processorType?.toUpperCase()}</td>
                  <td>{formatDate(r?.createdAt)}</td>
                  <td>{r?.paymentAmount}</td>
                  <td>{r?.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No transactions</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const JobsTable = ({ data, onClose, loading }: IRecurringTableProp) => {
  return (
    <div className="modal-body">
      <div className="table-scroll-style">
        <table className="table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Next Run</th>
              <th>Status</th>
              <th>CreatedAt</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((r: any) => (
                <tr>
                  <td>{r?._id}</td>
                  <td>
                    {" "}
                    {r?.next !== null
                      ? formatDate(new Date(r?.next))
                      : "-- -- --"}
                  </td>
                  <td>{r?.status?.toUpperCase()}</td>
                  <td>{formatDate(moment(r?.createdAt).toDate())}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No transactions</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const LogsTable = ({
  data,
  onClose,
  loading,
  skip,
  retry,
}: IRecurringTableProp) => {
  const [requestLoading, setRequestLoading] = React.useState<boolean>(false);

  React.useEffect(() => {}, [requestLoading]);

  return (
    <div className="modal-body">
      <div className="table-scroll-style">
        <table className="table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Invoice</th>
              <th>Message</th>
              <th>Status</th>
              <th>CreatedAt</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((r: any) => (
                <tr
                  style={{
                    backgroundColor:
                      r?.status == "pending" ? "#dc3545" : "white",
                  }}
                >
                  <td className={r?.status == "pending" ? "color-white" : ""}>
                    {r?._id}
                  </td>
                  <td className={r?.status == "pending" ? "color-white" : ""}>
                    {r?.invoices}
                  </td>
                  <td className={r?.status == "pending" ? "color-white" : ""}>
                    {r?.message}
                  </td>
                  <td className={r?.status == "pending" ? "color-white" : ""}>
                    {r?.status?.toUpperCase()}
                  </td>
                  <td className={r?.status == "pending" ? "color-white" : ""}>
                    {formatDate(moment(r?.createdAt).toDate())}
                  </td>
                  <td className={r?.status == "pending" ? "color-white" : ""}>
                    <button
                      onClick={async () => {
                        setRequestLoading(true);
                        const resp = await skip!(r?._id);
                        if (!resp?.hasError) {
                          r.status = "skipped";
                        }
                        setRequestLoading(false);
                      }}
                      disabled={r?.status != "pending" || requestLoading}
                      style={{
                        padding: "5px",
                        fontSize: "12px",
                        borderRadius: "5px",
                      }}
                      className="btn btn-warning"
                    >
                      {requestLoading ? "Loading" : "Skip"}
                    </button>
                    |
                    <button
                      onClick={async () => {
                        setRequestLoading(true);
                        const resp = await retry!(r?._id);
                        console.log("xxxxxxxxxxxxx", resp);
                        if (!resp?.hasError) {
                          r.status = "completed";
                        }
                        setRequestLoading(false);
                      }}
                      disabled={r?.status != "pending" || requestLoading}
                      style={{
                        padding: "5px",
                        fontSize: "12px",
                        borderRadius: "5px",
                      }}
                      className="btn btn-info"
                    >
                      {requestLoading ? "Loading" : "Retry"}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No Records</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

interface IFinalFilter {
  startDate?: Date;
  finalDate?: Date;
  amount?: string;
  frecuency?: string;
  status?: string;
  date?: DateObject[];
}
interface IFormProps {
  initialValues: ISchedule | undefined;
  // status: IStatus[] | undefined;
  onSubmit: (values: IFinalFilter) => void;
  localLoading: boolean;
}

const EditForm = (props: IFormProps) => {
  const [status, setStatus] = React.useState<IStatus[]>([]);
  const [frecuencies, setFrecuencies] = React.useState<IFrecuency[]>([]);
  const fromRef = React.useRef(null);
  const [daysString, setdaysString] = React.useState<string[]>([]);
  const [amount, setAmount] = React.useState<string>("");

  //@ts-ignore
  const [dates, setDates] = React.useState([]);
  React.useEffect(() => {
    const datesList = [];
    //@ts-ignore
    const indexs = props?.initialValues?.dates?.length || 0;
    for (let i = 0; i < indexs; i++) {
      //@ts-ignore
      datesList.push(props?.initialValues?.dates[i]?.date);
    }
    //@ts-ignore
    setDates(datesList);
  }, [props.initialValues]);

  const getStatus = async () => {
    const resp = await reccurringPayment.GetStatus();
    if (resp !== null) {
      if (!resp.hasError) {
        const s = resp.Data as IStatus[];
        setStatus(s);
      }
    }
  };

  const getFrecuencies = async () => {
    const resp = await reccurringPayment.GetFrecuenciesList();
    const respData = resp as IServicesResponse<IFrecuency[]>;
    if (!respData.hasError) {
      setFrecuencies(respData.Data);
    }
  };

  React.useEffect(() => {
    getStatus();
    getFrecuencies();
  }, []);

  const disableCalendar = (frecuencyId: string | undefined) => {
    if (frecuencyId !== undefined) {
      const frecuency = frecuencies.filter((x) => x._id === frecuencyId);
      if (frecuency.length > 0) {
        if (frecuency[0].code === "calendar") {
          return false;
        }
      }
    }
    return true;
  };

  const dateFomatted = (date?: string): string => {
    return moment(date).format("YYYY-MM-DD");
  };

  return (
    <Form
      //@ts-ignore
      onSubmit={(f) => {
        console.log("ddddddddddddddddddddddddddddddddddddddddddddddddddddd", f);
        let d: DateObject[] = [];

        const dString: any[] =
          //@ts-ignore
          window.document.getElementById("calendars-xxx")?.value?.split(",") ??
          [];

        dString.map((dt) => {
          d.push({ date: moment(dt).toDate(), hasRun: false });
        });
        props.initialValues?.date?.map((dm) => {
          if (dm.hasRun) {
            if (d.includes({ date: moment(dm.date).toDate(), hasRun: false })) {
              d = d.filter((x) => {
                if (moment(x.date).toDate() == moment(dm.date).toDate()) {
                  x.hasRun = true;
                }
                return x;
              });
            }
          }
        });

        props.onSubmit({ ...f, date: d });
        // props.onSubmit(f);
      }}
      initialValues={props?.initialValues}
      // initialValues={props.initialValues as IFinalFilter}
      // subscription={submitingSubscription}
    >
      {({ handleSubmit, form, values }: FormRenderProps<IFinalFilter>) => (
        <form
          action="seachByType"
          className="search-by-type"
          onSubmit={handleSubmit}
        >
          <div
            className="type-search"
            style={{ padding: "15px", border: "none" }}
          >
            {/* <div className="type-search-content form-two-column"> */}

            <div>
              <label htmlFor="datefrom">Amount</label>

              <Field
                component="input"
                name="amount"
                className="form-control"
                type="text"
                initialValue={values.amount}
              >
                {({ input }) => <input {...input} className="form-control" />}
              </Field>
            </div>

            <div className="col-3">
              <label htmlFor="datefrom">Status</label>

              <Field name="status" type="text" id="status">
                {({ input }) => (
                  <select
                    {...input}
                    className="form-control"
                    id="status"
                    placeholder="--- Frecuencies ---"
                  >
                    {status.map((f: IStatus) => {
                      return (
                        <option key={f._id} value={f.code}>
                          {f.descripcion}
                        </option>
                      );
                    })}
                  </select>
                )}
              </Field>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="col-3">
              <label htmlFor="datefrom">Start Date</label>
              <Field
                name="startDate"
                type="date"
                id="startDate"
                initialValue={formatDate(
                  moment(props.initialValues?.startDate).toDate()
                )}
              >
                {({ input }) => (
                  <input
                    {...input}
                    className="form-control"
                    id="startDate"
                    min={Date.now()}
                    value={dateFomatted(values.startDate?.toString())}
                  />
                )}
              </Field>
            </div>

            <div className="col-3">
              <label htmlFor="datefrom">End Date</label>
              <Field
                name="finalDate"
                type="date"
                id="finalDate"
                // initialValue={values.finalDate}
              >
                {({ input }) => (
                  <input
                    {...input}
                    className="form-control"
                    id="finalDate"
                    name="finalDate"
                    type="date"
                    // min={ values?.startDate }

                    value={dateFomatted(values.finalDate?.toString())}
                    // value={moment(values.finalDate).format("DD-MM-YYYY")}
                  />
                )}
              </Field>
            </div>

            <div className="col-3">
              <label htmlFor="datefrom">Select Frequency</label>
              <Field name="frecuency" type="text" id="frecuency">
                {({ input }) => (
                  <select
                    {...input}
                    className="form-control"
                    id="frecuency"
                    placeholder="--- Frecuencies ---"
                  >
                    {frecuencies.map((f: IFrecuency) => {
                      return (
                        <option key={f._id} value={f._id}>
                          {f.description}
                        </option>
                      );
                    })}
                  </select>
                )}
              </Field>
            </div>

            <div className="col-3">
              <label htmlFor="datefrom">Calendar Days</label>

              <Field name="calendar" type="date" id="calendars">
                {({ input }) => (
                  <DatePicker
                    id="calendars-xxx"
                    value={dates}
                    disabled={disableCalendar(values.frecuency)}
                    style={{
                      padding: "0.375rem 0.75rem",
                      height: "calc(1.5rem + 0.75rem + 2px)",
                    }}
                    plugins={[<DatePanel />]}
                    minDate={moment(Date.now()).add("day", 1).toDate()}
                    maxDate={moment(values.finalDate).toDate()}
                    sort
                    multiple
                    onChange={(days: any[] | null) => {
                      let daysString: string[] = [];
                      days?.map((d) => {
                        daysString.push(formatDate(d));
                      });
                      console.log("zzzzzzzzzz", form.getFieldState("calendar"));
                      setdaysString(daysString);
                    }}
                  />
                )}
              </Field>

              {/* </div> */}
            </div>
          </div>

          <div style={{ paddingTop: "10px" }} className="form-buttons">
            <button className="btn btn-primary" type="submit">
              {props.localLoading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      )}
    </Form>
  );
};

const EditRecurringPay = () => {
  const history = useHistory();
  const [logs, setLog] = React.useState<any>([]);
  const onClose = history.goBack;
  const [localLoading, setLoading] = React.useState<boolean>(false);
  const [global] = useGlobalState();
  const loading = localLoading || !!global?.isLoading;
  const { addError, addSuccess } = useMessages();
  const [tabSelected, setTabSelected] = React.useState<
    "tranx" | "jobs" | "logs"
  >("tranx");
  const [tableSelected, setTableSelected] = React.useState<any>(null);
  const [cronjobs, setCronjobs] = React.useState<any[]>([]);
  const [transactions, setTransactions] = React.useState<ITranx[]>([]);

  const location = useLocation();
  const {
    state: { data, freqSelect },
  } = location;

  const editSchedule = (value: ISchedule | any) => {
    //AQUI
    const body: IEditSchedule = {
      amount: value.amount,
      finalDate: value.finalDate,
      frecuency: value.frecuency,
      startDate: value.startDate,
      status: value.status,
      date: value?.date,
    };

    setLoading(true);
    reccurringPayment
      .editSchedule(data?._id, body)
      .then((resp) => {
        console.log(resp);
        setLoading(false);
        addSuccess(`Schedule updated successfully!`);

        history.goBack();
      })
      .catch((err) => {
        setLoading(false);
        addSuccess(`Error!`);
        history.goBack();
        console.log(err);
      });
  };

  const getJobs = async () => {
    const cronJobsData = await reccurringPayment.getCronJobList(data?._id);
    setCronjobs(cronJobsData);
  };

  const skipTranx = async (id: string) => {
    const resp = await reccurringPayment.skipTranx(id);
    if (!resp?.hasError) addSuccess(resp?.message);
    else addError(resp?.message);
    return resp;
  };

  const retryTranx = async (id: string) => {
    const resp = await reccurringPayment.retryTranx(id);
    if (!resp?.hasError) addSuccess(resp?.message);
    else addError(resp?.message);
    return resp;
  };

  const getTransactions = async () => {
    const resp = await reccurringPayment.getTransaccionByScheduleId(data?._id);
    setTransactions(resp);
    console.log("getTransaccionByScheduleId", resp);
  };

  const getLogs = async () => {
    const resp = await reccurringPayment.getlogsByScheduleId(data?._id);
    setLog(resp);
  };

  React.useEffect(() => {
    getJobs();
    getTransactions();
    getLogs();
  }, []);

  React.useEffect(() => {
    switch (tabSelected) {
      case "tranx":
        setTableSelected(
          <RecuttingTable
            data={transactions}
            loading={loading}
            onClose={onClose}
          />
        );
        break;
      case "jobs":
        setTableSelected(
          <JobsTable data={cronjobs} loading={loading} onClose={onClose} />
        );
        break;
      case "logs":
        setTableSelected(
          <LogsTable
            skip={skipTranx}
            retry={retryTranx}
            data={logs}
            loading={loading}
            onClose={onClose}
          />
        );
        break;

      default:
        setTableSelected(
          <RecuttingTable
            data={transactions}
            loading={loading}
            onClose={onClose}
          />
        );
        break;
    }
  }, [tabSelected]);

  // const { state } = props;
  return (
    <div>
      <div
        className="modal payment-details-modal show scrollable-y"
        id="paymentDetail"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="paymentDetailLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="paymentDetailLabel">
                {data?.edit ? "Edit " : ""}
                Subscription Id: {data?._id}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {
              //AQUI
              data?.edit ? (
                <EditForm
                  initialValues={data}
                  localLoading={localLoading}
                  onSubmit={(e) => {
                    editSchedule(e);
                  }}
                />
              ) : (
                <div></div>
              )
            }
            <nav className="nav nav-tabs">
              <li
                style={{
                  backgroundColor: `${
                    tabSelected === "tranx" ? "#E7E7E7" : "white"
                  }`,
                  padding: "8px",
                  border: "1px solid #E7E7E7",
                  borderRadius: "2px",
                  cursor: "pointer",
                  textAlign: "center",
                  minWidth: "150px",
                }}
                onClick={() => {
                  setTabSelected("tranx");
                }}
              >
                Transactions
              </li>
              <li
                style={{
                  backgroundColor: `${
                    tabSelected === "jobs" ? "#E7E7E7" : "white"
                  }`,
                  border: "1px solid #E7E7E7",
                  padding: "8px",
                  borderRadius: "2px",
                  cursor: "pointer",
                  textAlign: "center",
                  minWidth: "150px",
                }}
                onClick={() => {
                  setTabSelected("jobs");
                }}
              >
                Jobs
              </li>
              <li
                style={{
                  backgroundColor: `${
                    tabSelected === "logs" ? "#E7E7E7" : "white"
                  }`,
                  border: "1px solid #E7E7E7",
                  padding: "8px",
                  borderRadius: "2px",
                  cursor: "pointer",
                  textAlign: "center",
                  minWidth: "150px",
                }}
                onClick={() => {
                  setTabSelected("logs");
                }}
              >
                Logs
              </li>
            </nav>

            {tableSelected}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRecurringPay;
