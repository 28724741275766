
import React, {useState, useEffect} from 'react';
import {
    Field,
    Form,
    FormRenderProps 
  } from "react-final-form";

  import { useLocation, useHistory, Redirect } from 'react-router-dom';

  import FrequencySelect from "./FrequencySelect"

  import { usePaymentId } from '../../../common/hooks/utils';

  import firebase from "firebase";


  import ProcessorSelectField from "../../Payment/components/ProcessorSelectField"
  import CountryPicker from "../../Payment/components/CountryPicker";
  import StatePicker from "../../Payment/components/StatePicker";

  import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
  } from "common/utils/card";
import { useMessages } from 'config/messagesStateContext';


  interface IFormValueProps {
    [key: string]: any;
    id?: string,
    merchantInfo?: string,
    datefrom?: string,
    dateTo?: string,
    freqSelect?: string,
    amount?: string,
    cardHolder?: string,
    cardNumber?: string,
    expiry?: string,
    cvv?: string,
    contact?: string,
    email?: string,
    addressOne?: string,
    reference?: string,
    numOfEvents?: string,
    countrySelect?: string,
    state?: string,

}

// take an object and a array of keys and return if the object has all the keys in the array filled
const hasAllValues = (obj: IFormValueProps, keys: string[]) => {
    return keys.every((key) => {
        return !!obj[key]
    });
};

const uuid = () =>
"xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
    v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
});


const AddScheduledInfo = () => {
    const history = useHistory();
    const REQUIRED = ['merchantInfo','datefrom','dateTo','freqSelect','amount','cardHolder','cardNumber','expiry','cvv']
    const [formValues, setFormValues] = useState<IFormValueProps>({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [recurringID, resetToken] = usePaymentId('RC');
    const [isloading, setIsloading] = useState(false);
    const { addError, addSuccess } = useMessages();

   

    const saveScheduledOnSubmit = async (values: IFormValueProps) => {
      setIsloading(true)
      const scheduledId = recurringID
      const info = {
        scheduledId,
        ...values
      };
     await firebase
      .firestore()
      .doc(`scheduled/scheduledParent/scheduledColl/${scheduledId}`).set(info)
      .then(() => {
        console.log("Scheduled Info Saved");
        addSuccess(`"Scheduled Info Saved successfully!`);
        setIsloading(false);
      }).catch( err => setIsloading(false));
      history.goBack()

       
    }

    

    useEffect(() => {
      console.log('formValues', formValues)
      if(hasAllValues(formValues, REQUIRED) && isSubmitted) {
       saveScheduledOnSubmit(formValues)
       setIsSubmitted(false)
        history.goBack()
      }
    }, [formValues,saveScheduledOnSubmit, isSubmitted]);


    const onClose = history.goBack;




  const resetWeekPath = () =>{

  }
  return (
    <div className="modal show">
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
        <div className="modal-header">
                <div className="modal-title" id="disputeModalLabel">
                   <h1>{`Set new scheduled payment`}</h1> 
                    </div>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={onClose}
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
            </div>
            <div className="modal-body scrollable-y" >

            <Form 
  onSubmit={saveScheduledOnSubmit}
  render={({ handleSubmit, values, form}) => (
    <>
        <form onSubmit={handleSubmit} name="scheduledPayModal" >
        
        <div className="row">
          <div className="col-6">
          <label htmlFor="choose-pet">Select Merchant</label>
          <ProcessorSelectField recurrent/>
          </div>
        
        </div>
        {setFormValues(values)}
        
        <div className="row">
          <div className="col-6" style={{padding:"0"}}>
            <DatesFromToHandler />
            </div>
            <div className="col-3" >
   
        <div className="form-group form-inline">
        <label htmlFor="frequency" style={{marginBottom:'7px'}}>Select Frequency</label>
        <FrequencySelect />
        </div>
        
                </div>

                <div className="col-3">
        <div className="form-group form-inline">
                <label htmlFor="amount" style={{marginBottom:'7px'}}>Amount</label>
                <Field
                  component="input"
                  name="amount"
                  className="form-control"
                  type="text"
                  id="amount"
     
                />
                </div>
        </div>


        </div>

        <div className="row" style={{marginTop:"20px"}}>
        

        </div>

        <div className="row">
     

        <div className="col-3" >
        <div className="form-group form-inline">
                <label htmlFor="cardHolder">Card holder's name</label>
                <Field
                  component="input"
                  name="cardHolder"
                  className="form-control"
                  type="text"
                  id="cardHolder"
     
                />
                </div>
        </div>

        <div className="col-3">
        <div className="form-group form-inline">
                <label htmlFor="cardNumber">Card number</label>
                <Field
                  component="input"
                  name="cardNumber"
                  className="form-control"
                  type="text"
                  id="cardNumber"
                  pattern="[\d| ]{16,22}"
                  format={formatCreditCardNumber}
                  required
     
                />
                </div>
        </div>

        <div className="col-3" >
        <div className="form-group form-inline">
                <label htmlFor="expiry">Expiration date</label>
                <Field
                  component="input"
                  name="expiry"
                  className="form-control"
                  type="text"
                  id="expiry"
                  format={formatExpirationDate}
                      placeholder="MM/YY"
                      required
                      pattern="(1[0-2]|0[1-9])\/(1[5-9]|2\d)"
     
                />
                </div>
        </div>

        <div className="col-3" >
        <div className="form-group form-inline">
                <label htmlFor="cvv">CVV</label>
                <Field
                  component="input"
                  name="cvv"
                  className="form-control"
                  type="text"
                  id="cvv"
     
                />
                </div>
        </div>

        </div>

        <div className="row">

     

        


        </div>

                <div className="row">

                <div className="col-3" >
                <div className="form-group form-inline">
                        <label htmlFor="contact">Contact</label>
                        <Field
                          component="input"
                          name="contact"
                          className="form-control"
                          type="text"
                          id="contact"

                        />
                        </div>
                </div>

              <div className="col-3" >
              <div className="form-group form-inline">
                      <label htmlFor="email">Email</label>
                      <Field
                        component="input"
                        name="email"
                        className="form-control"
                        type="text"
                        id="email"

                      />
                      </div>
              </div>

              <div className="col-6">
              <label htmlFor="addressOne" style={{margin:'0'}}>Cardholder Billing Address</label>
                  <Field
                    component="input"
                    className="form-control"
                    type="text"
                    id="addressOne"
                    name="addressOne"
                    placeholder="Address1, Address2, City, Zipcode"
                  />
              </div>

        </div>


        <div className="row">

            <div className="col-3" >
            <div className="form-group form-inline">
                  <label htmlFor="reference">Reference</label>
                  <Field
                    component="input"
                    name="reference"
                    className="form-control"
                    type="text"
                    id="reference"

                  />
                  </div>
            </div>

            <div className="col-3" >
            <div className="form-group form-inline">
                  <label htmlFor="numOfEvents">Number of Events</label>
                  <Field
                    component="input"
                    name="numOfEvents"
                    className="form-control"
                    type="text"
                    id="reference"

                  />
                  </div>
            </div>

            <div className="col-3">
            <label htmlFor="countrySelect">Country</label>
                  <Field name="countrySelect">
                    {({ input }) => (
                      <CountryPicker
                        className="form-control"
                        id="countrySelect"
                       
                        {...input}
                      />
                    )}
                  </Field>
            </div>

            <div className="col-3">
            <label htmlFor="stateSelect">State</label>
                  <Field name="countrySelect">
                    {({ input: cInput }) => (
                      <Field name="state">
                        {({ input }) => (
                          <StatePicker
                            className="form-control"
                            id="stateSelect"
                           
                            country={cInput.value}
                            title="Select a country first"
                            {...input}
                          />
                        )}
                      </Field>
                    )}
                  </Field>
            </div>

       
            {/* <pre>{JSON.stringify(values)}</pre> */}
            </div>

            <div className="modal-footer">


               <button
                      type="submit"
                      className="btn btn-primary"
                      style={{marginRight:'15px'}}
                      onClick={() => setIsSubmitted(true)}
                  >
                     { isloading ? 'Loading...' : 'Save' }
                  </button>

          <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => form.reset()}
                >
                 Reset
            </button>
          </div>


                    
        </form>

    </>
    )}
    />


            </div>

            
        </div>
        </div>
    </div>
)
}

export default AddScheduledInfo;


const DatesFromToHandler = () => {

    const todaysDate = () =>{
        const d = new Date();
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${ye}-${mo}-${da}`;
    }

    const [fromDate, setFromDate] = useState<string>(todaysDate())
    const [toDate, setToDate] = useState<string>(todaysDate())
    const [numOfWeeks, setNumOfWeeks] = useState<string>("");

    function getWeeks(days: number) {
        return {
          weeks : Math.floor(days / 7),
          days : days % 7
        };
      }

    useEffect(() =>{
        if(fromDate !== "" && toDate !== ""){
            const d1 = new Date(fromDate)
            const d2 = new Date(toDate)
            const diff = d2.getTime() - d1.getTime();
            const Difference_In_Days = diff / (1000 * 3600 * 24);
            console.log('weeks: ',getWeeks(Difference_In_Days).weeks)
            const wmsg = `for about ${getWeeks(Difference_In_Days).weeks} week(s)`
            setNumOfWeeks(wmsg)
           
        } 
  
  
      },[fromDate, toDate])

      useEffect(() =>{
          console.log('DatesFromToHandler:', numOfWeeks)
      }, [numOfWeeks])

    const fromEv = (date: string) => {
        console.log('fromEv', date)
        setFromDate(date)
      }
  
      const toDateEv = (date: string) => {
        console.log('toDateEv', date)
        setToDate(date)
      }

    return (
        <div style={{display:'flex'}}>
        <div className='col-6'>
            <label htmlFor="datefrom">Start Date</label>

          <Field name='datefrom'  type="date"  id='datefrom'  required>
            {({input}) => (
              <input {...input} 
                className="form-control" 
                id='datefrom'  
                min={todaysDate()}
                 />
            )}

          </Field>
    
      
        </div>
        <div className='col-6'>
            <label htmlFor="dateTo">End Date</label>

            <Field name='dateTo'  type="date"  id='dateTo'  required>
            {({input}) => (
              <input {...input} 
                className="form-control" 
                min={todaysDate()}
                 />
            )}

          </Field>
    
      
        </div>
        </div>
     
    )
}