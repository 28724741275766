import React from "react";
import { NavLink, Route, Switch } from 'react-router-dom';
import Processors from './Processors';
import GlobalProcessors from './GlobalProcessors';
import Companies, { MyCompany } from './Companies';
import SimpleToNewLogic from './Migra/SimpleToNewLogic';
import ManualTrxEntry from './Migra/ManualEntry';
import ConditionalProcessors from './ConditionalProcessors';
import Users from './Users';
import { useGlobalState } from "config/globalStateContext";
import Maybe from "common/components/Maybe";

function Admin() {
  const [userData] = useGlobalState();
  const isSuperAdmin = !!userData?.access?.superAdmin;
  const isCreateClients = !!userData?.access?.createClients;
  const isAdminUser = !!userData?.access?.createUsers;
  return (
    <div className="main-content">
      <div className="card">
        <div className="head grid-item">
          <h4>Administrator</h4>
        </div>
        <nav className="nav nav-tabs">
          <Maybe visible={isSuperAdmin || isAdminUser} component={React.Fragment}>
            <li className="nav-item">
              <NavLink to="/super-admin" exact className="nav-link" activeClassName="active">Users</NavLink>
            </li>
          </Maybe>
          <Maybe visible={isSuperAdmin || isCreateClients} component={React.Fragment}>
            <li className="nav-item">
              <NavLink to="/super-admin/mycompany" className="nav-link" activeClassName="active">My Company</NavLink>
            </li>
          </Maybe>
          <Maybe visible={isSuperAdmin} component={React.Fragment}>
            <li className="nav-item">
              <NavLink to="/super-admin/companies" className="nav-link" activeClassName="active">Companies</NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink to="/super-admin/legacy-processors" className="nav-link" activeClassName="active">Processors</NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink to="/super-admin/processors" className="nav-link" activeClassName="active">Global Processors</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/super-admin/migration/simple_logic_trxs" className="nav-link" activeClassName="active">Simple Trxs Migration</NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink to="/super-admin/migration/manual_trxs_entry" className="nav-link" activeClassName="active">Manual Trxs Entry</NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink to="/super-admin/cond-processors" className="nav-link" activeClassName="active">Conditional Processors</NavLink>
            </li> */}
          </Maybe>
        </nav>

        <Switch>
          <Route path="/super-admin" exact component={Users} />
          <Route path="/super-admin/legacy-processors" component={Processors} />
          <Route path="/super-admin/processors" component={GlobalProcessors} />
          <Route path="/super-admin/cond-processors" component={ConditionalProcessors} />
          <Route path="/super-admin/users" component={Users} />
          <Route path="/super-admin/migration/simple_logic_trxs" component={SimpleToNewLogic} />
          <Route path="/super-admin/companies/:client0/companies/:client1/processors" component={GlobalProcessors} />
          <Route path="/super-admin/companies/:parentId/companies" component={Companies} />
          <Route path="/super-admin/companies" component={Companies} />
          <Route path="/super-admin/mycompany/:client1/processors" component={GlobalProcessors} />
          <Route path="/super-admin/mycompany" component={MyCompany} />
          <Route path="/super-admin/migration/manual_trxs_entry" component={ManualTrxEntry} />
        </Switch>        
      </div>
    </div>
  );
}

export default Admin;
