import * as React from "react";
import { Field } from 'react-final-form';

function TrinityProcessorFields() {
  return (
    <>
      <div className="form-group">
        <label htmlFor="merchant_token">Merchant Token</label>
        <Field
          component="input"
          className="form-control"
          name="merchant_token"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="currencyCode">Currency Code</label>
        <Field
          component="input"
          className="form-control"
          name="currencyCode"
          defaultValue="USD"
          maxLength={3}
          required
        />
      </div>
    </>
  );
}

export default TrinityProcessorFields;
