import * as React from "react";
import { Field } from 'react-final-form';
import { emptyString } from "common/utils/constants";

interface IProps {
  isNew: boolean,
  hideNonGlobal?: boolean,
}

function CommonProcessorFields(props: IProps) {
  const { isNew, hideNonGlobal } = props;
  return (
    <>
      {hideNonGlobal ? null :
        <div className="form-group">
          <label htmlFor="merchantId">Merchant ID</label>
          <Field
            component="input"
            className="form-control"
            id="merchantId"
            name="id"
            required
            disabled={!isNew}
          />
        </div>
      }
      <div className="form-group">
        <label htmlFor="store">Merchant Number</label>
        <Field
          component="input"
          className="form-control"
          id="store"
          name="store"
          required
          disabled={!isNew && !!hideNonGlobal}
        />
      </div>
      <div className="form-group">
        <label htmlFor="processorType">Merchant Type</label>
        <Field
          component="select"
          className="form-control"
          id="processorType"
          name="type"
          required
          // disabled={!isNew}
        >
          <option value={emptyString}>Select a type</option>
          <option value="azul">Azul</option>
          <option value="resortcom">Resortcom</option>
          <option value="trinity">Trinity</option>
        </Field>
      </div>
      {hideNonGlobal ? null :
        <div className="form-group">
          <label htmlFor="store">Merchant Name</label>
          <Field
            component="input"
            className="form-control"
            name="name"
            required
          />
        </div>
      }
      <div className="form-group">
        <Field
          allowNull
          type="checkbox"
          defaultValue={false}
          component="input"
          id="isCVVOptional"
          name="isCVVOptional"
        />
        &nbsp;
        <label htmlFor="isCVVRequired">Is CVV Optional?</label>
      </div>
      
    </>
  )
}

export default CommonProcessorFields;
