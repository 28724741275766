import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { useCollection } from 'react-firebase-hooks/firestore';
import { useGlobalState } from "config/globalStateContext";
import { emptyArray, emptyObject } from "common/utils/constants";
import {
  compose,
  getMessage,
  lazyApply,
  ignore,
  lazyGetSessionClientName,
  lazyGetAgentName,
  idReduce,
  lazyGetSessionItem,
} from "common/utils/fns";
import { getDocsByID, fetchClientName, fetchAgentName, fetchSubMerchantName, buildBaseQuery } from "common/utils/firebase";
import { IDEntity, IKeysOfStr, IAccess, IProcessor } from "types";

export function useDocumentDataOnceByIds<T extends IDEntity>(
  collectionPath: string | undefined,
  ids: string[] | undefined
): [T[], boolean, string | undefined] {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [items, setItems] = React.useState<T[]>(emptyArray);
  React.useEffect(() => {
    setError(undefined);
    setItems(emptyArray);
    if (collectionPath && ids && ids.length > 0) {
      setLoading(true);
      getDocsByID(collectionPath, ids)
        // @ts-ignore
        .then(setItems, compose(setError, getMessage))
        .then(lazyApply(setLoading, false));
    } else {
      setLoading(false);
    }
  }, [collectionPath, ids]);
  return [items, loading, error];
}

export function useClientName(
  clientPath: string[] | undefined,
  setLoading: ((v: boolean) => void) | undefined = undefined,
  addError: ((s: string) => void) | undefined = undefined
): string {
  const [clientName, setClientName] = React.useState<string | null>(
    lazyGetSessionClientName(clientPath)
  );
 
  React.useEffect(() => {
    function onSuccess(clientName: string) {
      setClientName(clientName);
      sessionStorage.setItem(JSON.stringify(clientPath), clientName);
    }
    if (clientPath) {
      const maybeName = lazyGetSessionClientName(clientPath)();
      
      if (maybeName) {
        setClientName(maybeName);
        return;
      }
      setLoading ? setLoading(true) : setClientName("...");
      fetchClientName(clientPath)
        .then(
          onSuccess,
          compose(addError ? addError : setClientName, getMessage)
        )
        .then(lazyApply(setLoading ? setLoading : ignore, false));
    }
  }, [clientPath]);
  return clientName ?? "...";
}

export function useSubMerchantName(client: string[], processor: string, keysOfProcessors: IKeysOfStr): string {
  
  const [subMerchantName, setSubMerchantName] = React.useState<string|null>(keysOfProcessors[processor] || lazyGetSessionItem(processor))
  React.useEffect(() => {
    if (!subMerchantName) {
      fetchSubMerchantName(client, processor)
        .then(setSubMerchantName)
        .catch(console.error)
    }
  }, [subMerchantName, client, processor]);
  return subMerchantName ?? '-';
}

export function useAgentName(
  uid: string | undefined
): string | null {
 
  const [result, setResult] = React.useState<string|null>(lazyGetAgentName(uid));
  React.useEffect(() => {
    if (uid) {
      fetchAgentName(uid)
        .then(setResult, compose(setResult, getMessage))
    }
  }, [uid]);

  if(result?.includes("_not-found")) {
    setResult(result.replace("_not-found", ""))
  }
  return result;
}

export function useAgentEmail(
  uid: string | undefined
): string | null {
  const [result, setResult] = React.useState<string|null>(lazyGetAgentName(uid+'@email'));
  React.useEffect(() => {
    if (uid) {
      fetchAgentName(uid, true)
        .then(setResult, compose(setResult, getMessage))
    }
  }, [uid]);
  return result;
}

export function useProcessorsName(): IKeysOfStr {
  const [userData] = useGlobalState();
  return React.useMemo(
    () =>
      (Array.prototype.concat(userData?.processors, userData?.gProcessors).filter(Boolean).reduce(
        (acc, it) => ({ ...acc, [it.id]: it.name }),
        {}
      ) ?? emptyObject) as IKeysOfStr,
    [userData?.processors, userData?.gProcessors]
  );
}

export function useUserPermissions(): IAccess|undefined {
  const [userData] = useGlobalState();
  return React.useMemo(() => userData?.access, [userData?.access]);
}

export interface IPaginatedCollection<T extends IDEntity> {
  page: number,
  onBackClick(): void,
  onNextClick(): void,
  onReset(): void,
  items: T[],
  loading: boolean,
  error: Error | undefined,
}

export function usePaginatedCollection<T extends IDEntity>(defaultQuery: firebase.firestore.Query, pageSize: number, sendQuery: Boolean = true): IPaginatedCollection<T> {
 
  const [query, setQuery] = React.useState(() => defaultQuery.limit(pageSize));
  const [qs, loading, error] = useCollection(sendQuery ? query : null);


  query?.get().then(function(querySnapshot) {
    if (querySnapshot.empty) {
        console.log('no documents found');
    } else {
        // do something with the data
    }
  });

  const items = React.useMemo(
    () =>
      qs?.docs.map((it) => ({ id: it.id, ...it.data() } as T)) ??
      emptyArray,
    [qs]
  );

  const [firstOrLast, setFirstOrLast] = React.useState<
    | [
        firebase.firestore.QueryDocumentSnapshot | undefined,
        firebase.firestore.QueryDocumentSnapshot | undefined
      ]
    | undefined
  >(undefined);
  const [page, setPage] = React.useState(0);
  const [_onBackClick, setOnBackClick] = React.useReducer(idReduce, undefined);
  const [_onNextClick, setOnNextClick] = React.useReducer(idReduce, undefined);
  
  const onBackClick = React.useCallback(() => {
    setPage((p) => p - 1);
    _onBackClick();
    return false;
  }, [setPage, _onBackClick])
  const onNextClick = React.useCallback(() => {
    setPage((p) => p + 1);
    _onNextClick();
    return false;
  }, [setPage, _onNextClick]);
  const onReset = React.useCallback(() => {
    setFirstOrLast(undefined);
    setPage(0);
  }, [setPage, setFirstOrLast]);

  React.useEffect(() => {
    onReset();
  }, [defaultQuery]);

  React.useEffect(() => {
    const filteredQuery = defaultQuery;
    const [first, last] = firstOrLast ?? emptyArray;
    const newQuery = first
      ? filteredQuery.endBefore(first).limitToLast(pageSize)
      : last
      ? filteredQuery.startAfter(last).limit(pageSize)
      : filteredQuery.limit(pageSize);
    setQuery(newQuery);
  }, [defaultQuery, pageSize, firstOrLast]);
  React.useEffect(() => {
    if (qs && !qs.empty) {
      setOnBackClick(() => setFirstOrLast([qs?.docs[0], undefined]));
      setOnNextClick(() =>
        setFirstOrLast([undefined, qs?.docs[qs.docs.length - 1]])
      );
    } else if (!loading) {
      setPage(0);
      setFirstOrLast(undefined);
    }
  }, [qs]);
  return {
    page,
    onBackClick,
    onNextClick,
    onReset,
    items,
    loading,
    error,
  };
}
