// @ts-ignore
import moment from "moment";
import * as payment from "payment";
import { IKeysOf, ITranx, IUserResp } from "types";
import { emptyString } from "./constants";

export function getLast<T>(xs: T[] | undefined): T | undefined {
  return xs?.[xs.length - 1];
}

const batchIDScan = (batchID: string) =>{
  let sublen = batchID.length - 8 
  if(sublen > 0){
    return [batchID.substring(0, sublen), batchID.substring(sublen)]
    } else {
      return ["", batchID.substring(sublen)]
    }
}

const getTransRootID = (rootID: string, transID:string) =>{
  const front = transID.substring(0, transID.indexOf(rootID))
  if(front !== ""){
    return [front, transID.substring(front.length)]
  } else{
    return ["", transID]
  }
}

interface IDMeta{
  isBatchId: boolean
  isRootBatchID: boolean
  rootBatchID: string
  batchIDFrontNumber: string
  lineNumber: string
  recharges: string
 rootTransactionId: string
 nextBatchID: string
 nextTransactionID: string
 completeRecharge: string
sequenceOfBatchIds: (n: number) => string[]

}

export const scanMyID = (id: string): IDMeta =>{
  const [batchID, line, recharges] = id.split('-')
  const [batchCounter, rootBatchID] = batchIDScan(batchID)
  const isBatchId = line == undefined
  const isRootBatchID = rootBatchID === id

  return {
      isBatchId,
      isRootBatchID,
      rootBatchID: rootBatchID,
      batchIDFrontNumber: batchCounter,
      lineNumber: isBatchId ? 'this is a batch ID' : line,
      recharges: isBatchId ? 'this is a batch ID' :  recharges,
      rootTransactionId: isBatchId ? 'this is a batch ID' :  rootBatchID + "-" + line + "-" +"0",
      nextBatchID: (Number(batchCounter) + 1) + rootBatchID,
      nextTransactionID: isBatchId ? 'this is a batch ID' : batchCounter + rootBatchID + "-" + line + "-" + (Number(recharges) + 1),
      completeRecharge: isBatchId ? 'this is a batch ID' : (Number(batchCounter) + 1) + rootBatchID + "-" + line + "-" + (Number(recharges) + 1),
      sequenceOfBatchIds: isRootBatchID ? (n: number) => [rootBatchID, ...Array.from(Array(n).keys()).map(nu => nu + rootBatchID).slice(1)] : (x: number) => [batchID],        

  }
  
}



export const buildFileName = (key: string, file: File): string =>
  `${key}_${file.name}`;

export const prop = <T>(attr: string | number) => (obj: Object): T =>
  // @ts-ignore
  obj[attr];

export const getMessage = (err: Error): string => err.message;

export const lazyApply = <T, R>(f: (p: T) => R, a: T) => (): R => f(a);
export const ignore = () => {};
export const constant = <T>(v: T) => (): T => v;

export const compose = <R>(...fns: Function[]): ((...args: any[]) => R) =>
  // @ts-ignore
  fns.reduceRight(
    (prevFn, nextFn) =>
      // @ts-ignore
      (...args) => nextFn(prevFn(...args)),
    // @ts-ignore
    (value) => value
  );

export const log = <T>(tag: string, ...args0: any[]) => (x: T): T => (
  console.log(tag, ...args0, x), x
);

export const logAndKeepErr = <T>(tag: string, ...args0: any[]) => (err: Error): Promise<T> => {
  console.error(tag, ...args0, err);
  return Promise.reject(err);
}

export const timeout = <T = any>(time: number, value: T): Promise<T> =>
  new Promise((resolve) => setTimeout(resolve, time, value));

export function getStatusTextClass(
  type: "sale" | "refund" | 'recharge' | 'void' | 'recurrent',
  status: string | null
): [string, string, string] {
  switch (type + status) {
    case "saleA":
    case "recurrentA":
    case 'rechargeA':
      return ["Approved", "approved", "Debit"];
    case "saleV":
    case 'rechargeV':
      return ["Void", "refunded", "Debit"];
    case "refundA":
      return ["Approved", "refunded", "Credit"];
    case 'voidA':
      return ["Void", "refunded", "Credit"];
    case 'voidV':
      return ["Void", "refunded", "Void"];
    case "saleD":
    case "recurrentD":
    case "rechargeD":
      return ["Declined", "declined", "Debit"];
    case "refundD":
    case 'voidD':
      return ["Declined", "declined", "Credit"];
    case 'U':
      return ["Upload", emptyString, emptyString];
    default:
      return ["Incomplete", emptyString, emptyString];
  }

}

export function reduceDuplicates<T extends { [key: string]: any }>(
  xs: T[],
  idAttr: string = "id"
): T[] {
  const keysOfX = xs.reduce(
    (acc, it) => ({ ...acc, [it[idAttr]]: it }),
    {} as IKeysOf<T>
  );
  return Object.values(keysOfX);
}

function downloadLink(href: any, filename: string) {
  const a = document.createElement("a");
  a.href = href;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
  }, 0);
}

function buildTableContentByObjArr(xs: object[], headers: string[], isDispute: Boolean = false): string {
  const headerCells = headers.map((it) => `<th>${it}</th>`).join(emptyString);
  const buildRowCells = (obj: object): string =>
  {
    
    return Object.keys(obj)
    // @ts-ignore
    .map((key) => {
      
      // @ts-ignore
      let value = obj[key];
     if(isDispute){
       if(value?.toString().includes("$")){
         if(value?.toString().includes("-")){
           value = value?.toString().replace("-","")
         }
       }
     }
 
      
      return `<td>${value?.toString()}</td>`})
    .join(emptyString);
  }
  const bodyRows = xs.reduce(
    (acc, it) => `${acc}<tr>${buildRowCells(it)}</tr>`,
    ""
  );
 
  return `<table><thead><tr>${headerCells}</tr></thead><tbody>${bodyRows}</tbody></table>`;
}

function buildCsvByObjArr(xs: object[], headers: string[]): string {
  const buildColls = (obj: object): string =>
    Object.keys(obj)
      // @ts-ignore
      .map((key) => obj[key])
      .join(";");
  const rows = xs.map((it) => buildColls(it)).join("\n");
  return headers.join(";") + "\n" + rows;
}

export function fnCSVReport(maybeContent?: string | undefined) {
  function buildStrFromTable() {
    var csv = [];
    const table = document.getElementById("table-to-export");
    // @ts-ignore
    var rows = table.querySelectorAll("tr");
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++)
        // @ts-ignore
        row.push(cols[j].innerText);
      csv.push(row.join(";"));
    }
    return csv.join("\n");
  }
  const tableStr = maybeContent ?? buildStrFromTable();
  const csvFile = new Blob(["\ufeff", tableStr], { type: "text/plain" });
  const href = window.URL.createObjectURL(csvFile);
  // Download CSV
  downloadLink(href, "data-report.txt");
}

export function exportCSVContent(xs: object[], headers: string[]) {
  const content = buildCsvByObjArr(xs, headers);
  fnCSVReport(content);
}

export function fnExcelReport(content: string | any) {
  
  let table = "table-to-export",
    filename = "excel_data.xls",
    name: string = "Data";
  const uri = "data:application/vnd.ms-excel;base64,",
    template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
    // @ts-ignore
    base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format = function (s: string, c: object) {
      // @ts-ignore
      return s.replace(/{(\w+)}/g, (m, p) => c[p]);
      
    };

  const isContentStr = typeof content === "string";
  // @ts-ignore
  if (!isContentStr) table = document.getElementById(table);
  const ctx = {
    worksheet: name || "Worksheet",
    // @ts-ignore
    table: (isContentStr ? content : table.innerHTML)
    .replace(
      /<br\s*\/?>/gi,
      "\n"
    ),
  };


  const xls64 = uri + base64(format(template, ctx));
 

  downloadLink(xls64, filename);
}

export function exportExcelContent(xs: object[], headers: string[], isDispute: Boolean = false) {
  
  
  
  console.log('exportExcelContent fns.ts line 179 xs: ', xs)
  const tableInnerHtml = buildTableContentByObjArr(xs, headers, isDispute);
  
  fnExcelReport(tableInnerHtml);
}


export function getCCName(creditCardNumber: string): string {
  const cType = payment.fns.cardType(creditCardNumber);
  switch (cType) {
    case "visa":
      return "Visa";
    case "mastercard":
      return "MasterCard";
    case "discover":
      return "Discover";
    case "amex":
      return "American Express";
    case "jcb":
      return "JCB";
    case "dinersclub":
      return "Diners Club";
    default:
      return cType;
  }
}

export const lazyGetSessionClientName = (client: string[] | undefined) => ():
  | string
  | null => (client ? sessionStorage.getItem(JSON.stringify(client)) : null);

export const lazyGetAgentName = (
  uid: string | undefined
) => (): string | null => {
  return uid ? sessionStorage.getItem(uid) : null;
};

export const lazyGetSessionItem = (key: string) => () => sessionStorage.getItem(key);

export function buildQueryStr(
  state: ITranx | undefined,
  clientName: string,
  agentName: string | null,
  subMerchantName: string
): string {
  if (!state) return emptyString;

  const typeOfTranx = state.type ? state.type.toLocaleLowerCase(): state.status;
  const status = (typeOfTranx === "sale" || typeOfTranx === "recharge" || typeOfTranx === "recurrent" || typeOfTranx === "refund") && state.status === "A"
    ? "Approved"
    : state.type === 'void' || state.status === "V"
    ? 'VOID'
    : state.status === "D"
    ? "Declined"
    : "RETURN"

  const createdAt = new Date(
    // @ts-ignore
    (state.createdAt?.seconds ?? state.createdAt?._seconds) * 1000
  ).toLocaleDateString();

  const date = state.entryStatus === 'U' || state.statusRefund && state.statusRefund === 'U' ? moment(state.date).format('M/D/YYYY').toString() : createdAt;
  const newObj = {
    paymentAmount: new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: state.currency ?? "USD",
    }).format(
      (typeOfTranx === "refund" || typeOfTranx === 'void')
        ? state.refundAmount ?? state.paymentAmount
        : state.paymentAmount
    ),
    createdAt: date,
    status: status,
    authCode: state.authCode ?? emptyString,
    currency: state.currency ?? "USD",
    cardNumber: state.cardNumber + "******" + state.ccLast4,
    expDate: state.expDate,
    cardholderName: state.cardholderName,
    descr: state.descr ?? emptyString,
    processor: state.altProcessor ?? state.processor,
    clientName,
    merchantName: subMerchantName,
    invoiceNumber: state.invoiceNumber ?? emptyString,
    origInvoiceNumber: state.origInvoiceNumber ?? emptyString,
    orderNumber: state.orderNumber ?? emptyString,
    agentName,
  };
  // @ts-ignore
  const query = new URLSearchParams(newObj).toString();
  // const voucherTypeTemp = (state.type === "sale" || state.type === "recharge" || state.type === "recurrent") ? "card-authorization-voucher" : "card-refund-voucher";
  
  const voucherType = (typeOfTranx === "sale" || typeOfTranx === "recharge" || typeOfTranx === "recurrent")
    ? "card-authorization-voucher" 
    : state.type === 'void'
    ? "card-void-voucher"
    : "card-refund-voucher";
   
  return `/vouchers/${voucherType}.html?${query}`;
}


export function runPromiseSeq<T>(xs: Promise<T>[]): Promise<T[]> {
  // console.log('runPromiseSeq xs: ', xs)
  return xs.reduce(
    (acc, it) => it.then((res) => acc.then((accRes) => [...accRes, res])),
    Promise.resolve([] as T[])
  );
}

/** source: https://stackoverflow.com/questions/667555/how-to-detect-idle-time-in-javascript-elegantly */
export function onInactivityTime(action: () => any, ms: number) {
  // @ts-ignore
  let time: NodeJS.Timeout;
  window.onload = resetTimer;
  const events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];
  events.forEach((name) => {
    document.addEventListener(name, resetTimer, true);
  });

  function logout() {
    clearTimeout(time);
    action();
  }

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(logout, ms);
  }
}


export const getRefundableAmount = (tranx: ITranx): number =>{

  return tranx.paymentAmount -
   (tranx.refunds?.reduce((acc, it) => acc + it.amount, 0) ?? 0);
}

// @ts-ignore
export const idReduce = (_: T, action: T): T => action;


function isString(value: any) {
	return typeof value === 'string' || value instanceof String;
}
// levenshtein distance
const editDistance = (s1: string, s2: string) => {
  console.log('editDistance s1: ', s1)
  console.log('editDistance s2: ', s2)
  if(!isString(s1) || !isString(s2)) {
    return 0
  }

  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  let costs = new Array();
  for (let i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i == 0)
        costs[j] = j;
      else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue),
              costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0)
      costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

export const  similarity = (s1: string, s2: string) => {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  let longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(`${longerLength}`);
}

export function initCap(s: string | undefined): string {
  return s ? s[0].toUpperCase() + s.slice(1) : emptyString;
}

/**
 *
 * @param adminOfIt expressed as /{clientCode0}/{clientCode}/p/{processor}
 * @returns {processor}
 */
export function cleanProcessor(adminOfIt: string): string {
  const processorIndex = adminOfIt.indexOf("/p/");
  return processorIndex >= 0
    ? adminOfIt.slice(processorIndex + 3)
    : emptyString;
}

export function getAttachmentFile(formName: string = 'payment', fileInputName: string = 'attachment') {
  // @ts-ignore
  const domForm = document.forms[formName] as HTMLFormElement;
  const formData = new FormData(domForm);
  const file = formData.get(fileInputName) as File;
  return file;
}


export function isVoidAvailableAtTime(trxTime: Date) {
  const currentDateStr = trxTime?.toJSON().slice(0, 10);
  const limitDate = new Date(`${currentDateStr}T23:59:59.999-05:00`);
  return limitDate > new Date();
}


export const buildProcessorPath = ([client0, client1, merchantID]: string[]): string =>
  `clients/${client0}/clients/${client1}/processors/${merchantID}`;


export const getSearchEndValue = (val: string): string =>
  val.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1));


/**
 *
 * @param {Date} date
 * @param {number} days
 * @returns {Date}
 */
export function addDays(date: Date, days: number): Date {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function roundDate(timeStamp: Date): Date {
  // @ts-ignore
  timeStamp -= timeStamp % (24 * 60 * 60 * 1000);//subtract amount of time since midnight
  // @ts-ignore
  timeStamp += new Date().getTimezoneOffset() * 60 * 1000;//add on the timezone offset
  return new Date(timeStamp);
}

// /**
//  *
//  * @template T
//  * @param {number} ms
//  * @param {T} value
//  * @returns {Promise<T>}
//  */
// const sleep = <T>(ms: number, value: T): Promise<T> => new Promise(resolve => setTimeout(resolve, ms, value));

// const LOADING_TAG = '%%LOADING%%';

// /**
//  * @async
//  * @template T
//  * @param {string} id
//  * @param {() => Promise<T>} fn
//  * @param {{ defaultTimeout: number }} config
//  * @returns {T}
//  */
// async function centralFetcher<T>(id: string, fn: () => Promise<T>, config = { defaultTimeout: 100, defaultStorage: localStorage }) {
//   const localValue = config.defaultStorage.getItem(id);
//   if (localValue === LOADING_TAG) {
//     await sleep(config.defaultTimeout, null);
//     return centralFetcher(id, fn);
//   }
//   if (localValue === null) {
//     config.defaultStorage.setItem(id, LOADING_TAG);
//     const value = await fn();
//     config.defaultStorage.setItem(id, value);
//     return value;
//   }
//   return localValue;
// }
