import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams
} from 'react-router-dom';
import Login from 'pages/Login';
import ForgotPassword from 'pages/Login/ForgotPassword';
import Payment from 'pages/Payment';
import Report from 'pages/Report';
import DisputesReport from 'pages/Report/DisputesReport'
import Dashboard from 'pages/Dashboard';
import ChangePassword from 'pages/ChangePassword';
import DetailModal from 'pages/Report/DetailModal';
import NewRefundModal from 'pages/Report/NewRefundModal';
import RechargeModal from 'pages/Report/RechargeModal';
import VoidModal from 'pages/Report/VoidModal';
import MainNav from 'pages/MainNav';
import Admin from 'pages/Admin';
import PrivateRoute from 'common/components/PrivateRoute';
import Messages from 'common/components/Messages';
import { MessagesProvider } from 'config/messagesStateContext';
import { GlobalStateProvider } from 'config/globalStateContext';
import PrintableDetail from "pages/Report/PrintableDetail";
import Batch from "pages/Batch";
import UploadModal from "pages/Batch/UploadModal";
import DisputeModal from "pages/Report/DisputeModal";
import DisputeCancellationConfirmModal from "pages/Report/DisputeCancellationConfirmModal";
import DisputeModalReadOnly from "pages/Report/DisputeModalReadOnly";
import DisputesPrivateRoute from './pages/Report/DisputesPrivateRoute'
import ViewBatchTableModal from './pages/Batch/ViewBatchTableModal';
// import ScheduledPaymentTerminal from './pages/Payment/components/ScheduledPaymentFormForPaymentTerminal';
import ScheduledPaymentTerminal from "pages/Payment/components/ScheduledPaymentTerminal";
import ScheduledPayMain from "pages/Report/ScheduledPayMain";
import AddEditFrequency from "pages/Report/components/AddEditFrequency"
import AddScheduledInfo from "pages/Report/components/AddScheduledInfo";
import EditRecurringPay from "pages/Report/components/EditRecurringPay";
import ManualTrxEntry from 'pages/Admin/Migra/ManualEntry';

import Playground from "Playground";
import { useUserPermissions } from "common/hooks/firestore";





function Root(): React.FC<React.ReactElement> {

  // @ts-ignore
  return (
    // @ts-ignore
    <GlobalStateProvider> <MessagesProvider>
        <Router>
          <Switch>
          <Route path="/playground">
              <Playground />
           </Route>
            
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <PrivateRoute path="/">
              <Route path="/change-password">
                <ChangePassword />
              </Route>
              <div className="content-wrapper">
                <MainNav />
              
                <Route exact path="/">
                  <Payment />
                </Route>
                <Route exact path="/home">
                  <Payment />
                </Route>
                <Route path="/scheduledPayment" >
                  <ScheduledPaymentTerminal />
                </Route>
                <Route path="/RecurringPay">
                  <ScheduledPayMain />
                </Route>
                <Route path="/RecurringPay/add-edit-frequency">
                  <AddEditFrequency />
                </Route>
                <Route path="/RecurringPay/add-scheduled-info">
                  <AddScheduledInfo />
                </Route>
                <Route path="/RecurringPay/details/:id">
                  <EditRecurringPay />
                </Route>
                <Route path="/dashboard">
                  <Dashboard />
                </Route>
                <Route path="/:id/print-version">
                  <PrintableDetail />
                </Route>
                <Route exact path="/report/detail/:id">
                  <DetailModal />
                </Route>
                <Route path="/report/detail/:id/print-version">
                  <PrintableDetail />
                </Route>
                <Route path="/report/refund/:id">
                  <NewRefundModal />
                </Route>
                <Route path="/report/recharge/:id">
                  <RechargeModal />
                </Route>
                <Route path="/report/void/:id">
                  <VoidModal />
                </Route>
                <Route path="/report">
                  <Report />
                </Route>
               
                <Route path="/report/dispute">
                  <DisputeModal />
                </Route>
                <Route path="/report/disputeCancellation">
                  <DisputeCancellationConfirmModal />
                </Route>
                <Route path="/report/disputeReadOnly">
                  <DisputeModalReadOnly />
                </Route>
                <DisputesPrivateRoute  path="/disputes"  component={DisputesReport}  exact  />
                <Route path="/report/:id/print-version">
                  <PrintableDetail />
                </Route>
                <Route path="/batch">
                  <BatchNew />
                </Route>
                <Route path="/batch/upload">
                  <UploadModal />
                </Route>
                <Route path="/manual-payments">
                  <ManualTrxEntry />
                </Route>
                <Route path="/super-admin">
                  <Admin />
                </Route>
                <Route path="/batch/BatchTableModal/:batchID">
                  <ViewBatchTableModal />
                </Route>
                <Route path="/refreshReport">
                  <Redirect to='/report' /> 
                </Route>
                
              </div>
            </PrivateRoute>
          </Switch>
          <Messages />
        </Router>
      </MessagesProvider>
    </GlobalStateProvider>
  );
}

//Esto es un force, refactoring luego
const BatchNew = () => {
  const maybeAccess = useUserPermissions();

  return (
    <>
      {maybeAccess ? <Batch /> : <></>}
    </>
  )
}

export default Root;
