import * as React  from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { emptyArray, emptyString, optionsByIdField } from 'common/utils/constants';
import { ICombinedProcessor, IProcessor } from 'types';
import { useMessages } from 'config/messagesStateContext';
import { useGlobalState } from "config/globalStateContext";

import {
  Field,
  Form,
  FormRenderProps, useForm
} from "react-final-form";

interface IFormValueProps {
    name: string,
    freqSelect: string,
    period: string,
    id: string
}

const uuid = () =>
        "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    
interface FQSelectProps {
    selected?: string
}

const FrequencySelect = ({selected}: FQSelectProps) => {
    const [global] = useGlobalState();
    const [freqList, setFreqList] = React.useState<IFormValueProps[]>([])
    const userUID = global?.uid

    React.useEffect(() => {

        firebase.firestore().collection(`scheduled/freqParent/frequencies`)
        .get()
        .then(snap => setFreqList(snap.docs?.map(doc => doc.data() as IFormValueProps)))

    }, [userUID])

   console.log('FrequencySelect ', freqList)

   const myOptions = freqList.map(it => (
        <option key={uuid()} value={it.id} selected={!!selected && it.id === selected ? true : false}>
        {[it.name, it.freqSelect, it.period].filter(Boolean).join(' | ')}
    </option>
   ))

   return (
    // <select
    //   className="form-control merchant-select"
    // >
    <Field name="freqSelect" component="select" className="form-control" id="freqSelect" type="select">
      <option value={emptyString} >{'-- Select Frequency --'}</option>
      {myOptions}
      </Field>
    // </select>
  );

   
}

export default FrequencySelect;