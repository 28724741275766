import * as React from 'react';
import { Form, Field, FormRenderProps } from 'react-final-form';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import { ILogin } from 'types.d';
import { emptyObject, emptyArray, submitingSubscription } from 'common/utils/constants';
import { getMessage, compose, lazyApply } from 'common/utils/fns';
import Maybe from 'common/components/Maybe';

function useLogin() {
  const [error, setError] = React.useState<null | string>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [user, initializing, authError] = useAuthState(firebase.auth());
  const history = useHistory();
  const location = useLocation();
  function onSubmit(values: Partial<ILogin>) {
    const { email, password } = values;
    setError(null);
    setIsLoading(true);
    function relocateSite() {
      // @ts-ignore
      const { from } = location.state ?? emptyObject;
      history.replace(from ? from : "/");
    }
    function onSuccess(userRecord: any) {
      console.log('userRecord', userRecord);
      // @ts-ignore
      localStorage.setItem('email', email);
      relocateSite();
    }
    firebase.auth()
      // @ts-ignore
      .signInWithEmailAndPassword(email, password)
      .then(onSuccess)
      .catch(compose(setError, getMessage))
      .then(lazyApply(setIsLoading, false));
  }
  return {
    error: error ?? authError?.message,
    isLoading: isLoading || initializing,
    onSubmit,
  };
}

function clearCache() {
  if (window.caches) {
    window.caches.keys().then(function (names) {
      for (let name of names) {
        console.log('clearCache cacheName', name);
        caches.delete(name);
      }
    });
  }
}

function Login() {
  const { error, isLoading, onSubmit } = useLogin();
  const email = localStorage.getItem('email');
  React.useEffect(clearCache, emptyArray);
  return (
    <div className="login-wrapper">
      <img src="/assets/images/logos/netpay.svg" alt="Netpay Selfservice" />
      <div className="card">
        <h4>Login</h4>
        <Form
          onSubmit={onSubmit}
          // @ts-ignore
          initialValues={{ email }}
          subscription={submitingSubscription}
        >
          {({ handleSubmit }: FormRenderProps<Partial<ILogin>>) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Email address</label>
                <Field
                  disabled={isLoading}
                  className="form-control"
                  component="input"
                  placeholder="user@client.com"
                  autoComplete="username"
                  type="email"
                  name="email"
                  required
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <Field
                  disabled={isLoading}
                  className="form-control"
                  component="input"
                  type="password"
                  autoComplete="current-password"
                  name="password"
                  required
                />
              </div>
              <Maybe
                component="div"
                visible={!!error}
                className="alert alert-danger"
                role="alert"
              >
                {error}
              </Maybe>
              <div>
                <Maybe
                  component="button"
                  visible={!isLoading}
                  className="btn btn-primary"
                  type="submit"
                >
                  Login
                </Maybe>
                <Maybe
                  component="button"
                  visible={isLoading}
                  type="button"
                  className="btn btn-primary has-loading"
                >
                  <div className="loading-spin-wrapper">
                    <div className="loading-spin">
                      <div>
                      </div>
                    </div>
                  </div>
                </Maybe>
              </div>
              <NavLink to="/forgot-password">Forgot password?</NavLink>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
}

export default Login;
