import React from "react";
import { emptyString, emptyArray } from "common/utils/constants";
import { cleanProcessor } from "common/utils/fns";
import { useGlobalState } from "config/globalStateContext";

function getStrToken(extra: string | undefined, toLowercase: boolean) {
  const value = Date.now().toString(36).slice(0, 8);
  return (toLowercase ? value : value.toUpperCase()) + (extra ?? emptyString);
}

export function usePaymentId(
  extra: string | undefined = undefined,
  toLowercase: boolean = false
): [string, () => void] {
  const [boolToken, setBoolToken] = React.useState<boolean>(false);
  return React.useMemo(
    () => [
      getStrToken(extra, toLowercase),
      () => setBoolToken((token) => !token),
    ],
    [boolToken]
  );
}

const dedupeMatrix = (bigArray: string[][]) => {
  const stringArray = bigArray.map((arr) => JSON.stringify(arr));
  const uniqueStringArray = new Set(stringArray);
  //@ts-ignore
  const uniqueArray = Array.from(uniqueStringArray, JSON.parse);
  return uniqueArray;
};

/**
 *
 * @param processorFilter
 * @returns {[string[], boolean]} [processor[], isAdmin]
 */
export function useUserInfo(
  processorFilter: Record<string, any> | undefined
): [string[][], string[], boolean, string[][], string[][], string[]] {
  const [userData] = useGlobalState();

  // console.log('userData', userData?.access);
  // @ts-ignore
  return React.useMemo(
    () => {
      const merchantFilter =
        processorFilter?.filterBy === "values"
          ? processorFilter.merchant
          : undefined;
      const processors = merchantFilter
        ? [merchantFilter]
        : Array.from(
            new Set(userData?.adminOf?.map(cleanProcessor).filter(Boolean))
          );
      const clients =
        userData?.adminOf
          ?.filter((it) => !it.includes("/p/"))
          .map((it) => it.split("/")) ?? emptyArray;

      const fullPathProcessors =
        userData?.adminOf
          ?.filter((it) => it.includes("/p/"))
          .map((it) => it.split("/"))
          .map((it) => it.filter((j) => j !== "p")) ?? emptyArray;

      const allClients = (
        userData?.adminOf?.map((it) => it.split("/")) ?? emptyArray
      )
        .filter((it) => it.length >= 2) // client0 / client1
        .map(([client0, client1]) => [client0, client1].join("/"));

      const processorsClientTruncated = Array.from(new Set(allClients)).map(
        (it) => it.split("/")
      );

      const adminOf = userData?.adminOf;
      return [
        clients,
        processors,
        userData?.admin ?? false,
        dedupeMatrix(fullPathProcessors),
        processorsClientTruncated,
        adminOf,
      ];
    },
    // @ts-ignore
    [
      userData?.adminOf,
      processorFilter?.merchant,
      processorFilter?.filterBy,
      userData?.admin,
    ]
  );
}
