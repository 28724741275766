import React from 'react';
// @ts-ignore
import payment from "payment";
import { Field, useField } from 'react-final-form';
import {
  formatCVC,
} from "common/utils/card";
import { useGlobalState } from 'config/globalStateContext';
import { IProcessor, ICombinedProcessor, CardTypes } from 'types';
import {
  valueSubsctiption,
  emptyString,
  activeStr,
} from "common/utils/constants";

function getCombinedProcessorSpecific(cProcessor: ICombinedProcessor, gProcessors: IProcessor[]|undefined, cardType: CardTypes): IProcessor|undefined {
  const cc = cProcessor.cc[cardType] || cProcessor.cc.default;
  const merchatNumber = cc ? cProcessor.pt[cc] : undefined;
  return gProcessors?.find(it => it.id === merchatNumber);
}

function getProcessor(processors: IProcessor[]|undefined, gProcessors: IProcessor[]|undefined, cardType: CardTypes, merchantInfo?: string): IProcessor|undefined {
  const maybeProcessor = processors?.find(it => it.id === merchantInfo);
  return maybeProcessor?.type === 'combined'
    ? getCombinedProcessorSpecific(maybeProcessor as ICombinedProcessor, gProcessors, cardType)
    : maybeProcessor;
}

function CVVField() {
  const processorField = useField<string>('merchantInfo');
  const { input } = useField("cardNumber", valueSubsctiption);
  // @ts-ignore
  const cardType: CardTypes = React.useMemo(() => payment.fns.cardType(input.value), [input.value]);
  const [state] = useGlobalState();
  const maybeProcessor = React.useMemo(
    () => getProcessor(state?.processors, state?.gProcessors, cardType, processorField.input?.value),
    [state?.processors, state?.gProcessors, processorField.input?.value]
  );
  return (
    <Field
      component="input"
      className="form-control"
      type="number"
      id="cvv"
      name="cvv"
      maxLength={4}
      format={formatCVC}
      required={!maybeProcessor?.isCVVOptional}
    />
  );
}

export default CVVField;