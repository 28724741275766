import React from 'react';

interface IProps {
  page: number,
  pageSize: number,
  itemsLength: number,
  onBackClick(): void,
  onNextClick(): void,
}

function Pagination(props: IProps) {
  const { page, pageSize, itemsLength, onBackClick, onNextClick } = props;
  return (
    <nav className="nav-page" aria-label="Page navigation example">
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            onClick={onBackClick}
            disabled={!(page > 0)}
          >
            Previous
          </button>
        </li>
        <li>
          <button className="page-link" disabled>
            ({page + 1})
          </button>
        </li>
        <li className="page-item">
          <button
            className="page-link"
            onClick={onNextClick}
            disabled={!(itemsLength >= pageSize)}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
