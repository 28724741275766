import * as React from 'react';

interface IProps {
  children: React.ReactNode,
  title: string,
  onClose(): void,
  name: string,
}

function Modal(props: IProps) {
  const { children, name, onClose, title } = props;
  const [id, ariaLabelledBy] = React.useMemo(() => [`${name}Modal`, `${name}ModalLabel`], [name]);
  return(
    <div
      className="modal scrollable-y fade show"
      id={id}
      tabIndex={-1}
      role="dialog"
      aria-labelledby={ariaLabelledBy}
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={ariaLabelledBy}>{title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
