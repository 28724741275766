import React, {useState, useEffect, useRef} from 'react';
import {
    Field,
    Form,
    FormRenderProps, useForm
  } from "react-final-form";

  import { useLocation, useHistory, Redirect } from 'react-router-dom';

  import firebase from "firebase";

  import { usePaymentId } from '../../../common/hooks/utils';

  import { useGlobalState } from "config/globalStateContext";

  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";


  interface IFormValueProps {
      name: string,
      freqSelect: string,
      period: string,
      retry: string,
      processingDay: string
      id: string
  }

  interface MyFormProps {
     addToTable: (formVal: IFormValueProps) => void
  }

  const uuid = () =>
        "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });

const AddEditFrequency = () => {
    const [period, setPeriod] = useState<string>("");
    const [freqList, setFreqList] = useState<IFormValueProps[]>([])
    const [incomingFreq, setIncomingFreq] = useState<IFormValueProps[]>([])
    const [updateMe, setUpdateMe] = useState<IFormValueProps[]>([])
    const [deleteDoc, setDeleteDoc] = useState<IFormValueProps | undefined>(undefined)
    const didMountRef = useRef(false);

    console.log("incomingFreq", incomingFreq)

    const [global] = useGlobalState();
    const userUID = global?.uid

    const delItem = (myVal: IFormValueProps) => {
        const idx = incomingFreq.findIndex(it => it.id === myVal.id)
        // console.log('addToUpdateList idx', idx)
        let showMe = [...incomingFreq]
        showMe.splice(idx, 1)
        // console.log('addToUpdateList', showMe)
        setIncomingFreq(showMe)
        setDeleteDoc(myVal)
    }

    const addToTable = (formVal: IFormValueProps) => {
        setIncomingFreq([...incomingFreq, formVal])
        setFreqList([...freqList, formVal])
    }

   

    const addToUpdateList = (myVal: IFormValueProps ) => {
        const idx = incomingFreq.findIndex(it => it.id === myVal.id)
        console.log('addToUpdateList idx', idx)
        let showMe = [...incomingFreq]
        showMe.splice(idx, 1, myVal)
        console.log('addToUpdateList', showMe)
        setIncomingFreq(showMe)
        setUpdateMe([...updateMe, myVal])
    }
    const history = useHistory();
    const onClose = history.goBack;
    const updateFreq = () => {
        if(updateMe.length > 0) {
            let tempArr = [...updateMe];
            for (let item of tempArr) {
                console.log(item);
                firebase.firestore().doc(`scheduled/freqParent/frequencies/${item.id}`)
                    .update(item)
            }
        }
    }

    const saveFreq = () => {
       
            let tempArr = [...freqList];
            for (let item of tempArr) {
                console.log(item);
                firebase.firestore().doc(`scheduled/freqParent/frequencies/${item.id}`)
                    .set(item)
            }
            history.goBack()
    }

    const saveBtnHandler = () => {
        updateFreq()
        saveFreq()
    }

    useEffect(() =>{

        firebase.firestore().collection(`scheduled/freqParent/frequencies`)
        .get()
        .then(snap => setIncomingFreq(snap.docs?.map(doc => doc.data() as IFormValueProps)))


    },[])

    useEffect(() => {
        if (didMountRef.current && !!deleteDoc) {
            firebase.firestore().collection(`scheduled/freqParent/frequencies`).doc(`${deleteDoc?.id}`).delete()
            setDeleteDoc(undefined)
        } else {
            didMountRef.current = true;
        }

    }, [deleteDoc])

    



    const rows = incomingFreq.map((it) => <TableItem key={it.id} item={it} delItem={delItem} addToUpdateList={addToUpdateList} />)

    


    const handleOnSubmit = () =>{
        history.push({
          pathname: "/",
          state: {}
        })
      }

    return (
        <div className="modal show">
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content scrollable-y">
            <div className="modal-header">
               <h1> Add/Edit Recurring Payment</h1>
               <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={onClose}
                    >
                <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="modal-body">
                <>
                <MyFreqFrom addToTable={addToTable}/>
                <div className="row">
                    <div className="col-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Frequency Name</th>
                                    <th>Select Frequency Period</th>
                                    <th>Recurrent Frequency</th>
                                    <th>Retries Number</th>
                                    <th>Processing Day</th>
                            
                                    <th>Actions</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                            
                        </table>
                    </div>
                </div>
                </>
            </div>

            <div className="modal-footer">
                <div className="form-buttons">
             
                <button 
                    style={{marginRight:"20px"}}
                    className="btn btn-primary" 
                    onClick={() => saveBtnHandler()}>
                    Save
                </button>
                <button
                    type="reset"
                    className="btn btn-secondary"
                    onClick={onClose}
                >
                    Close
                </button>


            </div>

            </div>

            </div>
            </div>
        </div>
    )

}

export default AddEditFrequency

const MyFreqFrom = ({addToTable} : MyFormProps) => {
    const [fqid, resetToken] = usePaymentId('FQ');

    const handleOnSubmit = () =>{

      }

    return (
        
        <Form 
        onSubmit={handleOnSubmit}
        render={({ handleSubmit, values, form}) => (
        <>
        <form onSubmit={handleSubmit} name="addEditFreq" >
        <div className="row">
            <div className="col-3">
          
            <label htmlFor="name">Frequency Name</label>
            <Field
                component="input"
                name="name"
                className="form-control"
                type="text"
                id="name"
                />
 
            </div>

            <div className="col-3">
            <label htmlFor="freqSelect">Select Frequency Period</label>

                <Field name="freqSelect" component="select" className="form-control" id="freqSelect" type="select">
                      <option value="">--Select Period--</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                </Field>
            </div>

            <div className="col-3">
                <div>
                <label htmlFor="period">Recurring Frequency</label>
                    <Field 
                        component="input"
                        name="period"
                        className="form-control"
                        type="number"
                        id="period"
                        />
                </div>
            </div>

        </div> 

        <div className="row">

        <div className="col-3">

        <div>
                <label htmlFor="retry">Retries Number</label>
                    <Field 
                        component="input"
                        name="retry"
                        className="form-control"
                        type="number"
                        id="retry"
                        />
                </div>
               
            </div>

            <div className="col-3">
            <div>
                <label htmlFor="processingDay">Processing Day</label>
                    <Field 
                        component="input"
                        name="processingDay"
                        className="form-control"
                        type="number"
                        id="processingDay"
                        />
                </div>
                
            </div>

            {/* <div className="col-1"></div> */}

            <div className="col-3">
            <div className="form-buttons">
            
            <button 
                style={{marginTop:"29px"}}
                className="btn btn-primary" 
                onClick={(e) => {
                    console.log('add called', values)
                    let returnedTarget = Object.assign({}, values);
                    returnedTarget["id"] = fqid
                    form.reset()
                    addToTable(returnedTarget as IFormValueProps)
                }}
                >
                Add
            </button>
            </div>
                

            </div>



        </div>
        {/* <pre>{JSON.stringify(values)}</pre> */}


        </form>
        </>
    )}
    />
    )

}

interface ITableItemProps {
     addToUpdateList: (myVal: IFormValueProps) => void
     delItem: (myVal: IFormValueProps) => void
     item: IFormValueProps
}

const TableItem = ({item, addToUpdateList, delItem}: ITableItemProps) => {
    const [toggleEdit, setToggleEdit] = useState(false)
    const [selectVal, setSelectVal] = useState(item.freqSelect)
    const [period, setPeriod] = useState(Number(item.period))
    const [retry, setRetry] = useState(Number(item.retry))
    const [processingDay, setProcessingDay] = useState(Number(item.processingDay))

    const saveChanges = () => {
        if(selectVal !== item.freqSelect || period !== Number(item.period) || retry !== Number(item.retry) || processingDay !== Number(item.processingDay)){
            let returnedTarget = Object.assign({}, item);
            returnedTarget["freqSelect"] = selectVal
            returnedTarget["period"] = period.toString()
            returnedTarget["retry"] = retry.toString()
            addToUpdateList(returnedTarget)
            setToggleEdit(!toggleEdit)
        }
    }
    return(
         !toggleEdit
            ? (
        <tr>
            <td>{item.name}</td>
            <td>{item.freqSelect}</td>
            <td>{item.period}</td>
            <td>{item.retry}</td>
            <td>{item.processingDay}</td>
            <td>
            <FontAwesomeIcon 
                icon={faEdit}
                 style={{fontSize:'20px', marginRight:'20px', color: '#0033ae', cursor: 'pointer'}} 
                 onClick={() => setToggleEdit(!toggleEdit)}
                 />
            <FontAwesomeIcon 
                icon={faTrash} 
                style={{fontSize:'20px', color: '#0033ae', cursor: 'pointer'}} 
                onClick={() => delItem(item)}
                />

            </td>
        </tr>
            ) : (
                <tr>
                    
                    <td>{item.name}</td>
                    <td style={{width:"20%"}}>
                        <select name="freqSelect" 
                                id="freqSelect" 
                                className="form-control" 
                                value={selectVal}
                                onChange={(ev) => setSelectVal(ev.target.value)}>
                            <option value="">--Choose an option--</option>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="yearly">Yearly</option>
                        </select>
                    </td>
                    <td>
                    <input type="number" 
                        name="period" 
                        className="form-control"
                        value={period}
                        onChange={(ev) => setPeriod(Number(ev.target.value))} />

                    </td>
                    <td>
                    <input type="number" 
                        name="retry" 
                        className="form-control"
                        value={retry}
                        onChange={(ev) => setRetry(Number(ev.target.value))} />

                    </td>
                    <td style={{width:"20%"}}>
                    <input type="number" 
                           name="processindDay" 
                           className="form-control"
                           value={processingDay}
                           onChange={(ev) => setProcessingDay(Number(ev.target.value))} />
                    </td>
                    <td>
                    <FontAwesomeIcon 
                        icon={faEdit}
                        style={{fontSize:'20px', marginRight:'20px', color: '#0033ae'}} 
                        onClick={() => saveChanges()}
                    />

                    </td>
             
                </tr>
            )
        
    
    )
    
}