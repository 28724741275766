import * as React from 'react';
import { Form, Field, FormRenderProps } from 'react-final-form';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { useHistory, Link } from 'react-router-dom';
import ErrorMessage from 'common/components/ErrorMessage';
import { compose, getMessage, lazyApply } from 'common/utils/fns';
import { useMessages } from 'config/messagesStateContext';

type IValues = {
  email: string,
}

function ForgotPassword() {
  const [error, setError] = React.useState<undefined|string>(undefined);
  const { addSuccess } = useMessages();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const history = useHistory();
  function onSubmit(values: IValues) {
    setError(undefined);
    setIsLoading(true)
    function onSuccess() {
      addSuccess('An email has been sent to reset your password.');
      history.goBack();
    }
    firebase.auth()
      .sendPasswordResetEmail(values.email)
      // @ts-ignore
      .then(onSuccess, compose(setError, getMessage))
      .then(lazyApply(setIsLoading, false));
  }
  return (
    <div className="login-wrapper">
      <img src="/assets/images/logos/netpay.svg" alt="Isotipo de Caribbean Marketing Services" />
      <div className="card">
        <ErrorMessage text={error} />
        <Link to="/login" onClick={history.goBack}>Go back to login</Link>
        <p>Please enter your email address to recover your password</p>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }: FormRenderProps) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="emailInputRecover">Email address</label>
                <Field
                  component="input"
                  type="email"
                  name="email"
                  className="form-control"
                  id="emailInputRecover"
                  placeholder="user@client.com"
                  required
                  disabled={isLoading}
                />
              </div>
              <button type="submit" disabled={isLoading} className="btn btn-primary">Recover password</button>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
}

export default ForgotPassword;
