import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { Form, Field, FormRenderProps } from "react-final-form";
import { useHistory } from "react-router-dom";

import { emptyString, activeStr, submitingSubscription } from "common/utils/constants";
import { ITranx } from "types";
import Maybe from "common/components/Maybe";
import { useGlobalState } from "config/globalStateContext";
import { usePaymentId } from "common/hooks/utils";
import { useMessages } from "config/messagesStateContext";
import {
  compose,
  getMessage,
  log,
  lazyApply,
  getRefundableAmount,
  scanMyID
} from "common/utils/fns";
import { useProcessorsName, useSubMerchantName } from "common/hooks/firestore";
import { showPrintVersion } from "common/utils/firebase";
import ModalConfirmation from './ModalConfirmation';

interface IProps {
  isModal?: boolean;
  paymentId?: string;
  state: ITranx;
  onClose: () => void;
}

type IRechargeConfirmProps = {
  newInvoiceNumber: string;
  onSubmit: () => void;
  visible: boolean;
  handleClose: () => void;
};

function RechargeConfirmation(props: IRechargeConfirmProps) {
  const mainClassName = `pop-message card refund-confirmation ${
    props.visible ? activeStr : emptyString
  }`;
  return (
    <div className={mainClassName} id="rechargeConfirmation">
      <a className="close dismiss-message" onClick={props.handleClose}>
        <img src="/assets/images/icons/close.svg" alt="" />
      </a>
      <div className="pop-message-content">
        <p>
          {props.newInvoiceNumber} Are you sure you want to recharge this payment?
        </p>
        <button className="btn btn-revoke" onClick={props.onSubmit}>
          Recharge payment
        </button>
      </div>
    </div>
  );
}

interface IFormData {
  rechargeAmount: string,
  rechargeDescription: string;
  invoiceNumber: string,
}

function RechargeInput(props: IProps) {
  const history = useHistory();
  const [paymentId, resetToken] = usePaymentId();
  const [global, _, setLoading] = useGlobalState();
  const loading = !!global?.isLoading;
  const [confirmVisible, setConfirmVisible] = React.useState<boolean>(false);
  const { addError, addSuccess } = useMessages();
  const rechargeableAmount = props.state.paymentAmount;
  const isAzulRecurr = props.state.processorType === 'azul' && props.state.type === "recurrent"
  console.log('RechargeInput ', props.state)
  const [rechargeData, setRechargeData] = React.useState<IFormData>(
    () =>
      ({
        rechargeAmount: rechargeableAmount.toString(),
        rechargeDescription: emptyString,
        invoiceNumber:isAzulRecurr ? scanMyID(props.state.invoiceNumber).nextTransactionID :  paymentId,
      } as IFormData)
  );


  const newInvNum = isAzulRecurr ? scanMyID(props.state.invoiceNumber).nextTransactionID : paymentId
  const keysOfProcessorNames = useProcessorsName();
  const subMerchantName = useSubMerchantName(props.state.client, props.state.processor, keysOfProcessorNames);
  const makeRecharge = (values: IFormData) => () => {
    setConfirmVisible(false);
    const makeRechargeBackend = firebase.functions().httpsCallable("makeRecharge");
    setLoading(true);
    function onSuccess(result: firebase.functions.HttpsCallableResult): ITranx {
      console.log("makeRecharge onSuccess", result);
      addSuccess(result.data.message);
      sessionStorage.setItem("shouldRefreshReport", "yes");
      return result.data.data as ITranx;
    }
    
    const rechargeReq = {
      ...props.state,
      paymentAmount: parseFloat(values.rechargeAmount),
      descr: values.rechargeDescription,
      // origInvoiceNumber: props.state.invoiceNumber,
      invoiceNumber: isAzulRecurr ? scanMyID(props.state.invoiceNumber).nextTransactionID : paymentId,
    } as ITranx;
    console.log('rechargeReq: ', rechargeReq)
    makeRechargeBackend(rechargeReq)
      // timeout(2500, { data: { message: 'Test succesfull.' }} as firebase.functions.HttpsCallableResult)
      .then(onSuccess)
      .then(
        showPrintVersion(
          subMerchantName,
          // @ts-ignore
          history,
          "/report",
          true
        )
      )
      .catch(compose(addError, getMessage, log("makeRechargeAzul error")))
      .then(resetToken)
      .then(lazyApply(setLoading, false));
  };


  
  function onSubmit(values: IFormData) {
    setConfirmVisible(true);
    setRechargeData(values);
  }
  const mainClassName = `refund-details ${
    props?.isModal ? "no-border" : emptyString
  }`;
  const shouldBeVisible =
    (props.state?.type === "sale" || props.state?.type === "recharge" || props.state?.type === "recurrent") &&
    props.state?.status === "A";
  return (
    <>
      <Maybe
        visible={shouldBeVisible}
        component="div"
        className={mainClassName}
      >
        <p>
          Please enter the recharge amount.
        </p>
        <Form onSubmit={onSubmit} subscription={submitingSubscription} initialValues={rechargeData}>
          {({ handleSubmit }: FormRenderProps) => (
            <form onSubmit={handleSubmit}>
              <div className="form-column">
                <div className="form-group">
                  <label htmlFor="invoiceNumber">New invoice number</label>
                  <Field
                    component="input"
                    disabled={loading}
                    required
                    className="form-control"
                    id="invoiceNumber"
                    name="invoiceNumber"
                  />
                </div>
                <div className="form-group" />
              </div>
              <div className="form-column">
                <div className="form-group">
                  <label htmlFor="rechargeAmount">Recharge amount</label>
                  <Field
                    component="input"
                    disabled={loading}
                    type="number"
                    required
                    max={rechargeableAmount}
                    step={0.01}
                    className="form-control"
                    id="rechargeAmount"
                    name="rechargeAmount"
                    placeholder="$"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="rechargeDescription">Recharge reference</label>
                  <Field
                    component="textarea"
                    disabled={loading}
                    className="form-control"
                    id="rechargeDescription"
                    name="rechargeDescription"
                    required
                  />
                </div>
              </div>
              <Maybe
                component="button"
                visible={!loading}
                type="submit"
                className="btn btn-revoke"
              >
                Recharge payment
              </Maybe>
              <Maybe
                component="button"
                visible={loading}
                type="button"
                className="btn btn-revoke has-loading"
              >
                <div className="loading-spin-wrapper">
                  <div className="loading-spin">
                    <div></div>
                  </div>
                </div>
              </Maybe>
            </form>
          )}
        </Form>
      </Maybe>
      <ModalConfirmation
        onSubmit={makeRecharge(rechargeData)}
        visible={confirmVisible}
        newInvoiceNumber={newInvNum}
        operation="Recharge"
        handleClose={() => setConfirmVisible(false)}
      />
    </>
  );
}
export default React.memo(RechargeInput);
