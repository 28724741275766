import * as React from "react";
import RecurrentProcessorSelect from './RecurrentProcessorSelect';
import ProcessorSelect from './ProcessorSelect'
import {useUserPermissions} from "../../common/hooks/firestore";

function ChooseProcessorSelect(){
    const [selected, setSelected] = React.useState(false)
    const maybeAccess = useUserPermissions();
    console.log('ChooseProcessorSelect-maybeAccess:', maybeAccess?.doRecurrents)

    return(
        <div style={{display:'flex', flexDirection:'column'}}>
       {
        maybeAccess?.doRecurrents
        ? 
        <div>
        {/* <input
            type="checkbox"
            onChange={() => setSelected(!selected)}
        /> */}
         {/*Coment solicitud de Juan Erasmos <span style={{paddingLeft:'7px'}}> Is a recurrent transaction</span> */}
        </div>
        : <div></div>
        }
        {selected 
            ? <RecurrentProcessorSelect
                className="form-control merchant-select"
                name="processor"
                required
                />
            : <ProcessorSelect
                className="form-control merchant-select"
                name="processor"
                required
                />
        }
        </div>
    )

}

export default ChooseProcessorSelect