
import React, {useState, useEffect} from 'react';
import {
    Field,
    Form,
    FormRenderProps 
  } from "react-final-form";

  import { useLocation, useHistory, Redirect } from 'react-router-dom';

  import DateRangeWithName from "common/components/DateRangePickerDeux"

  import FrequencySelect from 'pages/Report/components/FrequencySelect';

  // import {useUserInfo} from '../../common/hooks/utils';
  // import {useUserInfo} from '../../../common/hooks/utils';

  interface IPaymentValues {
    name: string;
    email: string;
    sendReceipt: boolean;
    invoiceNumber: string;
    orderNumber: string;
    paymentAmount: number | string;
    cardNumber: string;
    cardholderName: string;
    merchantInfo: string;
    expDate: string;
    cvv: number;
    paymentDescription: string;
    addressOne: string;
    addressTwo: string;
    state: string;
    countrySelect: string;
    zipCode: string;
    citySelect: string;
  }

  interface IBetweenRoutes{
    paymentForm: IPaymentValues
    scheduledForm: IFormValues
  }
  
  const ScheduledPaymentTerminal = () => {

    const { state } = useLocation<IBetweenRoutes>();

    console.log('interRoute', state)

    const history = useHistory();
    // const onClose = history.goBack;
    const onClose = () => {
      history.push({
        pathname: "/",
        state: state
      })
    }

    const handleOnSubmit = () =>{
        history.push({
          pathname: "/",
          state: state
        })
      }

      


  const resetWeekPath = () =>{

  }
  

    return (
        <div className="modal show">
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content scrollable-y">
            <div className="modal-header">
                    <div className="modal-title" id="disputeModalLabel">
                       <h1>{`Set new scheduled payment`}</h1> 
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={onClose}
                            >
                            <span aria-hidden="true">&times;</span>
                            </button>
                </div>
                <div className="modal-body " >

                  <SetScheduledPaymentForm />

                </div>

                {/* <div className="modal-footer">


                   <button
                          type="submit"
                          className="btn btn-primary"
                          style={{marginRight:'15px'}}
                          onClick={handleOnSubmit}
                      >
                          {'Save'}
                      </button>

              <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => resetWeekPath()}
                    >
                     Reset
                </button>
              </div> */}
            </div>
            </div>
        </div>
    )

  }

  export default ScheduledPaymentTerminal;


  interface IFormProps {
    handleOnSubmit: () => void
  }

  interface IFormValues {
    freqSelect: string
    dueDate: string[]
    chargeDay: string
    contact?: string
    email?: string
    sendInvoice?: string

  }

  const SetScheduledPaymentForm = () => {

    const { state } = useLocation<IBetweenRoutes>();
    const [interRoute, setInterRoute] = React.useState<IFormValues>({} as IFormValues)

    const history = useHistory();
    const onClose = history.goBack;

    const handleOnSubmit = (formVals: IFormValues) =>{
        history.push({
          pathname: "/",
          state: {paymentForm: state.paymentForm, scheduledForm: formVals} as IBetweenRoutes
        })
      }


    
    return (
      <Form 
      onSubmit={handleOnSubmit}
      initialValues={state.scheduledForm}
      render={({ handleSubmit, values, form}) => (
        <>
        <form onSubmit={handleSubmit} name="scheduledPayModal" >

        <div className="row">
        <div className="col-3">
                    <label htmlFor="period">Frequency</label>
                          <FrequencySelect />
                </div>
            <div className="col-3">

          
            <label htmlFor="dueDate">Due date</label>
                <div className="form-group form-inline">
                 
                <DateRangeWithName name='dueDate' zindex="" disabled={false}/>
                </div>
          

                
            </div>

            <div className="col-1"></div>
            <div className="col-2">
                    <label htmlFor="scheduledPaymentName">Charge Day</label>
                    <div className="form-icon client-icon"></div>
                    <Field
                      component="input"
                      className="form-control"
                      name="chargeDay"
                      type="text"
                      id="chargeDay"
                    />
                  </div>

        </div>

        <div className="row">
        <div className="col-3">
                    <label htmlFor="contact">Contact</label>
                    <div className="form-icon client-icon"></div>
                    <Field
                      component="input"
                      className="form-control"
                      name="contact"
                      type="text"
                      id="contact"
                    />
                  </div>


                  <div className="col-6">
                    <label htmlFor="email">Email</label>
                    <div className="form-icon client-icon"></div>
                    <Field
                      component="input"
                      className="form-control"
                      name="email"
                      type="text"
                      id="email"
                    />
                  </div>

        </div>

        <div className="row">
            <div style={{marginLeft:"20px"}}></div>
        <label>
                  <Field
                    name="sendInvoice"
                    component="input"
                    type="checkbox"
                    value="ketchup"
                  />{" "}
                  Send invoice
                </label>
        </div>

              <div className="modal-footer">
                  <button
                        type="submit"
                        className="btn btn-primary"
                        style={{marginRight:'15px'}}
                        onClick={() => handleOnSubmit(values)}
                    >
                        {'Save'}
                    </button>

              <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => form.reset(undefined)}
              >
                Reset
              </button>
              </div>

        <pre>{JSON.stringify(values)}</pre>
        </form>

        </>
        )}
        />

    )
  }