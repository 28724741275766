import axios, { AxiosError } from "axios";
import { IFrecuency } from "./models/responses/IFrecuency";
import { IScheduleRequest } from "./models/IScheduleRequest";
import { IServicesResponse } from "./models/IServicesResponse";
import { ISchedule } from "./models/responses/ISchedule";
import { IFindRequest } from "./models/IFindRequest";
import { IStatus } from "./models/responses/IStatus";
import { ITranx } from "types";
import moment from "moment";

export class RecurringPaymentServices {
  //TODOS: crear logica para manejo de errores

  private _urlBEFrecuency = process.env.REACT_APP_FRECUENCYBEURL;

  async GetFrecuenciesList() {
    const resp = await axios.get(
      this._urlBEFrecuency + "/api/frecuencies/list"
    );

    // IFrecuency
    if (resp?.data !== undefined && resp?.data !== null) {
      const data = resp.data as IServicesResponse<IFrecuency[]>;
      return data;
    }

    return resp;
  }

  async GetStatus() {
    try {
      const resp = await axios.get(this._urlBEFrecuency + "/api/status/list");
      if (resp.data !== undefined) {
        const data = resp.data as IServicesResponse<IStatus[]>;
        return data;
      }

      // return resp;
      return null;
    } catch (err) {
      console.error("RecurringPaymentServices > GetStatus", err);
      return null;
    }
  }

  CreateRecurringPayment(request: IScheduleRequest) {
    try {
      console.log("Pagos Recurrentes 1", request);
      //TODO: logica para transacciones declinadas y procesadas
      axios
        .post(this._urlBEFrecuency + "/api/schedule/create", request)
        .then((resp) => {
          console.log("Pagos Recurrentes 2", resp);
          return true;
        })
        .catch((_) => false);
    } catch (err) {
      console.log("CreateRecurringPayment", err);
    }
  }

  async GetSchedulesList(page: Number = 1, limit: Number = 10) {
    try {
      const resp = await axios.get<IServicesResponse<any>>(
        `${this._urlBEFrecuency}/api/schedule/list?page=${page}&limit=${limit}`
      );
      return resp.data;
    } catch (err) {
      // console.log("xxxxxxxx 4", err);
      // return err?.response;
      return null;
    }
  }

  async GetFindSchedules(
    request: IFindRequest,
    page: Number = 1,
    limit: Number = 10
  ) {
    try {
      const resp = await axios.post<IServicesResponse<any>>(
        `${this._urlBEFrecuency}/api/schedule/search?page=${page}&limit=${limit}`,
        request
      );
      return resp.data;
    } catch (err) {
      // console.log("xxxxxxxx 4", err);
      // return err?.response;
      return null;
    }
  }

  async getScheduleId(id: string) {
    try {
      const resp = await axios.get<IServicesResponse<any>>(
        `${this._urlBEFrecuency}/api/schedule/${id}`
      );
      return resp.data;
    } catch (err) {
      return null;
    }
  }

  async editSchedule(id: string, body: any) {
    body.finalDate = moment(body.finalDate).toDate();

    try {
      console.log("xxxxxxxxxxxxxxxxxxxxxxx", body);
      const resp = await axios.put(
        `${this._urlBEFrecuency}/api/schedule/edit/${id}`,
        body
      );
      return resp.data;
    } catch (err: any) {
      return err?.response;
    }
  }

  async getRecords(scheduleId: string, page = "1", limit = "10") {
    try {
      const resp = await axios.get<IServicesResponse<any>>(
        `${this._urlBEFrecuency}/api/schedule/records/${scheduleId}?page=${page}&limit=${limit}`
      );
      return resp.data;
    } catch (err: any) {
      return err?.response;
    }
  }

  async getTransaccionByScheduleId(scheduleId: string) {
    let resp: ITranx[] = [];
    try {
      const transacionsResp = await axios.get(
        `${this._urlBEFrecuency}/api/schedule/transactions/${scheduleId}`
      );

      if (!transacionsResp?.data?.hasError) {
        resp = transacionsResp?.data?.Data as ITranx[];
      }
    } catch (err: any) {
      console.log("getTransaccionByScheduleId", err.message);
    }

    return resp;
  }

  async getlogsByScheduleId(scheduleId: string) {
    let resp: any[] = [];
    try {
      const transacionsResp = await axios.get(
        `${this._urlBEFrecuency}/api/logs/list/${scheduleId}`
      );

      if (!transacionsResp?.data?.hasError) {
        resp = transacionsResp?.data?.Data;
      }
    } catch (err: any) {
      console.log("getTransaccionByScheduleId", err);
      resp = err.responses;
    }

    return resp;
  }

  async skipTranx(scheduleId: string) {
    let resp: any = null;
    try {
      console.log(this._urlBEFrecuency);
      const transacionsResp = await axios.post(
        `${this._urlBEFrecuency}/api/logs/skip/${scheduleId}`
      );

      if (!transacionsResp?.data?.hasError) {
        resp = transacionsResp?.data;
      }
    } catch (err: any) {
      resp = err?.response?.data;
    }

    return resp;
  }

  async retryTranx(scheduleId: string) {
    let resp: any = null;
    try {
      console.log(this._urlBEFrecuency);
      const transacionsResp = await axios.post(
        `${this._urlBEFrecuency}/api/logs/retry/${scheduleId}`
      );

      if (!transacionsResp?.data?.hasError) {
        resp = transacionsResp?.data;
      }
    } catch (err: any) {
      resp = err?.response?.data;
    }

    return resp;
  }

  async getCronJobList(scheduleId: string) {
    const jobs: {
      createdAt: string;
      _id: string;
      status: string;
      lastRun: string;
      next: string;
    }[] = [];

    try {
      const resp = await axios.get<IServicesResponse<any>>(
        `${this._urlBEFrecuency}/api/cronjobs/list/${scheduleId}`
      );

      if (!resp.data?.hasError) {
        for (let i = 0; i < resp.data.Data?.docs.length; i++) {
          const job = resp.data.Data?.docs[i];
          jobs.push({
            _id: job?._id,
            createdAt: job?.createdAt,
            lastRun: job?.lastRun,
            status: job?.status,
            next: job?.next,
          });
        }
      }
    } catch (err) {}

    return jobs;
  }
}
