import * as React from "react";
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { ITranx, IDisputeFormValues, IDisputeData } from 'types';
import { Form, Field, FormRenderProps } from 'react-final-form';
import { getCCName, getAttachmentFile, buildFileName, lazyApply, log, compose, getMessage } from 'common/utils/fns';
import { uploadFile } from "common/utils/firebase";
import { attachmentsFolderName } from "common/utils/constants";

import { useMessages } from "config/messagesStateContext";
import ErrorMessage from "common/components/ErrorMessage";
import firebase from "firebase";

import { FormApi } from "final-form";
import Maybe from "common/components/Maybe"

import { useDownloadURL } from "react-firebase-hooks/storage";
import { usePaymentId } from "../../common/hooks/utils"


import { useGlobalState } from "config/globalStateContext";


import  Tabs  from "./components/Tabs"

import DisputesWithTabs from "./DisputeModalWithTabs"


function DisputeModal(){
    const { state } = useLocation<ITranx>();
    const [userData] = useGlobalState();

    // const hasOldDisputesModelOld = state?.disputeData !== undefined;
    // const hasOldDisputesModel = state?.disputeData !== undefined && state?.disputeDataList.length > 0;
 

    const [url] = useDownloadURL(
        state.disputeData?.fileName
          ? firebase
              .storage()
              .ref(attachmentsFolderName + "/" + state.disputeData.fileName)
          : null
      );

    const cardNum = (state?.cardNumber && (getCCName(state?.cardNumber) !== null)) ? getCCName(state?.cardNumber) : "not a CC" ;
   
   
    const history = useHistory();
    const onClose = history.goBack;
    

    

    const goBackToReport = () =>{
        history.push({pathname:`/refreshReport`})
    }
    // const goBackToReport = () =>{
    //     setTimeout(() => {
    //         history.go(0)
    //     }, 1200);
    //     history.goBack()
    // }

    const { addSuccess, addError, clear } = useMessages();

    const [loading, setIsLoading] = React.useState(false);
    const [canceling, setCenceling] = React.useState(false);

    const [error, setError] = React.useState<string|undefined>(undefined);

    const forgetDispute0 = async () =>{
        const successfullDelete = (result: firebase.functions.HttpsCallableResult) =>{
            addSuccess(result.data.message);
            goBackToReport();
            
        }

        setCenceling(true);

        const deleteDispute = firebase.functions().httpsCallable('deleteDispute');
            deleteDispute({invoiceNumber: state.invoiceNumber})
            .then(successfullDelete)
            .catch(compose(addError, getMessage, log("err while creating a dispute")))

            if(state.disputeData?.fileName){
                firebase.storage().ref(attachmentsFolderName).child(state.disputeData.fileName).delete();
            }


    }

    const forgetDispute2 = async () =>{

        const successfullDelete = (result: firebase.functions.HttpsCallableResult) =>{
            addSuccess(result.data.message);
            goBackToReport()
        }
        const updateDisputeData = {
            client: state.client,
            invoiceNumber: state.invoiceNumber,
            disputeData: undefined
        }
        const updateDispute = firebase.functions().httpsCallable('updateDispute');
        updateDispute(updateDisputeData)
        .then(successfullDelete)
        .catch(compose(addError, getMessage, log("err while creating a dispute")))
        .then(lazyApply(setIsLoading, false))

        if(state.disputeData?.fileName){
            firebase.storage().ref(attachmentsFolderName).child(state.disputeData.fileName).delete();
        }

        

    }

    const forgetDispute = () =>{
        history.push({pathname:"/report/disputeCancellation", state: state})   
    }

  


    const handleOnSubmit = async (values: IDisputeFormValues, form: FormApi<IDisputeFormValues>) =>{
 
    
        const genDispute = firebase.functions().httpsCallable('genDispute');
        const updateDispute = firebase.functions().httpsCallable('updateDispute');
        const file = getAttachmentFile('disputeForm');
    
        
        const myDisputeData = {
            brand: values.brand,
            debitDate: values.debitDate,
            reversalDate: values.reversalDate,
            paymentDate: values.paymentDate,
            caseNumber: values.caseNumber,
            date: values.date,
            type: values.type,
            dueDate: values.dueDate,
            status: values.status,
            reason: values?.reason,
            comments:values?.comments,
            invoiceNumber: state.invoiceNumber,
            createdBy: userData?.uid,
            distype: values?.distype,
            ...(file.name ? { fileName: buildFileName(state.invoiceNumber, file) } : undefined),
        }
        const updateDisputeData = {
            client: state.client,
            invoiceNumber: state.invoiceNumber,
            disputeData: myDisputeData
        }
        setIsLoading(true);
        clear();
        function maybeUploadAttachment(): Promise<string> {
            return file.name
              ? uploadFile(attachmentsFolderName, state.invoiceNumber, file)
              : Promise.resolve("No file to upload");
          }
          function onSuccess(result: firebase.functions.HttpsCallableResult) {
            addSuccess(result.data.message);
            form.reset()
            goBackToReport();
            
            
        }
        function onSuccess2(result: firebase.functions.HttpsCallableResult) {
            addSuccess(result.data.message);
            form.reset()
            onClose();
        }

        updateDispute(updateDisputeData)
        .then(onSuccess)
        .then(maybeUploadAttachment)
        .catch(compose(addError, getMessage, log("err while creating a dispute")))
        .then(lazyApply(setIsLoading, false))

        

    }

   

    return (
        // hasOldDisputesModel ?
        false ?
         <div className="modal show scrollable-y " id="disputeModal" role="form" >
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <div className="modal-title" id="disputeModalLabel">
                       <h1>{`Dispute > ${state.disputeData ? 'Edit dispute' : 'New dispute'}`}</h1> 
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={onClose}
                            >
                            <span aria-hidden="true">&times;</span>
                            </button>
                </div>

       
             <div className="tabs-container">
                 
      
                 <div className="modal-body" >
                                    <Form 
                                        onSubmit={handleOnSubmit}
                                        
                                        initialValues={state.disputeData as IDisputeFormValues }
                                        render={({ handleSubmit, values, form}) => (
                                        <>
                                        <form onSubmit={handleSubmit} name="disputeForm">
                                            <div className="row">
                                            <div className="col">
                                                    <label htmlFor="brand">Brand</label>
                                                    <Field
                                                        name="brand"
                                                        className="form-control"
                                                        component="input"
                                                        id="brand"
                                                        type="text"
                                                        placeholder={cardNum}
                                                        disabled={true}
                                                        
                                                    />
                                            </div>
                                            <div className='col'>
                                                 <label htmlFor="debitDate">Debit date</label>
                
                                                <Field
                                                    name='debitDate'
                                                    component="input"
                                                    type="date"
                                                    className="form-control" 
                                                    id='debitDate'
                                                    
                                                    
                                                />
                                            
                                             </div>
                                             <div className='col'>
                                                 <label htmlFor="reversalDate">Reversal Date </label>
                
                                                <Field
                                                    name='reversalDate'
                                                    component="input"
                                                    type="date"
                                                    className="form-control" 
                                                    id='reversalDate'
                                                      />
                                            
                                             </div>
                                             <div className='col'>
                                                 <label htmlFor="paymentDate">Payment date</label>
                
                                                <Field
                                                    name='paymentDate'
                                                    component="input"
                                                    type="date" 
                                                    className="form-control"
                                                    id='paymentDate' 
                                                    
                                                    />
                                            
                                             </div>
                                            
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="caseNumber">Case # (*)</label>
                                                    <Field
                                                        name='caseNumber'
                                                        type="text" 
                                                        className="form-control"
                                                        id='caseNumber'
                                                        component="input"
                                                        required
                
                                                    />
                                                    
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="date"> Date (*)</label>
                                                    <Field
                                                        name='date'
                                                        type="date"
                                                        className="form-control" 
                                                        id='date' 
                                                        component="input"
                                                        required
                
                                                    />
                                                </div>
                                                <div className="col">
                                                <label htmlFor="type">Operation Type (*)</label>
                                                <Field
                                                     name='type'
                                                     required
                                                >
                                                        {({input, meta}) =>(
                                                            <>
                                                            
                                                            <select className="form-control" id='type' {...input}>
                                                             <option value="unselected" >-- Operation type --</option>
                                                            <option value="C">Chargeback</option>
                                                            <option value="I">Inquiry</option>
                                                            <option value="PA">Pre-arbitration</option>
                                                            <option value="ARBITRATION">Arbitration</option>
                                                            <option value="RETRIEVAL">Retrieval</option>
                
                                                            </select>
                                                            {meta.touched && meta.error && <span style={{color:'red'}}>{meta.error}</span>}
                                                            </>
                                                        )}
                                        
                                                </Field>
                                                </div>
                                                <div className="col">
                                                <label htmlFor="dueDate">Due date (*)</label>
                
                                                    <Field
                                                        name='dueDate'
                                                        type="date" className="form-control" id='dueDate'
                                                        component="input"
                                                        required
                                                    />
                                                </div>
                
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                <label htmlFor="status">Status (*)</label>
                                                <Field
                                                     name='status'
                                                     required
                                                >
                                                        {({input, meta}) =>(
                                                            <>
                                                            
                                                            <select className="form-control" id='status' {...input}>
                                                            <option value="unselected" >-- Dispute status --</option>
                                                            <option value="disputing">Disputing</option>
                                                            <option value="pending">Pending</option>
                                                            <option value="accepted">Accepted</option>
                                                            <option value="closed">Closed</option>
                                                            <option value="lost">LOST</option>
                                                            <option value="won">WON</option>
                
                                                            </select>
                                                            {meta.touched && meta.error && <span style={{color:'red'}}>{meta.error}</span>}
                                                            </>
                                                        )}
                                        
                                                </Field>
                                                </div>
                                                <div className="col-3">
                                                <label htmlFor="distype">Dispute Type</label>
                                                <Field
                                                     name='distype'
                                                     
                                                >
                                                        {({input, meta}) =>(
                                                            <>
                                                            
                                                            <select className="form-control" id='status' {...input}>
                                                            <option value="unselected" >-- Dispute type --</option>
                                                            <option value="RE">Reversal</option>
                                                            <option value="DE">Debit</option>
                                                           
                
                                                            </select>
                                                            {meta.touched && meta.error && <span style={{color:'red'}}>{meta.error}</span>}
                                                            </>
                                                        )}
                                        
                                                </Field>
                                                </div>
                                                {url ?
                                                    <div className="col" style={{display:'flex', flexDirection:'column'}}>
                                                    <label>Download evidence</label>
                                                    <a target="_blank" href={url} className={url ? undefined : "hidden"}>
                                                        <img src="/assets/images/icons/Download.svg" alt="" />
                                                    </a>
                                                    </div>
                                                    : <></>
                                                }
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                <label htmlFor="reason">Reason</label>
                                  
                                                    <Field
                                                        name='reason'
                                                        className="form-control"
                                                        component='textarea'
                                                        id='reason'
                                                        type='text'
                                                        rows='5'
                                                    />
                                                </div>
                                                <div className="col-6">
                                                <label htmlFor="comments">Comments</label>
                                  
                                                    <Field
                                                        name='comments'
                                                        className="form-control"
                                                        component='textarea'
                                                        id='comments'
                                                        type='text'
                                                        rows='5'
                                                    />
                                                </div>
                                            </div>
                
                                            <div className="row">
                                            <fieldset  style={{paddingLeft:'20px'}}>
                                                <div className="form-group has-icon">
                                                
                                                    <label htmlFor="attachment">Upload file (i.e.: evidence)</label>
                                                    <div>
                                                        <img src="/assets/images/icons/Upload.svg" alt="" />
                                                        <input name="attachment" type="file" title="TODO" />
                                                    </div>
                                                </div>
                
                                          
                                               
                                            </fieldset>
                                            
                                            {/* <UploadForm state={state} onClose={onClose}/>  justifyContent:'space-between'*/}
                                            </div>
                                            {/* <pre>{JSON.stringify(values)}</pre> */}
                                            <div style={{display:'flex', flexDirection: `row-reverse`, 
                                                }}>
                                                <button
                                                 className="btn btn-primary"
                                                 onClick={() => form.reset()}
                                                 >
                                                    Reset all
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    style={{marginRight:'15px'}}
                                                >
                                                    {loading ? 'Saving...' : 'Save'}
                                                </button>
                                                
                                                <Maybe component={'div'} style={{marginRight:'15px'}} visible={!!state.disputeData}>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    data-dismiss="modal"
                                                    onClick={() => { forgetDispute() }}
                                                    style={{backgroundColor:'red'}}
                                                    >
                                                    {canceling ?'canceling...' : 'cancel dispute'}
                                            </button>
                                           
                                            </Maybe>
                                                </div>
                                            </form>
                                        </>
                                        
                                        )}
                                    />
                                    
                                </div>
                            
                 
             </div>
 
                <div className="modal-footer">
            
                    <button
                    type="button"
                    className="btn btn-secondary"
         
                    onClick={onClose}
                    >
                    Close
                </button>

                </div>

            </div>
            </div>
        </div>
        : <DisputesWithTabs 
                cardNum={cardNum} 
                disputesList={state.disputesList ?? []} 
                invoiceNumber={state.invoiceNumber} 
                client={state.client}
                paymentAmount={state.paymentAmount}
                disputeDataList={state.disputeDataList ?? []} 
                createdBy={state.createdBy}
                itranx={state}
                />
    )
}

export default  DisputeModal;