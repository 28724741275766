import React, { useState } from 'react';
import {
  Form,
  FormRenderProps,
  Field,
  FieldRenderProps,
  useField
} from "react-final-form";
import { submitingSubscription } from "common/utils/constants";
import ProcessorSelect from "common/components/ProcessorSelect";
import { IAccess } from '../../../types'
import DateRangePickerWithName from '../../../common/components/DateRangePickerDeux'

//@ts-ignore
//import ReactHTMLTableToExcel from "react-html-table-to-excel";


interface IExcelProps {
  excelButtonText: string,
  isExcelLoading: boolean,
  onExcelClick: () => void

}

export interface IDisputesFilters {
  [index: string]:string | undefined,
  filterBy: string,
  invoiceNumber?: string;
  merchant?: string;
  dueDate?: string;
  paymentDate?:string;
  date?:string;
  caseNumber?:string;
  disputeOperationType?:string;
  disputestatus?:string;
  distype?: string;
  disputeID?: string;

}

interface IFormProps {
  initialValues: IDisputesFilters | undefined;
  onSubmit: (values: IDisputesFilters) => void;
  access: IAccess | undefined
  excelProps: IExcelProps;
  loading: boolean;
}

function omitNull(obj: IDisputesFilters) {
  return Object.keys(obj).reduce((result, key) => {
    if(obj[key] !== null) {
       result[key] = obj[key];
    }
    return result;
  }, {} as IDisputesFilters);
}

const handleDisabled = (val: IDisputesFilters, name: string): boolean => {
  return  Object.keys(omitNull(val)).length > 1 && !Object.keys(omitNull(val)).includes(name)
}

function DisputesFilterForm(props: IFormProps) {

  const [disableDisputeDates, setDisableDisputeDates] = useState(false);
  const [disableReversalDates, setDisableReversalDates] = useState(false);
  const [disablePaymentDates, setDisablePaymentDates] = useState(false);
 
  const excel = props.excelProps;
  const [excelButtonText, isExcelLoading, onExcelClick] = [excel.excelButtonText, excel.isExcelLoading, excel.onExcelClick]
  const [newValues, setNewValues] = React.useState<IDisputesFilters>()
  
  
  const inputChange = (e: any, name: String) => {
    
    if(e != null){
      switch (name) {
        case "dueDate":
          setDisableDisputeDates(false);
          setDisablePaymentDates(true);
          setDisableReversalDates(true);
  
          break;
        case "paymentDate":
          setDisableDisputeDates(true);
          setDisablePaymentDates(false);
          setDisableReversalDates(true);
  
          break;
        case "date":
          setDisableDisputeDates(true);
          setDisablePaymentDates(true);
          setDisableReversalDates(false);
        
          break;
      
        default:
          break;
      }
    }else{
      setDisableDisputeDates(false);
      setDisablePaymentDates(false);
      setDisableReversalDates(false);
    }
    
  }
  
  
  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={props.initialValues as IDisputesFilters}
      // subscription={submitingSubscription}
      
    >
      {({ handleSubmit, form, values}: FormRenderProps<IDisputesFilters>) => (
        
        <form
          action="seachByType"
          className="search-by-type"
          onSubmit={handleSubmit}
        >
          {/* {console.log('formValues: ', values )} */}
       
          
           
           <h1>Disputes search & report</h1>
          <div className="type-search">
            
          </div>
          <div className="type-search">
            <div className="form-group">
              
            </div>
            <div className="type-search-content form-two-column">
              <div>
                <div className="form-group form-inline">
                  <label htmlFor="merchant">Merchant</label>
                  <Field name="merchant">
                    {({ input }: FieldRenderProps<string>) => (
                      <ProcessorSelect
                        className="form-control"
                        id="merchant"
                        placeholder="All Merchants"
                        {...input}
                      />
                    )}
                  </Field>

                </div>
                <div className="form-group form-inline">
                <label htmlFor="invoiceNumber">Invoice #</label>
                <Field
                  component="input"
                  name="invoiceNumber"
                  className="form-control"
                  type="text"
                  id="invoiceNumber"
                  //disabled={handleDisabled(values,'invoiceNumber')}
                  
                />
                </div>
                <div className="form-group form-inline" >
                <label htmlFor="disputeDate">Due date</label>
                    {/* <Field
                        name='dueDate'
                        component="input"
                        type="date"
                        className="form-control" 
                        id='dueDate'
                    /> */}
                    <DateRangePickerWithName 
                      name='dueDate' 
                      zindex="big-z-index"  
                      disabled={disableDisputeDates}
                      //onChange={undefined}
                      onChange={inputChange}
                      //disabled={handleDisabled(values,'dueDate' )}
                    />
                </div>
                <div className="form-group form-inline">
                <label htmlFor="paymentDate">Payment date</label>
                    {/* <Field
                        name='paymentDate'
                        component="input"
                        type="date"
                        className="form-control" 
                        id='paymentDate'
                    /> */}
                    <DateRangePickerWithName 
                      name='paymentDate' 
                      zindex="" 
                      disabled={disablePaymentDates}
                      //onChange={undefined}
                      onChange={inputChange}
                      //disabled={handleDisabled(values,'paymentDate' )}
                    />
                </div>
                <div className="form-group form-inline">
                    <label htmlFor="distype">Dispute type</label>
                  <Field name="distype" 
                      component="select" 
                      className="form-control" id="distype" type="select"
                     // disabled={ handleDisabled(values,'distype') }
                      >
                      <option value="" >-- Dispute type --</option>
                       <option value="RE">Reversal</option>
                       <option value="DE">Debit</option>
                       <option value="CL">Claim</option>         
                        <option value="All">All type</option> 
                  </Field>
                  </div>
                
              </div>
              <div className="second-column">
              <div className="form-group form-inline">
                <label htmlFor="reversalDate">Date</label>
                    {/* <Field
                        name='reversalDate'
                        component="input"
                        type="date"
                        className="form-control" 
                        id='reversalDate'
                    /> */}
                    <DateRangePickerWithName 
                      name='date' 
                      zindex=""
                      disabled={disableReversalDates} 
                      onChange={inputChange}
                      //onChange={undefined}
                      //disabled={handleDisabled(values,'reversalDate' )}
                    />
                </div>
                <div className="form-group form-inline">
                    <label htmlFor="disputestatus">Dispute status</label>
                  <Field name="disputestatus" 
                    component="select"
                    className="form-control" 
                    id="disputestatus" 
                    type="select"
                    //disabled={handleDisabled(values,'disputestatus')}
                   >
                  <option value="" >Select status</option>
                    <option value="All">All status</option>
                    {/* <option value="all" >All status</option> */}
                    <option value="disputing">Disputing</option>
                    <option value="pending">Pending</option>
                    <option value="accepted">Accepted</option>
                    <option value="closed">Closed</option>
                    <option value="lost">Lost</option>
                    <option value="won">Won</option>
                    <option value="expired">Expired</option>
                  </Field>
                  </div>

                <div className="form-group form-inline">
                <label htmlFor="caseNumber">Case  #</label>
                <Field
                  component="input"
                  name="caseNumber"
                  className="form-control"
                  type="text"
                  id="caseNumber"
                  //disabled={handleDisabled(values,'caseNumber')}
                />
                </div>
                
                <div className="form-group form-inline">
                    <label htmlFor="disputeOperationType">Operation type</label>
                  <Field name="disputeOperationType" 
                    component="select" 
                    className="form-control" 
                    id="disputeOperationType" 
                    type="select"
                    //disabled={handleDisabled(values,'disputeOperationType')}
                    >
                  <option value="" >Select type</option>
                    <option value="Chargeback">Chargeback</option>
                    <option value="Inquiry">Inquiry</option>
                    <option value="Pre-arbitration">Pre-arbitration</option>
                    <option value="ARBITRATION">Arbitration</option>
                    <option value="RETRIEVAL">Retrieval</option>
                    <option value="All">All status</option>
                  </Field>
                  </div>

                  {/* <div className="form-group form-inline">
                <label htmlFor="disputeID">Dispute ID</label>
                <Field
                  component="input"
                  name="disputeID"
                  className="form-control"
                  type="text"
                  id="disputeID"
                  //disabled={handleDisabled(values,'disputeID')}
                />
                </div> */}
               
              </div>
              
            </div>
            
          </div>
          
         
          <div className="form-buttons">

          {/* <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="export-button btn btn-primary"
            table="table-to-export"
            filename="tablexls"
            sheet="tablexls"
            buttonText="Export Excel"
          /> */}
          {/* <div className="export-button"> */}
          <button className="btn btn-primary" type="submit">
              { props.loading ? 'Loading...' : 'Search' }
          </button>
          <button

            className="export-button btn btn-primary"
            onClick={onExcelClick}
            disabled={isExcelLoading}
          >
            {excelButtonText}
          </button>
          
        {/* </div> */}
            
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={() => {
                //form.reset(undefined)
                form.reset();
                setDisableDisputeDates(false);
                setDisablePaymentDates(false);
                setDisableReversalDates(false);
              }}
            >
              Clear All
            </button>
          </div>
        
        </form>
      )}
    </Form>
  );
}

export default DisputesFilterForm