import { useProcessorsName, useUserPermissions } from "common/hooks/firestore";
import { usePaymentId, useUserInfo } from "common/hooks/utils";
import { buildFileName, compose, getAttachmentFile, getMessage, lazyApply, log } from "common/utils/fns";
import { useGlobalState } from "config/globalStateContext";
import { useMessages } from "config/messagesStateContext";
import { FormApi } from "final-form";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  currencyFormatConfig,
  attachmentsFolderName,
  emptyString,
} from "common/utils/constants";
import Swal from "sweetalert2";
import { showPrintVersion, uploadFile } from "common/utils/firebase";
import { ITranx } from "types";
import firebase from "firebase";
import { IFinalFilter } from "pages/Report/components/FilterForm";
import { Field, FieldRenderProps, FormRenderProps, Form } from "react-final-form";
import CardNumberFlags from "pages/Payment/components/CardNumberFlags";
import ProcessorSelectField from "pages/Payment/components/ProcessorSelectField";
import StatePicker from "pages/Payment/components/StatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Maybe from "common/components/Maybe";
import {  faStopwatch } from "@fortawesome/free-solid-svg-icons";
import {
  formatCreditCardNumber,
  formatExpirationDate,
} from "common/utils/card";
import CountryPicker from "pages/Payment/components/CountryPicker";
import CVVField from "pages/Payment/components/CVVField";
import ProcessorAndTypeSelect from '../components/ProcessorAndTypeSelect';
import ClientSelect from "../components/ClientSelect";
import { IProcessor } from '../../../types';
//@ts-ignore
import payment from "payment";
import moment from "moment";

interface IPaymentValues {
  name: string;
  email: string;
  sendReceipt: boolean;
  invoiceNumber: string;
  orderNumber: string;
  paymentAmount: number | string;
  cardNumber: string;
  cardholderName: string;
  merchantInfo: string;
  expDate: string;
  cvv: number;
  paymentDescription: string;
  addressOne: string;
  addressTwo: string;
  state: string;
  countrySelect: string;
  zipCode: string;
  citySelect: string;
  authorizationCode: string;
  azulOrderId: string;
  bankAuthorizationId: string;
  correlation_id: string;
  date: Date;
}

function useOnSubmitPayment() {

  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSucess, setIsSuccess] = React.useState<boolean>(false);
  const { addSuccess, addError, clear } = useMessages();
  const [state] = useGlobalState();
  const [paymentId, resetToken] = usePaymentId();
  const keysOfProcessorNames = useProcessorsName();
  const [userData] = useGlobalState();

  async function onSubmit(values: IPaymentValues, form: FormApi) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    
    Swal.fire({
      title: '',
      text: 'Loading...',
      imageHeight: 300,
      showCancelButton: false,
      showConfirmButton: false
    })

    const customClient = state?.processors?.find(
      (it) => it.id === values.merchantInfo
    )?.client;
    const file = getAttachmentFile();
    
    const getProcessor = (merchantInfo: string, cardNumber: string) => {
      const cardType = payment.fns.cardType(cardNumber);
      const searchProcessor = userData?.processors?.find((it) => it?.id === merchantInfo);
  
      if(searchProcessor?.type === "combined") {
        
        // @ts-ignore
        if(searchProcessor?.cc?.[cardType] === 'azul') {
          return searchProcessor?.pt?.azul;
        }
        // @ts-ignore
        if(searchProcessor?.cc?.[cardType] === 'trinity') {
          return searchProcessor?.pt?.trinity;
        }
        // @ts-ignore
        if(searchProcessor?.cc?.[cardType] === 'resortcom') {
          return searchProcessor?.pt?.resortcom;
        }

        if(searchProcessor?.cc?.default === "azul") return searchProcessor?.pt?.azul;
        if(searchProcessor?.cc?.default === "trinity") return searchProcessor?.pt?.trinity;
        if(searchProcessor?.cc?.default === "resortcom") return searchProcessor?.pt?.resortcom;
  
      } 

      if(searchProcessor?.type){
        return searchProcessor?.id;
      } 

      // @ts-ignore
      if(searchProcessor?.[cardType] === 'azul') return searchProcessor?.azul;
       // @ts-ignore
      if(searchProcessor?.[cardType] === 'trinity') return searchProcessor?.trinity;
       // @ts-ignore
      if(searchProcessor?.[cardType] === 'resortcom') return searchProcessor?.resortcom;

      if(searchProcessor?.azul || searchProcessor?.trinity || searchProcessor?.resortcom){
  
        if(searchProcessor?.default === "azul") return searchProcessor?.azul
        if(searchProcessor?.default === "trinity") return searchProcessor?.trinity
        if(searchProcessor?.default === "resortcom") return searchProcessor?.resortcom     
      }

    }

    const processor = await firebase.firestore().collection('processors').doc(values.merchantInfo).get();
    const processorData = {
      id: processor.id,
      data: processor.data()
    }
    const { data } = processorData; 

    const tranx = {
      name: values.name,
      email: values.email,
      status: 'A',
      entryStatus: 'U',
      type: 'sale',
      date: values.date ? values.date : `${new Date().getDay()}/${new Date().getMonth()}/${new Date().getFullYear()}`,
      batchId: null,
      currency: 'USD',
      originInvoiceNumber: null,
      authcode: `not-required-${paymentId}`,
      createdBy: `${userData?.displayName}`,
      sendReceipt: !!values.sendReceipt,
      invoiceNumber: values.invoiceNumber,
      orderNumber: values.orderNumber ?? emptyString,
      client: customClient ?? state?.client,
      processor: getProcessor(values.merchantInfo, values.cardNumber),
      expdate: null,
      altProcesor:  data?.type ? (data.type === 'combined' ? processor.id : null) : null,
      processorType: data?.type,
      combined: data?.type ? (data.type === 'combined' ? true : false) : false,
      paymentAmount: parseFloat(values.paymentAmount as string),
      cardholderName: values.cardholderName,
      cardNumber: values.cardNumber.split(' ').join('').slice(0, 6),
      ccLast4: values.cardNumber.split(' ').join('').slice(-5).slice(0, 4),
      expDate: values.expDate,
      ...(values.cvv && { cvv: values.cvv }),
      descr: values.paymentDescription,
      billingAddress: {
        addressOne: values.addressOne,
        country: values.countrySelect,
        state: values.state,
      },
      ...(file.name ? { fileName: buildFileName(paymentId, file) } : undefined),
      resp: {
        AuthorizationCode: values.authorizationCode,
        AzulOrderId: values.azulOrderId,
        bankAuthorizationId: values.bankAuthorizationId,
        correlation_id: values.correlation_id,
        date: values.date,
        CustomOrderId: '',
        DateTime: '',
        ErrorDescription: '',
        IsoCode: '',
        LotNumber: '',
        RRN: '',
        ResponseCode: '',
        ResponseMessage: '',
        Ticket: '',
        currency: '$'

      }
    };

    setIsLoading(true);
    clear();

    function maybeUploadAttachment(): Promise<string> {
      return file.name
        ? uploadFile(attachmentsFolderName, paymentId, file)
        : Promise.resolve("No file to upload");
    }

    console.log(tranx)

    function resetForm() {
      form.change("cardNumber", "");
      form.resetFieldState("cardNumber");
      form.reset();
    }

    function onSuccess(result: firebase.functions.HttpsCallableResult) {
      console.log('resortcom test', result)
      addSuccess(result.data.message);
      // showPrintVersion(
      //   keysOfProcessorNames[values.merchantInfo],
      //   // @ts-ignore
      //   history
      // )(result.data.data as ITranx);
      resetForm();
      setIsSuccess(true)
    }
    
    const manualTrxEntry = firebase.functions().httpsCallable('manualTrxEntry');
    
    manualTrxEntry(tranx)
    .then(onSuccess)
    .then(maybeUploadAttachment)
    .catch( () => { 
      compose(addError, getMessage, log("err")) 
      swalWithBootstrapButtons.close();
  
    })
    .then(() => {
      swalWithBootstrapButtons.close();
      setIsLoading(false)
    })
    .then(resetToken);

    setIsLoading(false)
  }

  return {
    paymentId,
    onSubmit,
    isLoading,
    isSucess
  };
}

interface IFormValues {
  freqSelect: string
  dueDate: string[]
  chargeDay: string
  contact?: string
  email?: string
  sendInvoice?: string

}

interface IBetweenRoutes{
  paymentForm: IPaymentValues
  scheduledForm: IFormValues
}


function ManualTrxEntry() {
  const { onSubmit, isLoading, paymentId, isSucess } = useOnSubmitPayment();
  const [loadingPayment, setLoadingPayment] = React.useState<boolean>(false);
  const [scheduleMsg, setScheduleMsg] = React.useState<string>("schedule not set")
  const [CardType, setCardType] = React.useState('');
  const accessManager = useUserPermissions();
  const [globalThing] = useGlobalState()
  const [userData] = useGlobalState();
  const defaultFilterVal: IFinalFilter = { filterBy: "values",}
  const [clients, processors, isAdmin, fullPathProcessors, processorsClientTruncated] = useUserInfo(defaultFilterVal);
  const [processorType, setProcessorType] = React.useState<string|undefined>(undefined);
  const prequery = {clients:clients, processors:processors, isAdmin:isAdmin, processorsClientTruncated:processorsClientTruncated}
  console.log('clients ===> ', clients)
  console.log('globalThing ===> ', JSON.stringify(globalThing?.client))
  const prequeryInfo = {client1: prequery.clients[1] || [], 
                        client2: prequery.clients[1] || [],
                        isAdmin: prequery.isAdmin,
                        processorsClientTruncated1: prequery.processorsClientTruncated[0],
                        processorsClientTruncated2: prequery.processorsClientTruncated[1],
                        processors: prequery.processors
                      }
                    console.log('prequeryInfo', prequeryInfo)
  console.log('Payment maybeAccess.scheduledPay: ', accessManager?.scheduledPay)

  const  submitFunc = (values: IPaymentValues, form: FormApi<object>) => {
    setLoadingPayment(true);

    onSubmit(values, form)

    setLoadingPayment(false);
  }

  console.log(userData)

  return (
    <div className="main-content payment">
      <section className="title-section">
        <h4>Payment Terminal</h4>
        <div className="payments-methods">
          <img src="/assets/images/payment/visa.png" alt="" />
          <img src="/assets/images/payment/mc.png" alt="" />
          <img src="/assets/images/payment/ae.png" alt="" />
          <img src="/assets/images/payment/discover.png" alt="" />
          <img src="/assets/images/payment/dc.png" alt="" />
          <img src="/assets/images/payment/jcb.png" alt="" />
        </div>
      </section>
      <div className="card">
        <Form
          // @ts-ignore
          onSubmit={submitFunc}
        >
          {({ handleSubmit, values}: FormRenderProps) => (
            <form
              className="payment-form"
              name="payment"
              onSubmit={handleSubmit}
            >
              <fieldset disabled={isLoading}>
                <legend>Card information</legend>
                <pre>
                 </pre>

        <div className="form-group has-icon payment-terminal-paddingBottomFifteenPx" >
                          <label htmlFor="paymentAmount">Amount</label>
                          <div className="form-icon amount-icon"></div>
                          <Field
                            component="input"
                            className="form-control"
                            name="paymentAmount"
                            type="number"
                            id="paymentAmount"
                            step={0.01}
                            required
                          />
                        </div>
                        
                       <div style={{display:'flex'}}>
                          
                          <CardNumberFlags />
            
                       
                       </div>
  
                      <div className="form-group has-icon credit-card-input">
                        <label htmlFor="cardNumber">Card number</label>
                        <div className="form-icon credit-card-icon"></div>
                        <Field
                          component="input"
                          className="form-control"
                          name="cardNumber"
                          id="cardNumber"
                          pattern="[\d| ]{16,22}"
                          format={(value: any) => {
                            setCardType(payment.fns.cardType(value));
                            return formatCreditCardNumber(value);
                          }}
                          required
                        />
                      </div>

                <ProcessorSelectField />
                <div className="form-three-column">
                  <div className="form-group has-icon">
                    <label htmlFor="cardholderName">Cardholder Name</label>
                    <div className="form-icon client-icon"></div>
                    <Field
                      component="input"
                      className="form-control"
                      name="cardholderName"
                      type="text"
                      id="cardholderName"
                    />
                  </div>
                  <div className="form-group has-icon">
                    <label htmlFor="expDate">Exp. Date</label>
                    <div className="form-icon exp-date-icon"></div>
                    <Field
                      component="input"
                      id="expDate"
                      name="expDate"
                      className="form-control mask"
                      format={formatExpirationDate}
                      placeholder="MM/YY"
                      required
                      // pattern="(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|6(?:011|5[0-9][0-9])[0-9]{12})$/)"
                    />
                  </div>
                </div>

                {/*  @ts-ignore */}
                { (userData?.processors?.find((it) => it?.id === values.merchantInfo)?.cc && userData?.processors?.find((it) => it?.id === values.merchantInfo)?.cc[CardType] === 'azul')  && (
                  <>
                    <div className="form-three-column">
                      <div className="form-group has-icon">
                        <label htmlFor="authorizationCode">Authorization Code</label>
                        <Field
                          component="input"
                          className="form-control"
                          name="authorizationCode"
                          type="text"
                          id="authorizationCode"
                        />
                      </div>
                    </div>

                    <div className="form-three-column" >
                      <div className="form-group has-icon">
                        <label htmlFor="azulOrderId">Azul Order Id</label>
                        <Field
                          component="input"
                          className="form-control"
                          name="azulOrderId"
                          type="text"
                          id="azulOrderId"
                        />
                      </div>
                    </div>

                  </>
                )} 

                {/*  @ts-ignore */}
                {userData?.processors?.find((it) => it?.id === values.merchantInfo)?.[CardType] && userData?.processors?.find((it) => it?.id === values.merchantInfo)[CardType] === 'azul'  && (
                  <>
                    <div className="form-three-column">
                      <div className="form-group has-icon">
                        <label htmlFor="authorizationCode">Authorization Code</label>
                        <Field
                          component="input"
                          className="form-control"
                          name="authorizationCode"
                          type="text"
                          id="authorizationCode"
                        />
                      </div>
                    </div>

                    <div className="form-three-column" >
                      <div className="form-group has-icon">
                        <label htmlFor="azulOrderId">Azul Order Id</label>
                        <Field
                          component="input"
                          className="form-control"
                          name="azulOrderId"
                          type="text"
                          id="azulOrderId"
                        />
                      </div>
                    </div>

                  </>
                )} 

                {userData?.processors?.find((it) => it?.id === values.merchantInfo)?.type === 'azul' && (
                  <>
                    <div className="form-three-column">
                      <div className="form-group has-icon">
                        <label htmlFor="authorizationCode">Authorization Code</label>
                        <Field
                          component="input"
                          className="form-control"
                          name="authorizationCode"
                          type="text"
                          id="authorizationCode"
                        />
                      </div>
                    </div>

                    <div className="form-three-column" >
                      <div className="form-group has-icon">
                        <label htmlFor="azulOrderId">Azul Order Id</label>
                        <Field
                          component="input"
                          className="form-control"
                          name="azulOrderId"
                          type="text"
                          id="azulOrderId"
                        />
                      </div>
                    </div>

                  </>
                )}

                  {/*  @ts-ignore */}
                {userData?.processors?.find((it) => it?.id === values.merchantInfo)?.[CardType] && userData?.processors?.find((it) => it?.id === values.merchantInfo)[CardType] === 'resortcom' && (
                    <div className="form-three-column" >
                    <div className="form-group has-icon">
                      <label htmlFor="bankAuthorizationId">Bank Authorization Id</label>
                      <Field
                        component="input"
                        className="form-control"
                        name="bankAuthorizationId"
                        type="text"
                        id="bankAuthorizationId"
                      />
                    </div>
                  </div>
                )}     

                {/*  @ts-ignore */}
                {userData?.processors?.find((it) => it?.id === values.merchantInfo)?.cc && userData?.processors?.find((it) => it?.id === values.merchantInfo)?.cc[CardType] === 'resortcom' && (
                    <div className="form-three-column" >
                    <div className="form-group has-icon">
                      <label htmlFor="bankAuthorizationId">Bank Authorization Id</label>
                      <Field
                        component="input"
                        className="form-control"
                        name="bankAuthorizationId"
                        type="text"
                        id="bankAuthorizationId"
                      />
                    </div>
                  </div>
                )}  

              {(userData?.processors?.find((it) => it?.id === values.merchantInfo)?.type === 'resortcom') && (
                  <div className="form-three-column" >
                  <div className="form-group has-icon">
                    <label htmlFor="bankAuthorizationId">Bank Authorization Id</label>
                    <Field
                      component="input"
                      className="form-control"
                      name="bankAuthorizationId"
                      type="text"
                      id="bankAuthorizationId"
                    />
                  </div>
                </div>
              )}

              {/*  @ts-ignore */}
               {userData?.processors?.find((it) => it?.id === values.merchantInfo)?.cc && userData?.processors?.find((it) => it?.id === values.merchantInfo)?.cc[CardType] === 'trinity'  && (
                   <div className="form-three-column" >
                    <div className="form-group has-icon">
                      <label htmlFor="correlation_id">Correlation id</label>
                      <Field
                        component="input"
                        className="form-control"
                        name="correlation_id"
                        type="text"
                        id="correlation_id"
                      />
                    </div>
                  </div>
                )}

                { (userData?.processors?.find((it) => it?.id === values.merchantInfo)?.type === 'trinity')  && (
                   <div className="form-three-column" >
                    <div className="form-group has-icon">
                      <label htmlFor="correlation_id">Correlation id</label>
                      <Field
                        component="input"
                        className="form-control"
                        name="correlation_id"
                        type="text"
                        id="correlation_id"
                      />
                    </div>
                  </div>
                )}

            {/*  @ts-ignore */}
            { (userData?.processors?.find((it) => it?.id === values.merchantInfo)?.[CardType] && userData?.processors?.find((it) => it?.id === values.merchantInfo)[CardType] === 'trinity')  && (
                   <div className="form-three-column" >
                    <div className="form-group has-icon">
                      <label htmlFor="correlation_id">Correlation id</label>
                      <Field
                        component="input"
                        className="form-control"
                        name="correlation_id"
                        type="text"
                        id="correlation_id"
                      />
                    </div>
                  </div>
              )}
                <div className="form-three-column">
                  <div className="form-group has-icon">
                    <label htmlFor="date">Date</label>
                    <Field
                      component="input"
                      className="form-control"
                      name="date"
                      type="date"
                      id="date"
                      max={moment(new Date()).format('YYYY-MM-DD').toString()}
                    />
                  </div>
                </div>

                <div className="two-column-form">
                  <div className="form-group has-icon">
                    <label htmlFor="invoiceNumber">Invoice number</label>
                    <div className="form-icon invoice-icon"></div>
                    <Field
                      component="input"
                      className="form-control"
                      name="invoiceNumber"
                      id="invoiceNumber"
                      type="text"
                      readOnly
                      defaultValue={paymentId}
                    />
                  </div>
                  <div className="form-group has-icon">
                    <label htmlFor="orderNumber">Account #</label>
                    <div className="form-icon order-icon"></div>
                    <Field
                      component="input"
                      className="form-control"
                      name="orderNumber"
                      id="orderNumber"
                      type="text"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="paymentDescription">Reference</label>
                  <Field
                    component="textarea"
                    className="form-control"
                    id="paymentDescription"
                    name="paymentDescription"
                    rows={3}
                  />
                </div>
              </fieldset>

              <fieldset disabled={isLoading}>
                <legend>Billing address</legend>
                <div className="form-group">
                  <label htmlFor="addressOne">Address 1</label>
                  <Field
                    component="input"
                    className="form-control"
                    type="text"
                    id="addressOne"
                    name="addressOne"
                    placeholder="Address1, Address2, City, Zipcode"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="countrySelect">Country</label>
                  <Field name="countrySelect">
                    {({ input }: FieldRenderProps<string>) => (
                      <CountryPicker 
                        className="form-control"
                        id="countrySelect"
                       
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className="form-group">
                  <label htmlFor="stateSelect">State</label>
                  <Field name="countrySelect">
                    {({ input: cInput }: FieldRenderProps<string>) => (
                      <Field name="state">
                        {({ input }: FieldRenderProps<string>) => (
                          <StatePicker
                            className="form-control"
                            id="stateSelect"
                           
                            country={cInput.value}
                            title="Select a country first"
                            {...input}
                          />
                        )}
                      </Field>
                    )}
                  </Field>
                </div>
              </fieldset>

              <fieldset className="two-column-section" disabled={isLoading}>
                <legend>Client's information</legend>
                <div className="form-group has-icon">
                  <label htmlFor="name">Client name</label>
                  <div className="form-icon client-icon"></div>
                  <Field
                    component="input"
                    className="form-control"
                    name="name"
                    id="name"
                    type="text"
                  />
                </div>
              </fieldset>

              <fieldset disabled={isLoading}>
                <div className="form-group has-icon">
                  <label htmlFor="attachment">Upload file</label>
                  <div>
                    <img src="/assets/images/icons/Upload.svg" alt="" />
                    <input name="attachment" type="file" title="TODO" />
                  </div>
                </div>
              </fieldset>

              <Maybe component="div" visible={false}>
                <div  onClick={() => {}}>
                <FontAwesomeIcon icon={faStopwatch} style={{fontSize:'20px', color: '#0133ad'}} />

                <span style={{color:'black', marginLeft:'5px'}}>Set schedule payment</span>

                </div>

                </Maybe>

                <Maybe component={'span'} visible={false}>
                  <input
                    style={{width: '600px', border: '0'}}
                   value={scheduleMsg}
                  />
               
                </Maybe>

                

              <div className="form-footer">
                <Field name="paymentAmount">
                  {({ input }: FieldRenderProps<number>) => (
                    <Maybe
                      component="button"
                      visible={!isLoading}
                      type="submit"
                      className="btn btn-primary"
                    >
                      { loadingPayment ? "Loading..." : "Save" }
                    </Maybe>
                  )}
                </Field>

                <Maybe
                  component="button"
                  visible={isLoading}
                  className="btn btn-primary has-loading"
                >
                  <div className="loading-spin-wrapper">
                    <div className="loading-spin">
                      <div></div>
                    </div>
                  </div>
                </Maybe>
              </div>

            </form>
          )}
        </Form>
      </div>
    </div>
  );
}

export default ManualTrxEntry;

