import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { Form, Field, FormRenderProps } from "react-final-form";
import { useHistory } from "react-router-dom";

import { emptyString, activeStr, submitingSubscription } from "common/utils/constants";
import { ITranx } from "types";
import Maybe from "common/components/Maybe";
import { useGlobalState } from "config/globalStateContext";
import { usePaymentId } from "common/hooks/utils";
import { useMessages } from "config/messagesStateContext";
import {
  compose,
  getMessage,
  log,
  lazyApply,
  getRefundableAmount,
  timeout,
} from "common/utils/fns";
import { useProcessorsName, useSubMerchantName } from "common/hooks/firestore";
import { showPrintVersion } from "common/utils/firebase";
import ModalConfirmation from './ModalConfirmation';
import moment from "moment";

interface IProps {
  isModal?: boolean;
  paymentId?: string;
  state: ITranx;
  onClose: () => void;
}

interface IFormData {
  refundAmount: string;
  refundDescription: string;
  originalInvoice: string;
  date: Date;
}

function RefundInput(props: IProps) {
  const history = useHistory();
  const [paymentId, resetToken] = usePaymentId();
  const [global, _, setLoading] = useGlobalState();
  const [isManualRefund, setIsManualRefund] = React.useState<boolean>(false)
  const loading = !!global?.isLoading;
  const [confirmVisible, setConfirmVisible] = React.useState<boolean>(false);
  const { addError, addSuccess } = useMessages();
  const refundableAmount = React.useMemo(
    () => getRefundableAmount(props.state),
    [props.state]
  );
  const [refundData, setRefundData] = React.useState<IFormData>(
    () =>
      ({
        refundAmount: refundableAmount.toString(),
        refundDescription: emptyString,
      } as IFormData)
  );
  const keysOfProcessorNames = useProcessorsName();
  const subMerchantName = useSubMerchantName(props.state.client, props.state.processor, keysOfProcessorNames);

  const makeRefundManual = (values: IFormData) =>  () => {
    setConfirmVisible(false);
    const manualRefund = firebase.functions().httpsCallable('manualRefund');
    setLoading(true);

    function onSuccess(result: firebase.functions.HttpsCallableResult): ITranx {
      console.log("makeRefundManual onSuccess", result);
      addSuccess(result.data.message);
      sessionStorage.setItem("shouldRefreshReport", "yes");
      return result.data.data as ITranx;
    }

    const refundReq = {
      ...props.state,
      statusRefund: 'U',
      type: "refund",
      status: "A",
      createdBy: global?.displayName,
      refundAmount: parseFloat(values.refundAmount),
      descr: values.refundDescription,
      origInvoiceNumber: props.state.invoiceNumber,
      invoiceNumber: paymentId,
      originalInvoice: values.originalInvoice,
      date: values.date
    } as ITranx;

    manualRefund(refundReq)
    .then(onSuccess)
    .then(
      (tranx) => history.push("/report")
    )
    .catch(compose(addError, getMessage, log("makeRefundManual error")))
    .then(lazyApply(setLoading, false));


  }

  const makeRefund = (values: IFormData) => () => {
    setConfirmVisible(false);
    const makeRefundAzul = firebase.functions().httpsCallable("makeRefundAzul");
    setLoading(true);
    function onSuccess(result: firebase.functions.HttpsCallableResult): ITranx {
      console.log("makeRefund onSuccess", result);
      addSuccess(result.data.message);
      sessionStorage.setItem("shouldRefreshReport", "yes");
      return result.data.data as ITranx;
    }
    const refundReq = {
      ...props.state,
      statusRefund: "",
      type: props.state.type ? props.state.type : "refund",
      refundAmount: parseFloat(values.refundAmount),
      descr: values.refundDescription,
      origInvoiceNumber: props.state.invoiceNumber,
      invoiceNumber: paymentId,
      // originalInvoice: props.state.invoiceNumber,
      // date: values.date
    } as ITranx;
    makeRefundAzul(refundReq)
    // to test refunddummy data
      // timeout(2500, { data: { message: 'Test succesfull.', data: props.state }} as firebase.functions.HttpsCallableResult)
      .then(onSuccess)
      .then(
        showPrintVersion(
          subMerchantName,
          // @ts-ignore
          history,
          "/report",
          true
        )
      )
      .catch(compose(addError, getMessage, log("makeRefundAzul error")))
      .then(lazyApply(setLoading, false));
  };
  function onSubmit(values: IFormData) {
    setConfirmVisible(true);
    setRefundData(values);
  }
  const mainClassName = `refund-details ${
    props?.isModal ? "no-border" : emptyString
  }`;
  const shouldBeVisible =
    (props.state?.type === "sale" || props.state?.type === "recharge" || props.state?.type === "recurrent") &&
    props.state?.status === "A" &&
    !!global?.access?.refund &&
    refundableAmount > 0;

  return (
    <>
      <Maybe
        visible={shouldBeVisible}
        component="div"
        className={mainClassName}
      >
        <p>
          Please enter the refund amount. It cannot exced the total amount
          processed.
        </p>
        <Form onSubmit={onSubmit} subscription={submitingSubscription} initialValues={refundData}>
          {({ handleSubmit }: FormRenderProps) => (
            <form onSubmit={handleSubmit}>
              <div className="form-column">
                <div className="form-group">
                  <label htmlFor="refundAmount">Refund amount</label>
                  <Field
                    component="input"
                    disabled={loading}
                    type="number"
                    required
                    max={refundableAmount}
                    step={0.01}
                    className="form-control"
                    id="refundAmount"
                    name="refundAmount"
                    placeholder="$"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="refundDescription">Refund reference</label>
                  <Field
                    component="textarea"
                    disabled={loading}
                    className="form-control"
                    id="refundDescription"
                    name="refundDescription"
                    required
                  />
                </div>
              </div>

              { global?.access.manualPayments && (
                <div className="form-column">
                  <div className="form-group" style={{ marginLeft: 20 }}>
                    <input className="form-check-input" type="checkbox" checked={isManualRefund} onChange={ (e) => {
                      setIsManualRefund(!isManualRefund)
                    }}/>
                    <label className="form-check-label">
                      Manual Refund
                    </label>
                  </div>
                </div>
              )}


              { isManualRefund && (
                <div className="form-column">

                  <div className="form-group">
                    <label htmlFor="refundAmount">Date</label>
                      <Field
                        component="input"
                        disabled={loading}
                        type="date"
                        required
                        className="form-control"
                        id="date"
                        name="date"
                        max={moment(new Date()).format('YYYY-MM-DD').toString()}
                    />
                  </div>

                </div>
              )}
              <Maybe
                component="button"
                visible={!loading}
                type="submit"
                className="btn btn-revoke"
              >
                Refund payment
              </Maybe>
              <Maybe
                component="button"
                visible={loading}
                type="button"
                className="btn btn-revoke has-loading"
              >
                <div className="loading-spin-wrapper">
                  <div className="loading-spin">
                    <div></div>
                  </div>
                </div>
              </Maybe>
            </form>
          )}
        </Form>
      </Maybe>
      <ModalConfirmation
        onSubmit={ isManualRefund ? makeRefundManual(refundData) : makeRefund(refundData) }
        visible={confirmVisible}
        newInvoiceNumber={paymentId}
        operation="Refund"
        handleClose={() => setConfirmVisible(false)}
      />
    </>
  );
}
export default React.memo(RefundInput);
